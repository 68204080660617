import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Paper = ({ children, className }) => {
	return (
		 <div className={classnames('paper', className)}>
			 {children}
		 </div>
	)
}

Paper.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string
}

export default Paper
