import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { refresh, storeLocalUser } from '../accueil/loginApi'
import * as messageActions from '../global/component/messages/messageActions'
import ModaleMessages from '../global/component/messages/ModaleMessages'
import BandeauCookies from '../global/cookies/BandeauCookies'
import Footer from '../global/footer/Footer'
import Navigation from '../global/navigation/Navigation'
import * as donneesMetierActions from '../modules/donneesMetierActions'
import './App.scss'
import * as baseActions from './baseActions'

class App extends Component {


	componentWillMount() {
		const { user, location, gotoLogin, recupDonnees, theme } = this.props
		if (!user.authenticated) {
			gotoLogin(location, theme || 'larurale')
		}
		if (window.localStorage.tokenFO) {
			recupDonnees()
		}
		this.refresh()
		// setInterval(() => {
		// 	this.refresh()
		// }, 1700 * 1000)
	}

	componentWillReceiveProps(nextProps) {
		const { theme } = nextProps
		if (!nextProps.user.authenticated) {
			this.props.gotoLogin(nextProps.location, theme)
		}
	}


	refresh() {
		if (this.props.user && this.props.user.token) {
			const refreshAction = this.props.refreshAction
			const token = localStorage.getItem('tokenFO')
			refresh(token).then((json) => {
				if (json) {
					refreshAction(json.token)
					storeLocalUser(json.token)
				}
			})
		}
	}

	render() {
		const { messages, closeModale, children, baseLocation, theme } = this.props

		window.addEventListener('storage', () => {
			// Lorsque le stockage local change, on vérifie que tokenFO est différent de null
			// S'il est null, c'est que l'un des onglets s'est déconnecté, et on reload la page pour retourner sur la mire de connexion
			this.props.refreshAction(localStorage.getItem('tokenFO'))
			if(this.props.user.token === null){
				window.location.reload()
			}
		});

		return (
			<div>
				<Navigation baseLocation={baseLocation} />
				{children}
				<ModaleMessages isOpened={messages.modaleOpened}
				                type={messages.modaleType}
				                titre={messages.modaleTitre}
				                text={messages.modaleTexte}
				                textButton={messages.modaleTexteButton}
				                toggle={closeModale}
				/>
				<BandeauCookies />
				<Footer
					theme={theme}
					isLogged />
			</div>
		)
	}

}

App.propTypes = {
	location: PropTypes.object.isRequired,
	baseLocation: PropTypes.string.isRequired,
	gotoLogin: PropTypes.func.isRequired,
	refreshAction: PropTypes.func.isRequired,
	recupDonnees: PropTypes.func,
	closeModale: PropTypes.func,
	user: PropTypes.object,
	messages: PropTypes.object
}

// TODO mettre un selector ici
const mapStateToProps = (state) => ({
	user: state.user,
	messages: state.messages
})

const actions = {
	refreshAction: baseActions.refreshAction,
	gotoLogin: baseActions.gotoLogin,
	recupDonnees: donneesMetierActions.getDonneesExtranet,
	closeModale: messageActions.closeModale
}
export default connect(mapStateToProps, actions)(App)
