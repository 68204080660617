import React from "react";
import {Button, Col, Row} from "reactstrap";
import {formatDateTime} from "../../utils";



class LigneDeclarationPODashboard extends React.Component{
    render() {
        const {declaration, goToDeclaration} = this.props
            return (
                <Button onClick={()=> goToDeclaration(declaration.declaration)}>
                    <Row className="table-item liste-sinistre">
                        <Col xs="1" className="text-left">
                            <span className="icon icon-file c-light"> </span>
                        </Col>
                        <Col xs="10" className="d-flex flex-column align-items-start">
                            <div style={{marginBottom:8,lineHeight:'20px'}} className="ft-s-allianz-semi-bold ft-s-16 reference">Sinistre du {formatDateTime(declaration.declaration.dateSinistre)} - {declaration.libellePeril}</div>
                            <div style={{marginBottom:4,color:'#5F6368',lineHeight:'16px'}} className="date ft-s-allianz">Déclaré le {formatDateTime(declaration.declaration.dateModification)}</div>
                            <div style={{lineHeight:'16px'}} className="motif">"Pour information" - A confirmer avant le {formatDateTime(declaration.declaration.dateConfirmation)}</div>
                        </Col>
                        <Col xs="1" className="text-right p-0">
                            <span className="icon icon-chevron-right c-light"> </span>
                        </Col>
                    </Row>
                </Button>
            )
    }
}

export default LigneDeclarationPODashboard