import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Button, Col, Row } from 'reactstrap'
import * as configurationSelectors from '../../../config/metier/configurationSelectors'
import * as messageActions from '../../../global/component/messages/messageActions'
import Paper from '../../../global/component/paper/Paper'
import { changeMajosMinosParameters, libellePerilFromValeur, openWindowPdf } from '../../../utils'
import * as contratActions from '../contratActions'
import * as selectors from '../contratSelectors'
import * as tarificateurSelectors from '../tarificateur/tarificateurSelectors'
import EspaceAssureMessages from "../../../config/metier/EspaceAssureMessages"
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

class MesAvantages extends React.Component {

	downloadContrat(numeroContrat) {
		return this.props.downloadContrat(numeroContrat)
			.then((response) => {
				openWindowPdf(response.url, 'Contrat', 'status=0,height=400,width=600,resizable=1')
			})
			.catch(() => {
				this.props.messagesErreur('Consulter la version intégrale du contrat', 'Le service de téléchargement de vos documents est actuellement indisponible. Veuillez réessayer ultérieurement.')
			})
	}
	findCulturesByMajosMinos(valeur) {
		var cultures = ""
		if (this.props.donneesTarificateurCultures.length>0){
			this.props.donneesTarificateurCultures.forEach((culture) => {
				if(culture.perils.length>0){
					culture.perils.forEach((peril) => {
						if(peril.applicationMajoMino.length>0){
							peril.applicationMajoMino.forEach((mm) => {
								if(mm.numeroMajoMino === valeur) {
									cultures = cultures.concat(culture.codeCulture.nom+", ")
								}
							})
						}
					})
				}
			})
		}
		cultures = cultures.substring(0,cultures.length-2)
		return cultures
	}

	render() {
		const { donneesTarificateurMm, majosMinosBO } = this.props
		let hasAvantage = false
		if (Object.getOwnPropertyNames(majosMinosBO).length > 0 && donneesTarificateurMm.length>0) {
			donneesTarificateurMm.forEach((majomino) => {
				if(majosMinosBO[majomino.majoMino.numero] != null && majosMinosBO[majomino.majoMino.numero].active === true) {
					hasAvantage = true
				}
			})
			if (hasAvantage) {
				return (
					<Paper className="mon-contrat-page push-tpl-1 mes-avantages">
						<Row className="push-head">
							<Col>
								<Row style={{marginBottom:42}}>
									<Col className="d-flex align-items-center">
										<div className="title">
											Mes avantages
										</div>
									</Col>
									<Col md={4} sm={12} xs={12}>
										<Button onClick={() => this.downloadContrat(this.props.contratActif)} outline color="light" size="xs" className="btn-block">
											Consulter la version intégrale du contrat
										</Button>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className="m-0 ft-s-14">
											<EspaceAssureMessages id="texte_contrat_avantages"/>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className="push-body m-0 mes-avantages">
							<Col className="content flex-column text-left" xs="12">
								<div className="avantage-content">
									<Row>
										<Col>
											<div className="push-body">
												<Table>
													<Thead>
													<Tr>
														<Th>Avantage</Th>
														<Th>Informations</Th>
													</Tr>
													</Thead>
													<Tbody>
													{donneesTarificateurMm.map((majomino) =>
														<Tr key={`${majomino.majoMino.numero} - ${majomino.majoMino.libelle}`}>
															{(majosMinosBO[majomino.majoMino.numero] != null && majosMinosBO[majomino.majoMino.numero].active === true) && <Td className="font-weight-bolder">{majosMinosBO[majomino.majoMino.numero].libelle}</Td>}
															{(majosMinosBO[majomino.majoMino.numero] != null && majosMinosBO[majomino.majoMino.numero].active === true) && <Td>{changeMajosMinosParameters(majosMinosBO[majomino.majoMino.numero].informations,libellePerilFromValeur(majomino.majoMino.peril),this.findCulturesByMajosMinos(majomino.majoMino.numero))}</Td>}
														</Tr>
													)}
													</Tbody>
												</Table>
											</div>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					</Paper>
				)
			} else return null
		} else return null

	}
}

MesAvantages.propTypes = {
	majosMinosBO: PropTypes.object,
	donneesTarificateur: PropTypes.object,
	donneesTarificateurMm: PropTypes.array,
	donneesTarificateurCultures: PropTypes.array,
	contratActif: PropTypes.string,
	messagesErreur: PropTypes.func,
	downloadContrat: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		majosMinosBO: configurationSelectors.getMajosMinos(state),
		donneesTarificateur: tarificateurSelectors.getDonneesTarificateur(state),
		donneesTarificateurMm: tarificateurSelectors.getDonneesTarificateurMm(state),
		donneesTarificateurCultures: tarificateurSelectors.getDonneesTarificateurCultures(state),
		contratActif: selectors.getContratActif(state)
	}
}
const actions = {
	downloadContrat: contratActions.downloadContrat,
	messagesErreur: messageActions.messagesErreur
}

export default connect(mapStateToProps, actions)(MesAvantages)