import userReducer from '../accueil/userReducer'
import metierReducer from '../modules/reducer'
import messageReducer from '../global/component/messages/messageReducer'
import majosMinosReducer from './majosMinosReducer'
import questionsFrequentesReducer from './questionsFrequentesReducer'
import echeancierReducer from './echeancierReducer'
import notificationsReducer from './notificationsReducer'
import assoNotificationsReducer from './assoNotificationReducer'
import configurationReducer from './metier/configurationReducer'
import libellesReducer from './libellesReducer'
export default {
	user: userReducer(window.localStorage),
	metier: metierReducer,
	messages: messageReducer,
	libelles: libellesReducer,
	majosMinos: majosMinosReducer,
	questionsFrequentes: questionsFrequentesReducer,
	echeancier: echeancierReducer,
    notifications: notificationsReducer,
    assoNotifications: assoNotificationsReducer,
	configuration: configurationReducer
}
