import React from 'react'
import MiniPaper from "../../../global/component/miniPaper/MiniPaper";
import ModificationPreferences from '../modification/ModificationPreferences'
import {connect} from "react-redux";
import PropTypes from "prop-types";
import * as modificationActions from '../modification/modificationActions'
import * as selectors from "../coordonneesSelectors";
import * as messageActions from "../../../global/component/messages/messageActions";

class ComponentPreferences extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            isOpened: false
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.toggleModale = this.toggleModale.bind(this)
    }

    handleSubmit = (values) => {
        return this.props.modifierPreferences(values)
            .then(() => {
                this.props.messagesSuccesBlocked("Mise à jour de vos préférences","Votre demande de modification a bien été enregistrée. Vos préférences ont été mises à jour.","Fermer")
                this.toggleModale()
            })
            .catch(e => {
                this.props.messagesErreurBlocked("Mise à jour de vos préférences","Une erreur est survenue.","Fermer")
                this.toggleModale()
                // throw new SubmissionError(e)
            })
    }
    toggleModale() {
        this.setState(prevState => ({
            isOpened: !prevState.isOpened
        }))
    }

    render() {
        const {isOpened} = this.state
        const {initialConsentement} = this.props
        return (
            <MiniPaper
                titre={"Mes préférences"}
                className="mes-preferences"
                type={"tertiary"}
                sizeTitre={24}
                boutons={[{texte:"Modifier mes préférences",action: ()=>{this.toggleModale()},couleur:"blanc"}]}
                icone={""}>
                    <div className="content">
                        {initialConsentement.consentement === false ?
                        <p style={{lineHeight:'17px',marginBottom:24}}>
                            Vous avez refusé de recevoir des informations commerciales de votre agence.
                        </p> :
                        <p style={{lineHeight:'17px',marginBottom:24}}>
                            Vous avez accepté de recevoir des informations commerciales de votre agence.
                        </p>
                        }
                        <ModificationPreferences consentement={initialConsentement.consentement} isOpened={isOpened} onSubmit={this.handleSubmit} toggle={this.toggleModale}/>
                    </div>
            </MiniPaper>
        )
    }
}

ComponentPreferences.propTypes = {
    modifierPreferences:PropTypes.func,
    initialConsentement: PropTypes.object,
    messagesSuccesBlocked:PropTypes.func,
    messagesErreurBlocked: PropTypes.func

}

const mapStateToProps = (state) => ({
    initialConsentement:{"consentement":!!selectors.getDonneesPerso(state).consentement},
})

const actions = {
    modifierPreferences:modificationActions.modificationPreferences,
    messagesSuccesBlocked:messageActions.messagesSuccesBlocked,
    messagesErreurBlocked:messageActions.messagesErreurBlocked

}

export default connect(mapStateToProps, actions)(ComponentPreferences)