import * as sinistreApi from './sinistreApi'

export const GET_SINISTRE_ERROR = 'GET_SINISTRE_ERROR'
export const GET_SINISTRE = 'GET_SINISTRE'
export const GET_SINISTRE_SUCCES = 'GET_SINISTRE_SUCCES'

export const GET_SINISTRE_OBSERVATION = 'GET_SINISTRE_OBSERVATION'
export const SET_SINISTRE_OBSERVATION_SUCCES = 'SET_SINISTRE_OBSERVATION_SUCCES'

export const GET_LISTE_CULTURES_ERROR = 'GET_LISTE_CULTURES_ERROR'
export const GET_LISTE_CULTURES = 'GET_LISTE_CULTURES'
export const GET_LISTE_CULTURES_SUCCES = 'GET_LISTE_CULTURES_SUCCES'

export const GET_QUITTANCES_INDEMNITES_ERROR = 'GET_QUITTANCES_INDEMNITES_ERROR'
export const GET_QUITTANCES_INDEMNITES = 'GET_QUITTANCES_INDEMNITES'
export const GET_QUITTANCES_INDEMNITES_SUCCES = 'GET_QUITTANCES_INDEMNITES_SUCCES'

export const SET_SINISTRE_ACTIF = 'SET_SINISTRE_ACTIF'

export const getSinistres = () => dispatch => {
    dispatch({type:GET_SINISTRE})
    return new Promise((resolve,reject) => {
        return sinistreApi.getSinistres()
            .then(response => {
                if(response.status === 200) {
                    resolve(response.json())
                } else {
                    reject(response)
                }
            })
    }).then(response => {
        return setSinistres(response)(dispatch)
    })
}

export const getSinistreObservation = (idSinistre) => dispatch => {
    dispatch({type:GET_SINISTRE_OBSERVATION})
    return new Promise((resolve,reject) => {
    return sinistreApi.getSinistreObservation(idSinistre)
        .then (response => {
            if (response.status === 200) {
                resolve(response.json())
            } else {
                reject(response)
            }
        })
    }).then (response =>{
        // return setSinistreObservation(response)(dispatch)
        return response
    })
}

export const setSinistres = (sinistres) => (dispatch) => {
    dispatch({
        type:GET_SINISTRE_SUCCES,
        payload:sinistres,
    })
}

export const setSinistreObservation = (observation) => (dispatch) => {
    dispatch({
        type:SET_SINISTRE_OBSERVATION_SUCCES,
        payload:observation,
    })
}

export const getListeCultures = () => dispatch => {
    dispatch({type:GET_LISTE_CULTURES})
    return new Promise((resolve,reject) => {
        return sinistreApi.getListeCultures()
            .then(response => {
                if(response.status === 200) {
                    resolve(response.json())
                } else {
                    reject(response)
                }
            })
    }).then (response => {
        return setListeCultures(response)(dispatch)
    })
}

export const setListeCultures = (listeCultures) => (dispatch) => {
    dispatch({
        type:GET_LISTE_CULTURES_SUCCES,
        payload: listeCultures
    })
}

export const getQuittancesIndemnites = () => dispatch => {
    dispatch({type:GET_QUITTANCES_INDEMNITES})
    return new Promise((resolve,reject) => {
        return sinistreApi.getQuittancesIndemnites()
            .then(response => {
                if(response.status === 200) {
                    resolve(response.json())
                } else {
                    reject(response)
                }
            })
    }).then (response => {
        return setQuittancesIndemnites(response)(dispatch)
    })
}
export const setQuittancesIndemnites = (listeQuittances) => (dispatch) => {
    dispatch({
        type:GET_QUITTANCES_INDEMNITES_SUCCES,
        payload: listeQuittances
    })
}

export const setSinistreActif = (nSinistre) => (dispatch) => {
    dispatch({
        type:SET_SINISTRE_ACTIF,
        payload: nSinistre
    })
}