import * as api from '../../../modules/contrat/quittancement/quittancementAPI'


export const GET_QUITTANCEMENT= 'GET_QUITTANCEMENT';
export const GET_QUITTANCEMENT_SUCCES = 'GET_QUITTANCEMENT_SUCCES';
export const GET_QUITTANCEMENT_ERROR = 'GET_QUITTANCEMENT_ERROR';


export const getQuittancementExtranet = () => (dispatch) => {
    dispatch({ type: GET_QUITTANCEMENT })
    return new Promise((resolve, reject) => {
        return api.getQuittancement()
            .then(response => {
                if (response.status !== 200) {
                    resolve(response.json())
                } else {
                    resolve(response.json())
                }
            })
    }).then(response => {
        return setAssolement(response)(dispatch)
    }).catch(() => {
        dispatch({ type: GET_QUITTANCEMENT_ERROR })
    })
}

export const setAssolement = (quittancement) => (dispatch) => {
    dispatch({
        type: GET_QUITTANCEMENT_SUCCES,
        payload: quittancement
    })
}
;