import MiniPaper from '../../global/component/miniPaper/MiniPaper'
import React from 'react'
import PropTypes from 'prop-types'
import * as selectors from './coordonneesSelectors'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'


class CoordonneesDashboard extends React.Component {
	constructor(props) {
		super(props)
		this.redirectToTarget = this.redirectToTarget.bind(this)
	}

	redirectToTarget = (path) => {
		this.props.history.push(path)
	}

	render() {
		const { donnees, baseLocation } = this.props
		return (
			<MiniPaper
				titre={'Mes coordonnées'}
				icone={'icon icon-keys'}
				type={'secondary'}
				className={'mes-coordonnees'}
				boutons={[{
					texte: 'Modifier mes données personnelles', action: () => {
						this.redirectToTarget(`${baseLocation}/services/mesCoordonnees`)
					}, couleur: 'blanc'
				}]}
			>
				<div className="content" style={{paddingTop:17,paddingBottom:32}}>
					<div>
						<p style={{marginBottom:18}} className="ft-s-allianz-semi-bold text-uppercase">{donnees.titre} {donnees.nom} {donnees.prenom}</p>
						{donnees.typePm && <p className={'text-uppercase'}>{donnees.representantTitre} {donnees.representantNom} {donnees.representantPrenom}</p>}
						{donnees.sirenSiret && <p className={'text-uppercase'}>SIRET : {donnees.sirenSiret}</p>}
						<p style={{lineHeight:'17px',marginBottom:18}} className={'text-uppercase'}>{donnees.adresseNumero} {donnees.adresseTypeVoie} {donnees.adresseVoie}
							<br/>{donnees.adresseComplement}
							<br/>{donnees.codePostal} {donnees.ville}</p>
						{donnees.mail ?
						<div className="info">
							<span className="icon icon-mail mr-2 c-light"> </span>
							<span className="wording-small">{donnees.mail}</span>
						</div> : null}
						{donnees.telephoneFixe && donnees.telephoneFixe !== "0000000000" ?
						<div className="info">
							<span className="icon icon-phone-o mr-2 c-light"> </span>
							<span className="wording-small">{donnees.telephoneFixe}</span>
						</div> : null}
						{donnees.telephoneMobile && donnees.telephoneMobile !== "0000000000" ?
						<div className="info">
							<span className="icon icon-mobile mr-2 c-light"> </span>
							<span className="wording-small">{donnees.telephoneMobile}</span>
						</div> : null}
					</div>
				</div>
			</MiniPaper>
		)
	}
}

CoordonneesDashboard.propTypes = {
	donnees: PropTypes.object,
	baseLocation: PropTypes.string.isRequired

}


const mapStateToProps = (state) => {
	return {
		donnees: selectors.getDonneesPerso(state)
	}
}
export default compose(
	connect(mapStateToProps),
	withRouter
)(CoordonneesDashboard)