import React from 'react'
import {Modal, Button, CustomInput, ModalBody, ModalFooter, ModalHeader, FormGroup} from "reactstrap";
import PropTypes from "prop-types";
import {Field, reduxForm} from "redux-form";

const renderRadioField = ({ input, type, consentement, valueYes, valueNo, idYes, idNo, meta: { touched, error } }) => {
    return (
        <FormGroup className="m-0">
            <CustomInput {...input} type="radio" id={idYes} value={valueYes} label="Oui" defaultChecked={consentement === valueYes} inline />
            <CustomInput {...input} type="radio" id={idNo} value={valueNo} label="Non" defaultChecked={consentement === valueNo} inline />
        </FormGroup>
    )
}

class ModificationPreferences extends React.Component {

    render() {
        const {consentement, isOpened, handleSubmit, toggle,className} = this.props;
        return(
            <Modal isOpen={isOpened} toggle={toggle} className={className + " small"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader className="margin-header-modale" toggle={toggle}>Modifier mes préférences</ModalHeader>
                    <ModalBody className="margin-body-modale">
                        <div className="ft-s-14" style={{lineHeight:'17px',marginBottom:8}}>
                            J'accepte de recevoir des informations commerciales de mon agence. En aucun cas mon adresse e-mail ne sera cédée à des tiers.
                        </div>
                        <div style={{display: 'flex',justifyContent:'center',marginBottom:30}}>
                            <div>
                                <Field
                                    name="consentement"
                                    component={renderRadioField}
                                    valueYes="true"
                                    valueNo="false"
                                    idYes="consentementOui"
                                    idNo="consentementNon"
                                    consentement={consentement.toString()}
                                />
                            </div>
                        </div>
                        <div className="ft-s-12" style={{lineHeight:'15px'}}>
                            Votre accord vaut pour les offres commerciales du Groupe Allianz en France et ses partenaires pour les services, les produits d'assurance,
                            bancaires et financiers qu'ils distribuent. Le ciblage des offres commerciales peut être automatisé et basé sur des profils de clients ou de prospects.
                        </div>
                    </ModalBody>
                    <ModalFooter className={"d-flex justify-content-center margin-footer-modale"}>
                        <Button outline color="light" className="border-2 w-25" onClick={toggle}>Annuler</Button>
                        <Button color="light" className="w-25" type="submit">Confirmer</Button>
                    </ModalFooter>
                </form>
            </Modal>
        )
    }
}

ModificationPreferences.propTypes = {
    consentement:PropTypes.bool,
    isOpened: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired
}

ModificationPreferences = reduxForm({
	form: 'modificationPreferences',
	enableReinitialize: true,
	destroyOnUnmount: false,
})(ModificationPreferences)

export default ModificationPreferences
