import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Col, Row } from 'reactstrap'
import { compose } from 'recompose'
import * as agenceSelector from '../../agence/agenceSelectors'
import * as messagerieActions from '../messagerieActions'
import FormReponseMessage from './FormReponseMessage'

const ReponseMessage = ({ contrat, objet, agence, cancel, createNewMessage, idDiscussion, setRepondre, setButtonVisible, sendMailMessageToAgence }) => {

	const submitNewMessage = (data) => {
		data.id = idDiscussion
		createNewMessage(data)
			.then(() => {
				data.objet = objet
				data.contrat = contrat
				sendMailMessageToAgence(data)
				setRepondre(false)
				setButtonVisible(true)
			})
	}
	return (
		<Row className={'mb-4'}>
			<Col lg={12} sm={12} md={12}>
				<Row style={{ marginBottom: 29.82 }}>
					<Col lg={12} sm={12} md={12} className={'sousTitre'}>
						À : {agence.nom}
					</Col>
				</Row>
				<Row style={{ marginBottom: 25 }}>
					<Col lg={12} sm={12} md={12}>
						<FormReponseMessage
							onSubmit={submitNewMessage}
							cancel={() => {
								cancel()
							}} />
					</Col>
				</Row>
				<Row>
					<Col lg={12} sm={12} md={12}>
						<hr className={'separationMessage'} />
					</Col>
				</Row>
			</Col>
		</Row>
	)
}
ReponseMessage.propTypes = {
	agence: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {
	return {
		agence: agenceSelector.getDonneesAgence(state)
	}
}
const actions = {
	setMessageActif: messagerieActions.setMessageActif,
	createNewMessage: messagerieActions.createNewMessage,
	sendMailMessageToAgence: messagerieActions.sendMailMessageToAgence
}

export default compose(
	connect(mapStateToProps, actions),
	withRouter
)(ReponseMessage)