import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
import { Col, Collapse, Row } from 'reactstrap'
import { compose } from 'recompose'
import Paper from '../../../global/component/paper/Paper'
import { formatDateTime, formatDecimales, formatMillier } from '../../../utils'
import * as sinistreSelectors from '../sinistreSelectors'

const DecompteDeSinistre = ({ sinistreDecompte, sinistresExerciceActif, exerciceActif }) => {
	const [open, setOpen] = React.useState(true)

	const toggle = () => {
		setOpen(!open)
	}

	let totalSurface = 0
	let totalFranchise = 0
	let totalIndemnite = 0
	if (sinistreDecompte.length !== 0) {
		sinistreDecompte.forEach((decompte) => {
			totalSurface += parseInt(decompte.surface)
			totalFranchise += parseInt(decompte.franchise)
			totalIndemnite += parseInt(decompte.indemnite)
		})
	}

    let listeSinistreDate = ""
    let multipleSinistreDate = false
    Object.values(sinistresExerciceActif).forEach(function(value){
        if (value.declaration.exercice === exerciceActif && value.declaration.typeDeclaration !== "PO" && ((value.sinistre.etatSinistre !== 'S' && !(value.sinistre.etatSinistre === 'D' && value.statutQuittanceIndemnite === 'REG')) && (value.sinistre.etatSinistre === 'D' || value.sinistre.etatSinistre === 'I'))){
            if (listeSinistreDate !== "") {
                multipleSinistreDate = true
            }
            listeSinistreDate = listeSinistreDate + formatDateTime(value.declaration.dateSinistre) + " et du "
        }
    })
    listeSinistreDate = listeSinistreDate.substring(0, listeSinistreDate.length-7)

	return (
		<Paper className="push-tpl-1">
			<Row className="push-head">
				<Col>
					<Row>
						<Col md={12} sm={12} xs={12}>
							<Row onClick={toggle}>
								<Col md={11} sm={11} xs={11} className="d-flex align-items-center">
									<div className="title">
										Décompte de sinistre
									</div>
								</Col>
								<Col md={1} sm={1} xs={1} className="d-flex justify-content-end">
									<span className={open ? 'icon icon-chevron-up c-dark mr-0' : 'icon icon-chevron-down c-dark mr-0'}> </span>
								</Col>
							</Row>
						</Col>
					</Row>
					<Collapse isOpen={open}>
						<Row>
							<Col md={12} sm={12} xs={12} className="mt-4">
								{listeSinistreDate.length > 10 &&
								<Row className="mb-3">
									<Col className="pl-3" md={1} sm={1} xs={1}>
                                             <span
	                                             style={{ fontSize: '32px', color: '#EFBE25' }}
	                                             className={`d-flex justify-content-center align-items-center h-100 icon-info-circle`}
                                             >
                                             </span>
                                        </Col>
                                        <Col md={11} sm={11} xs={11}>
                                            <p className="d-flex align-items-center h-100">
                                                {multipleSinistreDate ? `Ce décompte regroupe les indemnités des sinistres du ${listeSinistreDate}` : `Ce décompte regroupe les indemnités du sinistre du ${listeSinistreDate}`}
                                            </p>
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col md={12} sm={12} xs={12} className="mb-2">Récapitulatif par culture :</Col>
                                </Row>
                                <Row>
                                    <Col md={12} sm={12} xs={12}>
                                        <Table className="tableSuivreSinistre table">
                                            <Thead>
                                                <Tr>
                                                    <Th className="font-weight-bold">Culture</Th>
                                                    <Th className="font-weight-bold text-align-center">Surface sinistrée</Th>
                                                    <Th className="font-weight-bold text-align-center">Franchise</Th>
                                                    <Th className="font-weight-bold text-align-center">Indemnité</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                            {sinistreDecompte
                                                    .map((decompte,index) =>
                                                        <Tr key={index}>
                                                            <Td>{decompte.codeCultureDetail.nom}</Td>
                                                            <Td className="text-align-center">{formatMillier(formatDecimales(decompte.surface,4))} ha</Td>
                                                            <Td className="text-align-center">{formatMillier(decompte.franchise)} €</Td>
                                                            <Td className="text-align-center">{formatMillier(parseInt(decompte.indemnite))} €</Td>
                                                        </Tr>)}
                                            </Tbody>
                                            <tfoot>
                                                <Tr>
                                                    <Td>Totaux</Td>
                                                    <Td className="text-align-center">{formatMillier(formatDecimales(totalSurface,4))} ha</Td>
                                                    <Td className="text-align-center">{formatMillier(totalFranchise)} €</Td>
                                                    <Td className="text-align-center">{formatMillier(totalIndemnite)} €</Td>
                                                </Tr>
                                            </tfoot>
                                        </Table>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Collapse>
                </Col>
            </Row>
        </Paper>
    )
}
DecompteDeSinistre.propTypes = {
	sinistreActif: PropTypes.object,
	exerciceActif: PropTypes.string,
	sinistresExerciceActif: PropTypes.object
}
const mapStateToProps = (state) => ({
	sinistresExerciceActif: sinistreSelectors.getDonneesSinistreContratExerciceSinistreActif(state),
	exerciceActif: sinistreSelectors.getExerciceSinistreActif(state)
})
export default compose(
	connect(mapStateToProps)
)(DecompteDeSinistre)