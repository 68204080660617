import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'reactstrap'
import ModaleMentions from './ModaleMentions'
import ModaleCredits from './ModaleCredits'
import ModaleUtilisationCookies from './ModaleUtilisationCookies'
import ModaleDonnees from './ModaleDonnees'
import ModaleSecurite from './ModaleSecurite'

class Footer extends Component {

	//var modales

	constructor(props) {
		super(props)
		this.state = {
			cookiesOpened: false,
			mentionsOpened: false,
			securiteOpened: false,
			creditsOpened: false,
			donneesOpened: false
		}
		this.toggle = this.toggle.bind(this)

	}

	toggle(typeModale) {
		this.setState(({
			[typeModale]: !this.state[typeModale]
		}))
	}

	render() {
		const { mentionsOpened, securiteOpened, cookiesOpened, donneesOpened, creditsOpened} = this.state
		const {theme } = this.props
		return (
			<div className={`footer ${this.props.isLogged && window.location.pathname.indexOf('inprogress') === -1 && window.location.pathname.indexOf('404') === -1 && 'logged'}`}>
				<Container>
					<Row>
						<Col lg={2} md={3}>
							{theme === "larurale" ?
								<a target="_blank" rel="noopener noreferrer" href="http://larurale.fr">La Rurale</a>
								:
								<a target="_blank" rel="noopener noreferrer" href="http://allianz.fr">Allianz</a>
							}
						</Col>
						<Col lg={2} md={3}>
							<ModaleMentions
								themeRurale = {theme === "larurale"}
								isOpened={mentionsOpened}
								toggle={() => this.toggle('mentionsOpened')}
							/>
						</Col>
						<Col lg={2} md={3}>
							<ModaleSecurite
								themeRurale = {theme === "larurale"}
								isOpened={securiteOpened}
								toggle={() => this.toggle('securiteOpened')}
							/>
						</Col>
						<Col className="border" lg={2} md={3}>
							<ModaleUtilisationCookies
								themeRurale = {theme === "larurale"}
								isOpened={cookiesOpened}
								toggle={() => this.toggle('cookiesOpened')}
							/>
						</Col>
						<Col className="border-0" lg={2} md={4}>
							<ModaleDonnees
								themeRurale = {theme === "larurale"}
								isOpened={donneesOpened}
								toggle={() => this.toggle('donneesOpened')}
							/>
						</Col>
						<Col lg={2} md={4}>
							<ModaleCredits
								isOpened={creditsOpened}
								toggle={() => this.toggle('creditsOpened')}
							/>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

Footer.propTypes = {
	isLogged: PropTypes.bool.isRequired,
	theme: PropTypes.string.isRequired
}

Footer.defaultProps = {
	isLogged: false
}

export default Footer
