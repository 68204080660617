import * as api from '../../../modules/contrat/tarificateur/tarificateurApi'


export const GET_TARIFICATEUR = 'GET_TARIFICATEUR'
export const GET_TARIFICATEUR_SUCCES = 'GET_TARIFICATEUR_SUCCES'
export const GET_TARIFICATEUR_ERROR = 'GET_TARIFICATEUR_ERROR'
export const GET_TARIFICATEUR_HISTORIQUE_SUCCES = 'GET_TARIFICATEUR_HISTORIQUE_SUCCES'
export const SET_TARIFICATEUR_EXERCICE_ACTIF = 'SET_TARIFICATEUR_EXERCICE_ACTIF'


export const getTarificateurExtranet = (isHistorique) => dispatch => {
	dispatch({ type: GET_TARIFICATEUR })
	return new Promise((resolve, reject) => {
		return api.getTarificateur(isHistorique)
			.then(response => {
				if (response.status !== 200) {
					reject(response)
				} else {
					resolve(response.json())
				}
			})
	}).then(response => {
		if(!isHistorique){
			return setTarificateur(response)(dispatch)
		} else {
			return setListTarificateur(response)(dispatch)
		}
	}).catch(() => {
		dispatch({ type: GET_TARIFICATEUR_ERROR })
	})
}

export const setTarificateur = (tarificateur) => (dispatch) => {
	dispatch({
		type: GET_TARIFICATEUR_SUCCES,
		payload: tarificateur
	})
}

export const setListTarificateur = (tarificateur) => (dispatch) => {
	dispatch({
		type: GET_TARIFICATEUR_HISTORIQUE_SUCCES,
		payload: tarificateur
	})
}

export const setTarificateurExerciceActif = (tarificateur) => (dispatch) => {
	dispatch({
		type: SET_TARIFICATEUR_EXERCICE_ACTIF,
		payload: tarificateur
	})
}