import { createSelector } from 'reselect'
import { getContratActif } from '../contratSelectors'
import {getExerciceActifTarificateur} from "../tarificateur/tarificateurSelectors";


const getLocalState = (state) => state.metier.contrat.quittancement

const getQuittancementParContrat = (state) => {
    return getLocalState(state).quittancementParContrat
}

export const getQuittancementContratActif = createSelector(getQuittancementParContrat,getContratActif,
    (quittancementContratActif, contratActif ) => quittancementContratActif[contratActif] || {})

export const getQuittancementContratExerciceActif = createSelector(getQuittancementContratActif,getExerciceActifTarificateur,
    (quittancementContratExerciceActif, exerciceActif ) => quittancementContratExerciceActif[exerciceActif] || {})

export const getQuittancementCultureContratExerciceActif = (state) => getQuittancementContratExerciceActif(state).quittanceCulture || []
