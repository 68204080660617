import React from 'react'
import {Col, FormFeedback, FormText, Label, Row} from 'reactstrap'
import CKEditor from 'ckeditor4-react'

const RenderEditor = ({input, label, info, newMessage, meta: {touched, error}}) => {
    return (
        <Row form className="declaration-sinistre-complement" style={{marginBottom: 22}}>
            <Col>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        {label && <Label className="c-primary ft-s-14">{label}{label === "Objet" &&
                        <b className="c-red"> *</b>}</Label>}
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        {(input.value || newMessage) && <CKEditor
                            type="classic"
                            data={input.value}
                            content={input.value}
                            onChange={ (event) => {
                                    input.onChange(event.editor.getData())
                            }}
                            config={{
                                removePlugins: 'elementspath'
                            }}
                        />}
                        <FormFeedback valid={!(touched && error)} style={{ display: (error && touched) ? 'block' : 'none' }}>
                            <div style={{display: 'flex'}}>
                                <p style={{fontSize: '12px'}}>{error}</p>
                            </div>
                        </FormFeedback>
                        <FormText style={{display: (error && touched) ? 'none' : ''}}>
                            <div style={{display: 'flex'}}>
                                <p style={{fontSize: '12px', width: '85%'}}>{info}</p>
                            </div>
                        </FormText>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
export default RenderEditor