import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const ModalCompteBloque = ({ adresse, openModal, closeModal, history }) => {
	const handleOnClose= useCallback(() => history.push('/'), [history]);

	return <Modal isOpen={openModal} className={'small'}>
		<ModalHeader toggle={handleOnClose}>Inscription sur l'Espace Client</ModalHeader>
		<ModalBody>Votre inscription a bien été enregistrée.
			Dès validation de votre inscription par votre agence, vous recevrez un mail (sur votre adresse : {adresse}) et vous pourrez finaliser l’accès à votre espace Client. </ModalBody>
		<ModalFooter>
			<Link to={`/`}>
				<Button onClick={closeModal}>Fermer</Button>
			</Link>
		</ModalFooter>
	</Modal>
}

export default ModalCompteBloque