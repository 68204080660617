import React from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'reactstrap'
import Form from 'reactstrap/es/Form'

class ModaleDeconnexion extends React.Component {

	render() {
		const { isOpened, toggle, className, onSubmit } = this.props
		return (
				<Modal isOpen={isOpened} toggle={toggle} className={className + ' small'}>
					<Form onSubmit={onSubmit}>
						<ModalHeader>Vous voulez nous quitter ?</ModalHeader>
						<ModalBody>
							<p>Voulez-vous vous déconnecter ?</p>
						</ModalBody>
						<ModalFooter className="d-flex justify-content-center">
							<Button onClick={toggle} outline color="light" size="sm" className="border-radius-4 btn-block w-25 m-1">Annuler</Button>
							<Button type="submit" color="light" size="sm" className="border-radius-4 btn-block w-25 m-1">Confirmer</Button>
						</ModalFooter>
					</Form>
				</Modal>
		)
	}
}

ModaleDeconnexion.propTypes = {
	isOpened: PropTypes.bool,
	toggle: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired
}

export default ModaleDeconnexion