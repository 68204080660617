import React from 'react'
import { Row, Col, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import * as selectors from '../../../accueil/userSelectors'
import { Field, reduxForm } from 'redux-form'
import InputFormModale from '../../../global/component/input/InputFormModale'
import { isEmailValid } from '../../../utils'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const validate = (values) => {
	const errors = {}
	const requiredFields = [
		'email',
		'validEmail'
	]
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = 'Ce champ est obligatoire'
		}
	})
	if (values.email && !isEmailValid(values.email)) {
		errors.email = 'L\'adresse e-mail renseignée est incorrecte.'
	}
	if (values.email !== values.validEmail) {
		errors.validEmail = 'Les adresses e-mail saisies ne sont pas identiques.'
	}
	return errors
}

class ModificationEmailForm extends React.Component {

	render() {
		const { donneesUser, pristine, submitting, handleSubmit, toggle } = this.props
		return (
			<form onSubmit={handleSubmit}>
				<ModalHeader toggle={toggle}>Modifier mon adresse e-mail</ModalHeader>
				<ModalBody>
					<p className="accroche">Attention : cette adresse e-mail est utilisée pour votre connexion et pour
						vos alertes.</p>
					<p className="accroche">Votre adresse e-mail actuelle est : <b>{donneesUser.email}</b></p>
					<div className="form-group">
						<Row>
							<Col>
								<Field
									name="email"
									modale={true}
									component={InputFormModale}
									label="Nouvelle adresse e-mail"
									type="text"
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Field
									name="validEmail"
									modale={true}
									component={InputFormModale}
									type="text"
									label="Confirmez votre nouvelle adresse e-mail"
								/>
							</Col>
						</Row>
					</div>
				</ModalBody>
				<ModalFooter className={'d-flex justify-content-center'}>
					<Button outline color="light" className="border-2 w-25" onClick={toggle}>Annuler</Button>
					<Button color="light" disabled={pristine || submitting} className="w-25" type="submit">Confirmer</Button>
				</ModalFooter>
				<p className="notabene"><b className="c-red">* </b>Champ obligatoire</p>
			</form>
		)
	}
}

ModificationEmailForm.propTypes = {
	donneesUser: PropTypes.object
}

const mapStateToProps = (state) => {
	return {
		donneesUser: selectors.getIdentifiants(state)
	}
}


ModificationEmailForm = connect(mapStateToProps)(ModificationEmailForm)
ModificationEmailForm = reduxForm({
	form: 'modificationEmail',
	validate,
	touchOnBlur: false
})(ModificationEmailForm)


export default ModificationEmailForm
