import * as actions from './sinistreActions'

const initialState = {
    isLoading: false,
}

const sinistresEtDeclarations = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_SINISTRE: {
            return {
                ...state,
                isLoading: true
            }
        }
        case actions.GET_SINISTRE_SUCCES: {
            return {
                ...state,
                isLoading: false,
                listeSinistres: action.payload,
                listeSinistresParNumero: action.payload.reduce((acc, sinistre) => ({
                        ...acc,
                        [sinistre.declaration.idDeclarationSinistre]: sinistre,
                    }), {}),
                sinistresParContrat: action.payload.reduce((acc, sinistre) => (
                    {
                        ...acc,
                        [parseInt(sinistre.declaration.contrat)]: {
                            ...acc[[parseInt(sinistre.declaration.contrat)]],
                            [sinistre.declaration.exercice]: {
                                ...(acc[[parseInt(sinistre.declaration.contrat)]] ? acc[[parseInt(sinistre.declaration.contrat)]][sinistre.declaration.exercice] : {}),
                                [sinistre.declaration.idDeclarationSinistre]: sinistre
                            }
                        },
                    }), {}),
            }
        }
        case actions.GET_SINISTRE_ERROR: {
            return state
        }
        case actions.SET_SINISTRE_ACTIF:{
            return{
                ...state,
                sinistreActif: action.payload.idDeclarationSinistre,
                exerciceActif: action.payload.exercice,
                contratActif: action.payload.contrat
            }
        }
        case actions.SET_SINISTRE_OBSERVATION_SUCCES:{
            return{
                ...state,
                observation: action.payload.reponse
            }
        }
        case actions.GET_LISTE_CULTURES: {
            return{
                ...state,
            }
        }
        case actions.GET_LISTE_CULTURES_SUCCES:{
            return {
                ...state,
                listeCultures:action.payload
            }
        }
        case actions.GET_LISTE_CULTURES_ERROR: {
            return state
        }
        default:
            return state
    }
}

export default sinistresEtDeclarations