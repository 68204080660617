import * as agenceApi from './agenceApi'
import { formatVille } from '../../utils'
export const GET_AGENCE_ERROR = 'GET_AGENCE_ERROR'
export const GET_AGENCE = 'GET_AGENCE'
export const GET_AGENCE_SUCCES = 'GET_AGENCE_SUCCES'
export const GET_MAP_SUCCES = 'GET_MAP_SUCCES'
export const GET_MAP_ERROR = 'GET_MAP_ERROR'
export const GET_MAP = 'GET_MAP'

export const getAgence = () => dispatch => {
    dispatch({type:GET_AGENCE})
    return new Promise((resolve,reject) => {
        return agenceApi.getAgence()
            .then(response => {
                if(response.status === 200) {
                    resolve(response.json())
                } else {
                    reject(response)
                }
            })
    }).then(response => {
        return setAgence(response.agence)(dispatch)
    })
}

export const getMap = (ville,codePostal) => dispatch => {
    dispatch({type:GET_MAP})
    return new Promise((resolve,reject) => {
        return agenceApi.getMap(formatVille(ville),codePostal)
            .then(response => {
                if(response.status === 200) {
                    resolve(response.json())
                } else {
                    reject(response)
                }
            })
    }).then(response => {
	        return setMap(response[0]['lon'],response[0]['lat'])(dispatch)
    })
}

export const setAgence = (agence) => (dispatch) => {
    dispatch({
        type:GET_AGENCE_SUCCES,
        agence
    })
}
export const setMap = (longitude,latitude) => (dispatch) => {
    dispatch({
        type:GET_MAP_SUCCES,
        longitude,
        latitude
    })
}
