import React from 'react'
import {Input, FormFeedback, Col, Row, FormText} from 'reactstrap';

const InputLogin = ({marge='mt-4',input,placeholder, label, type, info,name, meta: {touched, error}}) => {
	return (
		<Row form className={marge}>
			<Col md={12}>
				<div>
					<Input {...input} name={name} placeholder={placeholder} invalid={!!(touched && error)} style={{width: '100%', backgroundImage:!!(touched && error) ? 'url("")':'' }} type={type}  />
					<FormFeedback valid={!(touched && error)}>
						<div style={{display: 'flex'}}>
							<p style={{fontSize: '12px'}}>{error}</p>
						</div>
					</FormFeedback>
					<FormText style={{ display: (error && touched) ? 'none' : '' }}>
						<div style={{ display: 'flex'}}>
							<p style={{ fontSize: '12px', width: '85%' }}>{info}</p>
						</div>
					</FormText>
				</div>
			</Col>
		</Row>
	)
}

export default InputLogin
