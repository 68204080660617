import React from "react";
import {Row,Col} from "reactstrap";
import PropTypes from 'prop-types'
import * as selectors from '../sinistreSelectors'
import * as contratSelectors from '../../contrat/contratSelectors'
import {connect} from "react-redux";
import {compose} from "redux";
import LigneAutreSinistre from "./LigneAutreSinistre";
import Paper from "../../../global/component/paper/Paper";
import { supprimeZerosContratString } from '../../../utils'
import {withRouter} from "react-router";
import * as sinistreActions from '../sinistreActions'


class AutresSinistres extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            tooltipOpen: {}
        }
        this.toggle = this.toggle.bind(this);
        this.redirectToTarget = this.redirectToTarget.bind(this)
        this.goToSinistre = this.goToSinistre.bind(this)
    }
    toggle(numerosinistre) {
        this.setState({
	        tooltipOpen : {
	        	...this.state.tooltipOpen,
                [numerosinistre] :  this.state.tooltipOpen[numerosinistre] ? !this.state.tooltipOpen[numerosinistre] : true
	        },
        });
    }
    redirectToTarget = (path) => {
        this.props.history.push(path)
    }
    goToSinistre(nSinistre){
        this.props.setSinistreActif(nSinistre)
        this.redirectToTarget(`${this.props.baseLocation}/mesSinistres/suivreSinistre?idDeclarationSinistre=` + nSinistre.idDeclarationSinistre + `&exercice=` + nSinistre.exercice+ `&sinistre=`+nSinistre.numeroSinistre+`&date=`+nSinistre.dateSinistre.substring(0,10))
    }
    render() {
        const {donneesSinistre, contratListe, getDonneesContrat } = this.props
        if (donneesSinistre.length > 0) {
            return (
                <Paper className="push-tpl-1 mes-autres-sinistres">
                    <Row className="push-head">
                        <Col>
                            <Row>
                                <Col className="d-flex align-items-center">
                                    <div className="title">
                                        Sinistres récoltes traités ou clos
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="push-body padding-top-0 padding-bottom-20">
                        <div className="col-12 ft-s-16">
                            La liste ci-dessous présente les sinistres déclarés au cours des 5 dernières années, sur vos différents contrats.
                            Ces dossiers sinistres ne nécessitent plus d'action, ni de la part de la compagnie, ni de votre part.
                        </div>
                    </Row>
                    <Row className="push-body">
                        <div className="content flex-column text-left col-12">
                            <div className="sinistre-content">
                                <Row>
                                    <Col>
                                        <div className="list-group">
                                            {donneesSinistre.map((sinistre, index) =>
                                                <LigneAutreSinistre key={`sinistre-autres${sinistre.declaration.idDeclarationSinistre}`}
                                                                    sinistre={sinistre}
                                                                    target={`Tooltip${sinistre.declaration.idDeclarationSinistre}`}
                                                                    isOpen={this.state.tooltipOpen[sinistre.declaration.idDeclarationSinistre] || false}
                                                                    toggle={() => {this.toggle(sinistre.declaration.idDeclarationSinistre)}}
                                                                    contratListe={contratListe}
                                                                    donneesContrat={getDonneesContrat(supprimeZerosContratString(sinistre.declaration.contrat))}
                                                                    goToSinistre = {this.goToSinistre}
                                                />
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Row>
                </Paper>
            )
        } else return null
    }
}

AutresSinistres.propTypes = {
	donneesSinistre:PropTypes.array,
	getDonneesContrat:PropTypes.func,
	contratListe:PropTypes.array,
    baseLocation: PropTypes.string.isRequired,
    setSinistreActif: PropTypes.func

}
const mapStateToProps = (state) => {
	return {
		donneesSinistre: selectors.getDonneesSinistre(state),
		contratListe: contratSelectors.getContratsListe(state),
		getDonneesContrat: contratSelectors.getDonneesContratByContrat(state)
	}
}
const actions = {
    setSinistreActif: sinistreActions.setSinistreActif
}
export default compose(
    connect(mapStateToProps, actions),
    withRouter
)(AutresSinistres)