import React from 'react'
import { Col, Row } from 'reactstrap'
import ComponentCoordonnees from './components/ComponentCoordonnees'
import ComponentInformations from './components/ComponentInformations'
import ComponentPreferences from './components/ComponentPreferences'

class CoordonneesPage extends React.Component {
	render() {
		return (
			<Col lg={9} md="12">
				<Row>
					<Col lg={6} md="6">
						<Row>
							<Col className="mb-3">
								<ComponentCoordonnees />
							</Col>
						</Row>
						<Row>
							<Col className="mb-3">
								{/*<ComponentBancaire/>*/}
								<ComponentPreferences />
							</Col>
						</Row>
					</Col>
					<Col lg={6} md="6">
						<Row>
							<Col className="mb-3">
								<ComponentInformations />
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		)
	}
}

export default CoordonneesPage
