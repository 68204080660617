import React from 'react'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {Link} from "react-router-dom";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";

const ModalUtililisateurExistant =({openModal, closeModal})=>(
            <Modal isOpen={openModal} className={"small"}>
                <ModalHeader>Votre compte a déjà été créé</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg={1} md={1} sm={1} xs={1}><span style={{fontSize:'32px',color: '#DC3149'}} className={`d-flex align-items-center h-100 icon-close-circle`}> </span></Col>
                        <Col lg={11} md={11} sm={11} xs={11}><p style={{fontSize:'15px'}} className="d-flex align-items-center h-100">Un compte existe déjà pour le SIRET et l’adresse e-mail que vous avez saisis.
                            Connectez-vous sur votre Espace Client, avec votre identifiant (N° client ou adresse e-mail) et votre mot de passe.
                            Si vous avez perdu votre mot de passe, cliquez sur Mot de passe oublié.</p></Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Link to={`/`}>
                        <Button color={'primary'} onClick={closeModal}>Fermer</Button>
                    </Link>
                </ModalFooter>
            </Modal>
)

export default ModalUtililisateurExistant;