import React from 'react'
import { Col, CustomInput, FormFeedback, Label, Row } from 'reactstrap'


const RenderRadioField = ({ option,po, checked, handleOptionChange, label, input, meta: { touched, error }, enumTypeDeclaration }) => {
	return (
		<Row className="declaration-sinistre-radio" style={{ marginBottom: 40 }}>
			<Col lg={3} md={3} sm={3} xs={3}>
				{label && <Label className="c-primary ft-s-14">{label}<b className="c-red"> *</b></Label>}
			</Col>
			<Col lg={{ size: 8, offset: 1 }} md={{ size: 8, offset: 1 }} sm={{ size: 8, offset: 1 }} xs={{ size: 8, offset: 1 }}>
				<Row className="ml-0 margin">
					<CustomInput {...input} type="radio" id={enumTypeDeclaration.global.code} value={enumTypeDeclaration.global.code}
					             label={enumTypeDeclaration.global.label}
					             onChange={() => handleOptionChange(enumTypeDeclaration.global.code)} inline
					/>
				</Row>
				<Row className="ml-0 margin">
					<CustomInput {...input} type="radio" id={enumTypeDeclaration.detaille.code} value={enumTypeDeclaration.detaille.code}
					             label={enumTypeDeclaration.detaille.label}
					             onChange={() => handleOptionChange(enumTypeDeclaration.detaille.code)} inline
					/>
				</Row>
				{!po &&
				<Row  className="m-0">
					<CustomInput {...input} type="radio" id={enumTypeDeclaration.pourOrdre.code} value={enumTypeDeclaration.pourOrdre.code}
					             label={enumTypeDeclaration.pourOrdre.label} inline
					             className="mr-0"
					             onChange={() => handleOptionChange(enumTypeDeclaration.pourOrdre.code)}
								 defaultChecked ={checked}
					/>
					{option === enumTypeDeclaration.pourOrdre.code &&
					<div
						style={{ marginTop: 10, marginRight: '1.5rem', color: '#414141', fontSize: 13 }}
					>Ce choix vous permet d'ouvrir un dossier Sinistre même si vous n'avez pas encore constaté les dégâts sur vos cultures.<br/>
						Vous aurez <b>30 jours maximum à partir d'aujourd'hui</b> pour confirmer votre déclaration et déclencher l'expertise.
					</div>}
				</Row>}
				<FormFeedback valid={!(touched && error)} style={{ display: (error && touched) ? 'block' : 'none' }}>
					<div style={{ display: 'flex' }}>
						<p style={{ fontSize: '12px' }}>{error}</p>
					</div>
				</FormFeedback>
			</Col>
		</Row>
	)
}
export default RenderRadioField