import React from 'react'
import {Row, Col, ModalHeader, ModalBody, ModalFooter, Button} from "reactstrap";
import {Field, reduxForm} from "redux-form";
import InputFormModale from "../../../global/component/input/InputFormModale";
import {isPasswordValid} from "../../../utils";

const validate = (values) => {
    const errors = {}
    const requiredFields = [
        'oldPassword',
        'newPassword',
        'newPasswordConfirmation'
    ]
    requiredFields.forEach(field => {
        if(!values[field]) {
            errors[field] = 'Ce champ est obligatoire'
        }
    })
    if (values.newPassword && !isPasswordValid(values.newPassword)) {
        errors.newPassword = 'Mot de passe incorrect : il doit contenir au moins 10 caractères, dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial.'
    }
    if (values.newPassword !== values.newPasswordConfirmation) {
        errors.newPasswordConfirmation = ' Les mots de passe saisis ne sont pas identiques.'
    }
    return errors
}

class ModificationMotDePasseForm extends React.Component {

    render() {
        const {handleSubmit,toggle,pristine, submitting } = this.props
        return(
            <form onSubmit={handleSubmit}>
                <ModalHeader className="margin-header-modale" toggle={toggle}>Modifier mon mot de passe</ModalHeader>
                <ModalBody className="margin-body-modale">
                    <p>Votre mot de passe doit respecter les règles suivantes :<br/>
                        - au moins 10 caractères,<br/>
                        - au moins une minuscule,<br/>
                        - au moins une majuscule,<br/>
                        - au moins un chiffre,<br/>
                        - et au moins un caractère spécial.
                    </p>
                    <div className="form-group">
                        <Row>
                            <Col>
                                <Field name="oldPassword"
                                       component={InputFormModale}
                                       label="Mot de passe actuel"
                                       type="password"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="newPassword"
                                       component={InputFormModale}
                                       type="password"
                                       label="Nouveau mot de passe"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="newPasswordConfirmation"
                                       component={InputFormModale}
                                       type="password"
                                       label="Confirmez votre nouveau mot de passe"
                                />
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter className={"d-flex justify-content-center"}>
                    <Button outline color="light" className="w-25 border-2" onClick={toggle}>Annuler</Button>
                    <Button color="light" className="w-25" disabled={pristine || submitting} type="submit">Confirmer</Button>
                </ModalFooter>
                <p className="notabene"><b className="c-red">* </b>Champ obligatoire</p>
            </form>
        )
    }
}

export default reduxForm({
	form: 'modificationMotDePasse',
	validate,
    touchOnBlur: false
})(ModificationMotDePasseForm)
