import PropTypes from 'prop-types'
import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { connect } from 'react-redux'

const Captcha = ({ apiKey = '',input, meta: { touched, error } }) => {
	return (
		apiKey && <div className="d-flex flex-column justify-content-center align-items-center container-captcha">
			<ReCAPTCHA
				sitekey={apiKey}
				onChange={input.onChange}
			/>
			<div><p className="text-danger ft-s-12">{touched ? error : ''}</p></div>
		</div>
	)
}
Captcha.propTypes = {
	apiKey: PropTypes.string
}

const mapStateToProps = (state) => ({
	apiKey: state.configuration.GOOGLE_API_KEY
})
export default connect(mapStateToProps)(Captcha)
