import PropTypes from 'prop-types'
import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

class ModaleDonnees extends React.Component {

	render() {
		const {isOpened,toggle,className, themeRurale} = this.props;
		return (
			<div>
				<div className='lien' onClick={toggle}>
					Protections des données personnelles
				</div>
				{themeRurale ?
					<Modal isOpen={isOpened} toggle={toggle} className={className + " large"}>
						<ModalHeader>Que fait La Rurale (Société du Groupe Allianz) pour protéger vos données
							personnelles ?</ModalHeader>
						<ModalBody>
							<div>
								<h4>Que fait La Rurale (Société du Groupe Allianz) pour protéger vos données
									personnelles ?</h4>
								<p>
									La Rurale (Société du Groupe Allianz) attache une haute importance à la protection
									de vos données personnelles et nous faisons en sorte que vous vous sentiez en
									sécurité lors de vos visites.

									Dans un souhait de totale transparence nous souhaitons vous informer sur les données
									que nous collectons, sur la manière dont ces informations sont utilisées et sur vos
									possibilités de vous opposer au traitement de ces données. Nous souhaitons également
									vous informer sur les dispositifs mis en place pour vous garantir une sécurité
									maximum.

									Le site internet larurale.fr est totalement sécurisé : dès la page d’accueil vous
									naviguez en toute sécurité avec une adresse https. Le https est un "protocole de
									transfert hypertexte sécurisé". Il garantit la confidentialité et l'intégrité des
									données envoyées par l'utilisateur.

									Les pages allianz.fr où sont collectées vos données personnelles font l’objet d’un
									dispositif supplémentaire de sécurité renforcée.
								</p>
							</div>
							<div>
								<h4>Pourquoi collectons-nous vos données personnelles ?</h4>
								<p>
									Vos données personnelles recueillies en tant qu’assuré, adhérant, bénéficiaire,
									payeur de prime ou affilié, sont utilisées dans le cadre de notre relation
									contractuelle et commerciale. Elles nous permettent de remplir nos obligations
									légales. A ce titre, nous vous informons que vos données font l’objet d’un
									traitement informatique destiné aux finalités suivantes :

									la passation, la gestion et l’exécution des contrats d’assurance
									l’exécution des dispositions légales, réglementaires et administratives en vigueur
									la lutte anti-blanchiment
									les opérations relatives à la gestion commerciale des clients et à la prospection
									commerciale
									Vos données sont nécessaires à la conclusion du contrat et du respect des
									obligations légales. Vos données sont uniquement utilisées dans le cadre des
									finalités mentionnées ci-dessus de façon pertinente et proportionnée. Elles sont
									relatives à votre identification, à l’appréciation du risque, à la détermination ou
									évaluation des préjudices y compris les données de santé. Vos données médicales font
									l’objet d’un traitement spécifique lié au respect du secret médical.

									Dans le cadre de la collecte des données d’infractions, les données sur les
									circonstances de l’infraction et les données postérieures à la contestation de
									l’infraction sont traitées.

									Pour notre relation commerciale, avec votre consentement express, elles peuvent
									aussi être utilisées dans un but de prospection pour les produits (assurances,
									produits bancaires et financiers, services) distribués par le groupe Allianz et ses
									partenaires. Nous sommes susceptibles d’utiliser des annonceurs et des réseaux
									publicitaires. Vos données faisant l’objet d’un traitement sont relatives :

									aux habitudes de vie en lien avec la relation commerciale
									au suivi de la relation commerciale
									à la localisation et la connexion
									à la sélection des personnes pour réaliser des actions de fidélisation, de
									prospection, de promotion, ainsi que des données relatives aux contributions des
									personnes.
									Le recueil de vos données nous permet de mieux vous connaître et d’améliorer nos
									services en vous proposant des produits ou des services les plus adaptés à vos
									besoins. Cela nous aidera également à réduire la sinistralité et à lutter contre la
									fraude afin de préserver la mutualité des assurés.

									Nous pouvons être amené à faire du profilage c’est-à-dire à employer des traitements
									consistant à utiliser vos données personnelles pour évaluer certains aspects
									personnels de votre personne.

									Enfin, nous sommes susceptibles de vous proposer des enquêtes de satisfaction.

									Dans le cadre des souscriptions en ligne, nous avons recours à un processus de
									décision automatisé de la souscription en fonction des risques à couvrir. Quelle que
									soit la décision prise, vous pouvez demander des explications à l’adresse indiquée
									au paragraphe "Vos contacts".
								</p>
							</div>
							<div>
								<h4>Que fait La Rurale (Société du Groupe Allianz) pour protéger vos données
									personnelles ?</h4>
								<p>
									La Rurale (Société du Groupe Allianz) attache une haute importance à la protection
									de vos données personnelles et nous faisons en sorte que vous vous sentiez en
									sécurité lors de vos visites.

									Dans un souhait de totale transparence nous souhaitons vous informer sur les données
									que nous collectons, sur la manière dont ces informations sont utilisées et sur vos
									possibilités de vous opposer au traitement de ces données. Nous souhaitons également
									vous informer sur les dispositifs mis en place pour vous garantir une sécurité
									maximum.

									Le site internet larurale.fr est totalement sécurisé : dès la page d’accueil vous
									naviguez en toute sécurité avec une adresse https. Le https est un "protocole de
									transfert hypertexte sécurisé". Il garantit la confidentialité et l'intégrité des
									données envoyées par l'utilisateur.

									Les pages allianz.fr où sont collectées vos données personnelles font l’objet d’un
									dispositif supplémentaire de sécurité renforcée.
								</p>
							</div>
							<div>
								<h4>Qui sont les destinataires de vos données personnelles ?</h4>
								<p>
									Dans le strict cadre des finalités énoncées, les destinataires de vos données sont
									les services des entreprises du Groupe Allianz ainsi que votre intermédiaire
									d’assurance. Elles sont également susceptibles d’être traitées par nos partenaires,
									sous-traitants, prestataires, réassureurs, et s’il y a lieu les organismes
									d’assurance ou les organismes sociaux des personnes impliquées ainsi que les
									personnes intéressées au contrat.

									Ces destinataires peuvent éventuellement se situer en dehors de l’Union Européenne.
									La liste actualisée de ces pays ainsi que les références aux garanties appropriées
									vous seront communiquées sur simple demande faite à l’adresse indiquée au paragraphe
									"Vos contacts".

									En cas de transfert de vos données personnelles en dehors de l’Europe, La Rurale
									(Société du Groupe Allianz) met en place les garanties appropriées pour assurer la
									protection des données personnelles. Si vous souhaitez obtenir des informations sur
									ces garanties, veuillez adresser votre demande à l’adresse indiquée au paragraphe
									"Vos contacts".

									Formation et sensibilisation des collaborateurs

									Tous les salariés et les intervenants au sein de La Rurale (Société du Groupe
									Allianz) en France sont sensibilisés aux principes de la loi Informatique et
									Libertés, obligation essentielle, dans le cadre de leur activité. Les formations
									dispensées à nos collaborateurs sont adaptées à leur activité et à leurs
									responsabilités.
									Nos collaborateurs sont sensibilisés au respect de notre charte interne et à ses 10
									principes de la protection des données personnelles d’Allianz France.
								</p>
							</div>
							<div>
								<h4>Quelle est la durée de conservation de vos données personnelles ?</h4>
								<p>
									La durée de conservation de vos données personnelles correspond à la durée de mise
									en œuvre des finalités énoncées supra, majorée du délai de prescription.

									En l’absence de conclusion d’un contrat ou dans le cadre de la prospection
									commerciale, les données peuvent être conservées pendant un délai de 3 ans à compter
									du dernier contact et être archivées pendant 5 ans pour les données de santé.

									Les données relatives aux cartes bancaires peuvent être conservées 13 mois à compter
									du débit. Vous pouvez demander l’ensemble des durées de conservation des données
									traitées à l’adresse indiquée au paragraphe "Vos contacts".
								</p>
							</div>
							<div>
								<h4>Pourquoi utilisons-nous des cookies ?</h4>
								<p>
									Le cookie est un simple fichier texte stocké temporairement ou définitivement sur
									votre périphérique fixe ou mobile mais aussi dans certains cas, dans votre
									navigateur.

									Le cookie est utile, voire indispensable pour la navigation sur internet car il
									permet aux éditeurs de sites internet :

									d’améliorer la performance de leur site
									de proposer une meilleure expérience de navigation aux internautes.
									Si vous souhaitez en savoir plus sur les cookies, leur fonctionnement et leur
									utilisation, consultez le site de la CNIL ou cliquez sur le bouton suivant : En
									savoir plus sur les cookies
								</p>
							</div>
							<div>
								<h4>De quels droits disposez-vous pour la protection de vos données personnelles ?</h4>
								<p>
									Droit d’opposition : lorsque vos données personnelles ne sont pas nécessaires ou ne
									sont plus nécessaires dans notre relation contractuelle vous pouvez demander à ce
									qu’elles ne fassent plus l’objet de traitement. Pour exercer ce droit veuillez
									adresser votre demande à l’adresse indiquée au paragraphe "Vos Contacts".
									Vous disposez également des droits suivants :

									Droits d’accès et de rectification : à tout moment, vous pouvez demander l’accès à
									vos données personnelles et à la rectification de celles-ci. Pour vos données
									personnelles collectées dans le cadre de la lutte anti-blanchiment et la lutte
									anti-terroriste, vous bénéficiez d’un droit d’accès aux données vous concernant
									directement auprès de la Commission Nationale de l`Informatique et des Libertés
									(CNIL) selon les modalités mentionnées sur son site.
									Droit à l’effacement : lorsque la durée de conservation de vos données personnelles
									est écoulée, vous pouvez demander leur effacement.
									Droit à une limitation de traitement : lorsque les données ne sont pas nécessaires
									ou ne sont plus nécessaires dans notre relation contractuelle, vous pouvez demander
									la limitation de leur utilisation.
									Droit à la portabilité de vos données : à tout moment, vous pouvez demander une
									copie des données que nous avons collectées pour l’exécution de votre contrat ou
									avec votre consentement. Vous pouvez nous demander de communiquer celles-ci à la
									personne de votre choix.
									Droit de retirer votre consentement : pour tous les traitements nécessitant votre
									consentement explicite, vous avez le droit de le retirer à tout moment notamment
									pour la prospection commerciale.
									La possibilité d'organiser le sort de vos données personnelles après votre mort :
									vous pouvez donner des directives relatives à la conservation, à l'effacement et à
									la communication de vos données après votre décès. Pour cela, vous désignez une
									personne pour exécuter vos directives. Après votre décès, elle prendra connaissance
									de vos directives et demandera leur mise en œuvre.(
									Si vous souhaitez exercer vos droits ou en savoir plus, vous pouvez adresser votre
									demande à l’adresse indiquée au paragraphe "Vos contacts". Pour en savoir plus sur
									vos droits, vous pouvez consulter le site de la CNIL.
								</p>
							</div>
							<div>
								<h4>Qui sont les responsables de traitement au sein de La Rurale (Société du Groupe
									Allianz) ?</h4>
								<p>
									Pour un contrat souscrit auprès de La Rurale (Société du Groupe Allianz) : Siège
									social Cœur Défense, Bâtiment 2, 82, Esplanade du Général De Gaulle , 92400
									Courbevoie, Compagnie d’assurances contre la grêle, S.A. Régie par le Code des
									Assurances, capital de 960000 euros, N° 572 166 437 RCS Nanterre
								</p>
							</div>
							<div>
								<h4>A qui pouvez-vous vous adresser en cas de contestation relative au traitement de vos
									données personnelles ?</h4>
								<p>
									Vous pouvez faire une réclamation selon les modalités précisées au paragraphe "Vos
									contacts".

									Si la réponse reçue ne vous satisfait pas, vous pouvez vous adresser à la CNIL selon
									les modalités mentionnées sur leur site. </p>
							</div>
							<div>
								<h4>Utilisation de vos données Facebook</h4>
								<p>
									Les agents généraux et courtiers de La Rurale (Société du Groupe Allianz) peuvent
									détenir pour leur(s) agence(s) commerciale(s) une page Facebook. Afin de vous
									apporter des solutions, conseils adaptés aux évènements majeurs de votre vie
									(événement marquant Facebook), l’agent général et le courtier La Rurale (Société du
									Groupe Allianz) peut vous demander d’avoir accès à ces évènements.

									Cette demande se matérialise par un email ou une publication Facebook. A tout
									moment, vous avez la faculté de révoquer votre autorisation de partage
									d’informations. </p>
							</div>
							<div>
								<h4>Vos contacts</h4>
								<p>
									Pour exercer vos droits ou contacter le responsable des données personnelles pour
									toute information complémentaire ou réclamation, il vous suffit de nous écrire par
									mail à informatiqueetliberte@larurale.fr ou par courrier à l’adresse La Rurale -
									Informatique et Libertés - Case courrier Y360 - Cœur Défense - 92086 Paris La
									Défense.
								</p>
							</div>
							<div>
								<h4>Nos conseils</h4>
								<p>
									1. Soyez attentif aux e-mails reçus

									Le phishing est une technique de piratage destinée à récupérer des informations
									personnelles. Elle consiste à vous envoyer un e-mail qui reprend les noms et logos
									de vrais opérateurs, banques ou sociétés connues

									Comment les repérer ?

									Soyez attentifs car ces faux e-mails comportent souvent des fautes d’orthographes et
									une syntaxe incorrecte
									Vérifiez l’adresse de l’expéditeur qui peut ressembler à la véritable adresse mais
									va différer par un petit détail
									Ne cliquez pas sur un lien dans un email, mais rendez-vous sur le vrai site de
									l’opérateur en saisissant vous-même l’adresse dans le navigateur
									Ne transmettez jamais vos coordonnées personnelles quel que soit le motif invoqué
									dans l’e-mail </p>
								<p>2. Changez régulièrement vos mots de passe

									La sécurité de vos données suppose de changer vos mots de passe régulièrement et de
									les protéger. Le mot de passe pour accéder à l'espace client du site larurale.fr
									doit comporter a minima une combinaison alphanumérique pour mieux vous protéger.

									Il est recommandé d’utiliser des mots de passe différents voire des phrases,
									beaucoup plus difficile à pirater (ex : Lili est ma fille plutôt que lili2).
								</p>
								<p>
									3. Méfiez-vous des offres trop alléchantes

									Ne répondez jamais à une promesse dont l'offre semble trop belle pour être vraie
									surtout s'il vous est demandé de payer à l'avance certains frais pour débloquer de
									l'argent.
								</p>
								<p>
									4. Equipez-vous de logiciels de protection

									D'un antivirus : logiciel qui s'assure en permanence que les fichiers de votre
									ordinateur ne contiennent pas de virus.
									D'un antispyware : logiciel qui vérifie si d'autres logiciels n'espionnent pas votre
									activité ou ne transmettent pas certaines de vos données confidentielles
									D'un pare-feu ou firewall : logiciel qui contrôle toutes les informations entrantes
									et sortantes de votre ordinateur
									Si vous n'êtes pas détenteur de ces outils, nous vous recommandons de vous les
									procurer et d'en assurer la mise à jour régulière
								</p>
								<p>
									5. Portez attention aux cookies

									Avant d’accepter les cookies veillez à consulter la politique appliquée par le site
									que vous visitez, soyez vigilants sur la nature des données collectées et
									l’utilisation qui en est faite.

									Politique d'utilisation des cookies La Rurale (Société du Groupe Allianz)
								</p>
								<p>
									6. Méthode de destruction des documents sensibles

									Si vous souhaitez jeter vos documents contenant des données personnelles sensibles
									ou confidentielles (après écoulement du délai légal de conservation), ne vous
									contentez pas de les déchirer : détruisez-les (passez-les au broyeur).

									Politique d'utilisation des cookies La Rurale (Société du Groupe Allianz)
								</p>
							</div>
						</ModalBody>
						<ModalFooter>
							<Button onClick={toggle} color="light" size="lg" className="btn-block w-50 m-auto">C'est
								compris</Button>
						</ModalFooter>
					</Modal>
					:
					<Modal isOpen={isOpened} toggle={toggle} className={className + " large"}>
						<ModalHeader>Protection de vos données personnelles</ModalHeader>
						<ModalBody>
							<div>
								<h4>Que fait Allianz pour protéger vos données personnelles ?</h4>
								<p>
									Allianz attache une haute importance à la protection de vos données personnelles1 et nous faisons en sorte que vous vous sentiez en sécurité lors de vos visites.
									<br/>
									Dans un souhait de totale transparence nous souhaitons vous informer sur les données que nous collectons, sur la manière dont ces informations sont utilisées et sur vos possibilités de vous opposer au traitement de ces données. Nous souhaitons également vous informer sur les dispositifs mis en place pour vous garantir une sécurité maximum.
									<br/>
									Le site internet allianz.fr est totalement sécurisé : dès la page d’accueil vous naviguez en toute sécurité avec une adresse https. Le https est un "protocole de transfert hypertexte sécurisé". Il garantit la confidentialité et l'intégrité des données envoyées par l'utilisateur.
									<br/>
									Les pages allianz.fr où sont collectées vos données personnelles font l’objet d’un dispositif supplémentaire de sécurité renforcée.
								</p>
							</div>
							<div>
								<h4>Pourquoi collectons-nous vos données personnelles ?</h4>
								<p>
									Vos données personnelles recueillies en tant qu’assuré, adhérant, bénéficiaire, payeur de prime ou affilié, sont utilisées dans le cadre de notre relation contractuelle et commerciale. Elles nous permettent de remplir nos obligations légales. A ce titre, nous vous informons que vos données font l’objet d’un traitement informatique destiné aux finalités suivantes :

									<ul>
										<li>la passation, la gestion et l’exécution des contrats d’assurance</li>
										<li>l’exécution des dispositions légales, réglementaires et administratives en vigueur</li>
										<li>la lutte anti-blanchiment</li>
										<li>les opérations relatives à la gestion commerciale des clients et à la prospection commerciale.</li>
									</ul>

									Vos données sont nécessaires à la conclusion du contrat et du respect des obligations légales. Vos données sont uniquement utilisées dans le cadre des finalités mentionnées ci-dessus de façon pertinente et proportionnée. Elles sont relatives à votre identification, à l’appréciation du risque, à la détermination ou évaluation des préjudices y compris les données de santé. Vos données médicales font l’objet d’un traitement spécifique lié au respect du secret médical.
									<br/>
									Dans le cadre de la collecte des données d’infractions, les données sur les circonstances de l’infraction et les données postérieures à la contestation de l’infraction sont traitées.
									<br/>
									Pour notre relation commerciale, avec votre consentement express, elles peuvent aussi être utilisées dans un but de prospection pour les produits (assurances, produits bancaires et financiers, services) distribués par le groupe Allianz et ses partenaires. Nous sommes susceptibles d’utiliser des annonceurs et des réseaux publicitaires.
									Vos données faisant l’objet d’un traitement sont relatives :

									<ul>
										<li>aux habitudes de vie en lien avec la relation commerciale</li>
										<li>au suivi de la relation commerciale</li>
										<li>à la localisation et la connexion</li>
										<li>à la sélection des personnes pour réaliser des actions de fidélisation, de prospection, de promotion, ainsi que des données relatives aux contributions des personnes.</li>
									</ul>

									Le recueil de vos données nous permet de mieux vous connaître et d’améliorer nos services en vous proposant des produits ou des services les plus adaptés à vos besoins. Cela nous aidera également à réduire la sinistralité et à lutter contre la fraude afin de préserver la mutualité des assurés.
									<br/>
									Nous pouvons être amené à faire du profilage c’est-à-dire à employer des traitements consistant à utiliser vos données personnelles pour évaluer certains aspects personnels de votre personne.
									<br/>
									Enfin, nous sommes susceptibles de vous proposer des enquêtes de satisfaction.
									<br/>
									Dans le cadre des souscriptions en ligne, nous avons recours à un processus de décision automatisé de la souscription en fonction des risques à couvrir. Quelle que soit la décision prise, vous pouvez demander des explications à l’adresse indiquée au paragraphe "Vos contacts".
								</p>
							</div>
							<div>
								<h4>Qui sont les destinataires de vos données personnelles ?</h4>
								<p>
									Dans le strict cadre des finalités énoncées, les destinataires de vos données sont les services des entreprises du Groupe Allianz ainsi que votre intermédiaire d’assurance. Elles sont également susceptibles d’être traitées par nos partenaires, sous-traitants, prestataires, réassureurs, et s’il y a lieu les organismes d’assurance ou les organismes sociaux des personnes impliquées ainsi que les personnes intéressées au contrat.
									<br/>
									Ces destinataires peuvent éventuellement se situer en dehors de l’Union Européenne. La liste actualisée de ces pays ainsi que les références aux garanties appropriées vous seront communiquées sur simple demande faite à l’adresse indiquée au paragraphe "Vos contacts"
									<br/>
									En cas de transfert de vos données personnelles en dehors de l’Europe, Allianz met en place les garanties appropriées pour assurer la protection des données personnelles. Si vous souhaitez obtenir des informations sur ces garanties, veuillez adresser votre demande à l’adresse indiquée au paragraphe "Vos contacts".

									<b>Formation et sensibilisation des collaborateurs</b>

									Tous les salariés et les intervenants au sein d'Allianz en France sont sensibilisés aux principes de la loi Informatique et Libertés, obligation essentielle, dans le cadre de leur activité. Les formations dispensées à nos collaborateurs sont adaptées à leur activité et à leurs responsabilités.
									Nos collaborateurs sont sensibilisés au respect de notre charte interne et à ses 10 principes de la protection des données personnelles d’Allianz France.
								</p>
							</div>
							<div>
								<h4>Quelle est la durée de conservation de vos données personnelles ?</h4>
								<p>
									La durée de conservation de vos données personnelles correspond à la durée de mise en œuvre des finalités énoncées supra, majorée du délai de prescription.
									<br/>
									En l’absence de conclusion d’un contrat ou dans le cadre de la prospection commerciale, les données peuvent être conservées pendant un délai de 3 ans à compter du dernier contact et être archivées pendant 5 ans pour les données de santé.
									<br/>
									Les données relatives aux cartes bancaires peuvent être conservées 13 mois à compter du débit. Vous pouvez demander l’ensemble des durées de conservation des données traitées à l’adresse indiquée au paragraphe "Vos contacts".
								</p>
							</div>
							<div>
								<h4>Pourquoi utilisons-nous des cookies ?</h4>
								<p>
									Le cookie est un simple fichier texte stocké temporairement ou définitivement sur votre périphérique fixe ou mobile mais aussi dans certains cas, dans votre navigateur.
									<br/>
									Le cookie est utile, voire indispensable pour la navigation sur internet car il permet aux éditeurs de sites internet :

									<ul>
										<li>d’améliorer la performance de leur site</li>
										<li>de proposer une meilleure expérience de navigation aux internautes.</li>
									</ul>
									Si vous souhaitez en savoir plus sur les cookies, leur fonctionnement et leur utilisation, consultez le site de la CNIL ou cliquez sur le bouton suivant : En savoir plus sur les cookies
								</p>
							</div>
							<div>
								<h4>De quels droits disposez-vous pour la protection de vos données personnelles ?</h4>
								<ul>
									<li>Droit d’opposition : lorsque vos données personnelles ne sont pas nécessaires ou ne sont plus nécessaires dans notre relation contractuelle vous pouvez demander à ce qu’elles ne fassent plus l’objet de traitement. Pour exercer ce droit veuillez adresser votre demande à l’adresse indiquée au paragraphe "Vos Contacts".</li>
								</ul>
								<p>Vous disposez également des droits suivants :</p>
								<ul>
									<li>Droits d’accès et de rectification : à tout moment, vous pouvez demander l’accès à vos données personnelles et à la rectification de celles-ci. Pour vos données personnelles collectées dans le cadre de la lutte anti-blanchiment et la lutte anti-terroriste, vous bénéficiez d’un droit d’accès aux données vous concernant directement auprès de la Commission Nationale de l`Informatique et des Libertés (CNIL) selon les modalités mentionnées sur son site.</li>
									<li>Droit à l’effacement : lorsque la durée de conservation de vos données personnelles est écoulée, vous pouvez demander leur effacement.</li>
									<li>Droit à une limitation de traitement : lorsque les données ne sont pas nécessaires ou ne sont plus nécessaires dans notre relation contractuelle, vous pouvez demander la limitation de leur utilisation.</li>
									<li>Droit à la portabilité de vos données : à tout moment, vous pouvez demander une copie des données que nous avons collectées pour l’exécution de votre contrat ou avec votre consentement. Vous pouvez nous demander de communiquer celles-ci à la personne de votre choix.</li>
									<li>Droit de retirer votre consentement : pour tous les traitements nécessitant votre consentement explicite, vous avez le droit de le retirer à tout moment notamment pour la prospection commerciale.</li>
									<li>La possibilité d'organiser le sort de vos données personnelles après votre mort : vous pouvez donner des directives relatives à la conservation, à l'effacement et à la communication de vos données après votre décès. Pour cela, vous désignez une personne pour exécuter vos directives. Après votre décès, elle prendra connaissance de vos directives et demandera leur mise en œuvre.</li>
								</ul>
								<p>Si vous souhaitez exercer vos droits ou en savoir plus, vous pouvez adresser votre demande à l’adresse indiquée au paragraphe "Vos contacts".
									Pour en savoir plus sur vos droits, vous pouvez consulter le site de la CNIL.</p>
							</div>
							<div>
								<h4>Qui sont les responsables de traitement au sein du groupe Allianz ?</h4>
								<ul>
									<li>Pour un contrat souscrit auprès d’Allianz IARD : société anonyme au capital de 991.967.200 euros, entreprise régie par le Code des Assurances, dont le siège social est situé au 1 cours Michelet - CS 30051 - 92076 Paris La Défense Cedex - 542 110 291 RCS Nanterre</li>
									<li>Pour un contrat souscrit auprès d’Allianz Vie : société anonyme au capital de 643.054.425 euros, entreprise régie par le Code des Assurances, dont le siège social est situé au 1 cours Michelet - CS 30051 - 92076 Paris La Défense Cedex - 340 234 962 RCS Nanterre</li>
									<li>Pour un contrat souscrit auprès de Protexia : société anonyme au capital de 1.895.248 euros, entreprise régie par le Code des Assurances, dont le siège social est situé au 1 cours Michelet - CS 30051 - 92076 Paris La Défense Cedex - 382 276 624 RCS Nanterre.</li>
								</ul>
							</div>
							<div>
								<h4>A qui pouvez-vous vous adresser en cas de contestation relative au traitement de vos données personnelles ?</h4>
								<p>
									Vous pouvez faire une réclamation selon les modalités précisées au paragraphe "Vos
									contacts".
									<br/>
									Si la réponse reçue ne vous satisfait pas, vous pouvez vous adresser à la CNIL selon
									les modalités mentionnées sur leur site. </p>
							</div>
							<div>
								<h4>Utilisation de vos données Facebook</h4>
								<p>
									Les agents généraux Allianz peuvent détenir pour leur(s) agence(s) commerciale(s) une page Facebook. Afin de vous apporter des solutions, conseils adaptés aux évènements majeurs de votre vie (événement marquant Facebook), l’agent général Allianz peut vous demander d’avoir accès à ces évènements.
									<br/>
									Cette demande se matérialise par un email ou une publication Facebook. A tout moment, vous avez la faculté de révoquer votre autorisation de partage d’informations. </p>
							</div>
							<div>
								<h4>Vos contacts</h4>
								<p>
									Pour exercer vos droits ou contacter le responsable des données personnelles pour toute information complémentaire ou réclamation, il vous suffit de nous écrire par mail à informatiqueetliberte@allianz.fr ou par courrier à l’adresse Allianz - Informatique et Libertés – Case courrier S1805 – 1 cours Michelet – CS 30051 - 92076 Paris La Défense Cedex.
								</p>
							</div>
							<div>
								<h4>Nos conseils</h4>
								<p>
									1. Soyez attentif aux e-mails reçus

									Le phishing est une technique de piratage destinée à récupérer des informations personnelles. Elle consiste à vous envoyer un e-mail qui reprend les noms et logos de vrais opérateurs, banques ou sociétés connues
									<br/>
									Comment les repérer ?

									<ul>
										<li>Soyez attentifs car ces faux e-mails comportent souvent des fautes d’orthographes et une syntaxe incorrecte.</li>
										<li>Vérifiez l’adresse de l’expéditeur qui peut ressembler à la véritable adresse mais va différer par un petit détail.</li>
										<li>Ne cliquez pas sur un lien dans un email, mais rendez vous sur le vrai site de l’opérateur en saisissant vous-même l’adresse dans le navigateur.</li>
										<li>Ne transmettez jamais vos coordonnées personnelles quel que soit le motif invoqué dans l’e-mail.</li>
									</ul>
								</p>
								<p>2. Changez régulièrement vos mots de passe

									La sécurité de vos données suppose de changer vos mots de passe régulièrement et de les protéger. Le mot de passe pour accéder à l'espace client du site Allianz doit comporter a minima une combinaison alphanumérique pour mieux vous protéger.
									<br/>
									Il est recommandé d’utiliser des mots de passe différents voire des phrases, beaucoup plus difficile à pirater (ex : Lili est ma fille plutôt que lili2).
								</p>
								<p>
									3. Méfiez-vous des offres trop alléchantes

									Ne répondez jamais à une promesse dont l'offre semble trop belle pour être vraie surtout s'il vous est demandé de payer à l'avance certains frais pour débloquer de l'argent.
								</p>
								<p>
									4. Equipez-vous de logiciels de protection

									<ul>
										<li>D'un antivirus : logiciel qui s'assure en permanence que les fichiers de votre ordinateur ne contiennent pas de virus.</li>
										<li>D'un antispyware : logiciel qui vérifie si d'autres logiciels n'espionnent pas votre activité ou ne transmettent pas certaines de vos données confidentielles.</li>
										<li>D'un pare-feu ou firewall : logiciel qui contrôle toutes les informations entrantes et sortantes de votre ordinateur.</li>
										<li>Si vous n'êtes pas détenteur de ces outils, nous vous recommandons de vous les procurer et d'en assurer la mise à jour régulière.</li>
									</ul>
								</p>
								<p>
									5. Portez attention aux cookies

									Avant d’accepter les cookies veillez à consulter la politique appliquée par le site que vous visitez, soyez vigilants sur la nature des données collectées et l’utilisation qui en est faite.
									<br/>
									Politique d'utilisation des cookies Allianz
								</p>
								<p>
									6. Méthode de destruction des documents sensibles

									Si vous souhaitez jeter vos documents contenant des données personnelles sensibles ou confidentielles (après écoulement du délai légal de conservation), ne vous contentez pas de les déchirer : détruisez-les (passez-les au broyeur).
								</p>
							</div>
						</ModalBody>
						<ModalFooter>
							<Button onClick={toggle} color="light" size="lg" className="btn-block w-50 m-auto">C'est
								compris</Button>
						</ModalFooter>
					</Modal>
				}
			</div>
		);
	}
}

ModaleDonnees.propTypes = {
	isOpened: PropTypes.bool,
	toggle: PropTypes.func.isRequired
};

export default ModaleDonnees;