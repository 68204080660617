import React from 'react'
import { Col, FormFeedback, FormText, Input, Label, Row } from 'reactstrap'

const RenderTextField = ({ input, label, name, placeholder,info, meta: { touched, error } }) => {
	return (
		<Row form className="declaration-sinistre-complement" style={{ marginBottom: 40 }}>
			<Col lg={12} md={12} sm={12} xs={12}>
				{label && <Label className="c-primary ft-s-14">{label}</Label>}
			</Col>
			<Col lg={12} md={12} sm={12} xs={12}>
				<Row>
					<Input {...input} type="textarea" placeholder={placeholder}/>
					<FormFeedback valid={!(touched && error)}>
						<div style={{ display: 'flex' }}>
							<p style={{ fontSize: '12px' }}>{error}</p>
						</div>
					</FormFeedback>
					<FormText style={{ display: (error && touched) ? 'none' : '' }}>
						<div style={{ display: 'flex' }}>
							<p style={{ fontSize: '12px', width: '85%' }}>{info}</p>
						</div>
					</FormText>
				</Row>
			</Col>
		</Row>
	)
}
export default RenderTextField