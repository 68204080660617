import {fetchFactory} from "../../../entities/fetchEntities";

const COORDONNEES_ENDPOINT = '/extranet/coordonnees'


export const modificationPreferences = (consentement) => {
	return fetchFactory(`${COORDONNEES_ENDPOINT}/modificationPreferences`, {
		method: 'POST',
		body: JSON.stringify({
			consentement
		})
	})
}

export const modificationEmail = (email) => {
	//TODO : Dispatch la modification pour afficher le bon mail
	return fetchFactory(`${COORDONNEES_ENDPOINT}/modificationEmail`, {
		method: 'POST',
		body: JSON.stringify({
			email
		})
	})
}

export const modificationPassword = (oldPassword,newPassword) => {
	return fetchFactory(`${COORDONNEES_ENDPOINT}/modificationPassword`, {
		method: 'POST',
		body: JSON.stringify({
			oldPassword,
			newPassword
		})
	})
}
export const modificationCoordonnees = (adresseNumero,adresseTypeVoie,adresseVoie,adresseComplement,codePostal,ville,telephoneFixe,telephoneMobile) => {
	return fetchFactory(`${COORDONNEES_ENDPOINT}/modificationCoordonnees`, {
		method: 'POST',
		body: JSON.stringify({
			adresseNumero,
			adresseTypeVoie,
			adresseVoie,
			adresseComplement,
			codePostal,
			ville,
			telephoneFixe,
			telephoneMobile
		})
	}).then((response) => {
		if (response.ok) {
			return response
		} else {
			throw response
		}
	})
}
