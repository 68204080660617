import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button, Col, Row } from 'reactstrap'

class MiniPaper extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: true
		}
	}

	componentDidMount() {
		this.setState({ isLoading: false })
	}

	render() {
		const { titre, icone, isUserAllowed, boutons, children, className, type, sizeTitre } = this.props
		const { isLoading } = this.state
		if (isLoading) {
			return (
				<div className={`push-tpl-1 paper chargement`}>
					<span className="icon icon-refresh c-primary"> </span>
				</div>
			)
		}
		return (
			<div className={`push-tpl-1 paper ${className}`}>
				<div className="push-head">
					<span className={icone}> </span>
					<h2 className={sizeTitre ? 'little' : ''}>{titre}</h2>
				</div>
				<div className="push-body">
					{children}
				</div>
				{((type === 'tertiary' || type === 'secondary' || type === 'primary') && boutons) &&
				<div className={`push-foot ${type}`}>
					<Row>
						{type === 'tertiary' && boutons && boutons.map((item, index) =>
							<Col key={`boutons ${index}`} lg={{ size: 8, offset: 4 }} md={{ size: 10, offset: 2 }} sm={{ size: 12, offset: 0 }}
							     xs={{ size: 12, offset: 0 }}>
								<Button disabled={item.disabled} onClick={item.action} outline size="lg" color="light"
								        className="btn-push-tpl btn-block">
									<div className={item.icone}></div>
									<div className="wording">{item.texte}</div>
									{item.notification && <div className="notification">{item.notification}</div>}
								</Button>
							</Col>
						)}
						{type === 'secondary' && boutons && boutons.map((item, index) =>
							<Col key={`boutons ${index}`} lg={12} md={12} sm={12} xs={12}>
								<Button disabled={item.disabled} onClick={item.action} outline size="md" color="light"
								        className="d-inline-flex align-items-center justify-content-center btn-push-tpl btn-block">
									<div className={item.icone}></div>
									<div className="wording">{item.texte}</div>
								</Button>
							</Col>
						)}
						{type === 'primary' && isUserAllowed && boutons && boutons.map((item, index) =>
							<Col key={`boutons ${index}`} lg={6} md={6} sm={6} xs={6}>
								<Button disabled={item.disabled} onClick={item.action} size="lg" color="light" style={{ height: '80px' }}
								        className="btn-push-tpl btn-block btn-dash">
									<div>
										<div className={item.icone} />
									</div>
									<div className="wording">{item.texte}</div>
									{item.notification > 0 && <div className="notification">{item.notification}</div>}
								</Button>
							</Col>
						)}
						{type === 'primary' && !isUserAllowed && boutons && boutons.map((item, index) => {
							if (index === 0) return (
								<Col key={`boutons ${index}`} lg={12} md={12} sm={12} xs={12}>
									<Button onClick={item.action} size="lg" color="light"
									        className="d-flex justify-content-center align-items-center btn-push-tpl btn-block btn-dash">
										<div className={item.icone} />
										<div className="wording m-0">{item.texte}</div>
									</Button>
								</Col>
							)
						})}
					</Row>
				</div>}
			</div>
		)
	}
}

MiniPaper.propTypes = {
	titre: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	icone: PropTypes.string,
	children: PropTypes.node,
	isUserAllowed: PropTypes.bool,
	boutons: PropTypes.arrayOf(
		PropTypes.shape({
			texte: PropTypes.string.isRequired,
			icone: PropTypes.string,
			notification: PropTypes.string,
			disabled: PropTypes.bool,
			action: PropTypes.function
		})
	),
	className: PropTypes.string
}

MiniPaper.defaultProps = {
	isUserAllowed: true
}

export default MiniPaper
