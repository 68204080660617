import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Carousel, CarouselControl } from 'reactstrap'
import { compose } from 'redux'
import CarouselCaption from './CarouselCaption'
import CarouselItem from './CarouselItem'

const background = 'https://i.picsum.photos/id/247/100/300.jpg'

class CarouselNote extends Component {
	constructor(props) {
		super(props)
		this.state = { activeIndex: 0 }
		this.next = this.next.bind(this)
		this.previous = this.previous.bind(this)
		this.goToIndex = this.goToIndex.bind(this)
		this.onExiting = this.onExiting.bind(this)
		this.onExited = this.onExited.bind(this)
	}

	onExiting() {
		this.animating = true
	}

	onExited() {
		this.animating = false
	}

	next() {
		if (this.animating) return
		const nextIndex = this.state.activeIndex === this.props.notifications.length - 1 ? 0 : this.state.activeIndex + 1
		this.setState({ activeIndex: nextIndex })
	}

	previous() {
		if (this.animating) return
		const nextIndex = this.state.activeIndex === 0 ? this.props.notifications.length - 1 : this.state.activeIndex - 1
		this.setState({ activeIndex: nextIndex })
	}

	goToIndex(newIndex) {
		if (this.animating) return
		this.setState({ activeIndex: newIndex })
	}

	render() {
		const { activeIndex } = this.state
		const { notifications } = this.props
		const slides = notifications.sort(({ type: previousCode }, { type: currentCode }) => previousCode > currentCode).map((item,index) => {
			return (
				<CarouselItem
					onExiting={this.onExiting}
					onExited={this.onExited}
					key={item.key}
				>
					<img alt="" src={background} />
					<div className="slide-body">
						<CarouselCaption
							activeIndex={activeIndex}
							previous={this.previous}
							baseLocation={this.props.baseLocation}
							total={notifications.length}
							index={index+1}
							item={item}
						/>
					</div>
				</CarouselItem>
			)
		})
		if (notifications.length !== 0) {
			return (
				<Carousel
					interval={10000}
					activeIndex={activeIndex}
					next={this.next}
					previous={this.previous}
				>
					{slides}
					{notifications.length > 1 && <CarouselControl className={'carousel-control'} direction="prev" directionText="Previous"
					                 onClickHandler={this.previous} />}
					{notifications.length > 1 && <CarouselControl className={'carousel-control'} direction="next" directionText="Next"
					                 onClickHandler={this.next} />}
				</Carousel>
			)
		} else return null

	}
}



export default compose(withRouter)(CarouselNote)