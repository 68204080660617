import {Col, CustomInput, FormFeedback, Label, Row} from "reactstrap";
import React from "react";

const RenderSelectFieldQuestion = ({ thematique,disabled, name, label, input, meta: { touched, error }, placeholder }) => {
    return (
        <Row>
            <Col xl={2} lg={3} md={3} sm={3} className={"d-flex align-items-center thematique-label"}>
                {label && <Label className="c-primary ft-s-14">{label}</Label>}
            </Col>
            <Col xl={10} lg={{ size: 9 }} md={{ size: 9 }} sm={{ size: 9 }} xs={{ size: 9 }}>
                <CustomInput {...input} disabled={disabled}  onChange={(value) => {
                    input.onChange(value)
                }} type="select" name={name} id={"categorie"}
                >
                    {thematique.map((theme) =>
                        <option
                            value={theme.code}
                            key={theme.code}
                        >{theme.label}
                        </option>
                    )}
                </CustomInput>
                <FormFeedback valid={!(touched && error)}>
                    <div style={{ display: 'flex', color:'red' }}>
                        <p style={{ fontSize: '12px' }}>{error}</p>
                    </div>
                </FormFeedback>
            </Col>
        </Row>
    )
}
export default RenderSelectFieldQuestion