import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import * as configurationSelectors from '../../../config/metier/configurationSelectors'
import MiniPaper from '../../../global/component/miniPaper/MiniPaper'

const ComponentQuestions = ({ location, history, questionsFrequentes, setIsVisible, thematiques }) => {

	const [theme, setTheme] = React.useState(location.pathname.split('/')[location.pathname.split('/').length - 1])
	const [listQuestions, setListQuestions] = React.useState([])

	const redirectToTarget = (path) => {
		history.push(path)
	}

	const goToQuestion = () => {
		redirectToTarget(`${location.pathname.substring(0, 16)}/aideEtContact/questionsFrequentes?theme=${location.pathname.split('/')[location.pathname.split('/').length - 1]}`)
	}

	const goToQuestionWithIndex = (index) => {
		redirectToTarget(`${location.pathname.substring(0, 16)}/aideEtContact/questionsFrequentes?index=` + index + `&theme=${location.pathname.split('/')[location.pathname.split('/').length - 1]}`)
	}

	React.useEffect(() => {
		setTheme(thematiques)
		if (theme.length <= 2 && Object.keys(questionsFrequentes).length !== 0) {
			let listQuestionsTemp = []
			Object.values(questionsFrequentes).forEach(question => {
				if (question.categorie.includes(theme)) {
					listQuestionsTemp.push(question)
				}
			})
			setListQuestions(listQuestionsTemp)
			if (Object.values(listQuestionsTemp).length === 0) {
				setIsVisible(false)
			}
		}
	}, [theme, questionsFrequentes])

	return (
		listQuestions.length > 0 &&
		<MiniPaper titre={'Questions fréquentes'}
		           sizeTitre={20}
		           className={'questions-frequentes'}
		           boutons={[{
			           texte: 'Voir plus ..',
			           disabled: false,
			           href: '/',
			           action: () => goToQuestion(),
			           couleur: 'blanc'
		           }]}
		           type={'secondary'} icone={''}>
			<ul>
				{Object.values(listQuestions).slice(0, 2).map((question, index) =>
					<li key={index} onClick={() => {
						goToQuestionWithIndex(index)
					}}>{question.question}</li>
				)}
			</ul>
		</MiniPaper>
	)
}

ComponentQuestions.propsType = {
	questionsFrequentes: PropTypes.object
}
const mapStateToProps = (state) => {
	return {
		questionsFrequentes: configurationSelectors.getQuestions(state)
	}
}
export default compose(connect(mapStateToProps), withRouter)(ComponentQuestions)