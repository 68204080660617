import {createSelector} from "reselect";
import {getExerciceActifAssolement} from "../contrat/assolement/assolementSelectors";

const getContratState = (state) => state.metier.contrat
const getLocalState = (state) => state.metier.sinistre

const isSameDate = (dateString, dateDate) => {
	const date1 = new Date(dateString)
	const date2 = new Date(dateDate)
	return date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate()
}

export const getDonneesSinistre = (state) => (getLocalState(state).listeSinistres || []).filter(donnee => {
	// On vérifie que la données n'est pas une déclaration PO, sinon on vérifie qu'il n'existe pas d'autres sinistres ayant la même date que la déclaration
	return (!(donnee.declaration && donnee.declaration.typeDeclaration === 'PO') ||
		!(getLocalState(state).listeSinistres || []).some(donnee2 => {
			return (!(donnee2.declaration && donnee2.declaration.typeDeclaration === 'PO') &&
				(donnee2.sinistre && donnee.declaration.contrat === donnee2.declaration.contrat && isSameDate(donnee2.sinistre.dateSinistre, donnee.declaration.dateSinistre)))
		}))
})


export const getSinitreLoading = (state) => getLocalState(state).isLoading || false

export const getDonneesSinistreParNumero = (state) => getLocalState(state).listeSinistresParNumero || {}

export const getSinistreActif = (state) => getLocalState(state).sinistreActif || null

export const getContratSinistreActif = (state) => getContratState(state).contratActif || null

export const getObservationSinistre = (state) => getLocalState(state).observation || null

export const getExerciceSinistreActif = (state) => getLocalState(state).exerciceActif || ""

export const getListeCultures = (state) => (getLocalState(state).listeCultures || []).filter(culture => !culture.numeroCulture.startsWith('0'))

export const getListeQuittances = (state) => getLocalState(state).listeQuittances || []

export const getDonneesSinistreParContrat = (state) => getLocalState(state).sinistresParContrat || []

export const getDonneesSinistreContratActif = createSelector(getDonneesSinistreParContrat, getContratSinistreActif,
    (sinistres, contratActif) => {
		return sinistres[contratActif] || {}
    })

export const getDonneesSinistreContratExerciceActif = createSelector(getDonneesSinistreContratActif, getExerciceActifAssolement,
    (sinistres, exerciceActif) =>{
	    return sinistres[exerciceActif] || {}
    })

export const getDonneesSinistreContratExerciceSinistreActif = createSelector(getDonneesSinistreContratActif, getExerciceSinistreActif,
    (sinistres, exerciceActif) => sinistres[exerciceActif] || [])

export const getDonneesSinistreActif = createSelector(getDonneesSinistreParNumero, getSinistreActif ,(sinistre, numeroSinistre) => sinistre[numeroSinistre] || {})

export const getDonneesSinistreActifDeclaration  = (state) => getDonneesSinistreActif(state).declaration || {}
export const getDonneesSinistreActifSinistre  = (state) => getDonneesSinistreActif(state).sinistre || {}
export const getDonneesSinistreActifLibellePerils  = (state) => getDonneesSinistreActif(state).libellePeril || ""
export const getDonneesSinistreActifPerils  = (state) => getDonneesSinistreActif(state).perils || []
export const getDonneesSinistreActifParcelles  = (state) => getDonneesSinistreActif(state).parcelles || []
export const getDonneesSinistreActifDocuments  = (state) => getDonneesSinistreActif(state).documents || []
export const getDonneesSinistreActifMissionsEnvoyees  = (state) => getDonneesSinistreActif(state).missionEnvoyees || []
export const getDonneesSinistreActifRetourExpertise  = (state) => getDonneesSinistreActif(state).retourExpertise || []
export const getDonneesSinistreActifDecompte  = (state) => getDonneesSinistreActif(state).decompte || []
export const getDonneesSinistreActifAcomptes  = (state) => getDonneesSinistreActif(state).acomptes || []
export const getDonneesSinistreActifQuittance  = (state) => getDonneesSinistreActif(state).quittance || []
export const getDonneesSinistreActifQuittanceAcompte  = (state) => getDonneesSinistreActif(state).quittanceAcompte || []

