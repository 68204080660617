import React from 'react'
import { Col, FormFeedback, Input, Label, Row } from 'reactstrap'

const InputForm = ({ marge = 'mt-4', modale, facultatif, maxLength, labelSize, placeholder, input, label, type, info, meta: { touched, error } }) => (
	<Row form className={marge}>
		{facultatif ?
			<Col lg={4} md={4}>
				{label &&
				<div style={{ fontSize: labelSize ? labelSize : 'inherit' }}><Label className="c-primary ft-s-14">{label}</Label></div>}
			</Col> :
			<Col lg={4} md={4}>
				{label && <div style={{ fontSize: labelSize ? labelSize : 'inherit' }}><Label className="c-primary ft-s-14">{label}<b
					className="c-red"> *</b></Label></div>}
			</Col>}
		<Col lg={8} md={8}>
			<div>
				<Input {...input} maxLength={maxLength} invalid={touched && !!error} style={{ width: '100%' }} placeholder={placeholder}
				       type={type} />
				<FormFeedback valid={!(touched && error)}>
					<div style={{ display: 'flex' }}>
						<p style={{ fontSize: '12px' }}>{error}</p>
					</div>
				</FormFeedback>
			</div>
		</Col>
	</Row>

)

export default InputForm