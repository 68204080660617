import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Error404 from '../../global/error404/Error404'
import { isContratEnInstanceResiliation, isContratEnInstanceSuspensionOuContencieux, isContratSuspenduOuContencieux } from '../../utils'
import { loadConsultation } from './consultationSinistre/ConsultationInjector'
import ConsultationSinistre from './consultationSinistre/ConsultationSinistre'
import ConsulterIndemnisations from './consulterIndemnisations/ConsulterIndemnisations'
import DeclarationSinistrePage from './declarationSinistre/DeclarationSinistrePage'
import MesSinistresPage from './MesSinistresPage'

export default ({ pathname, pathToHome, isUserAllowed, contratActif, getDonneesContratByContrat, setThematique, setThematiqueFAQ }) => (
	<Switch>
		<Route path={`${pathname}/mesSinistres/suivreSinistre`}
		       component={loadConsultation(ConsultationSinistre, setThematique, setThematiqueFAQ)} />
		<Route path={`${pathname}/mesSinistres`}
		       render={() => <MesSinistresPage setThematique={setThematique} setThematiqueFAQ={setThematiqueFAQ} />} />
		<Route path={`${pathname}/consulterMesIndemnisations`}
		       render={() => <ConsulterIndemnisations setThematique={setThematique} setThematiqueFAQ={setThematiqueFAQ} />} />
		{(isContratEnInstanceResiliation(getDonneesContratByContrat(contratActif).etatCode, getDonneesContratByContrat(contratActif).etatDate)
			|| isContratEnInstanceSuspensionOuContencieux(getDonneesContratByContrat(contratActif).etatCode, getDonneesContratByContrat(contratActif).etatDate)
			|| isContratSuspenduOuContencieux(getDonneesContratByContrat(contratActif).etatCode, getDonneesContratByContrat(contratActif).etatDate))
			|| !isUserAllowed ?
			<Redirect from={`${pathname}/declarerUnSinistre`} to={`${pathToHome}`} /> :
			<Route path={`${pathname}/declarerUnSinistre`}
			       render={() => <DeclarationSinistrePage setThematique={setThematique} setThematiqueFAQ={setThematiqueFAQ} />} />
		}
		<Route path={`${pathToHome}/404`} component={Error404} />
		<Redirect from={`${pathname}/*`} to={`${pathToHome}/404`} />
	</Switch>
)


