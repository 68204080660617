import {createSelector} from "reselect";

const getLocalState = (state) => state.metier.messagerie

export const getMessageActif = (state) => getLocalState(state).messageActif || null

export const getListDiscussions = (state) => getLocalState(state).listeMessagerie || {}

export const getDiscussionActive = createSelector(getListDiscussions, getMessageActif,
    (listeMessagerie, messageActif) => listeMessagerie[messageActif] || {})

export const getMessagesDiscussionActive = (state) => getDiscussionActive(state).messageAssureContenu || []

export const getNombreDiscussionsNonLues = createSelector(getListDiscussions, (listDiscussion) => {
    let nonLue = 0
    Object.values(listDiscussion).forEach((discussion) => {
        if (discussion.messageAssure.nonLu === "1"){
            nonLue++
        }
    })
    return nonLue
})