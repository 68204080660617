import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { enumThematiquesFaq } from '../../../../constantes/FaqThematiques'
import * as donneesMetierActions from '../../../donneesMetierActions'
import * as assolementSelectors from '../../assolement/assolementSelectors'
import * as tarificateurSelectors from '../../tarificateur/tarificateurSelectors'
import MonAssolement from '../MonAssolement'
import MonContrat from '../MonContrat'

const MonAssolementPage = ({ donneesTarificateur, donneesTarificateurContrat, assolementHistoriqueExerciceActif, parcelles, setThematique, setThematiqueFAQ }) => {
	const [childThematique] = React.useState(enumThematiquesFaq.DECLARATION.code)

	useEffect(() => {
		setThematique(childThematique)
		setThematiqueFAQ(childThematique)
	}, [childThematique])

	return (
		<Col md="12" lg="9">
			<Row>
				<Col className="mb-3">
					<MonContrat
						titre="Ma déclaration d'assolement"
						libelleBouton="Télécharger ma déclaration d'assolement"
						isMesGaranties={false}
						action="AttestationAssolement"
						donneesTarificateur={donneesTarificateur}
						preListExercice={Object.keys(donneesTarificateurContrat)}
						texteMulti={'Vous pouvez consulter la déclaration d’assolement de chaque contrat en faisant défiler vos contrats dans le bloc ci-dessous.'}
					/>
				</Col>
			</Row>
			<Row>
				<Col className="mb-3">
					<MonAssolement
						titre="Détail parcellaire"
						libelleBouton="Demander une modification"
						afficherDate={true}
						donneesTarificateur={donneesTarificateur}
						assolementHistoriqueExerciceActif={assolementHistoriqueExerciceActif}
						parcelles={parcelles}
						isHistorique={false}
					/>
				</Col>
			</Row>
		</Col>
	)
}

MonAssolementPage.propTypes = {
	recupHistoriqueAssolements: PropTypes.func,
	donneesTarificateur: PropTypes.object,
	assolementHistoriqueExerciceActif: PropTypes.object,
	donneesTarificateurContrat: PropTypes.object,
	parcelles: PropTypes.array
}

const mapStateToProps = (state) => ({
	donneesTarificateur: tarificateurSelectors.getDonneesTarificateur(state),
	parcelles: assolementSelectors.getHistoriqueParcellesAssolementActif(state),
	assolementHistoriqueExerciceActif: assolementSelectors.getAssolementActif(state),
	donneesTarificateurContrat: tarificateurSelectors.getHistoriqueTarificateurActif(state)
})

const actions = {
	recupHistoriqueAssolements: donneesMetierActions.getHistoriqueAssolement
}

export default connect(mapStateToProps, actions)(MonAssolementPage)
