import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Col, Container, Row } from 'reactstrap'
import { compose } from 'recompose'
import FilAriane from '../../global/filAriane/FilAriane'
import BlockListeMessages from './components/BlockListeMessages'
import * as messagerieActions from './messagerieActions'
import MessagerieRoutes from './messagerieRoutes'
import * as messagerieSelectors from './messagerieselectors'

class ServiceMessageriePage extends React.Component {
	constructor(props) {
		super(props)
		this.props.setMessageActif(null)
	}

	render() {
		const { pathToHome, messageActif } = this.props
		return (
			<div className="background-coordonnees">
				<div className="bg-image-primary bannerSize theme-color"></div>
				<Container className="wrapper-dashboard">
					<FilAriane name="Mes services" path={this.props} />
					<Row>
						<Col md="12" lg={4}>
							<Row>
								<Col xs="12">
									<BlockListeMessages messageActif={messageActif}/>
								</Col>
							</Row>
						</Col>
						<MessagerieRoutes pathToHome={pathToHome} pathname={this.props.pathname} />
					</Row>
				</Container>
			</div>
		)
	}
}

ServiceMessageriePage.propTypes = {
	messageActif: PropTypes.string
}

const mapStateToProps = (state) => {
	return {
		messageActif: messagerieSelectors.getMessageActif(state)
	}
}
const actions = {
	setMessageActif: messagerieActions.setMessageActif
}
export default compose(
	connect(mapStateToProps, actions),
	withRouter
)(ServiceMessageriePage)
