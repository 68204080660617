import * as actions from './tarificateurActions'

const initialTarificateur = {
	isLoading: false,
	tarificateurParContrat: {},
	historiqueTarificateurParContrat: {}
}

const tarificateur = (state = initialTarificateur, action) => {
	switch (action.type) {
		case actions.GET_TARIFICATEUR: {
			return { ...state, isLoading: true }

		}
		case actions.GET_TARIFICATEUR_SUCCES: {
			return {
			    ...state,
				tarificateurParContrat: Object.keys(action.payload).reduce((acc, key) => {
					return {	...acc,
					[parseInt(key).toString()]: (action.payload[key][0] === undefined || action.payload[key][0] === null) ? acc[parseInt(key).toString()] : action.payload[key][0]
				}}, {}),
				isLoading: false
			}
		}
		case actions.GET_TARIFICATEUR_HISTORIQUE_SUCCES: {
			const keys = Object.keys(action.payload);
			const newHistorique = {}
			for (let i = 0; i < keys.length; i++) {
				const key = parseInt(keys[i])
				if(newHistorique[key.toString()] === undefined ){
					if (action.payload[keys[i]].length === 0){
						newHistorique[key.toString()]=[]
					}else {
						newHistorique[key.toString()]=action.payload[keys[i]]
					}
				} else if (action.payload[keys[i]].length !== 0){
					newHistorique[key.toString()]=action.payload[keys[i]]
				}
			}
			action.payload = newHistorique
			return {
			    ...state,
				historiqueTarificateurParContrat: Object.keys(action.payload).reduce((acc, key) => {
					return {
						...acc,
						[parseInt(key).toString()]: action.payload[key].sort((a,b)=>a.exercice - b.exercice)
							.reduce((acc, asso) => {
								return {
									...acc,
									exerciceActif: asso.exercice,
									[(asso.exercice).toString()]: asso
								}
							}, {})
					}
				}, {}),
				isLoading: false
			}
		}
		case actions.GET_TARIFICATEUR_ERROR: {
			return {
				...state,
				isLoading: false
			}
		}
		case actions.SET_TARIFICATEUR_EXERCICE_ACTIF: {
			const contratActif = action.payload.contratActif
			const exerciceActif = parseInt(action.payload.exerciceActif)
			return {
				...state,
				historiqueTarificateurParContrat: {
					...state.historiqueTarificateurParContrat,
					[contratActif]: {
						...state.historiqueTarificateurParContrat[contratActif],
						exerciceActif
					}
				}
			}
		}
		default:
			return state
	}
}


export default tarificateur