import React from 'react'
import {
	Row,
	Col,
	Label,
	Input,
	FormFeedback,
	FormGroup,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button
} from 'reactstrap'
import { Field, reduxForm } from 'redux-form'
import InputFormModale from '../../../global/component/input/InputFormModale'
import PropTypes from 'prop-types'
import InputNumberForm from "../../../global/component/input/InputNumberForm";

const validate = (values) => {
	const errors = {}
	const requiredFields = [
		'adresseNumero',
		'adresseTypeVoie',
		'adresseVoie',
		'codePostal',
		'ville',
		'telephoneMobile',
		'telephoneFixe'
	]
	requiredFields.forEach(field => {
		if(!values[field]) {
			errors[field] = 'Ce champ est obligatoire'
		}
	})
	if (values.adresseComplement && values.adresseComplement.length > 30){
		errors.adresseComplement = 'Le Maximum de caractères pour ce champ est 30'

	}
	return errors
}



const renderFieldAdresse = ({ maxLength, max, input, label, placeholder, type, info, meta: { touched, error } }) => {
	return (
		<FormGroup className="m-0">
			<Input {...input} maxLength={maxLength} invalid={touched && !!error} placeholder={placeholder}
			       style={{ width: '100%' }} type={type}
			/>
			<FormFeedback valid={!(touched && error)}>
				<div style={{ display: 'flex' }}>
					<p style={{ fontSize: '12px' }}>{error}</p>
				</div>
			</FormFeedback>
		</FormGroup>
	)
}

class ModificationCoordonnesForm extends React.Component {

	constructor(props) {
		super(props)
		this.state = {value: ''};
	}
	render() {
		const { handleSubmit, toggle, pristine, submitting, initialValues } = this.props
		return (
			<form onSubmit={handleSubmit}>
				<ModalHeader toggle={toggle}>Mettre à jour mes coordonnées</ModalHeader>
				<ModalBody className="modal-body-coordonnees">
					<p style={{lineHeight:'48px',marginBottom:16}} className="ft-s-allianz-semi-bold text-uppercase ft-s-18">{initialValues.titre} {initialValues.nom} {initialValues.prenom}</p>
					{initialValues.typePm && <p className="ft-s-16" style={{lineHeight:'20px',marginBottom:13}}>Représentée par : <span className="text-uppercase">{initialValues.representantTitre} {initialValues.representantNom} {initialValues.representantPrenom}</span></p>}
					<div className="form-group">
						<Row>
							<Col>
								<Row form className="row-coordonnees">
									<Col md={4}>
										<Label className="ft-s-14 c-primary font-weight-bold">Adresse<b className="c-red"> *</b></Label>
									</Col>
									<Col md={2} sm={2} xs={2}>
										<Field
											name="adresseNumero"
											component={renderFieldAdresse}
											type="text"
											placeholder={'Numero'}
											maxLength={4}
										/>
									</Col>
									<Col md={3} sm={3} xs={3}>
										<Field
											name="adresseTypeVoie"
											component={renderFieldAdresse}
											type="text"
											placeholder={'Type de voie'}
											maxLength={20}
										/>
									</Col>
									<Col md={3} sm={7} xs={7}>
										<Field
											name="adresseVoie"
											component={renderFieldAdresse}
											type="text"
											placeholder={'Nom de la voie'}
											maxLength={30}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col>
								<Field
									name="adresseComplement"
									component={InputFormModale}
									marge="row-coordonnees"
									labelSize={14}
									type="text"
									maxLength={30}
									placeholder={'Complément'}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Field
									name="codePostal"
									component={InputNumberForm}
									labelSize={14}
									marge="row-coordonnees"
									type="text"
									label="Code postal"
									maxLength={5}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Field
									name="ville"
									component={InputFormModale}
									labelSize={14}
									marge="row-coordonnees"
									type="text"
									label="Ville"
									maxLength={30}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Field
									name="telephoneFixe"
									component={InputNumberForm}
									labelSize={14}
									marge="row-coordonnees"
									type="text"
									label="Téléphone fixe"
									maxLength={10}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Field
									name="telephoneMobile"
									component={InputNumberForm}
									labelSize={14}
									marge="row-coordonnees"
									type="text"
									label="Téléphone portable"
									maxLength={10}
								/>
							</Col>
						</Row>
					</div>
				</ModalBody>
				<ModalFooter className={'d-flex justify-content-center'}>
					<Button outline color="light" className="w-25 border-2" onClick={toggle}>Annuler</Button>
					<Button disabled={pristine || submitting} color="light" className="w-25" type="submit">Confirmer</Button>
				</ModalFooter>
				<p className="notabene"><b className="c-red">* </b>Champ obligatoire</p>
			</form>
		)
	}
}

ModificationCoordonnesForm.propTypes = {
	initialValues: PropTypes.object
}


export default reduxForm({
	form: 'modificationCoordonnees',
	destroyOnUnmount: false,
	enableReinitialize: true,
	validate
})(ModificationCoordonnesForm)





