import React from 'react'
import MiniPaper from '../../../global/component/miniPaper/MiniPaper'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import * as selectors from '../contratSelectors'
import * as tarificateurSelectors from '../tarificateur/tarificateurSelectors'
import { withRouter } from 'react-router-dom'
import ShadowScrollbars from "../../../global/component/scrollbar/ShadowScrollbar";
import LigneMultiContrats from "./LigneMultiContrats";
import * as contratActions from '../contratActions'

class MultiContratsDashboard extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.redirectToTarget = this.redirectToTarget.bind(this)
	}

	redirectToTarget = (path) => {
		this.props.history.push(path)
	}

	goToGaranties = (nContrat) => {
		this.props.setContratActif(nContrat)
		this.redirectToTarget(`${this.props.location.pathname}/contrat/garanties`)
	}

	render() {
		const { donneesContrat, donneesTarificateur } = this.props
		return (
			<MiniPaper titre={'Mes contrats'} className={'mon-contract multi'} type={'primary'} icone={'icon icon-umbrella-protection'}>
				<div style={{padding:'12.5px 32px'}} className="d-flex warning-line align-items-center">
					<span style={{paddingLeft:5,fontSize:'22px',color:"#EFBE25"}} className={`d-flex justify-content-center align-items-center h-100 icon-info-circle`}> </span>
					<p style={{paddingLeft:5 }} className="m-0 warning d-flex align-items-center h-100">Vous avez plusieurs contrats en cours.</p>
				</div>
				<ShadowScrollbars style={{ height: 232,marginBottom:21 }} className="list-group" onScrollStart={this.handleScrollStart}>
					{/*todo passer par les selecteurs*/}
					{Object.values(donneesContrat).map((data, numero) =>
						<LigneMultiContrats key={`contrat${numero}`} donneesContrat={data} donneesTarificateur={donneesTarificateur[data.contrat] ? donneesTarificateur[data.contrat] : {}} goToGaranties={this.goToGaranties}/>
					)}
				</ShadowScrollbars>
			</MiniPaper>
		)
	}
}

MultiContratsDashboard.propTypes = {
	donneesContrat: PropTypes.object,
	donneesTarificateur: PropTypes.object,
	setContratActif: PropTypes.func,
	baseLocation: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
	return {
		donneesContrat: selectors.getDonneesAllContrat(state),
		donneesTarificateur: tarificateurSelectors.getTarificateursParContrat(state),
	}
}

const actions = {
	setContratActif: contratActions.setContratActif
}

export default compose(
	connect(mapStateToProps, actions),
	withRouter
)(MultiContratsDashboard)
