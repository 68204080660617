import * as api from '../../../modules/contrat/decomptesProvisoires/decomptesAPI'


export const GET_DECOMPTES = 'GET_DECOMPTES'
export const GET_DECOMPTES_SUCCES = 'GET_DECOMPTES_SUCCES'
export const GET_LIST_DECOMPTES_SUCCES = 'GET_LIST_DECOMPTES_SUCCES'
export const GET_DECOMPTES_ERROR = 'GET_DECOMPTES_ERROR'
export const SET_DECOMPTES_EXERCICE_ACTIF = 'SET_DECOMPTES_EXERCICE_ACTIF'


export const getDecomptesExtranet = (isHistorique) => (dispatch) => {
    dispatch({ type: GET_DECOMPTES })
    return new Promise((resolve, reject) => {
        return api.getDecomptes(isHistorique)
            .then(response => {
                if (response.status !== 200) {
                    resolve(response.json())
                } else {
                    resolve(response.json())
                }
            })
    }).then(response => {
        if(isHistorique){
            return setListDecomptes(response)(dispatch)
        } else {
            return setDecomptes(response)(dispatch)
        }
    }).catch(() => {
        dispatch({ type: GET_DECOMPTES_ERROR })
    })
}

export const setDecomptes = (decomptes) => (dispatch) => {
    dispatch({
        type: GET_DECOMPTES_SUCCES,
        payload: decomptes
    })
}

export const setListDecomptes = (decomptes) => (dispatch) => {
    dispatch({
        type: GET_LIST_DECOMPTES_SUCCES,
        payload: decomptes
    })
}

export const setDecomptesExerciceActif = (response) => (dispatch) => {
    dispatch({
        type: SET_DECOMPTES_EXERCICE_ACTIF,
        payload: response
    })
};