import {Col, Collapse, Row} from "reactstrap";
import React from "react";
import {formatDateTime, openWindowPdf} from "../../../utils";
import Paper from "../../../global/component/paper/Paper";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import * as contratActions from "../../contrat/contratActions";
import * as messageActions from "../../../global/component/messages/messageActions";

const Desistement = ({ messagesErreur, downloadLettreDesistement, sinistreActif, docDesistement }) => {
    const [open, setOpen] = React.useState(true)

    const toggle = () =>{
        setOpen(!open)
    }

    function downloadDocument(idDocument){
        return downloadLettreDesistement(idDocument)
            .then((response) => {
                openWindowPdf(response.url, "Constat Perte", "status=0,height=400,width=600,resizable=1");
            })
            .catch(() => {
                messagesErreur("Consulter la lettre de désistement","Le service de téléchargement de vos documents est actuellement indisponible. Veuillez réessayer ultérieurement.")
            })
    }

    return (
        <Paper className="push-tpl-1">
            <Row className="push-head">
                <Col>
                    <Row>
                        <Col md={12} sm={12} xs={12}>
                            <Row onClick={toggle}>
                                <Col md={11} sm={11} xs={11} className="d-flex align-items-center">
                                    <div className="title">
                                        Désistement
                                    </div>
                                </Col>
                                <Col md={1} sm={1} xs={1} className="text-right m-auto">
                                    <span className={open ? "icon icon-chevron-up c-dark" : "icon icon-chevron-down c-dark"}> </span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Collapse isOpen={open}>
                        <Row>
                            <Col md={12} sm={12} xs={12} className="mt-4">
                                <Row>
                                    <Col md={6} sm={6} xs={6}>Désistement enregistré le {formatDateTime(docDesistement.dateEnvoi)}</Col>
                                    <Col md={6} sm={6} xs={6}>
                                        <Row>
                                            <Col md={12} sm={12} xs={12}>
                                                Lettre de désistement :
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} sm={12} xs={12}>
                                                <ul>
                                                    <div onClick={()=>{downloadDocument(docDesistement.id)}}>
                                                        <li><div className="lienCliquable">Déclaration de désistement du {formatDateTime(docDesistement.dateEnvoi)}</div></li>
                                                    </div>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Collapse>
                </Col>
            </Row>
        </Paper>
    )
}
Desistement.propTypes = {
    downloadLettreDesistement:PropTypes.func,
    messagesErreur:PropTypes.func,
}
const actions = {
    downloadLettreDesistement: contratActions.downloadLettreDesistement,
    messagesErreur:messageActions.messagesErreur
}
export default connect(null, actions) (Desistement)