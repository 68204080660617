import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormFeedback, FormGroup, Input, ListGroup, ListGroupItem, Popover } from 'reactstrap'

class AutoCompleteField extends Component {

	constructor(props) {
		super(props)
		this.state = {
			textValue: this.props.defaultValue,
			focus: false
		}
		this.handleFocus = this.handleFocus.bind(this)
		this.handleBlur = this.handleBlur.bind(this)
		this.handleChangeTextValue = this.handleChangeTextValue.bind(this)
		this.handleSelect = this.handleSelect.bind(this)
		this.makeSuggestions = this.makeSuggestions.bind(this)
		this.removeSelection = this.removeSelection.bind(this)
		this.input = React.createRef()
	}


	componentWillReceiveProps(nextProps) {
		if (nextProps.value && this.state.textValue === '') {
			this.setState({ textValue: nextProps.value[nextProps.displayParamName] })
		}
	}

	handleFocus() {
		this.setState({ focus: true })
	}

	handleBlur() {
		this.setState({ focus: false })
	}

	handleChangeTextValue(value) {
		this.props.fetchAction(value)
		this.props.setCulture && this.props.setCulture(value)
		if (this.props.value) {
			this.removeSelection()
		} else {
			this.setState({ textValue: value })
		}
	}

	makeSuggestions() {
		const { suggestions, maxSuggestions, displayParamName } = this.props
		const { textValue } = this.state
		// return suggestions.filter(suggestion => console.log(suggestion[displayParamName].toLowerCase()))
		return suggestions.filter(suggestion => suggestion[displayParamName].toLowerCase().includes(textValue.toLowerCase())).slice(0, maxSuggestions)
	}

	handleSelect(value) {
		this.setState({ textValue: value[this.props.displayParamName] })
		this.props.onSelect(value)
		this.handleBlur()
	}

	removeSelection() {
		this.setState({ textValue: '' })
		this.props.onSelect(null)
	}

	render() {
		const { suggestions, name, displayParamName, submitFailed, value } = this.props
		const { focus, textValue } = this.state
		return (
			<div>
				<FormGroup>
					<Input
						type="text"
						name={name}
						id={name}
						innerRef={this.input}
						value={textValue}
						invalid={submitFailed && value == null}
						onChange={(e) => this.handleChangeTextValue(`${e.target.value}`)}
						onBlur={this.handleBlur}
						onFocus={this.handleFocus}
					/>
					<FormFeedback>
						<p style={{ fontSize: '12px' }}>Ce champ est obligatoire</p>
					</FormFeedback>
				</FormGroup>
				<Popover placement="bottom" isOpen={suggestions.length > 0 && focus} target={name} container="inline">
					{this.makeSuggestions().map((suggestion, index) => {
						return (
							<ListGroup key={`${suggestion[displayParamName]}-${index}`} onMouseDown={() => this.handleSelect(suggestion)} style={{margin: 0}}>
								<ListGroupItem className="autocomplete-item">{suggestion[displayParamName]}</ListGroupItem>
							</ListGroup>
						)
					})
					}
				</Popover>
			</div>
		)
	}

}

AutoCompleteField.propTypes = {
	suggestions: PropTypes.array.isRequired,
	displayParamName: PropTypes.string.isRequired,
	onSelect: PropTypes.func.isRequired,
	floatingLabelText: PropTypes.string.isRequired,
	defaultValue: PropTypes.string,
	maxSuggestions: PropTypes.number.isRequired,
	value: PropTypes.object,
	fetchAction: PropTypes.func
}

AutoCompleteField.defaultProps = {
	suggestions: [],
	onSelect: () => {
	},
	fetchAction: () => {
	},
	floatingLabelText: '',
	maxSuggestions: 5
}


export default AutoCompleteField
