import React from 'react'
import { Container,Row, Col, Button, Form, FormGroup, Label, Input, FormFeedback, FormText, CustomInput, Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const ChartePage = () => {
	return (
		 <div>

			 {/*COLORS THEME*/}
			 <Container className="my-5">
			 <Row className="head mb-3">
				 <Col>
					 <h2>Color Theme</h2>
				 </Col>
			 </Row>
			 <Row className="body">
				 <Col xs={4}>
					 <div className="charte-color mb-3">
						 <div className="mr-2 bg-primary ring"></div>
						 <p className="m-0">Vert La Rurale</p>
					 </div>
					 <div className="charte-color mb-3">
						 <div className="mr-2 bg-light ring"></div>
						 <p className="m-0">Bright Action</p>
					 </div>
					 <div className="charte-color mb-3">
						 <div className="mr-2 bg-dark ring"></div>
						 <p className="m-0">Dark Action</p>
					 </div>
					 <div className="charte-color mb-3">
						 <div className="mr-2 bg-white ring"></div>
						 <p className="m-0">Blanc</p>
					 </div>
				 </Col>
				 <Col xs={4}>
					 <div className="charte-color mb-3">
						 <div className="mr-2 bg-lighten-1 ring"></div>
						 <p className="m-0">Fond liste 1</p>
					 </div>
					 <div className="charte-color mb-3">
						 <div className="mr-2 bg-lighten-2 ring"></div>
						 <p className="m-0">Fond liste 2</p>
					 </div>
					 <div className="charte-color mb-3">
						 <div className="mr-2 bg-lighten-3 ring"></div>
						 <p className="m-0">Fond liste 3</p>
					 </div>
					 <div className="charte-color mb-3">
						 <div className="mr-2 bg-darken-3 ring"></div>
						 <p className="m-0">Text color</p>
					 </div>
				 </Col>

				 <Col xs={4}>
					 <div className="charte-color mb-3">
						 <div className="mr-2 bg-danger ring"></div>
						 <p className="m-0">Error color</p>
					 </div>
					 <div className="charte-color mb-3">
						 <div className="mr-2 bg-success ring"></div>
						 <p className="m-0">Success color</p>
					 </div>
					 <div className="charte-color mb-3">
						 <div className="mr-2 bg-warning ring"></div>
						 <p className="m-0">Warning color</p>
					 </div>
					 <div className="charte-color mb-3">
						 <div className="mr-2 bg-info ring"></div>
						 <p className="m-0">Info color</p>
					 </div>
				 </Col>
			 </Row>
		 </Container>

			 <hr/>

			 {/*BUTTON THEME*/}
			 <Container className="my-5">
				 <Row className="head mb-3">
					 <Col>
						 <h2>Buttons Theme</h2>
					 </Col>
				 </Row>
				 <Row className="body">
					 <Col>
						 <Row>
							 <Col xs={2}>
								 <p className="m-0">Primary Action</p>
							 </Col>
							 <Col xs={2}>
								 <div className="charte-button mb-3">
									 <Button color="light" size="lg" className="btn-block">Normal</Button>
								 </div>
							 </Col>
							 <Col xs={2}>
								 <div className="charte-button mb-3">
									 <Button color="light" size="lg" className="btn-block">Hover</Button>
								 </div>
							 </Col>
							 <Col xs={2}>
								 <div className="charte-button mb-3">
									 <Button active color="light" size="lg" className="btn-block">Pressed</Button>
								 </div>
							 </Col>
							 <Col xs={2}>
								 <div className="charte-button mb-3">
									 <Button disabled color="light" size="lg" className="btn-block">Disabled</Button>
								 </div>
							 </Col>
						 </Row>
						 <Row>
							 <Col xs={2}>
								 <p className="m-0">Secondary Action</p>
							 </Col>
							 <Col xs={2}>
								 <div className="charte-button mb-3">
									 <Button outline color="light" size="lg" className="btn-block">Normal</Button>
								 </div>
							 </Col>
							 <Col xs={2}>
								 <div className="charte-button mb-3">
									 <Button outline color="light" size="lg" className="btn-block">Hover</Button>
								 </div>
							 </Col>
							 <Col xs={2}>
								 <div className="charte-button mb-3">
									 <Button outline active color="light" size="lg" className="btn-block">Pressed</Button>
								 </div>
							 </Col>
							 <Col xs={2}>
								 <div className="charte-button mb-3">
									 <Button outline disabled color="light" size="lg" className="btn-block">Disabled</Button>
								 </div>
							 </Col>
						 </Row>
					 </Col>
				 </Row>
			 </Container>

			 <hr/>

			 {/*INPUT FIELD THEME*/}
			 <Container className="my-5">
				 <Row className="head mb-3">
					 <Col>
						 <h2>Input field Theme</h2>
					 </Col>
				 </Row>
				 <Row className="body">
					 <Col>
						 <Form>
							 <Row form>
								 <Col md={4}>
									 <FormGroup>
										 <Label for="exampleEmail">Label et Input standard</Label>
										 <Input placeholder="With a placeholder" />
										 <FormText>Message neutre exemple</FormText>
									 </FormGroup>
								 </Col>
								 <Col md={4}>
									 <FormGroup>
										 <Label for="exampleEmail" className='is-valid'>Valid input</Label>
										 <Input valid />
										 <FormFeedback valid>Message de validation</FormFeedback>
									 </FormGroup>
								 </Col>
								 <Col md={4}>
									 <FormGroup>
										 <Label for="examplePassword" className='is-invalid'>Invalid input</Label>
										 <Input invalid />
										 <FormFeedback>Message d'erreur</FormFeedback>
									 </FormGroup>
								 </Col>
							 </Row>
							 <Row form>
								 <Col md={4}>
									 <FormGroup>
										 <Label for="exampleEmail">Input disabled</Label>
										 <Input disabled placeholder="With a placeholder" />
										 <FormFeedback tooltip>You will not be able to see this</FormFeedback>
									 </FormGroup>
								 </Col>
							 </Row>
						 </Form>
					 </Col>
				 </Row>
			 </Container>

			 <hr/>

			 {/*DROPDOWN THEME*/}
			 <Container className="my-5">
				 <Row className="head mb-3">
					 <Col>
						 <h2>Dropdown Theme</h2>
					 </Col>
				 </Row>
				 <Row className="body">
					 <Col>
						 <Form>
							 <Row form>
								 <Col md={4}>
									 <FormGroup>
										 <Label for="exampleCustomSelect">Select Standard</Label>
										 <CustomInput type="select" id="exampleCustomSelect" name="customSelect">
											 <option value="">Select</option>
											 <option>Value 1</option>
											 <option>Value 2</option>
											 <option>Value 3</option>
											 <option>Value 4</option>
											 <option>Value 5</option>
										 </CustomInput>
									 </FormGroup>
								 </Col>
								 <Col md={4}>
									 <FormGroup>
										 <Label for="exampleCustomSelect" className="is-valid">Select Valid</Label>
										 <CustomInput valid type="select" id="exampleCustomSelect" name="customSelect">
											 <option value="">Select</option>
											 <option>Value 1</option>
											 <option>Value 2</option>
											 <option>Value 3</option>
											 <option>Value 4</option>
											 <option>Value 5</option>
										 </CustomInput>
										 <FormFeedback valid>Message de validation</FormFeedback>
									 </FormGroup>
								 </Col>
								 <Col md={4}>
									 <FormGroup>
										 <Label for="exampleCustomSelect"  className="is-invalid">Select Invalid</Label>
										 <CustomInput invalid type="select" id="exampleCustomSelect" name="customSelect">
											 <option value="">Select</option>
											 <option>Value 1</option>
											 <option>Value 2</option>
											 <option>Value 3</option>
											 <option>Value 4</option>
											 <option>Value 5</option>
										 </CustomInput>
										 <FormFeedback>Message d'erreur</FormFeedback>
									 </FormGroup>
								 </Col>
							 </Row>
							 <Row form>
								 <Col md={4}>
									 <FormGroup>
										 <Label for="exampleCustomSelect">Select Disabled</Label>
										 <CustomInput disabled type="select" id="exampleCustomSelect" name="customSelect">
											 <option value="">Select</option>
											 <option>Value 1</option>
											 <option>Value 2</option>
											 <option>Value 3</option>
											 <option>Value 4</option>
											 <option>Value 5</option>
										 </CustomInput>
									 </FormGroup>
								 </Col>
							 </Row>
						 </Form>
					 </Col>
				 </Row>
			 </Container>

			 <hr/>

			 {/*RADIO & CHECKBOX BUTTON THEME*/}
			 <Container className="my-5">
				 <Row className="head mb-3">
					 <Col>
						 <h2>Radio & Checkbox Theme</h2>
					 </Col>
				 </Row>
				 <Row className="body">
					 <Col >
						 <Form>
							 <Row>
								 <Col md={6}>
									 <FormGroup>
										 <Label for="exampleCheckbox">Radios</Label>
										 <div>
											 <CustomInput type="radio" id="exampleCustomRadio" name="customRadio" label="Select this custom radio" checked />
											 <CustomInput type="radio" id="exampleCustomRadio2" name="customRadio" label="Or this one" />
											 <CustomInput type="radio" id="exampleCustomRadio3" label="But not this disabled one" checked disabled />
											 <CustomInput type="radio" id="exampleCustomRadio4" label="Can't click this label to select!" htmlFor="exampleCustomRadio4_X" disabled />
										 </div>
									 </FormGroup>
								 </Col>
								 <Col md={6}>
									 <FormGroup>
										 <Label for="exampleCheckbox">Checkbox</Label>
										 <div>
											 <CustomInput type="checkbox" id="exampleCustomCheckbox" label="Check this custom checkbox" checked />
											 <CustomInput type="checkbox" id="exampleCustomCheckbox2" label="Or this one" />
											 <CustomInput type="checkbox" id="exampleCustomCheckbox3" label="But not this disabled one" checked disabled />
											 <CustomInput type="checkbox" id="exampleCustomCheckbox4" label="Can't click this label to check!" htmlFor="exampleCustomCheckbox4_X" disabled />
										 </div>
									 </FormGroup>
								 </Col>
							 </Row>
						 </Form>
					 </Col>
				 </Row>
			 </Container>

			 <hr/>

			 {/*PAGINATION THEME*/}
			 <Container className="my-5">
				 <Row className="head mb-3">
					 <Col>
						 <h2>Pagination Theme</h2>
					 </Col>
				 </Row>
				 <Row className="body">
					 <Col>
						 <Pagination size="sm" aria-label="Page navigation example">

							 <PaginationItem>
								 <PaginationLink previous href="#">
									 <span className="icon-chevron-small-left"></span>
									 <div>Parcelles précédentes</div>
								 </PaginationLink>
							 </PaginationItem>
							 <PaginationItem active>
								 <PaginationLink href="#">
									 1
								 </PaginationLink>
							 </PaginationItem>
							 <PaginationItem>
								 <PaginationLink href="#">
									 2
								 </PaginationLink>
							 </PaginationItem>
							 <PaginationItem>
								 <PaginationLink href="#">
									 3
								 </PaginationLink>
							 </PaginationItem>
							 <PaginationItem disabled>
								 <PaginationLink next href="#">
									 <div>Parcelles suivantes</div>
									 <span className="icon-chevron-tree-navi"></span>
								 </PaginationLink>
							 </PaginationItem>

						 </Pagination>
					 </Col>
				 </Row>
			 </Container>

			 <hr/>

			 {/*TOOLTIP THEME*/}
			 <Container className="my-5">
				 <Row className="head mb-3">
					 <Col>
						 <h2>Icons Theme</h2>
					 </Col>
				 </Row>
				 <Row className="body charte-icon">
						<Col className='d-flex flex-wrap'>
						<span className="icon-c-icon-vid-plain--dark-grey">

						</span>
						<span className="icon-c-icon-xls-plain--dark-grey">

						</span>
						<span className="icon-c-icon-eps-plain--dark-grey">

						</span>
						<span className="icon-c-icon-img-plain-grey">

						</span>
						<span class="icon-c-icon-jpg-plain--dark-grey">

						</span>
						<span className="icon-c-icon-mp3-plain--dark-grey">

						</span>
						<span className="icon-c-icon-pdf-plain--dark-grey">

						</span>
						<span className="icon-c-icon-pod-plain--dark-grey">

						</span>
						<span className="icon-c-icon-ppt-plain--dark-grey">

						</span>
						<span className="icon-c-icon-tiff-plain--dark-grey">

						</span>
						<span className="icon-c-icon-txt-plain--dark-grey">

						</span>
						<span className="icon-arrow-down">

						</span>
						<span className="icon-arrow-first">

						</span>
						<span className="icon-arrow-left">

						</span>
						<span className="icon-arrow-left-slim">

						</span>
						<span className="icon-arrow-right">

						</span>
						<span className="icon-arrow-right-slim">

						</span>
						<span className="icon-arrow-up">

						</span>
						<span className="icon-bars">

						</span>
						<span className="icon-bell">

						</span>
						<span className="icon-bell-o">

						</span>
						<span className="icon-bell-off">

						</span>
						<span className="icon-bell-off-o">

						</span>
						<span className="icon-bolt">

						</span>
						<span className="icon-bolt-o">

						</span>
						<span className="icon-bookmark">

						</span>
						<span className="icon-bookmark-plain">

						</span>
						<span className="icon-bulletpoint">

						</span>
						<span className="icon-calendar">

						</span>
						<span className="icon-calendar-plain">

						</span>
						<span className="icon-cards">

						</span>
						<span className="icon-chat-o">

						</span>
						<span className="icon-chat-plain">

						</span>
						<span className="icon-check">

						</span>
						<span className="icon-check-box">

						</span>
						<span className="icon-check-box-outline-blank">

						</span>
						<span className="icon-check-circle">

						</span>
						<span className="icon-check-circle-o">

						</span>
						<span className="icon-chevron-down">

						</span>
						<span className="icon-chevron-dropdown">

						</span>
						<span className="icon-chevron-left">

						</span>
						<span className="icon-chevron-left-circle-o">

						</span>
						<span className="icon-chevron-left--circle-plain">

						</span>
						<span className="icon-chevron-right">

						</span>
						<span className="icon-chevron-right-circle-o">

						</span>
						<span className="icon-chevron-right--circle-plain">

						</span>
						<span className="icon-chevron-small-left">

						</span>
						<span className="icon-chevron-small-up">

						</span>
						<span className="icon-chevron-tree-navi">

						</span>
						<span className="icon-chevron-up">

						</span>
						<span className="icon-c-icon-microphone">

						</span>
						<span className="icon-c-icon-microphone-plain">

						</span>
						<span className="icon-clock">

						</span>
						<span className="icon-clock-o">

						</span>
						<span className="icon-close">

						</span>
						<span className="icon-close-circle">

						</span>
						<span className="icon-cobrowsing">

						</span>
						<span className="icon-credit-card">

						</span>
						<span className="icon-date-check">

						</span>
						<span className="icon-date-check-plain">

						</span>
						<span className="icon-download">

						</span>
						<span className="icon-duplicate">

						</span>
						<span className="icon-edit">

						</span>
						<span className="icon-edit-pen">

						</span>
						<span className="icon-ellipsis-h">

						</span>
						<span className="icon-ellipsis-v">

						</span>
						<span className="icon-emails-read-o">

						</span>
						<span className="icon-emails-read-plain">

						</span>
						<span className="icon-exclamation-circle">

						</span>
						<span className="icon-exclamation-circle-o">

						</span>
						<span className="icon-exclamation-triangle">

						</span>
						<span className="icon-exclamation-triangle-o">

						</span>
						<span className="icon-export">

						</span>
						<span className="icon-facebook">

						</span>
						<span className="icon-facebook-circle">

						</span>
						<span className="icon-file">

						</span>
						<span className="icon-file-text">

						</span>
						<span className="icon-filter">

						</span>
						<span className="icon-google-circle">

						</span>
						<span className="icon-google-plus">

						</span>
						<span className="icon-hide-password-o">

						</span>
						<span className="icon-hide-password-plain">

						</span>
						<span className="icon-indeterminate-check-box">

						</span>
						<span className="icon-info-circle">

						</span>
						<span className="icon-info-circle-o">

						</span>
						<span className="icon-in-progress">

						</span>
						<span className="icon-instagram">

						</span>
						<span className="icon-instagram-circle">

						</span>
						<span className="icon-interpunct">

						</span>
						<span className="icon-launch">

						</span>
						<span className="icon-linkedin-circle">
						<span className="path1"></span>
						<span className="path2"></span>
						<span className="path3"></span>
						<span className="path4"></span>
						</span>
						<span className="icon-linkedin-o">

						</span>
						<span className="icon-list">

						</span>
						<span className="icon-location-o">

						</span>
						<span className="icon-location-plain">

						</span>
						<span className="icon-lock">

						</span>
						<span className="icon-lock-o">

						</span>
						<span className="icon-mail-circle">

						</span>
						<span className="icon-mail-circle-o">

						</span>
						<span className="icon-messanger-circle">
						<span className="path1"></span>
							<span className="path2"></span>
						</span>
						<span className="icon-messanger-o">

						</span>
						<span className="icon-minus">

						</span>
						<span className="icon-minus-circle">

						</span>
						<span className="icon-minus-circle-o">

						</span>
						<span className="icon-mute-o">

						</span>
						<span className="icon-mute-plain">

						</span>
						<span className="icon-paperclip">

						</span>
						<span className="icon-pause">

						</span>
						<span className="icon-phone">

						</span>
						<span className="icon-phone-chat">

						</span>
						<span className="icon-phone-chat-o">

						</span>
						<span className="icon-phone-o">

						</span>
						<span className="icon-play">

						</span>
						<span className="icon-plus">

						</span>
						<span className="icon-plus-circle">

						</span>
						<span className="icon-plus-circle-o">

						</span>
						<span className="icon-popout">

						</span>
						<span className="icon-print">

						</span>
						<span className="icon-print-plain">

						</span>
						<span className="icon-question">

						</span>
						<span className="icon-question-o">

						</span>
						<span className="icon-radio-button-checked">

						</span>
						<span className="icon-radio-button-unchecked">

						</span>
						<span className="icon-refresh">

						</span>
						<span className="icon-save">

						</span>
						<span className="icon-search">

						</span>
						<span className="icon-sentiment-dissatisfied">

						</span>
						<span className="icon-sentiment-neutral">

						</span>
						<span className="icon-sentiment-satisfied">

						</span>
						<span className="icon-sentiment-very-dissatisfied">

						</span>
						<span className="icon-sentiment-very-satisfied">

						</span>
						<span className="icon-set-reminder-o">

						</span>


						<span className="icon-set-reminder-plain">

						</span>


						<span className="icon-set-timer">

						</span>


						<span className="icon-setting">

						</span>


						<span className="icon-setting-o">

						</span>


						<span className="icon-share">

						</span>


						<span className="icon-show-password-o">

						</span>


						<span className="icon-show-password-plain">

						</span>


						<span className="icon-sort-down">

						</span>


						<span className="icon-sort-up">

						</span>


						<span className="icon-speak-o">

						</span>


						<span className="icon-speak-plain">

						</span>


						<span className="icon-speak-text-o">

						</span>


						<span className="icon-speak-text-plain">

						</span>


						<span className="icon-speak-wait-o">

						</span>


						<span className="icon-speak-wait-plain">

						</span>


						<span className="icon-star">

						</span>


						<span className="icon-star-half-full">

						</span>


						<span className="icon-star-o">

						</span>


						<span className="icon-telegram">

						</span>


						<span className="icon-trash">

						</span>


						<span className="icon-trash-plain">

						</span>


						<span className="icon-twitter">

						</span>


						<span className="icon-twitter-circle">

						</span>


						<span className="icon-unlock">

						</span>


						<span className="icon-unlock-o">

						</span>


						<span className="icon-upload">

						</span>


						<span className="icon-user-business-o">

						</span>


						<span className="icon-user-o">

						</span>


						<span className="icon-whatsapp-circle">
						<span className="path1"></span>
						<span className="path2"></span>
						</span>


						<span className="icon-whatsapp-o">

						</span>


						<span className="icon-xing-circle">
						<span className="path1"></span>
						<span className="path2"></span>
						<span className="path3"></span>
						</span>



						<span className="icon-xing-o">

						</span>


						<span className="icon-youtube-circle">

						</span>


						<span className="icon-youtube-circle-o">

						</span>

							{/*{*/}
								{/*['youtube-circle-o',*/}
									{/*'youtube-circle'].map(name => (*/}
									 {/*<Icon name={name} />*/}
								{/*))*/}

							{/*}*/}
					 </Col>
				 </Row>
			 </Container>

		 </div>
	)
}


// const Icon = ({ name, children }) => <span className={`icon icon-${name}`}>{children}</span>

export default ChartePage
