import React from 'react'
import PropTypes from 'prop-types'
import Captcha from '../../global/component/captcha/Captcha'
import InputForm from '../../global/component/input/InputForm'
import {
	Button,
	Modal,
	FormGroup,
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'reactstrap'
import { Field, reduxForm } from 'redux-form'
import Form from 'reactstrap/es/Form'
import { isEmailValid} from "../../utils";

const validate = values => {
	const errors = {}
	const requiredFields = [
		'mail',
		'numeroClient',
		'captcha'
	]
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = 'Ce champ est obligatoire.'
		}
	})
	if (values.mail && !isEmailValid(values.mail)) {
		errors.mail = 'L\'adresse e-mail renseignée est incorrecte'
	}
	if (values.numeroClient && !/^[A-Za-z0-9]{8}$/.test(values.numeroClient)) {
		errors.numeroClient = 'Votre numéro client est incorrect'
	}
	return errors
}

class ModaleEtape1 extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			openMessage: false,
			mail: '',
			numeroClient: '',
			error: ''
		}
	}


	render() {
		const { isOpened, toggle, className, pristine, submitting, handleSubmit } = this.props
		return (
				<Modal isOpen={isOpened} toggle={toggle} className={className + ' small'}>
					<Form onSubmit={handleSubmit}>
						<ModalHeader >Vous avez oublié votre mot de passe ?</ModalHeader>
						<ModalBody>
							<p style={{fontSize:16}}>Pour réinitialiser votre mot de passe, veuillez saisir votre adresse e-mail et votre N° Client.</p>
							<FormGroup>
								<Field
									name="mail"
									component={InputForm}
									type="mail"
									labelSize={14}
									label="Adresse e-mail"
									info=""
								/>
								<Field
									name="numeroClient"
									maxLength={8}
									labelSize={14}
									component={InputForm}
									type="numbers"
									label="N°Client"
								/>
								<Field
									name="captcha"
									component={Captcha}
								/>
							</FormGroup>
						</ModalBody>
						<ModalFooter className="footer-reinit d-flex justify-content-center">
							<Button onClick={toggle} outline color="light" size="sm" className="btn-block border-2 w-25 m-1">Annuler</Button>
							<Button
								type="submit"
								disabled={pristine || submitting}
								color="light" size="sm" className="btn-block w-25 m-1"
							>Confirmer</Button>
						</ModalFooter>
						<p className="notabene"><b className="c-red">* </b>Champ obligatoire</p>
					</Form>
				</Modal>
		)
	}
}

ModaleEtape1.propTypes = {
	isOpened: PropTypes.bool,
	toggle: PropTypes.func.isRequired,
	pristine: PropTypes.bool,
	submitting: PropTypes.bool,
	handleSubmit: PropTypes.func.isRequired

}

export default reduxForm({
	form: 'reinitialisationDemande',
	validate,
	touchOnBlur: false
})(ModaleEtape1)