import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Button, Col, Label, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { compose } from 'recompose'
import { Field, reduxForm } from 'redux-form'
import InputForm from '../../../global/component/input/InputForm'
import { loadListeCommunes } from '../../metierUtils/metierUtilsApi'
import * as sinistreSelectors from '../../sinistre/sinistreSelectors'
import AutoCompleteField from './CustomFields/AutoCompleteField'
import RenderNumberField from './CustomFields/RenderNumberField'

const validate = (values) => {
	const errors = {}
	const requiredFields = [
		'departement',
		'canton',
		'arrondissement',
		'commune',
		'surfaceTotale',
		'surfaceSinistree',
		'cultureChoisie'
	]
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = 'Ce champ est obligatoire'
		}
	})
	if (parseFloat(values.surfaceTotale) < parseFloat(values.surfaceSinistree)){
		errors.surfaceSinistree = 'La surface sinistrée doit être inférieur à la surface totale'
	}

	return errors
}

const FormAjoutParcelles = ({ change, handleSubmit, toggle, pristine, submitting,submitFailed, listeCultures }) => {
	const [listeCommunes, setListeCommunes] = React.useState([])
	const [commune, setCommune] = React.useState(null)
	const [cultureChoisie, setCultureChoisie] = React.useState(null)
	const [field, setFieldSelected] = React.useState(null)

	const setCultureParcelle = (value) => {
		setCultureChoisie(value)
		change('cultureChoisie', value)
	}
	const chargeListeCommune = (value) => {
		loadListeCommunes(value)
			.then(listeCommunes => {
				setListeCommunes(listeCommunes)
			})
	}

	const setCommuneParcelle = (value) => {
		setCommune(value)
		change('commune', value)
	}
	return (
		<form style={{ padding: '26px 16px 0 16px' }} onSubmit={handleSubmit}>
			<ModalHeader toggle={toggle}>Ajouter une parcelle sinistrée</ModalHeader>
			<ModalBody>
				<div className="form-group sinistre" style={{ paddingTop: 18 }}>
					<Row className="parcelle-sinistre">
						<Col>
							<Row form>
								<Col lg={3} md={4}>
									<Label className="c-primary ft-s-14">Commune<b className="c-red"> *</b></Label>
								</Col>
								<Col lg={9} md={8}>
									<AutoCompleteField
										displayParamName="nomCommune"
										onSelect={setCommuneParcelle}
										name={'commune'}
										submitFailed={submitFailed}
										maxSuggestions={5}
										fetchAction={chargeListeCommune}
										suggestions={listeCommunes}
										defaultValue=""
										value={commune}
										index={"commune"}
										parcelleSelected={field}
										selectParcelle={(index)=> {setFieldSelected(index)}}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className="parcelle-sinistre">
						<Col>
							<Field
								name="lieuDit"
								component={InputForm}
								type="text"
								marge=""
								facultatif={true}
								label="Lieu-dit"
								maxLength={50}
							/>
						</Col>
					</Row>
					<Row className="parcelle-sinistre">
						<Col>
							<Row form>
								<Col lg={3} md={4}>
									<Label className="c-primary ft-s-14">Culture<b className="c-red"> *</b></Label>
								</Col>
								<Col lg={9} md={8}>
									<AutoCompleteField
										displayParamName="nom"
										submitFailed={submitFailed}
										onSelect={setCultureParcelle}
										maxSuggestions={5}
										suggestions={listeCultures}
										name={'cultureChoisie'}
										defaultValue=""
										value={cultureChoisie}
										index={"culture"}
										parcelleSelected={field}
										selectParcelle={(index)=> {setFieldSelected(index)}}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className="parcelle-sinistre">
						<Col>
							<Row form>
								<Col lg={3} md={4}>
									<Label style={{ maxWidth: 125 }} className="c-primary ft-s-14">Surface totale de la parcelle<b
										className="c-red"
									> *</b></Label>
								</Col>
								<Col lg={9} md={8}>
									<Field
										name="surfaceTotale"
										component={RenderNumberField}
										type="text"
										placeholder="0,0000"
										className="w-100"
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className="parcelle-sinistre">
						<Col>
							<Row form>
								<Col lg={3} md={4}>
									<Label className="c-primary ft-s-14">Surface sinistrée<b className="c-red"> *</b></Label>
								</Col>
								<Col lg={9} md={8}>
									<Field
										name="surfaceSinistree"
										component={RenderNumberField}
										type="text"
										placeholder="0,0000"
										className="w-100"
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className="mt-4 d-flex warning-line">
						<Col md={1} sm={1} xs={1}>
					<span
						style={{ fontSize: '20px', color: '#EFBE25' }}
						className={`d-flex justify-content-flex-start align-items-center h-100 icon-info-circle`}
					> </span>
						</Col>
						<Col md={11} sm={11} xs={11}>
							<p
								style={{ fontSize: 13 }}
								className="warning d-flex align-items-center h-100"
							>Cette nouvelle parcelle ne sera prise en compte que dans votre déclaration de sinistre. Elle n'impactera pas votre déclaration d'assolement.</p>
						</Col>
					</Row>
				</div>
			</ModalBody>
			<ModalFooter className={'d-flex justify-content-center'}>
				<Button outline color="light" className="w-25 border-2" onClick={toggle}>Annuler</Button>
				<Button disabled={pristine || submitting} color="light" className="w-25" type="submit">Confirmer</Button>
			</ModalFooter>
			<p className="notabene"><b className="c-red">* </b>Champ obligatoire</p>
		</form>
	)
}
FormAjoutParcelles.propTypes = {
	listeCultures: PropTypes.array
}
const mapStateToProps = (state) => {
	return {
		listeCultures: sinistreSelectors.getListeCultures(state)
	}
}
export default compose(
	reduxForm({
		form: 'ajoutParcelles',
		validate,
		touchOnBlur: false
	}),
	connect(mapStateToProps)
)(FormAjoutParcelles)