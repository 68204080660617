import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Col, Row } from 'reactstrap'
import { compose } from 'recompose'
import { downloadFile } from '../../../fileUtils'
import { formatDateTimeHeure } from '../../../utils'
import * as agenceSelector from '../../agence/agenceSelectors'
import * as infoClientSelectors from '../../coordonnees/coordonneesSelectors'
import * as messagerieActions from '../messagerieActions'

const IS_NOT_PDF = false
const Message = ({ agence, first, infoClient, message, repondre, buttonVisible, setButtonVisible, getFile }) => {

	let nom = infoClient.nom.split(' ')[0]
	let prenom = infoClient.nom.split(' ')[1]
	let initiales = undefined
	if (nom != null && prenom != null) {
		initiales = nom.charAt(0) + prenom.charAt(0)
	} else if (nom != null && prenom == null) {
		initiales = nom.charAt(0)
	} else if (nom == null && prenom != null) {
		initiales = prenom.charAt(0)
	}
	return (
		<Row className={'messages mb-4'}>
			<Col lg={12} sm={12} md={12}>
				<Row className={'mb-3'}>
					<Col lg={1} md={1} sm={2} xs={2}>
						{agence.societe === '01' && message.uidUser === -1 &&
						<div className="img-petit-logo-rurale" />
						}
						{agence.societe === '02' && message.uidUser === -1 &&
						<div className="img-petit-logo-allianz" />
						}
						{message.uidUser !== -1 &&
						<div className="logo-utilisateur d-flex justify-content-center align-items-center">{initiales}</div>
						}
					</Col>
					<Col lg={8} md={8} sm={7} xs={10}>
						<Row className={'mb-2'}>
							<Col lg={12} sm={12} md={12} className={'d-flex'}>
								<div className={'semiBold'}>De :&nbsp;</div>
								<div className={'light'}>{message.uidUser !== -1 ? infoClient.titre + ' ' + infoClient.nom : agence.nom}</div>
							</Col>
						</Row>
						<Row className={'mb-2'}>
							<Col lg={12} sm={12} md={12} className={'d-flex'}>
								<div className={'semiBold'}>À :&nbsp;</div>
								<div className={'light'}>{message.uidUser !== -1 ? agence.nom : infoClient.titre + ' ' + infoClient.nom}</div>
							</Col>
						</Row>
						<Row>
							<Col lg={12} sm={12} md={12} className={'d-flex'}>
								<div className={'semiBold'}>Envoyé le :&nbsp;</div>
								<div
									className={'light'}>{message.uidUser !== -1 ? formatDateTimeHeure(message.dateMessage) : formatDateTimeHeure(message.dateReponse)}</div>
							</Col>
						</Row>
					</Col>
					{first && buttonVisible &&
					<Col lg={3} md={3} sm={3} xs={12}>
						<Button color="light" size="xs" onClick={() => {
							repondre()
							setButtonVisible(false)
						}}
						        className="btn-block w-75 d-flex justify-content-center align-items-center">
							<div className={'texteBouton'}>Répondre</div>
						</Button>
					</Col>
					}
				</Row>
				<Row className={'mb-5'}>
					<Col lg={12} sm={12} md={12} className={'texteMessage'}>
						{message.uidUser !== -1 ?
							<div>
								{message.message != null && message.message.split('\n').map(function (ligne,index) {
                                    return (
	                                    <div key={`message-${index}`} dangerouslySetInnerHTML={{ __html: ligne }} />
                                    )
								})}
							</div>
							:
							// message de l'ext
							<div>
                                {message.reponseMessage != null &&  message.reponseMessage.split('<br />').map(function (ligne) {
                                    return (
                                        <span>{ligne}<br/></span>
                                    )
                                })}
                            </div>
						}
					</Col>
				</Row>
				<Row>
					<Col>
						{message.file1name != null &&
						<div className={'d-flex align-items-center lien'}
						     onClick={() => downloadFile(message.idMessageAssureContenu, message.file1name, getFile(message.idMessageAssureContenu, 1), IS_NOT_PDF)}>
							<span className={'icon icon-paperclip m-0'}></span><div>{message.file1name}</div>
						</div>
						}
						{message.file2name != null &&
						<div className={'d-flex align-items-center lien'}
						     onClick={() => downloadFile(message.idMessageAssureContenu, message.file2name, getFile(message.idMessageAssureContenu, 2), IS_NOT_PDF)}>
							<span className={'icon icon-paperclip m-0'}></span><div>{message.file2name}</div>
						</div>
						}
						{message.file3name != null &&
						<div className={'d-flex align-items-center lien'}
						     onClick={() => downloadFile(message.idMessageAssureContenu, message.file3name, getFile(message.idMessageAssureContenu, 3), IS_NOT_PDF)}>
							<span className={'icon icon-paperclip m-0'}></span><div>{message.file3name}</div>
						</div>
						}
					</Col>
				</Row>
				<Row className={'separateur'}>
					<Col lg={12} sm={12} md={12}>
						<hr className={'separationMessage'} />
					</Col>
				</Row>
			</Col>
		</Row>
	)
}
Message.propTypes = {
	agence: PropTypes.object,
	getFile: PropTypes.func,
	infoClient: PropTypes.object
}

const mapStateToProps = (state) => {
	return {
		agence: agenceSelector.getDonneesAgence(state),
		infoClient: infoClientSelectors.getDonneesPerso(state)
	}
}
const actions = {
	getFile: messagerieActions.getFile
}

export default compose(
	connect(mapStateToProps, actions),
	withRouter
)(Message)