import qs from 'qs'
import React from 'react'
import * as ReactGa from 'react-ga'
import { getCookie } from './global/cookies/cookieUtils'

export const usePrevious = value => {
	const ref = React.useRef()
	React.useEffect(() => {
		ref.current = value
	})
	return ref.current || {}
}

export const isLuhnValid = siret => {
	// accept only digits, dashes or spaces
	if (/[^0-9-\s]+/.test(siret)) {
		return false
	}
	// The Luhn Algorithm.
	let nCheck = 0
	let nDigit = 0
	let bEven = false
	siret = siret.replace(/\D/g, '')

	for (let n = siret.length - 1; n >= 0; n--) {
		let cDigit = siret.charAt(n)
		nDigit = parseInt(cDigit, 10)

		if (bEven) {
			if ((nDigit *= 2) > 9) nDigit -= 9
		}

		nCheck += nDigit
		bEven = !bEven
	}

	return (nCheck % 10) === 0
}

// Au moins 10 caractères, 1 chiffre, 1 lettre minuscule, 1 lettre majuscule
export const isPasswordValid = password => /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*/])[a-zA-Z0-9!@#$%^&*/]{10,50}$/.test(password)

export const isEmailValid = email => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,50}))$/.test(email)

//Séparateur de millier
export const formatMillier = nombre => {
	if (typeof nombre != 'undefined' && typeof nombre === 'number') {
		nombre += ''
		var sep = ' '
		var reg = /(\d+)(\d{3})/
		while (reg.test(nombre)) {
			nombre = nombre.replace(reg, '$1' + sep + '$2')
		}
		return nombre
	} else if (typeof nombre != 'undefined' && typeof nombre == 'string') {
		let decimales = undefined
		if(nombre.includes(',')){
			decimales = nombre.split(',')[1]
			nombre = nombre.split(',')[0]
		}
		var regS = /(\d+)(\d{3})/
		sep = ' '
		while (regS.test(nombre)) {
			nombre = nombre.replace(regS, '$1' + sep + '$2')
		}
		if (decimales !== undefined){
			nombre = nombre + "," + decimales
		}
		return nombre
	}
	return nombre
}

export const formatDate = date => {
	var dateSeparee = date.split('-')
	var newDate = new Date(dateSeparee[0], dateSeparee[1] - 1, dateSeparee[2])
	return newDate.toLocaleDateString('fr-FR')
}

export const formatDateTime = date => {
	var dateUpdate = date.split('T')[0]
	var dateSeparee = dateUpdate.split('-')
	var newDate = new Date(dateSeparee[0], dateSeparee[1] - 1, dateSeparee[2])
	return newDate.toLocaleDateString('fr-FR')
}

export const formatDateTimeHeure = date => {
	var dateUpdate = date.split('T')
	var dateSeparee = dateUpdate[0].split('-')
	var newDate = new Date(dateSeparee[0], dateSeparee[1] - 1, dateSeparee[2])

	var heureSeparee = dateUpdate[1].split(':')
	var heure = heureSeparee[0] + ":" + heureSeparee[1]
	return newDate.toLocaleDateString('fr-FR') + " à " + heure
}

export const formatDateTimeHeureNotNull = date => {
	var dateUpdate = date.split('T')
	var dateSeparee = dateUpdate[0].split('-')
	var newDate = new Date(dateSeparee[0], dateSeparee[1] - 1, dateSeparee[2])

	var heureSeparee = dateUpdate[1].split(':')
	var heure = heureSeparee[0] + ":" + heureSeparee[1]
	if(heure === "00:00") {
		return newDate.toLocaleDateString('fr-FR')
	} else {
		return newDate.toLocaleDateString('fr-FR') + " à " + heure
	}
}

export const formatDateSinistre = date => {
	var dateSeparee = date.split('-')
	var newDate = new Date(dateSeparee[0], dateSeparee[1] - 1, dateSeparee[2])
	var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
	return newDate.toLocaleDateString('fr-FR', options)
}

export const formatDateSinistrePO = date => {
	var dateSeparee = date.split('T')
	var dateSepareeUpdate = dateSeparee[0].split('-')
	var newDate = new Date(dateSepareeUpdate[0], dateSepareeUpdate[1] - 1, dateSepareeUpdate[2])
	var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
	return newDate.toLocaleDateString('fr-FR', options)
}

export const formatDateAssolement = date => {
	var dateSeparee = date.split('/')
	var anneeActuelle = new Date().getFullYear()
	//Replace utilisé pour supprimer tous les caractères non ASCII rajoutés sur IE
	var newDate = new Date(anneeActuelle, parseInt(dateSeparee[1].replace(/[^\x00-\x7F]/g, "")) - 1, parseInt(dateSeparee[0].replace(/[^\x00-\x7F]/g, "")))
	return newDate.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' })
}

export const formatDecimales = (nombre, decimales) => {
	if (typeof nombre != 'undefined' && nombre && typeof nombre == 'number') {
		nombre = nombre.toFixed(decimales)
		nombre = nombre.toString().replace('.', ',')
	} else if (typeof nombre != 'undefined' && nombre && typeof nombre == 'string') {
		nombre = Number.parseFloat(nombre)
		nombre = nombre.toFixed(decimales)
		nombre = nombre.replace('.', ',')
	}
	return nombre
}

export const formatSpaceAfterColon = (texte) => {
	return texte.replace(/, /g, ",").replace(/,/g, ", ")
}

export const getThemeFromCode = (code) => {
	if (code === '01') {
		return 'larurale'
	} else if (code === '02') {
		return 'allianz'
	}
	return ''
}

export const getThemeFromUser = user => {
	return getThemeFromCode(user.societe)
}

export const supprimeZerosContrat = (contrat) => {
	if (contrat != null) {
		var sub = parseInt(contrat.contrat)
		contrat.contrat = sub.toString()
		return contrat
	}
}

export const supprimeZerosContratString = (contratString) => {
		var sub = parseInt(contratString)
		return sub.toString()
}

// ouverture d'un PDF
export const openWindowPdf = (url, name, parameters) => {
	// méthode pour faire fonctionner l'affichage des PDF sous IE
	// lorsque l'option du cache des pages web sécurisées est désactivée
	var w = window.open('about:blank', name, parameters)
	// on ne redirige que dans un second temps vers le PDF
	w.location.href = url

	return w
}
export const replaceTitle = (theme) => {
	if (theme === 'allianz') {
		document.title = `Espace client - Assurances récoltes | Allianz`
	} else {
		document.title = `Espace client - Assurances récoltes | La Rurale`
	}
}
export const replaceFavicon = (theme) => {
	document.querySelector('link[rel=\'shortcut icon\']').href = `/img/${theme}.ico`
}

export const formatVille = (ville) => {
	if(ville.includes('CEDEX')) {
		ville = ville.replace('CEDEX','')
	}
	if(ville.includes(' S ')){
		ville = ville.replace(' S ',' SUR ')
	}
	if(ville.includes('-FAUT-')){
		ville = ville.replace('-FAUT-', '-FAULT-')
	}
	if(ville.includes('-ST-')){
		ville = ville.replace('-ST-', '-SAINT-')
	}
	return ville.trim().replace(/ +/g, '-')
}

export const formatPostalCode = (code) => {
	if(code.substring(0,1) === "0"){
		return code.substring(1,2)
	}
	else return code
}

export const isEtatResilie = etat => {
	return ['CR',
		'RA',
		'RD',
		'RM',
		'RO',
		'RR',
		'RS',
		'RX',
		'SE'].includes(etat)
}

export const isEtatResilieOuSuspenduOuContentieux = etat => {
	return ['CR',
		'RA',
		'RD',
		'RM',
		'RO',
		'RR',
		'RS',
		'RX',
		'SU',
		'SE',
		'CS'].includes(etat)
}

export const isContratSuspenduOuContencieux = (etatCode,etatDate) => {
	var dateActuelle = new Date()
	etatDate = new Date(etatDate)
	return ((etatCode === "SU" || etatCode === "CS") && etatDate < dateActuelle)
}

export const isContratEnInstanceSuspensionOuContencieux = (etatCode,etatDate) => {
	var dateActuelle = new Date()
	etatDate = new Date(etatDate)
	return ((etatCode === "SU" || etatCode === "CS") && etatDate > dateActuelle)
}

export const isContratEnInstanceResiliation = (etatCode,etatDate) => {
	var dateActuelle = new Date()
	etatDate = new Date(etatDate)
	return (isEtatResilie(etatCode) && etatDate > dateActuelle)
}

/**
 * Permet de savoir si le contrat peut s'afficher sur IA
 * @param etatCode
 * @param etatDate
 * @returns {boolean}
 */
export const isContratValide = (etatCode,etatDate) => {
	var dateActuelle = new Date()
	etatDate = new Date(etatDate)
	if(etatCode === "EC") {
		return true
	} else if(etatCode === "SU") {
		return true
	} else if(etatCode === "CS") {
		return true
	} else return !!(isEtatResilie(etatCode) && etatDate > dateActuelle);
}

export const isContratActif = (etatCode,etatDate) => {
	var dateActuelle = new Date()
	etatDate = new Date(etatDate)
	if(etatCode === "EC") {
		return true
	} else if((etatCode === "SU" || etatCode === "CS") && etatDate < dateActuelle) {
		return false
	} else if((etatCode === "SU" || etatCode === "CS") && etatDate > dateActuelle) {
		return true
	} else return !!(isEtatResilie(etatCode) && etatDate > dateActuelle);
}

export const formatNumberAssolement = number => {
	let val = number.toString()
	while (val.length < 3){
		val = `0${val}`
	}
	return val
}

export const formatStringToDate = (date,heure) => {
	//Replace utilisé pour supprimer tous les caractères non ASCII rajoutés sur IE
	let parts = date.split('/').map(part => Number.parseInt(part.replace(/[^\x00-\x7F]/g, ""), 10))
	let temps = heure.split(':').map(part => Number.parseInt(part.replace(/[^\x00-\x7F]/g, ""), 10))
	return new Date(parts[2], parts[1] - 1, parts[0], temps[0], temps[1])
}

export const extractParamsUrl = chaineGET => {
	return qs.parse(chaineGET.replace('?',''))
}

export const changeMajosMinosParameters = (majoMino,perils,cultures) => {

	let informations = majoMino.replace('%PERILS%',perils)
	informations= informations.replace('%CULTURES%',cultures)
	return informations
}

export const libellePerilFromValeur = (valeur) => {
	if(valeur==="001") return "Grêle"
	else if(valeur==="002") return "Tempête"
	else if(valeur==="003") return "Gel"
	else if(valeur==="004") return "Excès d'eau"
	else if(valeur==="005") return "Sécheresse"
	else return ""
}

/** retournr les libelles des perils à partir de booleens*/
export const formatListePerils = (isGrele,isTempete,isGel,isEau,isSecheresse) => {
	let texte = ""
	if (isGrele) {
		texte = "Grêle"
	}
	else if (isTempete) {
		if (texte !== "") texte = texte.concat(" - Tempête")
		else texte = "Tempête"
	}
	else if (isGel) {
		if (texte !== "") texte = texte.concat(" - Gel")
		else texte = "Gel"
	}
	else if (isEau) {
		if (texte !== "") texte = texte.concat(" - Excès d'eau")
		else texte = "Excès d'eau"
	}
	else if (isSecheresse) {
		if (texte !== "") texte = texte.concat(" - Sécheresse")
		else texte = "Sécheresse"
	}
	return texte
}
// export const serializeParts = parts => {
// 	const form = new FormData();
// 	parts.forEach(part => {
// 		if (part.value) {v
// 			else {
// // JSON
// 				form.append(part.key, new Blob([JSON.stringify(part.value)], { type: 'application/json' }))
// 			}
// 		}
// 	});
// 	return form
// };

export const serializeParts = parts => {
	const form = new FormData()
	parts.forEach(part => {
		if (part.value) {
			if (typeof part.value === 'string') {
				// for (const el of part.value) {
				form.append(part.key, part.value)
			} else
			if (part.value instanceof FileList) {
				for (let i = 0; i < part.value.length; i++) {
					const el = part.value[i]
					form.append(part.key, el)
				}
			} else {
				form.append(part.key, new Blob([JSON.stringify(part.value)], { type: 'application/json' }))
			}
		}
	})
	return form
}

export const triggerGAEvent = (category, action, label) => {
	if(getCookie('isGoogleAnalyticsAuthorized')) {
		ReactGa.event({ category, action, label })
	}
}
