import { Cookies } from 'react-cookie'


const cookies = new Cookies()

/**
 * Mise à jour de la cookie. Une nouvelle cookie sera créée s'il n'existe pas
 * @param cookieName nom de la cookie à mettre à jour
 * @param value valeur à mettre à jour
 * @param options objet de toutes les options de RFC 6265
 */
export const updateCookies = (cookieName, value, options = {}) => {
	cookies.set(cookieName, value, {
		path: options.path,
		expires: options.expires,
		...options
	});
	window[cookieName] = true

}

/**
 * Vérifier si la cookie n'existe pas
 * @param cookieName nom de la cookie à vérifier
 * @returns {boolean}
 */
export const isCookieNotExist = (cookieName) => (
	typeof cookies.get(cookieName) === 'undefined'
)

/**
 * Récupérer la cookie par nom
 * @param cookieName nom de la cookie
 * @returns {string | undefined}
 */
export const getCookie = (cookieName) => (
	cookies.get(cookieName)
)

/**
 * Supprimer la cookie par nom
 * @param cookieName nom de la cookie
 * @param options bjet de toutes les options de RFC 6265
 */
export const removeCookie = (cookieName, options = {}) => {
	cookies.remove(cookieName, options)
	window[cookieName] = false
}