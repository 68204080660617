import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { getCookie, isCookieNotExist, updateCookies } from '../cookies/cookieUtils'

const ModaleUtilisationCookies = ({ isOpened, toggle, className, themeRurale }) => {

	const cookieName = 'isGoogleAnalyticsAuthorized'
	const cookiePath = '/'

	const enableCookies = () => {
		if (!isCookieNotExist(cookieName)) {
			var today = new Date()
			today.setMonth(today.getMonth() + 13)
			updateCookies(cookieName, true,
				{
					path: cookiePath,
					expires: new Date(today.getFullYear(), today.getMonth())
				})
		}
	}

	const disableCookies = () => {
		if (!isCookieNotExist(cookieName)) {
			var today = new Date()
			today.setMonth(today.getMonth() + 13)
			updateCookies(cookieName, false,
				{
					path: cookiePath,
					expires: new Date(today.getFullYear(), today.getMonth())
				})
		}
	}

	const replaceDivCookie = () => {
		document.getElementById("optanon-show-settings2").replaceWith(document.getElementsByClassName("optanon-toggle-display")[0].cloneNode(true))
		document.getElementById("optanon-cookie-policy2").replaceWith(document.getElementById("optanon-cookie-policy").cloneNode(true))
	}

	return (
		<div>
			<div className='lien' onClick={toggle}>
				Utilisation des cookies
			</div>
				<Modal isOpen={isOpened} toggle={toggle} className={className + ' large'} onOpened={replaceDivCookie}>
					<ModalHeader>Utilisation des cookies</ModalHeader>
					<ModalBody>
						<div>
							<section>
								<div>
									<div>
										<div className='lien-onetrust'>
											{/*Bouton Démarrer des paramètres OneTrust de cookies*/}
											<a id="optanon-show-settings2"></a>
											{/*Bouton Terminer des paramètres OneTrust de cookies*/}
										</div>

										<aside>
											<h3>En quoi consistent les cookies ? </h3>
										</aside>
										<h6>Les cookies, un outil indispensable </h6>
										<p>
											Il s’agit d’un simple fichier texte stocké temporairement ou
											définitivement sur votre périphérique fixe ou mobile
											(mais aussi dans certains cas, dans votre navigateur web) à la demande
											du serveur du site sur lequel vous naviguez.
										</p>
										<p>
											Il est renvoyé au site web concerné pour lui permettre de vous
											reconnaître lors de vos visites ultérieures.
											Mal connu des internautes, le cookie est utile, voire indispensable pour
											la navigation sur internet car il permet aux éditeurs de sites internet :
										</p>
										<ul>
											<li>d’améliorer la performance de leur site</li>
											<li>de proposer une meilleure expérience de navigation aux internautes
											</li>
										</ul>
										<p>
											Il permet en effet de retracer l’historique de consultation des pages du
											site visité, et cela, sur tous les supports existants :
											mobiles, tablettes ou ordinateur.
										</p>
										<p>
											En apprenant à connaitre le comportement des internautes sur ses sites,
											La Rurale (Société du Groupe Allianz) pourra :
										</p>
										<ul>
											<li>établir des statistiques fiables de fréquentation</li>
											<li>mieux comprendre leurs besoins</li>
											<li>sécuriser ses procédures</li>
											<li>mémoriser les informations remplies sur les parcours de souscription
											</li>
											<li>vous permettre de vous rendre dans votre espace personnel</li>
										</ul>
									</div>
								</div>
							</section>
							<section>
								<div>
									<div>
										<aside>
											<h3>Quels types de cookies utilisons-nous ? </h3>
										</aside>
										<p>
											Comme la plupart des sites internet, votre site <b>Espace Client - Assurances récoltes</b> utilise des cookies. En
											poursuivant la navigation sur notre site vous avez consenti à
											l’usage des types de cookies suivants :
										</p>
										<ul>
											<li><b>Les cookies de navigation</b></li>
										</ul>
										<p>
											Sans eux, vous ne pourriez tout simplement pas naviguer sur votre site <b>Espace Client - Assurances récoltes</b> et
											en utiliser les différentes fonctionnalités. Ils permettent
											notamment d’accéder à son espace personnel et de consulter l’ensemble des
											données qui y sont stockées.
										</p>
										<ul>
											<li><b>Les cookies de performance et de mesure d'audience</b></li>
										</ul>
                                        {themeRurale ?<>
                                        <p>
                                            Ces cookies recueillent anonymement un certain nombre d’informations
                                            lors de vos visites, comme la
                                            manière dont vous êtes arrivé sur le site (directement par l’intégration
                                            d’une URL du site dans votre navigateur web ou indirectement
                                            par l’intermédiaire d’une requête dans un moteur de recherche) ou encore
                                            les pages que vous fréquentez le plus souvent.
                                        </p>
                                        <p>
                                            Objectif de ces cookies : mesurer la fréquentation de nos pages et nous
                                            permettre d’analyser et de mieux comprendre les comportements
                                            des internautes sur le site en vue d’en améliorer les services, le
                                            contenu et le fonctionnement.
                                        </p>
                                        <p>
                                            Les cookies ne tracent pas les adresses IP des utilisateurs.
                                        </p>
                                        </> : <>
                                            <p>
                                                Sans eux, vous ne pourriez tout simplement pas naviguer sur votre site <b>Espace Client - Assurances récoltes</b> et en utiliser les différentes fonctionnalités.
                                                Ils permettent notamment d’accéder à son espace personnel et de consulter l’ensemble des données qui y sont stockées.
                                            </p>
                                            <ul>
                                                <li><b>Les cookies de performance et de mesure d'audience</b></li>
                                            </ul>
                                            <p>
                                                Ces cookies recueillent anonymement un certain nombre d’informations lors de vos visites, comme la manière dont vous êtes arrivé sur le site ou encore les pages que vous fréquentez le plus souvent.
                                            </p>
                                            <p>
                                                L'objectif de ces cookies est de mesurer la fréquentation de nos pages et de nous permettre d’analyser et de mieux comprendre les comportements des internautes sur le site en vue d’en améliorer les services, le contenu et le fonctionnement.
                                            </p>
                                        </> }

										<p>
											Si vous souhaitez en savoir plus sur les cookies, leur fonctionnement et
											leur utilisation, vous pouvez vous rendre sur le site de la CNIL :
										</p>
										<p>
											<a href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser">CNIL, les outils pour maîtriser les cookies</a>
										</p>

										{/*Début de la liste OneTrust de cookies*/}
										<div id="optanon-cookie-policy2"></div>
										{/*Fin de la liste OneTrust de cookies*/}
									</div>
								</div>
							</section>
							<section>
								<div>
									<div>
										<aside>
											<h3>Comment gérer les cookies ?</h3>
										</aside>
										<h6>La gestion des cookies se paramètre dans son navigateur web</h6>
										<p>Vous pouvez ainsi paramétrer votre navigateur de façon à ce qu’il :</p>
										<ul>
											<li>refuse systématiquement l’envoi de cookies</li>
											<li>n’accepte l’envoi de cookies que pour certains sites considérés
												comme
												étant "de confiance"
											</li>
											<li>n’accepte l’envoi de cookies que pour le site sur lequel vous êtes
												en
												train de naviguer
											</li>
										</ul>
										<h6>Comment paramétrer votre navigateur web ? </h6>
										<p>Cliquez sur le nom de votre navigateur pour en modifier la gestion des
											cookies :</p>
										<ul>
											<li><a
												href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=fr"
												target="_blank" rel="noopener noreferrer">Google Chrome</a></li>
											<li><a
												href="http://support.mozilla.org/fr/kb/activer-desactiver-cookies"
												target="_blank" rel="noopener noreferrer">Mozilla Firefox</a></li>
											<li><a href="http://www.apple.com/fr/privacy/use-of-cookies/"
											       target="_blank" rel="noopener noreferrer">Apple Safari</a></li>
											<li><a
												href="http://windows.microsoft.com/fr-fr/windows7/block-enable-or-allow-cookies"
												target="_blank" rel="noopener noreferrer">Microsoft Internet
												Explorer</a></li>
											<li><a
												href="http://support.apple.com/kb/HT1677?viewlocale=fr_FR&amp;locale=fr_FR"
												target="_blank" rel="noopener noreferrer">Apple Safari iOS</a></li>
											<li><a
												href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&amp;hl=fr"
												target="_blank" rel="noopener noreferrer">Google Android</a></li>
										</ul>
										<p>
											Notre conseil : si vous souhaitez bénéficier de l’ensemble des
											fonctionnalités existantes sur notre site, veuillez ne
											pas désactiver les cookies, votre expérience de navigation en
											deviendrait alors moins fluide.
										</p>
									</div>
								</div>
							</section>
							<section>
								<div>
									<div>
										<aside>
											<h3>Mesure statistique</h3>
										</aside>
                                        {themeRurale ?<>
										<p>
											La Rurale (société du groupe Allianz) mesure des statistiques anonymes et agrégées
											sur l'ensemble des visiteurs afin de connnaitre les volumes de fréquentation des pages
											et le comportement de groupes de visiteurs associés à des données techniques ou métier.
											Cette mesure n'impacte pas votre navigation et ne nous permet pas de vous identifier. Vous
											pouvez toujours refuser la mesure des statistiques en cliquant ci-après :
										</p>
										</> : <>
                                            <p>Allianz mesure des statistiques anonymes et agrégées sur l’ensemble des visiteurs afin de connaître les volumes de fréquentation des pages et le comportement de groupes de visiteurs associés à des données techniques ou métier. Cette mesure n'impacte pas votre navigation et ne nous permet pas de vous identifier. Vous pouvez toujours refuser la mesure des statistiques en cliquant ci-après :</p>
                                        </>
                                        }
                                        {getCookie(cookieName) === "true" ?
                                            <a href="#!" onClick={() => {disableCookies() }}>Désactiver la mesure des statistiques</a> :
                                            <a href="#!" onClick={() => {enableCookies() }}>Activer la mesure des statistiques</a>
                                        }
									</div>
								</div>
							</section>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button onClick={toggle} color="light" size="lg" className="btn-block w-50 m-auto">
							C'est compris
						</Button>
					</ModalFooter>
				</Modal>
		</div>
	)
}

ModaleUtilisationCookies.propTypes = {
	isOpened: PropTypes.bool,
	toggle: PropTypes.func.isRequired

}

export default ModaleUtilisationCookies