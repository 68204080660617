import { fetchFactory } from '../../entities/fetchEntities'

export const getConfiguration = () =>
	fetchFactory('/configuration', {}, '/configuration')
		.then(response => {
			return response.json()
		})

export const getLibelles = () =>
	 fetchFactory('/bo/libellesStatiques', { method: 'GET' })
		 .then((response) => {
			 if (response.ok) {
				 return response
			 } else {
				 throw response
			 }
		 })

export const getMajosMinos = () =>
	fetchFactory('/extranet/majomino', {method:'GET'})
		.then((response) => {
			if (response.ok) {
				return response
			} else {
				throw response
			}
		})

export const getQuestions = () =>
	fetchFactory('/extranet/questions', {method:'GET'})
		.then((response) => {
			if (response.ok) {
				return response
			} else {
				throw response
			}
		})

export const getEcheancier = () =>
	fetchFactory('/extranet/echeancier', {method:'GET'})
		.then((response) => {
			if (response.ok) {
				return response
			} else {
				throw response
			}
		})

export const getNotifications = () =>
    fetchFactory('/extranet/notifications', {method:'GET'})
        .then((response) => {
            if (response.ok) {
                return response
            } else {
                throw response
            }
        })

export const getAssoNotifications = () =>
    fetchFactory('/extranet/assoNotifications', {method:'GET'})
        .then((response) => {
            if (response.ok) {
                return response
            } else {
                throw response
            }
        })

export const deleteAssoNotifications = (id) =>
    fetchFactory('/extranet/assoNotifications/delete', {method:'POST', body: id})
        .then((response) => {
            if (response.ok) {
                return response
            } else {
                throw response
            }
        })