import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Button, Col } from 'reactstrap'
import * as selectors from '../../../accueil/userSelectors'
import MiniPaper from '../../../global/component/miniPaper/MiniPaper'
import ModificationEmail from '../modification/ModificationEmail'
import ModificationMotDePasse from '../modification/ModificationMotDePasse'
import EspaceAssureMessages from "../../../config/metier/EspaceAssureMessages";

class ComponentInformations extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			modificationMailOpened: false,
			modificationPasswordOpened: false
		}
		this.toggle = this.toggle.bind(this)
	}

	toggle(typeModale) {
		this.setState(({
			[typeModale]: !this.state[typeModale]
		}))
	}

	render() {
		const { donneesUser } = this.props
		const { modificationMailOpened, modificationPasswordOpened } = this.state
		return (
			<MiniPaper titre={'Mes informations de connexion'}
			           className="mes-informations"
			           type={'tertiary'}
			           boutons={[{
				           texte: 'Modifier mon mot de passe', couleur: 'blanc', action: () => {
					           this.toggle('modificationPasswordOpened')
				           }
			           }]}
			           icone={''}>
				<div className="content">
					<div className="ft-s-14" style={{lineHeight:'17px',marginBottom:24}}><EspaceAssureMessages id={'texte_info_connexion'}/></div>
					<p className="ft-s-14" style={{lineHeight:'17px',marginBottom:12}}>N° Client : <b style={{marginLeft:42}}>{donneesUser.numeroClient}</b></p>
					<p className="ft-s-14" style={{lineHeight:'17px',marginBottom:32}}>E-mail : <b style={{marginLeft:57}}>{donneesUser.email}</b></p>
				</div>
				<div style={{marginBottom:32}} className={'push-body w-100 d-flex p-0'}>
					<Col className={'p-0'} lg={{ size: 8, offset: 4 }} md={{ size: 10, offset: 2 }} sm={{ size: 12, offset: 0 }}
					     xs={{ size: 12, offset: 0 }}>
						<Button onClick={() => this.toggle('modificationMailOpened')} outline size="lg" color="light"
						        className="btn-push-tpl btn-block">
							<div className="wording">Modifier mon adresse e-mail</div>
						</Button>
					</Col>
				</div>
				<div className="content">
					<p className="ft-s-14" style={{lineHeight:'17px',marginBottom:24}}>Votre mot de passe vous est également demandé pour vous connecter.</p>
					<p className="m-0" style={{lineHeight:'17px',marginBottom:24}}>Mot de passe : *************</p>
				</div>
				<ModificationEmail className="modification-mail" isOpened={modificationMailOpened}
				                   toggle={() => this.toggle('modificationMailOpened')} />
				<ModificationMotDePasse className="modification-mdp" isOpened={modificationPasswordOpened}
				                        toggle={() => this.toggle('modificationPasswordOpened')} />
			</MiniPaper>
		)
	}

}

ComponentInformations.propTypes = {
	donneesUser: PropTypes.object
}

const mapStateToProps = (state) => {
	return {
		donneesUser: selectors.getIdentifiants(state)
	}
}

export default connect(mapStateToProps)(ComponentInformations)