import {Field, reduxForm} from "redux-form";
import React from "react";
import {compose} from "recompose";
import {withRouter} from "react-router";
import RenderSelectFieldQuestion from "./RenderSelectFieldQuestion";
import RenderSearchField from "./RenderSearchField";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";

const FormRechercheQuestion = ({handleSubmit, thematique, research, search, theme }) => {

    return (
        <form onSubmit={handleSubmit} className="w-100">
            <Row className="form-group">
                <Col lg={6} md={6} sm={12} xs={12} className="p-0">
                    <Field
                        name="search"
                        component={RenderSearchField}
                        search={search}
                        placeholder="Saisissez le texte à rechercher..."
                        onChange={(value) => {
                            research({search: value.currentTarget.value, theme: theme})
                        }}
                    />
                </Col>
                <Col  lg={6} md={6} sm={12} xs={12}  className="p-0">
                    <Field
                        name="thematique"
                        component={RenderSelectFieldQuestion}
                        label="Thématique"
                        placeholder={theme}
                        thematique={thematique}
                        onChange={(value) => {
                            research({theme: value.currentTarget.value, search: search})
                        }}
                    />
                </Col>
            </Row>
        </form>
    )
};

export default compose(
    reduxForm({
        form: 'rechercheQuestion',
        touchOnBlur: false,
        enableReinitialize : true,
    }),
    withRouter
)(FormRechercheQuestion)