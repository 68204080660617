import Paper from '../../../global/component/paper/Paper'
import React from 'react'
import { Col, Row, Button, FormGroup, CustomInput } from 'reactstrap'
import { connect } from 'react-redux'
import * as selectors from '../contratSelectors'
import PropTypes from 'prop-types'
import * as tarificateurSelectors from '../tarificateur/tarificateurSelectors'
import * as infosClientSelectors from '../../coordonnees/coordonneesSelectors'
import * as assolementSelectors from '../assolement/assolementSelectors'
import * as contratActions from '../contratActions'
import * as assolementActions from '../assolement/assolementActions'
import * as decomptesActions from '../decomptesProvisoires/decomptesActions'
import * as messageActions from '../../../global/component/messages/messageActions'
import * as tarificateurActions from '../tarificateur/tarificateurActions'
import TableMonContrat from './TableMonContrat'
import {
	Carousel,
	CarouselControl,
	CarouselIndicators
} from 'reactstrap'
import { isContratActif } from '../../../utils'
import CarouselItem from '../../../global/component/carousel/CarouselItem'
import {downloadFile} from "../../../fileUtils"

class MonContrat extends React.Component {

	constructor(props) {
		super(props)
		this.state = { activeIndex: this.props.listeContratsEnCoursOuRemplaces.indexOf(this.props.contratActif), value: '' }
		this.next = this.next.bind(this)
		this.previous = this.previous.bind(this)
		this.goToIndex = this.goToIndex.bind(this)
		this.onExiting = this.onExiting.bind(this)
		this.onExited = this.onExited.bind(this)
		this.handleChange = this.handleChange.bind(this)
	}

	handleChange(event) {
		this.setState({ value: event.target.value })
		this.props.setExerciceActifAssolement({ exerciceActif: event.target.value, contratActif: this.props.contratActif })
		this.props.setExerciceActifDecomptes({ exerciceActif: event.target.value, contratActif: this.props.contratActif })
		this.props.setExerciceActifTarificateur({ exerciceActif: event.target.value, contratActif: this.props.contratActif })
	}

	downloadAttestationAssurance(numeroContrat, exercice) {
		return downloadFile(numeroContrat, "attestation-assurance-contrat-" + numeroContrat,this.props.downloadAttestationAssurance(numeroContrat, exercice))
			.catch(() => {
				this.props.messagesErreur('Télécharger mon attestation', 'Le service de téléchargement de votre attestation d’assurance est actuellement indisponible. Veuillez réessayer ultérieurement.')
			})
	}

	downloadAttestationAssolement(numeroContrat, exercice) {
		return downloadFile(numeroContrat, "declaration-assolement-contrat-" + numeroContrat, this.props.downloadAttestationAssolement(numeroContrat, exercice))
			.catch(() => {
				this.props.messagesErreur('Télécharger mon attestation', 'Le service de téléchargement de votre attestation d’assolement est actuellement indisponible. Veuillez réessayer ultérieurement.')
			})
	}

	onExiting() {
		this.animating = true
	}

	onExited() {
		this.animating = false
	}

	next() {
		if (this.animating) return
		const nextIndex = this.state.activeIndex === this.props.listeContratsEnCoursOuRemplaces.length - 1 ? 0 : this.state.activeIndex + 1
		this.setState({ activeIndex: nextIndex })
		this.props.setContratActif(this.props.listeContratsEnCoursOuRemplaces[nextIndex])
	}

	previous() {
		if (this.animating) return
		const nextIndex = this.state.activeIndex === 0 ? this.props.listeContrats.length - 1 : this.state.activeIndex - 1
		this.setState({ activeIndex: nextIndex })
		this.props.setContratActif(this.props.listeContratsEnCoursOuRemplaces[nextIndex])
	}

	goToIndex(newIndex) {
		if (this.animating) return
		this.setState({ activeIndex: newIndex })
		this.props.setContratActif(this.props.listeContratsEnCoursOuRemplaces[newIndex])
	}

	componentWillMount() {
		const listExercice = (this.props.preListExercice ? this.props.preListExercice : []).slice()
		if (listExercice) {
			listExercice.pop()
			listExercice.sort((a, b) => b - a)
			const exerciceRecent = listExercice.shift()
			this.props.setExerciceActifAssolement({
				exerciceActif: exerciceRecent,
				contratActif: this.props.contratActif
			})
			this.props.setExerciceActifTarificateur({
				exerciceActif: exerciceRecent,
				contratActif: this.props.contratActif
			})
			this.setState({ value: exerciceRecent })
		}
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if(this.state.activeIndex<0){
			this.setState({ activeIndex: this.props.listeContratsEnCoursOuRemplaces.indexOf(this.props.contratActif)});
		}
		if (nextProps.contratActif !== this.props.contratActif){
			const listExercice = (nextProps.preListExercice ? nextProps.preListExercice : []).slice()
			if(listExercice.length > 0){
				listExercice.pop()
				listExercice.sort((a, b) => b - a)
				const exerciceRecent = listExercice.shift()
				this.props.setExerciceActifAssolement({ exerciceActif: exerciceRecent, contratActif: nextProps.contratActif })
				this.props.setExerciceActifTarificateur({ exerciceActif: exerciceRecent, contratActif: nextProps.contratActif })
				this.setState({ value: exerciceRecent })
			}
		}

	}

	render() {
		const { activeIndex } = this.state
		const { texteMulti, donneesContrat, donnnesContratEnCoursOuRemplaces, donneesAllContrat, getDonneesContratByContrat, listeContratsEnCoursOuRemplaces, contratListe, donneesTarificateur, titre, libelleBouton, action, donneesInfosClient, selectExercice, preListExercice, isMesGaranties } = this.props
		const isMulti = contratListe.length > 1
		const hasMultipleActiveContracts = donnnesContratEnCoursOuRemplaces.length > 1
		const listExercice = (preListExercice ? preListExercice : []).slice()
		if (listExercice.length > 0) {
			listExercice.pop()
		}
		const slides = listeContratsEnCoursOuRemplaces.map((item) => {
			return (
				<CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={`slide-${item}`}>
					<TableMonContrat donneesContrat={donneesAllContrat[item]} donneesTarificateur={donneesTarificateur} donneesInfosClient={donneesInfosClient} />
				</CarouselItem>
			)
		})
		return (
			<Paper className="mon-contrat-page push-tpl-1">
				<Row className="push-head">
					<Col>
						<Row>
							<Col lg={8} md={8} sm={12} xs={12} className="d-flex align-items-center">
								<div className="title">
									{isMesGaranties ?
										isMulti ? 'Mes contrats' : 'Mon contrat'
										:
										titre
									}
								</div>
							</Col>
							{libelleBouton && action === 'AttestationAssurance' &&
							<Col lg={4} md={4} sm={12} xs={12}>
								<Button
									onClick={() => this.downloadAttestationAssurance(this.props.contratActif, this.props.assolementsHistorique.exerciceActif)} color="light" size="xs" className="btn-block"
									style={{ display: isContratActif(getDonneesContratByContrat(this.props.contratActif).etatCode,getDonneesContratByContrat(this.props.contratActif).etatDate) ? 'block' : 'none' }}
								>
									{libelleBouton}
								</Button>
							</Col>
							}
							{libelleBouton && action === 'AttestationAssolement' &&
							<Col lg={4} md={4} sm={12} xs={12}>
								<Button
									onClick={() => this.downloadAttestationAssolement(this.props.contratActif, this.props.assolementsHistorique.exerciceActif)} color="light" size="xs" className="btn-block"
									style={{ display: isContratActif(getDonneesContratByContrat(this.props.contratActif).etatCode,getDonneesContratByContrat(this.props.contratActif).etatDate) ? 'block' : 'none' }}
								>
									{libelleBouton}
								</Button>
							</Col>
							}
						</Row>
					</Col>
				</Row>
				{(isMulti && hasMultipleActiveContracts) ?
					<Row style={{ padding: '10px 25px' }}>
						<Col className="pl-3" lg="auto" md="auto" sm="auto" xs="auto">
							 <span
								 style={{ fontSize: '32px', color: '#EFBE25' }}
								 className={`d-flex justify-content-start align-items-center h-100 icon-info-circle`}
							 >
							 </span>
						</Col>
						<Col>
							<p className="d-flex align-items-center h-100">
								Plusieurs contrats assurance récoltes sont associés à votre compte client. <br /> {texteMulti}
							</p>
						</Col>
					</Row> : null
				}
				{isMulti ?
					<Carousel interval={false} activeIndex={activeIndex} next={this.next} previous={this.previous}>
						<CarouselIndicators
							items={listeContratsEnCoursOuRemplaces} activeIndex={activeIndex}
							onClickHandler={this.goToIndex}
						/>
						{slides}
						<CarouselControl className="c-light" direction="prev" directionText="Previous" onClickHandler={this.previous} />
						<CarouselControl className="c-light" direction="next" directionText="Next" onClickHandler={this.next} />
					</Carousel> :
					<TableMonContrat donneesContrat={donneesContrat} donneesTarificateur={donneesTarificateur} donneesInfosClient={donneesInfosClient} />
				}
				{selectExercice &&
				<Row className="push-foot m-0">
					<Col lg={7} sm={12} xs={12}>
						Sélectionnez la campagne que vous souhaitez consulter
					</Col>
					<Col lg={5} sm={12} xs={12}>
						<FormGroup>
							<CustomInput onChange={this.handleChange} value={this.state.value} type="select" id="exampleCustomSelect" name="customSelect">
								{listExercice
									.sort((a, b) => b - a)
									.splice(0, 6)
									.map((key) =>
										<option key={key} value={key}>Campagne {key}</option>
									)}
							</CustomInput>
						</FormGroup>
					</Col>
				</Row>
				}
			</Paper>
		)
	}
}

MonContrat.propTypes = {
	contratListe: PropTypes.array,
	donneesContrat: PropTypes.object,
	donnnesContratEnCoursOuRemplaces: PropTypes.array,
	listeContrats: PropTypes.array,
	listeContratsEnCoursOuRemplaces: PropTypes.array,
	assolementsHistorique: PropTypes.object,
	donneesAllContrat: PropTypes.object,
	getDonneesContratByContrat: PropTypes.func,
	donneesTarificateur: PropTypes.object,
	donneesInfosClient: PropTypes.object,
	contratActif: PropTypes.string,
	setContratActif: PropTypes.func,
	setExerciceActifDecomptes: PropTypes.func,
	setExerciceActifTarificateur: PropTypes.func,
	preListExercice: PropTypes.array,
	isLoading: PropTypes.bool,
	setExerciceActifAssolement: PropTypes.func,
	messagesErreur: PropTypes.func,
	downloadAttestationAssurance: PropTypes.func
}
const mapStateToProps = (state) => {
	return {
		isLoading: tarificateurSelectors.getTarificateursIsLoading(state),
		contratListe: selectors.getContratsListe(state),
		donnnesContratEnCoursOuRemplaces: selectors.getDonneesContratsEnCoursOuRemplaces(state),
		donneesContrat: selectors.getDonneesContratEnCours(state),
		listeContrats: selectors.getContratsListe(state),
		listeContratsEnCoursOuRemplaces: selectors.getContratsEnCoursOuRemplacesListe(state),
		donneesAllContrat: selectors.getDonneesAllContrat(state),
		getDonneesContratByContrat: selectors.getDonneesContratByContrat(state),
		donneesInfosClient: infosClientSelectors.getDonneesPerso(state),
		contratActif: selectors.getContratActif(state),
		assolementsHistorique: assolementSelectors.getHistoriqueAssolementActif(state)
	}
}

const actions = {
	setExerciceActifAssolement: assolementActions.setAssolementExerciceActif,
	setExerciceActifDecomptes: decomptesActions.setDecomptesExerciceActif,
	setContratActif: contratActions.setContratActif,
	messagesErreur: messageActions.messagesErreur,
	downloadAttestationAssurance: contratActions.downloadAttestationAssurance,
	downloadAttestationAssolement: contratActions.downloadAttestationAssolement,
	setExerciceActifTarificateur: tarificateurActions.setTarificateurExerciceActif
}

export default connect(mapStateToProps, actions)(MonContrat)