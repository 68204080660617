import React from 'react'
import { connect } from 'react-redux'
import { Col, Row, Table } from 'reactstrap'
import * as configurationSelectors from '../../../config/metier/configurationSelectors'

const EcheancierContratDetail = ({ contrat, evenements }) => {

	const enumListMois = [
		{
			valeur: 1,
			libelle: 'Jan.'
		},
		{
			valeur: 2,
			libelle: 'Fev.'
		},
		{
			valeur: 3,
			libelle: 'Mars'
		},
		{
			valeur: 4,
			libelle: 'Avr.'
		},
		{
			valeur: 5,
			libelle: 'Mai'
		},
		{
			valeur: 6,
			libelle: 'Juin'
		},
		{
			valeur: 7,
			libelle: 'Juil.'
		},
		{
			valeur: 8,
			libelle: 'Août'
		},
		{
			valeur: 9,
			libelle: 'Sept.'
		},
		{
			valeur: 10,
			libelle: 'Oct.'
		},
		{
			valeur: 11,
			libelle: 'Nov.'
		},
		{
			valeur: 12,
			libelle: 'Dec.'
		}]

	const enumListDernierMois = [
		{
			valeur: 10,
			libelle: 'Oct.'
		},
		{
			valeur: 11,
			libelle: 'Nov.'
		},
		{
			valeur: 12,
			libelle: 'Dec.'
		}]

	return (
		<Row className={'calendar'}>
			<Col>
				<Row className={'bloc haut'}>
					<Col className={'m-2 titre'}>
						Contrat n° {contrat.contrat} : {contrat.packCode === 'A1' ? ('Assurance des récoltes - Grêle, Tempête ' + (contrat.optionGel ? '(option Gel)' : '')) : 'Garantie multirisques des récoltes'}
					</Col>
				</Row>
				<Row className={'bloc bas'}>
					<Table>
						<thead>
						<tr>
							{enumListDernierMois.map((mois) => {
								return (
									<td colSpan={2} className={'col15 titre petit dernier'} key={mois.valeur}>{mois.libelle}</td>
								)
							})}
							{enumListMois.map((mois) => {
								return (
									<td colSpan={2} className={'col15 titre petit'} key={mois.valeur}>{mois.libelle}</td>
								)
							})}
						</tr>
						</thead>
						{evenements.map((evenement, index) => {
							if (evenement[0].pack === contrat.packCode) {
								return (
									<tbody key={evenement[0].pack + evenement[0].type + index}>
									<tr>
										{evenement.map((event) => {
											let test = []
											for (let i = 0; i < event.avant; i++) {
												test.push(<td className={i % 2 !== 0 ? 'col15' : 'col15 noBordure'} key={i} />)
											}
											return test
										})}
										<td colSpan={evenement[0].largeur}
										    className={(evenement[0].avant + evenement[0].largeur) % 2 === 0 ? `event col15` : `event col15 noBordure`}>
											<div className={`${evenement[0].type} element`}>
												<div>{evenement[0].texte}</div>
												<div className={'attachTooltip'}>
	                                               <span className={'tooltipText'}>
                                                       {evenement[0].info.split('\n').map(function (ligne, numero) {
	                                                       return (
		                                                       <span key={`tooltip-${index}-${numero}`}>{ligne}<br /></span>
	                                                       )
                                                       })}
	                                               </span>
												</div>
											</div>
										</td>

										{evenement.map((event) => {
											let test = []
											for (let i = event.avant + event.largeur; i < 30; i++) {
												test.push(<td className={i % 2 !== 0 ? 'col15' : 'col15 noBordure'} key={i} />)
											}
											return test
										})}
									</tr>
									<tr>
										{evenement.map((event) => {
											let test = []
											for (let i = 0; i < event.avant; i++) {
												test.push(<td className={i % 2 !== 0 ? 'col15' : 'col15 noBordure'} key={i} />)
											}
											return test
										})}
										{evenement.map((event) => {
											let test = []
											for (let i = event.avant; i < event.avant + event.largeur; i++) {
												test.push(<td className={i % 2 !== 0 ? 'col15' : 'col15 noBordure'} key={i} />)
											}
											return test
										})}
										{evenement.map((event) => {
											let test = []
											for (let i = event.avant + event.largeur; i < 30; i++) {
												test.push(<td className={i % 2 !== 0 ? 'col15' : 'col15 noBordure'} key={i} />)
											}
											return test
										})}

									</tr>
									</tbody>

								)
							} else {
								return null
							}
						})}
					</Table>
				</Row>
			</Col>
		</Row>
	)
}

const mapStateToProps = (state) => {
	return {
		evenements: configurationSelectors.getEcheancierMaj(state)
	}
}

export default connect(mapStateToProps)(EcheancierContratDetail)
