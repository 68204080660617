import {fetchFactory} from "../../entities/fetchEntities";

export const getAgence = () =>
    fetchFactory('/extranet/agence',{method:'GET'})
        .then((response) => {
            if(response.ok) {
                return response
            } else throw response
        })

export const getMap = (ville,zip) =>
    fetch('https://nominatim.openstreetmap.org/?format=json&addressdetails=1&q='+ville+'+'+zip+'+&format=json&limit=1')
        .then((response) => {
            if(response.ok) {
                return response
            } else throw response
        })
