import * as actions from './metier/configurationActions'

const initialState = []

const notifications = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_NOTIFICATIONS: {
            return {
                ...state
            }
        }
        case actions.GET_NOTIFICATIONS_SUCCESS :
            return action.notifications.reduce((acc, notification) => (
                {
                    ...acc,
                    [notification.id]: notification,
                }), {})
        case actions.GET_NOTIFICATIONS_ERROR:
            return state
        default:
            return state
    }
}

export default notifications