import * as actions from './quittancementActions'

const initialStateQuittancement= {
    isLoading: false,
    quittancementParContrat: {},
}


const quittancement = (state = initialStateQuittancement, action) => {
    switch (action.type) {
        case actions.GET_QUITTANCEMENT: {
            return {...state, isLoading: true}
        }
        case actions.GET_QUITTANCEMENT_SUCCES: {
            const keys = Object.keys(action.payload);
            const newHistorique = {}
            for (let i = 0; i < keys.length; i++) {
                const key = parseInt(keys[i])
                if(newHistorique[key.toString()] === undefined ){
                    if (action.payload[keys[i]].length !== 0){
                        newHistorique[key.toString()]=action.payload[keys[i]]
                    }
                } else if (action.payload[keys[i]].length !== 0){
                    newHistorique[key.toString()]=action.payload[keys[i]]
                }
            }
            action.payload = newHistorique
            return {
                ...state,
                quittancementParContrat: Object.keys(action.payload).reduce((acc, key) => {
                    return{
                    ...acc,
                    [parseInt(key).toString()]: action.payload[key].reduce((acc,quittancement) => {
                        return {
                            ...acc,
                            [(quittancement.exercice).toString()]: quittancement
                        }
                        },{})
                }}, {}),
                isLoading: false
            }
        }
        case actions.GET_QUITTANCEMENT_ERROR: {
            return {
                ...state,
                isLoading: false
            }
        }
        default:
            return state
    }
}

export default quittancement