import {Col, Collapse, Row} from "reactstrap";
import React from "react";
import Paper from "../../../global/component/paper/Paper";
import {formatDateTime} from "../../../utils";
import * as PropTypes from "prop-types";
import * as contratActions from "../../contrat/contratActions";
import * as messageActions from "../../../global/component/messages/messageActions";
import {connect} from "react-redux";
import {downloadFile} from "../../../fileUtils"

const Quittances = ({ downloadQuittancementIndemnite, downloadQuittancementAcompte, messagesErreur, sinistreActif, sinistreQuittance, sinistreQuittanceAcompte }) => {
    const [open, setOpen] = React.useState(true)

    const toggle = () =>{
        setOpen(!open)
    }
    function downloadDocumentIndemnite(numeroContrat, exercice){
        return downloadFile(numeroContrat, "quittance-indemnite", downloadQuittancementIndemnite(numeroContrat, exercice))
            .catch(() => {
                messagesErreur("Consulter la version intégrale de la quittance d'indemnité","Le service de téléchargement de vos documents est actuellement indisponible. Veuillez réessayer ultérieurement.")
            })
    }
    function downloadDocumentAcompte(numeroContrat, exercice, numeroQuittance, identifiantQuittance){
        return downloadFile(numeroContrat, "quittance-acompte", downloadQuittancementAcompte(numeroContrat, exercice, numeroQuittance, identifiantQuittance))
            .catch(() => {
                messagesErreur("Consulter la version intégrale de la quittance d'acompte","Le service de téléchargement de vos documents est actuellement indisponible. Veuillez réessayer ultérieurement.")
            })
    }

    return (
        <Paper className="push-tpl-1">
            <Row className="push-head">
                <Col>
                    <Row onClick={toggle}>
                        <Col md={11} sm={11} xs={11} className="d-flex align-items-center">
                            <div className="title">
                                Quittances
                            </div>
                        </Col>
                        <Col md={1} sm={1} xs={1} className="d-flex justify-content-end">
                            <span className={open ? "icon icon-chevron-up c-dark mr-0" : "icon icon-chevron-down c-dark mr-0"}> </span>
                        </Col>
                    </Row>
                    <Collapse isOpen={open}>
                        <Row className="mt-4">
                            <Col md={12} sm={12} xs={12}>
                                <ul style={{paddingLeft:18}}>
                                    {sinistreQuittance.map((quittance, index)=>
                                        <div key={quittance.numeroQuittance} onClick={()=>{downloadDocumentIndemnite(sinistreActif.declaration.contrat, sinistreActif.declaration.exercice)}}>
                                            <li><div className="lienCliquable">Quittance d'indemnité n° {quittance.numeroQuittance} émise le {formatDateTime(quittance.dateEdition)}</div></li>
                                        </div>
                                    )}
                                    {sinistreQuittanceAcompte.map((quittanceAcompte, index) =>
                                        <div key={quittanceAcompte.numeroQuittance} onClick={()=>{downloadDocumentAcompte(sinistreActif.declaration.contrat, sinistreActif.declaration.exercice, quittanceAcompte.numeroQuittance, quittanceAcompte.identifiantQuittance)}}>
                                            <li><div className="lienCliquable">Quittance d'acompte n° {quittanceAcompte.numeroQuittance} émise le {formatDateTime(quittanceAcompte.dateEdition)}</div></li>
                                        </div>
                                    )}
                                </ul>
                            </Col>
                        </Row>
                    </Collapse>
                </Col>
            </Row>
        </Paper>
    )
}
Quittances.propTypes = {
    downloadQuittancementAcompte:PropTypes.func,
    downloadQuittancementIndemnite:PropTypes.func,
    messagesErreur:PropTypes.func
}
const actions = {
    downloadQuittancementIndemnite: contratActions.downloadQuittancementIndemnite,
    downloadQuittancementAcompte: contratActions.downloadQuittancementAcompte,
    messagesErreur:messageActions.messagesErreur

}
export default connect(null, actions)(Quittances)