import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {Button, Col, Collapse, Row} from 'reactstrap'
import {compose} from 'redux'
import Paper from '../../../global/component/paper/Paper'
import {
	formatDate,
	formatDateTime,
	formatDateTimeHeure,
	formatDateTimeHeureNotNull,
	supprimeZerosContratString
} from '../../../utils'
import PaginationParcelles from '../../../global/component/PaginationParcelles'
import * as sinistreActions from '../sinistreActions'
import * as declarationSinistreActions from '../declarationSinistre/declarationSinistreActions'
import ParcelleSinistree from './ParcelleSinistree'
import {withRouter} from "react-router"
import {downloadFile} from "../../../../src/fileUtils"


const DeclarationDuSinistre = ({ sinistreActif, sinistreSinistre, sinistrePerils, sinistreLibellePerils, sinistreDeclaration, sinistreParcelles, currentPage, donneesContrat, changePages, history, location, getFile, getSinistreObservation }) => {
	const [open, setOpen] = React.useState(false)
	const [pourOrdre] = React.useState(sinistreDeclaration.typeDeclaration === "PO" ? true : false)
	const pageSize = 5
	const pagesCount = Math.ceil(sinistreParcelles.length / pageSize)

	const toggle = () => {
		setOpen(!open)
	}

	const redirectToTarget = (path) => {
		history.push(path)
	}
	const goToDeclaration = (nSinistre) => {
		// setSinistreActif(nSinistre)
		let listPeril = ""
		if (sinistrePerils.length >0){
			sinistrePerils.forEach((peril, index) =>{
				listPeril += listPeril + " " + peril.numeroPeril
			})
		}
		let contrat = supprimeZerosContratString(sinistreDeclaration.contrat)
		let date = formatDateTimeHeure(sinistreDeclaration.dateSinistre).split(" à")[0]
		let heure = formatDateTimeHeure(sinistreDeclaration.dateSinistre).split("à ")[1]
		let grele = false
		let tempete = false
		let gel = false
		let eau = false
		let secheresse = false
		listPeril.split(" ").forEach((peril, index) => {
			if(peril === "001"){
				grele = true
			}
			if(peril === "002"){
				tempete = true
			}
			if(peril === "003"){
				gel = true
			}
			if(peril === "004"){
				eau = true
			}
			if(peril === "005"){
				secheresse = true
			}
		})
		redirectToTarget(`${location.pathname.replace( "/mesSinistres/suivreSinistre", "" )}/declarerUnSinistre?grele=`+ grele + `&tempete=`+ tempete + `&gel=`+ gel +`&eau=`+ eau +`&secheresse=`+ secheresse + `&contrat=`+ contrat + `&date=` + date  + `&heure=` + heure + `&PO=` + true)
	}

	return (
		<Paper className="push-tpl-1 declarationSinistre">
			<Row className="push-head">
				<Col>
					{sinistreDeclaration.typeDeclaration !== 'PO' ?
						<Row className="d-flex align-items-center" onClick={toggle}>
							<Col md={11} sm={11} xs={11} className="title">
								Déclaration du sinistre
							</Col>
							<Col md={1} sm={1} xs={1} className="d-flex justify-content-end">
								<span className={open ? 'icon icon-chevron-up c-dark mr-0' : 'icon icon-chevron-down c-dark mr-0'}> </span>
							</Col>
						</Row>
						:
						<Row className="d-flex align-items-center" onClick={toggle}>
							<Col md={7} sm={12} xs={12} className="title">
								Déclaration du sinistre
							</Col>
							<Col md={5} sm={12} xs={12}>
								<Button onClick={() => goToDeclaration(sinistreDeclaration.idDeclarationSinistre)} color="light" size="xs"
								        className="btn-block">
									Compléter et confirmer cette déclaration
								</Button>
							</Col>
						</Row>
					}
					<Collapse isOpen={open || pourOrdre}>
						<Row>
							<Col>
								<Row className="d-flex justify-content-end">
									{sinistreDeclaration.typeDeclaration === 'PO' ?
										<Col md={5} sm={12} xs={12}
										     className="sousTitre justify-content-end mt-4">Déclaration "pour information" enregistrée le {formatDateTime(sinistreDeclaration.dateModification)}</Col>
										:
										<Col md={3} sm={12} xs={12}
										     className="sousTitre justify-content-end">Sinistre déclaré le {formatDateTime(sinistreDeclaration.dateModification)}</Col>
									}
								</Row>
								<Row>
									<Col>
										<Row className="mt-3 mb-4">
											<Col md={2} sm={2} xs={2} className="sousTitre">Contrat associé</Col>
											<Col md={10} sm={10} xs={10} className="text-left ft-s-allianz-semi-bold">
												N° {parseInt(sinistreDeclaration.contrat)} - {donneesContrat.packCode === 'A1' ? 'Assurance des récoltes - Grêle, Tempête' : 'Garanties multirisques des récoltes'}
											</Col>
										</Row>
										<Row className="mb-4">
											<Col md={2} sm={2} xs={2} className="sousTitre">Péril(s)</Col>
											<Col md={10} sm={10} xs={10} className="text-left font-weight-bold">{sinistreLibellePerils}</Col>
										</Row>
										<Row className="mb-4">
											<Col md={2} sm={2} xs={2} className="sousTitre">Date du sinistre</Col>
											<Col md={10} sm={10} xs={10}
											     className="text-left ft-s-allianz-semi-bold">{formatDateTimeHeureNotNull(sinistreDeclaration.dateSinistre)}</Col>
										</Row>
										<Row className="mb-4">
											<Col md={2} sm={2} xs={2} className="sousTitre">Etendue du sinistre</Col>
											{sinistreDeclaration.typeDeclaration === 'PO' &&
											<Col md={10} sm={10} xs={10}
											     className="text-left ft-s-allianz-semi-bold">Je n'ai pas encore constaté les dégâts, j'ouvre une déclaration "pour information"</Col>
											}
											{sinistreDeclaration.typeDeclaration === 'GL' &&
											<Col md={10} sm={10} xs={10}
											     className="text-left ft-s-allianz-semi-bold">Toutes mes parcelles ont été sinistrées</Col>
											}
											{sinistreDeclaration.typeDeclaration === 'DE' &&
											<Col md={10} sm={10} xs={10}
											     className="text-left ft-s-allianz-semi-bold">Une partie de mes parcelles a été sinistrée</Col>
											}
										</Row>
									</Col>
								</Row>
								{(sinistreDeclaration.typeDeclaration === 'DE' || sinistreDeclaration.typeDeclaration === 'GL')  &&
								<Row>
									<Col md={12} sm={12} xs={12} className="sousTitre mb-3">
										Liste des parcelles sinistrées
									</Col>
									<Col md={12} sm={12} xs={12} className="pl-3">
										{sinistreParcelles.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
											.map((parcelle, index) =>
												<ParcelleSinistree
													key={`${parcelle.numeroParcelle}}`}
													parcelle={parcelle}
													id={index}
												/>
											)}
										{sinistreParcelles.length > 0 &&
										<PaginationParcelles changePages={changePages} currentPage={currentPage} pagesCount={pagesCount} />
										}
									</Col>
								</Row>
								}
								{sinistreDeclaration.observationAssure &&
								<Row>
									<Col lg={2} md={2} sm={2} xs={2} className="sousTitre align-items-start">
										Complément d'information
									</Col>
									<Col lg={10} md={10} sm={10} xs={10}>
										<div className="ft-s-16 ft-s-allianz-semi-bold">{sinistreDeclaration.observationAssure}</div>
									</Col>
								</Row>
								}
								{sinistreDeclaration.file1name &&
									<Row>
										<Col md={2} sm={2} xs={2} className="sousTitre align-items-start">
										</Col>
										<Col md={10} sm={10} xs={10}>
											<ul style={{paddingLeft:19}}>
												<li className="lienDoc" onClick={()=>downloadFile(sinistreDeclaration.idDeclarationSinistre, sinistreDeclaration.file1name, getFile(sinistreDeclaration.idDeclarationSinistre))}>Document joint à la déclaration du sinistre N° {sinistreSinistre.numeroSinistre} du {formatDate(sinistreSinistre.dateSinistre)} - {sinistreLibellePerils}</li>
											</ul>
										</Col>
									</Row>
								}
							</Col>
						</Row>
					</Collapse>
				</Col>
			</Row>
		</Paper>
	)
}

DeclarationDuSinistre.propTypes = {
	getSinistreObservation: PropTypes.func,
	getFile: PropTypes.func
}
const mapStateToProps = (state) => ({
})
const actions = {
	getSinistreObservation: sinistreActions.getSinistreObservation,
	getFile: declarationSinistreActions.getFile
}
export default compose(
	connect(mapStateToProps, actions),withRouter
)(DeclarationDuSinistre)
