import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Col, Row } from 'reactstrap'
import { compose } from 'recompose'
import Paper from '../../../global/component/paper/Paper'
import ShadowScrollbars from '../../../global/component/scrollbar/ShadowScrollbar'
import * as messagerieSelectors from '../messagerieselectors'
import Message from './Message'
import ReponseMessage from './ReponseMessage'

const ConsultationMessages = ({ messageActif, history, discussionActive, messages, handleScrollStart }) => {

	const [repondre, setRepondre] = React.useState(false)
	const [buttonVisible, setButtonVisible] = React.useState(true)

	const cancel = () => {
		setRepondre(false)
		setButtonVisible(true)
	}

	const repondreMessage = () => {
		setRepondre(true)
	}

	return (
		<Paper className={`push-tpl-1 consultationMessage ${messageActif == null ? 'noMessage' : ''}`}>
			{messageActif != null ?
				<ShadowScrollbars style={{ height: '70vh' }} className="list-group-messages" onScrollStart={handleScrollStart}>
					<Row className="push-head messagerie">
						<Col lg={12} sm={12} md={12}>
							<Row>
								<Col lg={12} sm={12} md={12} className={'titre petit text-left'}
								     style={{ marginBottom: 38 }}>
									{discussionActive.messageAssure.objet}
								</Col>
							</Row>
							{repondre &&
							<ReponseMessage
								objet={discussionActive.messageAssure.objet}
								contrat={discussionActive.messageAssure.idContrat}
								setButtonVisible={setButtonVisible}
								setRepondre={setRepondre}
								idDiscussion={messageActif}
								cancel={() => {
									cancel()
								}}
							/>
							}
							<Message
								first={true}
								buttonVisible={buttonVisible}
								setButtonVisible={setButtonVisible}
								repondre={() => {
									repondreMessage()
								}}
								message={messages[0]}
								discussionActive={discussionActive} />
							{messages.length > 1 && messages
								.slice(1)
								.map((message, index) =>
									<Message
										key={`${message.dateMessage} + ${message.dateReponse}`}
										first={false}
										repondre={() => {
											repondreMessage()
										}}
										message={message}
										discussionActive={discussionActive}
									/>
								)}
						</Col>
					</Row>
				</ShadowScrollbars>
				:
				<Row className="push-head">
					<Col lg={12} sm={12} md={12} className={'mb-5 mt-5'}>
						<Row className={'d-flex justify-content-center align-items-center titre'}>
							<Col lg={9} sm={9} md={9}>
								Bienvenue dans la messagerie de votre Espace Client
							</Col>
						</Row>
						<Row className={'d-flex justify-content-center align-items-center texte mt-4'}>
							<Col lg={7} sm={7} md={7}>
								Elle vous permet de communiquer directement avec votre agence, de lui transmettre
								des informations ou des documents, de lui poser des questions, demander un
								rendez-vous ...
							</Col>
						</Row>
						<Row className={'d-flex justify-content-center align-items-center mt-1'}>
							<Col>
								<div className="img-tablette" />
							</Col>
						</Row>
						<Row className={'d-flex justify-content-center align-items-center titre petit mt-1 mb-4'}>
							<Col lg={9} sm={9} md={9}>
								Sélectionnez une conversation sur la liste de gauche,<br /> ou bien créez un nouveau
								message.
							</Col>
						</Row>
					</Col>
				</Row>
			}
		</Paper>
	)
}
ConsultationMessages.propTypes = {
	discussionActive: PropTypes.object,
	messages: PropTypes.array,
	messageActif: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {
	return {
		messageActif: messagerieSelectors.getMessageActif(state),
		discussionActive: messagerieSelectors.getDiscussionActive(state),
		messages: messagerieSelectors.getMessagesDiscussionActive(state)
	}
}
const actions = {
	// messageActif: messagerieSelectors.getMessageActif
}

export default compose(
	connect(mapStateToProps, actions),
	withRouter
)(ConsultationMessages)