import React from 'react'
import { Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import * as selectors from '../sinistreSelectors'
import { connect } from 'react-redux'
import { compose } from 'redux'
import LigneSinistre from './LigneSinistre'
import Paper from '../../../global/component/paper/Paper'
import * as contratSelectors from '../../contrat/contratSelectors'
import { supprimeZerosContratString } from '../../../utils'
import * as sinistreActions from '../sinistreActions'
import { withRouter } from 'react-router'
import LigneDeclarationPO from './LigneDeclarationPO'


class SinistresEnCours extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			tooltipOpen: {}
		}
		this.toggle = this.toggle.bind(this)
		this.redirectToTarget = this.redirectToTarget.bind(this)
		this.goToSinistre = this.goToSinistre.bind(this)
	}

	toggle(numerosinistre) {

		this.setState({
			tooltipOpen: {
				...this.state.tooltipOpen,
				[numerosinistre]: this.state.tooltipOpen[numerosinistre] ? !this.state.tooltipOpen[numerosinistre] : true
			}
		})
	}

	redirectToTarget = (path) => {
		this.props.history.push(path)
	}

	goToSinistre(nSinistre) {
		this.props.setSinistreActif(nSinistre)
		this.redirectToTarget(`${this.props.baseLocation}/mesSinistres/suivreSinistre?idDeclarationSinistre=` + nSinistre.idDeclarationSinistre + `&exercice=` + nSinistre.exercice + `&sinistre=` + nSinistre.numeroSinistre + `&date=` + nSinistre.dateSinistre.substring(0, 10))
	}

	render() {
		const { donneesSinistre, contratListe, getDonneesContrat } = this.props
		if (donneesSinistre.length > 0) {
			return (
				<Paper className="push-tpl-1 mes-sinistres-en-cours">
					<Row className="push-head">
						<Col>
							<Row>
								<Col className="d-flex align-items-center">
									<div className="title">
										Mes sinistres en cours
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className="push-body">
						<div className="content flex-column text-left col-12">
							<div className="sinistre-content">
								<Row>
									<Col>
										<div className="list-group">
											{donneesSinistre.map((sinistre) => {
													if (sinistre.declaration.typeDeclaration !== 'PO') {
														return (
															<LigneSinistre key={`sinistre-en-cours${sinistre.declaration.idDeclarationSinistre}`}
															               sinistre={sinistre}
															               isOpen={this.state.tooltipOpen[sinistre.declaration.idDeclarationSinistre] || false}
															               toggle={() => {
																               this.toggle(sinistre.declaration.idDeclarationSinistre)
															               }}
															               target={`Tooltip${sinistre.declaration.idDeclarationSinistre}`}
															               contratListe={contratListe}
															               donneesContrat={getDonneesContrat(supprimeZerosContratString(sinistre.declaration.contrat))}
															               goToSinistre={this.goToSinistre}
															/>)
													} else {
														return (
															<LigneDeclarationPO key={`declaration-en-cours${sinistre.declaration.idDeclarationSinistre}`}
															                    declaration={sinistre}
															                    isOpen={this.state.tooltipOpen[sinistre.declaration.idDeclarationSinistre] || false}
															                    toggle={() => {
																                    this.toggle(sinistre.declaration.idDeclarationSinistre)
															                    }}
															                    target={`Tooltip${sinistre.declaration.idDeclarationSinistre}`}
															                    contratListe={contratListe}
															                    donneesContrat={getDonneesContrat(supprimeZerosContratString(sinistre.declaration.contrat))}
															                    goToDeclaration={this.goToSinistre}
															/>)
													}
												}
											)}
										</div>
									</Col>
								</Row>
							</div>
						</div>
					</Row>
				</Paper>
			)
		} else return null
	}
}

SinistresEnCours.propTypes = {
	donneesSinistre: PropTypes.array,
	getDonneesContrat: PropTypes.func,
	contratListe: PropTypes.array,
	baseLocation: PropTypes.string.isRequired,
	setSinistreActif: PropTypes.func
}
const mapStateToProps = (state) => {
	return {
		donneesSinistre: selectors.getDonneesSinistre(state),
		contratListe: contratSelectors.getContratsListe(state),
		getDonneesContrat: contratSelectors.getDonneesContratByContrat(state)
	}
}
const actions = {
	setSinistreActif: sinistreActions.setSinistreActif
}
export default compose(
	connect(mapStateToProps, actions),
	withRouter
)(SinistresEnCours)