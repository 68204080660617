import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { formatDecimales, formatMillier } from '../../../utils'
import * as assolementSelectors from '../assolement/assolementSelectors'

const LigneDetailParculture = ({ index, nom, variation, cotisation, codeCulture, donneesAssolementParcelles }) => {

	const [variete, setVariete] = React.useState('')

	React.useEffect(() => {
		donneesAssolementParcelles.forEach((parcelle) => {
			if (parcelle.codeCulture.numeroCulture === codeCulture) {
				setVariete(parcelle.variete.nom)
			}
		})
	}, [])
	if (cotisation !== 0) {
		return (
			<tr key={index}>
				<td style={{ textAlign: 'left' }}>{nom} {variete ? ' - ' + variete : ''} {variation}</td>
				<td className="font-weight-bold">{formatMillier(formatDecimales(cotisation, 2))} €</td>
			</tr>
		)
	} else {
		return null
	}
}

LigneDetailParculture.propTypes = {
	donneesAssolementParcelles: PropTypes.array
}

const mapStateToProps = (state) => {
	return {
		donneesAssolementParcelles: assolementSelectors.getParcellesAssolementActif(state)
	}
}

export default connect(mapStateToProps)(LigneDetailParculture)