import React from 'react'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {Link} from "react-router-dom";

const ModalConfirmationInscription =({adresse,openModal, closeModal})=>(
            <Modal isOpen={openModal} className={"small"}>
                <ModalHeader>Inscription sur l'Espace Client</ModalHeader>
                <ModalBody>Votre inscription a bien été enregistrée.
                    Afin de finaliser l’accès à votre espace Client, consultez vos mails (sur votre adresse : {adresse}), et suivez les indications transmises.</ModalBody>
                <ModalFooter>
                    <Link to={`/`}>
                        <Button onClick={closeModal}>Fermer</Button>
                    </Link>
                </ModalFooter>
            </Modal>
)

export default ModalConfirmationInscription;