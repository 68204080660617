import React from 'react'
import {Modal} from "reactstrap";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ModificationEmailForm from "./ModificationEmailForm";
import * as modificationAction from "../modification/modificationActions";
import * as messageActions from "../../../global/component/messages/messageActions";

class ModificationEmail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.submitModificationEmail = this.submitModificationEmail.bind(this)
    }
    submitModificationEmail(data) {
        return this.props.modficationEmail(data)
            .then(() => {
                this.props.messagesSuccesBlocked("Mise à jour de votre adresse e-mail","Votre demande de modification a bien été enregistrée. Votre adresse e-mail a été mise à jour.","Fermer")
                this.props.toggle()
        })
            .catch(e => {
                this.props.messagesErreurBlocked("Mise à jour de votre adresse e-mail","Votre demande de modification n’a pas pu être enregistrée car une erreur technique est survenue. Veuillez réessayer ultérieurement.","Fermer")
                this.props.toggle()
            })

    }

    render() {
        const {isOpened, toggle, className} = this.props;
        return (
            <div>
                <Modal isOpen={isOpened} toggle={toggle} className={className + " small"}>
                    <ModificationEmailForm toggle={toggle} onSubmit={this.submitModificationEmail}/>
                </Modal>
            </div>
        )
    }
}

ModificationEmail.propTypes = {
    isOpened: PropTypes.bool,
    toggle: PropTypes.func.isRequired,
    modficationEmail: PropTypes.func,
    messagesSuccesBlocked:PropTypes.func,
    messagesErreurBlocked: PropTypes.func
}

const mapStateToProps = () => ({})

const actions = ({
    modficationEmail: modificationAction.modificationEmail,
    messagesSuccesBlocked:messageActions.messagesSuccesBlocked,
    messagesErreurBlocked:messageActions.messagesErreurBlocked
})

export default connect(mapStateToProps, actions)(ModificationEmail)