import {Col, Row} from "reactstrap";
import React from "react";
import {formatDateTime} from "../../../utils";
import PropTypes from "prop-types";
import * as messagerieSelectors from "../messagerieselectors";
import * as messagerieActions from "../messagerieActions";
import {compose} from "recompose";
import {connect} from "react-redux";
import {withRouter} from "react-router";

const ElementMessage = ({ nonLu, activeMessage, discussion, messageActif, changeNonLu, saveNonLu, uneSeuleDiscussion  }) => {

    //Discussion focus
    const [focus, setFocus] = React.useState(false);
    //Discussion déjà visualisée ou non
    const [visualise, setVisualise] = React.useState(false);

    console.log("/////////////////////// DISCUSSION ////////////////////")
    console.log(discussion)
    React.useEffect(() => {
        if (discussion.messageAssure.idMessageAssure === messageActif) {
            setVisualise(true)
            setFocus(true)
            if(uneSeuleDiscussion){
                changeNonLu(discussion.messageAssure.idMessageAssure)
                saveNonLu(discussion.messageAssure.idMessageAssure)
            }
        } else {
            if (messageActif != null && nonLu && visualise && !uneSeuleDiscussion){
                    changeNonLu(discussion.messageAssure.idMessageAssure)
                    saveNonLu(discussion.messageAssure.idMessageAssure)
                }
            setFocus(false)
        }
    }, [messageActif]);

    const dateAffichable = () => {
        var date = new Date()
        var dateDerniereModif = discussion.messageAssure.dateDerniereModification.split("-")
        var listHeure = dateDerniereModif[2].split("T")[1].split(":")
        var heure = listHeure[0] + ":" + listHeure[1]
        if (date.getFullYear() === dateDerniereModif[0] && date.getMonth()+1 === dateDerniereModif[1] && date.getDate() === dateDerniereModif[2].split("T")[0]){
            return heure
        } else {
            return formatDateTime(discussion.messageAssure.dateDerniereModification) + "  " + heure
        }
    }

    return (
        <Row className={`messageElement p-3 ${nonLu && !focus && 'nonLu'} ${focus && 'focus'}`} onClick={()=>{activeMessage(discussion.messageAssure.idMessageAssure)}}>
            <Col lg={12} sm={12} md={12} className={"p-0"}>
                <Row className={"mb-1"}>
                    <Col lg={8} sm={8} md={8} className={"overflow"}>
                        {discussion.messageAssure.objet}
                    </Col>
                    <Col lg={4} sm={4} md={4} className={"overflow text-right"}>
                        {dateAffichable()}
                    </Col>
                </Row>
                <Row>
                    {
                        discussion.messageAssureContenu.length > 0 &&
                        <Col lg={12} sm={12} md={12} className={"sousTexte overflow"}>
                            {discussion.messageAssureContenu[0].uidUser !== -1 ?
                                <div dangerouslySetInnerHTML={ {__html: discussion.messageAssureContenu[0].message}}/>
                            :
                                <div dangerouslySetInnerHTML={ {__html: discussion.messageAssureContenu[0].reponseMessage}}/>
                            }
                        </Col>
                    }
                </Row>
            </Col>
        </Row>
    )
};
ElementMessage.propTypes = {
    messageActif: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
    return {
        messageActif: messagerieSelectors.getMessageActif(state),
    }
};
const actions = {
    changeNonLu: messagerieActions.changeNonLu,
    saveNonLu: messagerieActions.saveNonLu
};

export default compose(
    connect(mapStateToProps, actions),
    withRouter
)(ElementMessage)