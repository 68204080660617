import React from 'react'
import { Col, CustomInput, FormFeedback, Label, Row } from 'reactstrap'

const RenderSelectField = ({ fullWidth, donneesContrat, setContratActif, donneesTarificateur,disabled, id, name, label, placeholder, info, input, meta: { touched, error } }) => {
	return (
		<Row style={{ marginBottom: 40, marginTop: fullWidth ? null : 40 }}>
			<Col lg={fullWidth ? 12 : 3} md={fullWidth ? 12 : 3}>
				{label && <Label className="c-primary ft-s-14">{label}<b className="c-red"> *</b></Label>}
			</Col>
			<Col lg={fullWidth ? 12 : { size: 6, offset: 1 }} md={fullWidth ? 12 : { size: 6, offset: 1 }} sm={{ size: 12 }} xs={{ size: 12 }}>
				<CustomInput {...input} disabled={disabled}  onChange={(value) => {
					setContratActif(value)
					input.onChange(value)
				}} type="select" id={id} name={name}
				>
					<option value="">{placeholder}</option>
					{donneesContrat.map((data) =>
						<option
							value={data.contrat}
							key={`ncontrat` + data.contrat}
						>N°{data.contrat} - {data.packCode === 'A1' ? ('Assurance des récoltes - Grêle, Tempête ' + (donneesTarificateur.optionGel ? '(option Gel)' : '')) : 'Garantie multirisques des récoltes'}
						</option>
					)}
				</CustomInput>
				<FormFeedback valid={!(touched && error)}>
					<div style={{ display: 'flex', color:'red' }}>
						<p style={{ fontSize: '12px' }}>{error}</p>
					</div>
				</FormFeedback>
			</Col>

		</Row>
	)
}
export default RenderSelectField