import React from 'react'
import { Redirect } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import Dashboard from '../global/dashboard/Dashboard'
import Error404 from '../global/error404/Error404'
import ContratPage from './contrat/ContratPage'
import CoordonneesPage from './coordonnees/CoordonneesPage'
import InProgress from '../global/inProgress/InProgress'
import SinistresPage from "./sinistre/SinistresPage"
import ServiceMessageriePage from "./messagerie/ServiceMessageriePage"
import QuestionsFrequentesPage from "./faq/QuestionsFrequentesPage"
import ContacterMonAgencePage from "./agence/ContacterMonAgencePage"
import EcheancierPage from "./contrat/echeancier/EcheancierPage"

export default (pathname) => (
	<Switch>
		<Route exact path={`${pathname}/contrat/echeancier`} component={(props) => <EcheancierPage props={props} pathname={`${pathname}/contrat/echeancier`}/>}/>
		<Route path={`${pathname}/contrat/*`} component={(props) => <ContratPage props={props} pathToHome={pathname} pathname={`${pathname}/contrat`}/>}/>
		<Route path={`${pathname}/inprogress`} component={InProgress} />
		<Route path={`${pathname}/services/messagerie/*`} component={(props) => <ServiceMessageriePage props={props} pathToHome={pathname} pathname={`${pathname}/services/messagerie`}/>} />
		<Route path={`${pathname}/services/*`} component={(props) => <CoordonneesPage props={props} pathToHome={pathname} pathname={`${pathname}/services`}/>} />
		<Route path={`${pathname}/sinistre/*`}  component={(props) => <SinistresPage props={props} pathToHome={pathname} pathname={`${pathname}/sinistre`}/>} />
		<Route exact path={`${pathname}/aideEtContact/questionsFrequentes`}  component={(props) => <QuestionsFrequentesPage {...props} pathname={`${pathname}/aideEtContact/questionsFrequentes`}/>} />
		<Route exact path={`${pathname}/aideEtContact/contacterMonAgence`}  component={(props) => <ContacterMonAgencePage {...props} pathname={`${pathname}/aideEtContact/contacterMonAgence`}/>} />
		<Route exact path={`${pathname}`} component={Dashboard} />
		<Route path={`${pathname}/404`} component={Error404} />
		<Redirect from={`${pathname}/*`} to={`${pathname}/404`} />
	</Switch>
)