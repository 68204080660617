import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { extractParamsUrl, formatDateAssolement, formatDateSinistre } from '../../utils'

const menu = [
	{
		titre: 'contrat',
		sousMenus: [
			{
				titre: 'Mes garanties',
				lien: '/contrat/garanties'
			},
			{
				titre: 'Ma déclaration d\'assolement',
				lien: '/contrat/assolement'
			},
			{
				titre: 'Mes cotisations',
				lien: '/contrat/cotisations'
			},
			{
				titre: 'Historique de mes assolements',
				lien: '/contrat/historiqueAssolement'
			}
		]

	},
	{
		titre: 'sinistre',
		sousMenus: [
			{
				titre: 'Suivre mes sinistres',
				lien: '/sinistre/mesSinistres',
				child: {
					titre: 'Sinistre',
					lien: '/sinistre/mesSinistres/suivreSinistre'
				}
			},
			{
				titre: 'Sinistre',
				lien: '/sinistre/suivreSinistre'

			},
			{
				titre: 'Déclarer un sinistre',
				lien: '/sinistre/declarerUnSinistre'
			},
			{
				titre: 'Consulter mes indemnisations',
				lien: '/sinistre/consulterMesIndemnisations'
			}
		]
	},
	{
		titre: 'services',
		sousMenus: [
			{
				titre: 'Modifier mes données personnelles',
				lien: `/services/mesCoordonnees`
			}
		]
	},
	{
		titre: 'messagerie',
		sousMenus: [
			{
				titre: 'Mes échanges avec mon agence',
				lien: `/messagerie`
			}
		]
	},
	{
		titre: 'questionsFrequentes',
		sousMenus: [
			{
				titre: 'Questions fréquentes',
				lien: `/questionsFrequentes`
			}
		]
	},
	{
		titre: 'contacterMonAgence',
		sousMenus: [
			{
				titre: 'Contacter mon agence',
				lien: `/contacterMonAgence`
			}
		]
	},
	{
		titre: 'echeancier',
		sousMenus: [
			{
				titre: 'Mon échéancier contrat',
				lien: `/echeancier`
			}
		]
	}
]

export default class FilAriane extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			sinistre: this.props.path.location.search.includes('idDeclaration') ? extractParamsUrl(this.props.path.location.search).sinistre : null,
			date: (this.props.path.location.search.includes('idDeclaration') || this.props.path.location.search.includes('PO=true')) ? extractParamsUrl(this.props.path.location.search).date : null
		}
	}

	render() {
		let menuActif
		let menuChild
		menu.forEach((item) => {
			this.props.path.location.pathname.indexOf(item.titre) > -1 && item.sousMenus.forEach((sousMenu) => {
				if (this.props.path.location.pathname.indexOf(sousMenu.lien) > -1) {
					menuActif = <li><Link to={this.props.path.location.pathname}>{sousMenu.titre}</Link></li>
					if (sousMenu.child == null && this.state.date != null) {
						menuActif = <li><Link
							to={this.props.path.location.pathname.replace('/declarerUnSinistre', '/mesSinistres')}>Suivre mes sinistres</Link></li>
						menuChild = <li><Link
							to={this.props.path.location.pathname + this.props.path.location.search}>Sinistre du {formatDateAssolement(this.state.date)}</Link>
						</li>
					}
					/** pour la partie sinistre */
					if (sousMenu.child != null && this.props.path.location.pathname.includes(sousMenu.child.lien)) {
						menuActif = <li><Link to={this.props.path.location.pathname.replace('/suivreSinistre', '')}>{sousMenu.titre}</Link></li>
						if (this.state.sinistre === 'null') {
							menuChild = <li><Link
								to={this.props.path.location.pathname + this.props.path.location.search}>{sousMenu.child.titre} du {formatDateSinistre(this.state.date)}</Link>
							</li>
						} else {
							menuChild = <li><Link
								to={this.props.path.location.pathname + this.props.path.location.search}>{sousMenu.child.titre} N°{this.state.sinistre} du {formatDateSinistre(this.state.date)}</Link>
							</li>
						}
					}
				}
				return menuActif
			})
		})
		return (
			<Row>
				<Col className="wrapper-main-breadcrumb" xs="12">
					<ul>
						<li>
							<Link to={'/'}>Accueil</Link>
						</li>
						{menuActif}
						{menuChild}
					</ul>
				</Col>
			</Row>
		)
	}
}