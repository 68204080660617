import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Button, Col, Row } from 'reactstrap'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import * as configurationSelectors from '../../../config/metier/configurationSelectors'
import Paper from '../../../global/component/paper/Paper'
import { formatDate, formatDateAssolement, formatDecimales, formatMillier } from '../../../utils'
import PaginationParcelles from '../../../global/component/PaginationParcelles'
import * as sinistreActions from '../../sinistre/sinistreActions'
import * as sinistreSelectors from '../../sinistre/sinistreSelectors'
import * as assolementActions from '../assolement/assolementActions'
import * as assolementSelectors from '../assolement/assolementSelectors'
import * as contratSelectors from '../contratSelectors'
import * as contratActions from "../contratActions"
import {withRouter} from "react-router";
import {compose} from "redux";
import {downloadFile} from "../../../fileUtils";

const MonAssolement = ({ downloadAttestationAssolement,history, getDonneeConfiguration, donneesContrat, titre, setSinistreActif, libelleBouton, afficherDate, parcelles, assolementHistoriqueExerciceActif, sinistres, sousTitre, isHistorique }) => {
	const [currentPage, setCurrentPage] = React.useState(0)
	const pageSize = 10
	const pagesCount = Math.ceil(parcelles.length / pageSize)
	const handleClick = (e, index) => {
		e.preventDefault()
		setCurrentPage(index)
	}
	const redirectToTarget = (path) => {
		history.push(path)
	}

	const checkDateValid = (date) => {
		if (date == undefined) {
			return false
		}
		var dateActuelle = new Date()
		var dateSeparee = date.split('/')
		var anneeActuelle = new Date().getFullYear()
		var dateDeclaration = new Date(anneeActuelle, dateSeparee[1] - 1, dateSeparee[0])
		if (dateDeclaration >= dateActuelle) {
			return true
		} else {
			return false
		}
	}
	const goToSinistre = (nSinistre) => {
		setSinistreActif(nSinistre)
		redirectToTarget(`${window.location.pathname.replace('/contrat/historiqueAssolement',
			`/sinistre/mesSinistres/suivreSinistre?idDeclarationSinistre=` + 
			nSinistre.idDeclarationSinistre + `&exercice=` + nSinistre.exercice + `&sinistre=` + nSinistre.numeroSinistre + `&date=` + nSinistre.dateSinistre.substring(0, 10) )}`)
	}

	const downloadAttestationAssolementFunction = (numeroContrat, exercice) => {
		return downloadFile(numeroContrat, "attestation-assolement-contrat-" + numeroContrat, downloadAttestationAssolement(numeroContrat, exercice))
			.catch(() => {
				this.props.messagesErreur("Télécharger mon attestation d'assolement, 'Le service de téléchargement de votre attestation d’assurance est actuellement indisponible. Veuillez réessayer ultérieurement.")
			})
	}
	return (
		<Paper className="mon-contrat-page push-tpl-1">
			<Row className="push-head">
				<Col>
					<Row className="mb-3">
						<Col lg={8} md={8} sm={12} xs={12} className="d-flex align-items-center">
							{sousTitre ?
								<div className="title">
									{titre} <br /> {sousTitre} - campagne {assolementHistoriqueExerciceActif.exercice}

								</div>
								:
								<div className="title">
									{titre} - campagne {assolementHistoriqueExerciceActif.exercice}
								</div>
							}
						</Col>
						<Col lg={4} md={4} sm={12} xs={12}>
							<Button color="light" size="xs" className="btn-block" onClick={()=>{isHistorique ? downloadAttestationAssolementFunction(donneesContrat.contrat, assolementHistoriqueExerciceActif.exercice) : redirectToTarget(window.location.pathname.replace("/contrat/assolement", "/services/messagerie/nouveauMessage?provenance=I"))}}>
								{libelleBouton}
							</Button>
						</Col>
					</Row>
					{afficherDate &&
					<div>
						{
							donneesContrat.packCode === 'R3' ?
								checkDateValid(getDonneeConfiguration('CHGTEXRASSOR3').valeur) && (
									<Row>
										<Col>
											<p style={{ textAlign: 'right', marginTop: 24, marginBottom: 0 }}>
												Vous pouvez mettre à jour votre déclaration d'assolement jusqu'au
												: <b>{formatDateAssolement(getDonneeConfiguration('CHGTEXRASSOR3').valeur)}</b>
											</p>
										</Col>
									</Row>)
								:
								checkDateValid(getDonneeConfiguration('CHGTEXRASSOA1').valeur) && (
									<Row>
										<Col>
											<p style={{ textAlign: 'right' }}>
												Vous pouvez mettre à jour votre déclaration d'assolement jusqu'au
												: <b>{formatDateAssolement(getDonneeConfiguration('CHGTEXRASSOA1').valeur)}</b>
											</p>
										</Col>
									</Row>
								)
						}
					</div>
					}
					{isHistorique &&
					Object.keys(sinistres).length !== 0 &&
					<Row className="sinistres listSinistreDetail">
						<Col lg={12} md={12} sm={12} xs={12}>
							Au cours de cette campagne, vous avez
							déclaré {Object.keys(sinistres).length} {Object.keys(sinistres).length === 1 ? 'sinistre' : 'sinistres'} :
						</Col>
						{Object.keys(sinistres).map(function (sinistre) {
							if(sinistres[sinistre].sinistre.dateSinistre != null ) {
								const date = sinistres[sinistre].sinistre.dateSinistre
								const annee = date.split('-')[0]
								const mois = date.split('-')[1]
								const jour = date.split('-')[2]
								return (
									<Col lg={6} md={6} sm={12} xs={12} key={sinistres[sinistre].sinistre.numeroSinistre}
									     className="sinistreDetail">
										<a href="#!" className="c-pointer c-light" onClick={ () => {
											goToSinistre(sinistres[sinistre].declaration)
										}}>Sinistre n° {sinistres[sinistre].sinistre.numeroSinistre} du {jour}/{mois}/{annee} - {sinistres[sinistre].libellePeril}</a>
									</Col>
								)
							}
							else return null
							}
						)}
					</Row>
					}
				</Col>
			</Row>
			<Row className="push-body">
				<Col className="content flex-column text-left" xs="12">
					<div className="assolement-content">
						<Row>
							<Col>
								{parcelles.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
									.map((parcelle, index) =>
										<div key={`${parcelle.numeroParcelle}${assolementHistoriqueExerciceActif.exercice}`}
										     className="push-body assolement">
											<div className="table-item d-flex">
												<div className="d-flex flex-column col-12">
													<Row className="reference">
														<Col lg={8} md={8}  xs={12} sm={12} className="d-flex align-items-center"><h6
															className=" mb-0 font-weight-bold">Parcelle {parcelle.numeroParcelle} : {parcelle.libelleCommune} {(parcelle.nom !== '' && isNaN(parcelle.nom)) ? '(Lieu-dit : ' + parcelle.nom + ')' : ''}</h6>
														</Col>
														{afficherDate &&
														<Col lg={4} md={4} xs={12} sm={12} className="d-flex justify-content-end" style={{fontSize: '14px', fontWeight: 'lighter'}}>
															Renseigné
															le {formatDate(assolementHistoriqueExerciceActif.dateOuvertureDate)}
														</Col>
														}
													</Row>
												</div>
											</div>
											<Table>
												<Thead>
												<Tr>
													<Th style={{ textAlign: 'left'}} width="35%"><b>Culture</b></Th>
													<Th><b>Surface</b></Th>
													<Th><b>Rendement</b></Th>
													<Th><b>Prix</b></Th>
													<Th><b>Capital/ha</b></Th>
													<Th><b>Capital Assuré</b></Th>
												</Tr>
												</Thead>
												<Tbody>
												<Tr>
													<Td style={{ textAlign: 'left'}} className="font-weight-bolder">{parcelle.codeCulture ? parcelle.codeCulture.nom : ''}<br/> {parcelle.variete ? parcelle.variete.nom : parcelle.codeCulture.nom}</Td>
													<Td style={{verticalAlign:'middle'}}>{formatMillier(formatDecimales(parcelle.surface, 4))} ha</Td>
													<Td style={{verticalAlign:'middle'}}>{formatMillier(formatDecimales(parcelle.rendement, 2))} t/ha</Td>
													<Td style={{verticalAlign:'middle'}}>{formatMillier(Math.round(parcelle.prixUnitaire))} €/t</Td>
													<Td style={{verticalAlign:'middle'}}>{formatMillier(Math.round(parcelle.valeurHectare))} €/ha</Td>
													<Td style={{verticalAlign:'middle'}}>{formatMillier(Math.round(parcelle.capitalAssure))} €</Td>
												</Tr>
												</Tbody>
											</Table>
										</div>
									)}
							</Col>
						</Row>
					</div>
					{parcelles.length > 10 &&
					<PaginationParcelles changePages={handleClick} currentPage={currentPage} pagesCount={pagesCount} />
					}
				</Col>
			</Row>
		</Paper>
	)

}

MonAssolement.propTypes = {
	donneesAssolementHistorique: PropTypes.object,
	assolementHistoriqueExerciceActif: PropTypes.object,
	parcelles: PropTypes.array,
	assolementsHistorique: PropTypes.object,
	donneesContrat: PropTypes.object,
	sinistres: PropTypes.object,
	setSinistreActif: PropTypes.func,
	exerciceActif: PropTypes.number,
	setExerciceActif: PropTypes.func,
	contratActif: PropTypes.string,
	isHistorique: PropTypes.bool,
	getDonneeConfiguration: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		donneesAssolementHistorique: assolementSelectors.getAssolementActif(state),
		donneesContrat: contratSelectors.getDonneesContratEnCours(state),
		getDonneeConfiguration: configurationSelectors.getDonneeConfiguration(state),
		exerciceActif: assolementSelectors.getExerciceActifAssolement(state),
		contratActif: contratSelectors.getContratActif(state),
		sinistres: sinistreSelectors.getDonneesSinistreContratExerciceActif(state)
	}
}

const actions = {
	setSinistreActif: sinistreActions.setSinistreActif,
	setExerciceActif: assolementActions.setAssolementExerciceActif,
	downloadAttestationAssolement: contratActions.downloadAttestationAssolement,
}

export default compose(connect(mapStateToProps, actions), withRouter)(MonAssolement)