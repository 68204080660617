import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../../index.scss'
import EspaceAssureMessages from '../../config/metier/EspaceAssureMessages'
import InscriptionForm from './InscriptionForm'
import ModalCompteBloque from './ModalCompteBloque'
import ModalConfirmationInscription from './ModalConfirmationInscription'
import { Container, Row, Col } from 'reactstrap'
import connect from 'react-redux/es/connect/connect'
import * as inscriptionActions from './inscriptionActions'
import { SubmissionError } from 'redux-form'
import ModalUtililisateurExistant from "./ModalUtilisateurExistant";
import ModalCGU from "./ModalCGU";

class InscriptionPage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			modal: false,
			modalUser:false,
			modalCompteBloque:false,
			modalCGU:false,
			type: 'password',
			adresseMail: null,
			isLoading: false
		}
		this.updateModalUserState = this.updateModalUserState.bind(this)
		this.updateModalState = this.updateModalState.bind(this)
		this.updateModalCompteBloque = this.updateModalCompteBloque.bind(this)
		this.updateModalCGUState = this.updateModalCGUState.bind(this)
		this.openModalCGU = this.openModalCGU.bind(this)
		this.setState = this.setState.bind(this)
		this.showHide = this.showHide.bind(this)
	}

	updateModalState(adress) {
		this.setState(prevState => ({
			modal: !prevState.modal
		}))
		this.setState({ adresseMail: adress })
	}

	updateModalCompteBloque(adress){
		this.setState(prevState => ({
			modalCompteBloque: !prevState.modalCompteBloque
		}))
		this.setState({ adresseMail: adress })
	}

	updateModalUserState(){
		this.setState(prevState => ({
			modalUser: !prevState.modalUser
		}))
	}

	updateModalCGUState(){
		this.setState(prevState => ({
			modalCGU: !prevState.modalCGU
		}))
	}

	openModalCGU(){
		this.setState({
			modalCGU: true
		})
	}

	showHide() {
		this.setState({
			type: this.state.type === 'input' ? 'password' : 'input'
		})
	}

	submitInscriptionForm = (values) => {
		this.setState({isLoading:true})
		return this.props.inscrire(values)
			.then((statut) => {
				if(statut == "OU") {
					this.setState({isLoading:false})
					this.updateModalState(values.email)
				}
				else {
					this.setState({isLoading:false})
					this.updateModalCompteBloque(values.email)
				}
			}).catch(e => {
				this.setState({isLoading:false})
				return e.json()
					.then(json => {
						throw json
					})
			})
			.catch(e => {
				this.setState({isLoading:false})
				if (e.nomChamp === "modale"){
					this.updateModalUserState()
				}
				throw new SubmissionError({[e.nomChamp] : [e.messageErreur]})
			})
	}

	render() {
		const {type} = this.state
		return (
			<Container className="inscription-body d-flex">
				<div className="inscriptionPage">
					<Row className="m-auto">
						<Col sm={4} xs={4} style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div className="branding"> </div>
						</Col>
						<Col sm={8} xs={8}>
							<div className='accroche'>Inscription sur l'Espace Client</div>
						</Col>
					</Row>
					<hr style={{ borderTop: '3px solid' }} />
					<div style={{ marginBottom: '30px' }}>
						<EspaceAssureMessages id="inscription_introduction"/>
					</div>
					<InscriptionForm
						theme={this.props.theme}
						type={type}
						onSubmit={this.submitInscriptionForm}
						showHide={this.showHide}
						isLoading = {this.state.isLoading}
						openModalCGU={this.openModalCGU}
					/>
					<ModalConfirmationInscription
						adresse={this.state.adresseMail}
						openModal={this.state.modal}
						closeModal={this.updateModalState}
					/>
					<ModalCompteBloque
						adresse={this.state.adresseMail}
						openModal={this.state.modalCompteBloque}
						closeModal={this.updateModalCompteBloque}
					/>
					<ModalUtililisateurExistant
						openModal={this.state.modalUser}
						closeModal={this.updateModalUserState}
					/>
					<ModalCGU
						openModal={this.state.modalCGU}
						closeModal={this.updateModalCGUState}
					/>
				</div>
			</Container>
		)
	}
}


InscriptionForm.propTypes = {
	inscrire: PropTypes.func,
	theme: PropTypes.string

}

const mapStateToProps = (state) => ({})

const actions = {
	inscrire: inscriptionActions.inscriptionUtilisateur
}

export default connect(mapStateToProps, actions)(InscriptionPage)
