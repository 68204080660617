import React from "react";
import Button from "reactstrap/es/Button";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {gotoLogin} from "../../containers/baseActions";

class Error404 extends React.Component {
    constructor(props){
        super(props)
        this.redirect = this.redirect.bind(this)
    }
    redirect(){
        this.props.gotoLogin(this.props.location)
    }
    render(){
        return(
            <div className="background-error">
                <div className="bg-image-primary error bannerSize theme-color"> </div>
                <div className={"accroche"}>
                    <h1> <span className={"icon icon-danger"}> </span> Erreur 404</h1>
                    <h6>Oups, il semblerait que la page que vous cherchez n'existe pas</h6>
                    <Button className="btn-redirection" onClick={this.redirect}>Retourner à l'accueil</Button>
                </div>
            </div>
        )
    }
}
Error404.propTypes = {
    gotoLogin: PropTypes.func,
    location: PropTypes.object
}

const mapStateToProps = (state) => ({})

const actions = ({
    gotoLogin: gotoLogin
})
export default connect(mapStateToProps, actions)(Error404)
