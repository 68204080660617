import { getContratActif } from '../contratSelectors'
import { createSelector } from 'reselect'

const getLocalState = (state) => state.metier.contrat.tarificateur

export const getTarificateursParContrat = (state) => getLocalState(state).tarificateurParContrat

export const getTarificateursIsLoading = (state) => getLocalState(state).isLoading

export const getHistoriqueTarificateurParContrat = (state) => getLocalState(state).historiqueTarificateurParContrat

export const getExerciceActifTarificateur = (state) => {
	return getHistoriqueTarificateurActif(state).exerciceActif || ""
}

export const getDonneesTarificateur = createSelector(getTarificateursParContrat, getContratActif,
	(tarificateurs, contratActif) => {
	return  tarificateurs[contratActif] || {}})

export const getDonneesTarificateurCulture = (state) => getDonneesTarificateur(state).cultures || []

export const getDonneesHistoriqueTarificateurCulture = (state) => getHistoriqueTarificateurExerciceActif(state).cultures || []

export const getDonneesTarificateurMm = (state) => getDonneesTarificateur(state).majoMinos || []

export const getDonneesTarificateurCultures = (state) => getDonneesTarificateur(state).cultures || []

export const getHistoriqueTarificateurActif = createSelector(getHistoriqueTarificateurParContrat,getContratActif,
	(historiqueTarificateurParContrat, contratActif ) => historiqueTarificateurParContrat[contratActif] || {})

export const getHistoriqueTarificateurExerciceActif = createSelector(getHistoriqueTarificateurActif,getExerciceActifTarificateur,
	(historiqueTarificateurParContrat, exerciceActif ) => historiqueTarificateurParContrat[exerciceActif] || {})