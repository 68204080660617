import * as messagerieApi from './messagerieApi'

export const SET_MESSAGE_ACTIF = 'SET_MESSAGE_ACTIF'
export const SET_MESSAGE_NON_LU = 'SET_MESSAGE_NON_LU'
export const CREATE_NEW_MESSAGE = 'CREATE_NEW_MESSAGE'
export const GET_MESSAGE = 'GET_MESSAGE'
export const GET_FILE = 'GET_FILE'
export const GET_MESSAGERIE_SUCCESS = 'GET_MESSAGERIE_SUCCESS'
export const GET_MESSAGERIE_ERROR = 'GET_MESSAGERIE_ERROR'

export const getMessage = () => dispatch => {
    dispatch({type: GET_MESSAGE})
    return new Promise((resolve, reject) => {
        return messagerieApi.getMessagerie()
            .then(response => {
                if (response.status === 200) {
                    resolve(response.json())
                } else {
                    reject(response)
                }
            })
    }).then(response => {
        return setMessagerie(response)(dispatch)
    })
}

export const getFile = (id, numero) => dispatch => {
    dispatch({type: GET_FILE})
    return new Promise((resolve, reject) => {
        return messagerieApi.getFile(id, numero)
            .then(response => {
                if (response.status === 200) {
                    resolve(response.blob())
                } else {
                    reject(response)
                }
            })
    })
}

export const createNewDiscussion = (data) => dispatch => {
    return messagerieApi.createNewDiscussion(data)
        .then(response => {
            if (response.status === 200) {
                return new Promise((resolve) => {
                    dispatch(getMessage()).then(() => {
                        resolve(response)
                    })
                })
            } else {
                return response
            }
        })
}

export const createNewMessage = (data) => dispatch => {
    return messagerieApi.createNewMessage(data)
        .then(response => {
            if (response.status === 200) {
                return new Promise((resolve) => {
                    dispatch(getMessage()).then(() => {
                        resolve(response)
                    })
                })
            } else {
                return response
            }
        })
}

export const saveNonLu = (data) => dispatch => {
    return messagerieApi.saveNonLu(data)
}

export const sendMailToAgence = (data) => dispatch => {
    return messagerieApi.sendMailToAgence(data)
}

export const sendMailMessageToAgence = (data) => dispatch => {
    return messagerieApi.sendMailMessageToAgence(data)
}

export const setMessageActif = (message) => (dispatch) => {
    dispatch({
        type: SET_MESSAGE_ACTIF,
        payload: message
    })
};

export const setMessagerie = (message) => (dispatch) => {
    dispatch({
        type: GET_MESSAGERIE_SUCCESS,
        payload: message
    })
};

export const changeNonLu = (discussion) => (dispatch) => {
    dispatch({
        type: SET_MESSAGE_NON_LU,
        payload: discussion
    })
};