import React from 'react'
import { Col, Row } from 'reactstrap'
import { formatMillier } from '../../../utils'


const LigneQuittanceIndemnite = ({contrat,quittance, donwloadQuittance}) => {
	return (
		<div className="push-body assolement liste-quittances-ind">
			<div className="table-item d-flex" style={{padding:'20px 20px 18px 20px',cursor:'pointer'}} onClick={donwloadQuittance}>
				<div className="d-flex flex-column col-12">
					<Row>
						<Col lg={7} md={7}>
							<h6 style={{lineHeight:'20px',marginBottom:10}} className="c-light font-weight-bold">Quittance d'indemnité n° {quittance.numeroQuittance} émise le {new Date(quittance.dateEdition).toLocaleDateString("en-GB")}</h6>
						</Col>
						<Col className="d-flex justify-content-end valeurCotisation">
							<div style={{fontSize:13,alignItems:'center',display:'flex'}}>Indemnité :&nbsp;</div>
							<div style={{fontSize:20,alignItems:'center',display:'flex'}} className="ft-s-allianz-bold"> {formatMillier(quittance.montantIndemnite).toString().replace('.', ',')} €</div>
						</Col>
					</Row>
					<Row>
						<Col className="sousTitre ft-s-14">Contrat N°{contrat} : {quittance.packCode === "A1" ? "Assurance des récoltes - Grêle, Tempête " : "Garantie multirisques des récoltes"}</Col>
					</Row>
				</div>
			</div>
		</div>
	)
}

export default LigneQuittanceIndemnite