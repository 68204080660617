import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {Button, Col, Label, Row} from 'reactstrap'
import {compose} from 'recompose'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import * as contratSelector from '../../../modules/contrat/contratSelectors'
import * as assolementSelector from '../../../modules/contrat/assolement/assolementSelectors'
import * as contratActions from '../../contrat/contratActions'
import * as tarificateurSelectors from '../../contrat/tarificateur/tarificateurSelectors'
import ParcellesTouchees from './ParcellesTouchees'
import RenderCheckbox from './CustomFields/RenderCheckbox'
import RenderDatePicker from './CustomFields/RenderDatePicker'
import RenderFile from './CustomFields/RenderFile'
import RenderRadioField from './CustomFields/RenderRadioField'
import RenderSelectField from './CustomFields/RenderSelectField'
import RenderTextField from './CustomFields/RenderTextField'
import LoaderButton from "../../../global/component/loader/LoaderButton";

export const CHAMP_OBLIGATOIRE = "Ce champ est obligatoire"
export const DATE_ERREUR = "La date du sinistre doit être inférieure à la date d'aujourd'hui"

const validate = (values, ownprops) => {
	const errors = {}
	const requiredFields = [
		'date',
		'contrat',
		'typeDeclaration'
	]
	const requiredCheckBox = [
		'grele',
		'tempete',
		'gel',
		'eau',
		'secheresse'
	]
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = CHAMP_OBLIGATOIRE
		}
	})
	let countTrue = 0
	requiredCheckBox.forEach(req => {
		if (values[req]) {
			countTrue++
		}
	})
	if (countTrue <= 0) {
		errors['grele'] = 'Selectionnez au moins un péril'
	}

	let countParcelle = 0
	ownprops.parcellesAdded.forEach(parcelle => {
		if (values[`parcelleN${parcelle.numeroParcelle}-flagSinistree`]) {
			countParcelle++
			if (!values[`parcelleN${parcelle.numeroParcelle}-surfaceSinistree`]) {
				errors[`parcelleN${parcelle.numeroParcelle}-surfaceSinistree`] = 'Champs Requis'
			} else if (values[`parcelleN${parcelle.numeroParcelle}-surfaceSinistree`] > parcelle.surface) {
				errors[`parcelleN${parcelle.numeroParcelle}-surfaceSinistree`] = 'Surface sinistree trop grande'
			}
		}
	})
	if(values.typeDeclaration === "DE") {
		if (countParcelle <= 0) {
			errors[`parcelles`] = 'Vous devez sélectionner au moins une parcelle.'
		}
	}
	return errors
}

const validateDate = value => {
	return value && value > new Date() ? DATE_ERREUR : undefined
}


export const enumTypeDeclaration = {
	global: {
		code: 'GL',
		label: 'Toutes mes parcelles ont été sinistrées'
	},
	detaille: {
		code: 'DE',
		label: 'Une partie de mes parcelles a été sinistrée'
	},
	pourOrdre: {
		code: 'PO',
		label: 'Je n\'ai pas encore constaté les dégâts, j\'ouvre une déclaration "pour information"'
	}
}
const FormDeclarationSinistre = ({newParcelles, isLoading, baseLocation, history, grele, tempete, gel, eau, secheresse, date, heure, po, contratSelected, setContratSelected, change, flagSinistreValues, assolement, toggleModale, changePages, currentPage, handleSubmit, donneesContrat, donneesTarificateur,submitFailed, pristine, submitting,valid,isMulti, parcellesAdded, formParcellesValues }) => {
	const [option, setOption] = React.useState('')
	const [packCodeContratSelected, setPackCodeContratSelected] = React.useState(null)
	const [isOptionGel, setIsOptionGel] = React.useState(false)
	if (isMulti){
		change('contrat')
	}
	const handleOptionChange = (index) => {
		setOption(index)
	}

	const changeContrat = (event) => {
		setContratSelected(event.target.value)
	}

	const makeInitialSinistreData = (parcelle) => {
		return parcelle
	}

	const changeParcellesInitial = () => {
		parcellesAdded.forEach(parcelle => {
			change(`parcelleN${parcelle.numeroParcelle}`, makeInitialSinistreData(parcelle))
			change(`parcelleN${parcelle.numeroParcelle}-flagSinistree`, false)
			change(`parcelleN${parcelle.numeroParcelle}-surfaceSinistree`, parcelle.surface.toString())
		})
	}
	React.useEffect(()=>{
		donneesContrat.forEach((contrat) => {
			if (contrat.contrat === contratSelected){
				setPackCodeContratSelected(contrat.packCode)
				if (donneesTarificateur[contrat.contrat] && donneesTarificateur[contrat.contrat].optionGel === 1){
					setIsOptionGel(true)
				}
			}
		})
	}, [contratSelected])
	React.useEffect(changeParcellesInitial, [contratSelected])
	React.useEffect(() => {
		if(formParcellesValues!=null) {
			parcellesAdded.forEach(parcelle => {
				if (!formParcellesValues[`parcelleN${parcelle.numeroParcelle}`]) {
					change(`parcelleN${parcelle.numeroParcelle}`, makeInitialSinistreData(parcelle))
					change(`parcelleN${parcelle.numeroParcelle}-flagSinistree`, !!newParcelles[parcelle.numeroParcelle])
					change(`parcelleN${parcelle.numeroParcelle}-surfaceSinistree`, newParcelles[parcelle.numeroParcelle] ? newParcelles[parcelle.numeroParcelle].toString() : parcelle.surface.toString())
				}
			})
		}
	}, [parcellesAdded, newParcelles])
	const redirectToTarget = (path) => {
		history.push(path)
	}
	const goToSinistres = () => {
		redirectToTarget(`${baseLocation}/mesSinistres`)
	}

	return (
		<form onSubmit={handleSubmit} className="w-100">
			<div className="form-group m-0 sinistre">
				{isMulti && <Field
					name="contrat"
					component={RenderSelectField}
					label="Sur quel contrat souhaitez-vous déclarer le sinistre ?"
					placeholder="Sélectionnez un contrat"
					id="contrat"
					disabled={po}
					setContratActif={changeContrat}
					donneesContrat={donneesContrat}
					donneesTarificateur={donneesTarificateur}
				/>}
				{(isMulti && (!contratSelected && !po)) &&
				<div style={{paddingTop:40}} className="d-flex justify-content-center">
					<Button
						outline color="light" size="sm" style={{ marginLeft: 25, marginRight: 25 }}
						onClick={goToSinistres}
						className="border-2 btn-block w-25"
					>Annuler</Button>
					<Button
						style={{ marginLeft: 25, marginRight: 25, marginTop: 0 }}
						type="submit"
						disabled={true}
						color="light" size="sm" className="btn-block w-25"
					>Envoyer</Button>
				</div>}
				{(!isMulti ||  (contratSelected && !pristine) || po) &&
				<div>
					<Row className="declaration-sinistre-checkbox" style={{ marginBottom: 40 }}>
						<Col lg={3} md={3}>
							<Label className="c-primary ft-s-14">Par quel(s) péril(s) vos cultures ont-elles été touchées ?<b className="c-red"> *</b></Label>
						</Col>
						<Col lg={{ size: 2, offset: 1 }} md={{ size: 2, offset: 1 }} sm={{ size: 4, offset: 0 }} xs={{ size: 4, offset: 0 }}>
							<Row>
								<Col lg={12} md={12} sm={12} xs={12}>
									<Field disabled={po}  component={RenderCheckbox} id="isGrele" name="grele" label="Grêle" checked={grele} inline />
								</Col>
							</Row>
							{(packCodeContratSelected === "R3" || (packCodeContratSelected === "A1" && isOptionGel)) &&
								<Row>
									<Col lg={12} md={12} sm={12} xs={12}>
										<Field disabled={po} component={RenderCheckbox} id="isGel" name="gel" label="Gel" checked={gel} inline />
									</Col>
								</Row>
							}
						</Col>
						<Col xl={{ size: 2}} lg={{ size: 3}} md={{ size: 3 }} sm={{ size: 4 }} xs={{ size: 4 }}>
							<Row>
								<Col lg={12} md={12} sm={12} xs={12}>
									<Field disabled={po} component={RenderCheckbox} id="isTempete" name="tempete" label="Tempête" checked={tempete} inline />
								</Col>
							</Row>
							{packCodeContratSelected === "R3" &&
								<Row>
									<Col lg={12} md={12} sm={12} xs={12}>
										<Field disabled={po} component={RenderCheckbox} id="isEau" name="eau" label="Excès d'eau" checked={eau} inline />
									</Col>
								</Row>
							}
						</Col>
						<Col lg={{ size: 2 }} md={{ size: 2 }} sm={{ size: 4 }} xs={{ size: 4 }}>
							<Row>
								<Col lg={12} md={12} sm={12} xs={12}>
									<div style={{height:'24px'}}> </div>
								</Col>
							</Row>
							{packCodeContratSelected === "R3" &&
								<Row>
									<Col lg={12} md={12} sm={12} xs={12}>
										<Field disabled={po} component={RenderCheckbox} id="isSecheresse" name="secheresse" label="Sécheresse" checked={secheresse} inline />
									</Col>
								</Row>
							}
						</Col>
					</Row>
					<Field
						name="date"
						component={RenderDatePicker}
						initialDate={date}
						initialHeure={heure}
						po={po}
						disabled={po}
						id="idDate"
						changeDate={(value) => {
							change('date', value)
						}}
						validate={validateDate}
					/>
					<Field
						name="typeDeclaration"
						component={RenderRadioField}
						label="Quelle est l'étendue du sinistre ?"
						option={option}
						po={po}
						handleOptionChange={handleOptionChange}
						enumTypeDeclaration={enumTypeDeclaration}
					/>
					{option === enumTypeDeclaration.detaille.code &&
					<ParcellesTouchees flagSinistreValues={flagSinistreValues} formParcellesValues={formParcellesValues} change={change} assolement={assolement || []} toggleModale={toggleModale} currentPage={currentPage} parcellesAdded={parcellesAdded} changePages={changePages} />
					}
					{(option === enumTypeDeclaration.detaille.code || option === enumTypeDeclaration.global.code || option === enumTypeDeclaration.pourOrdre.code) &&
					<Field
						name="complement"
						component={RenderTextField}
						label="Complément d'information"
						placeholder="Indiquez ici toute information complémentaire que vous jugez utile."
					/>}
					{(option === enumTypeDeclaration.detaille.code || option === enumTypeDeclaration.global.code) &&
					<Field
						name="file"
						component={RenderFile}
						type="file"
						label="Joindre un document"
						sousTitre="(Taille maximum : 5Mo / formats acceptés : .pdf, .jpg, .jpeg, .png, .bmp)"
					/>}

				</div>
				}
				{(!isMulti || (isMulti && (contratSelected || po))) &&
				<div style={{paddingTop:21}} className="d-flex justify-content-center">
					<Button
						outline color="light" size="sm" style={{ marginLeft: 25, marginRight: 25 }}
						onClick={goToSinistres}
						className="border-2 btn-block w-25"
					>Annuler</Button>
					<Button
						style={{ marginLeft: 25, marginRight: 25, marginTop: 0 }}
						type="submit"
						disabled={pristine || submitting || option === ''}
						color="light" size="sm"
						className="btn-block w-25 d-flex align-items-center justify-content-center"
					>{isLoading? <LoaderButton/> : "Envoyer"}</Button>
				</div>}
				<p style={{ padding: '0' }} className="notabene"><b className="c-red">* </b>Champ obligatoire</p>
			</div>
			{(submitFailed &&  !valid) && window.scrollTo(0,0)}
		</form>
	)
}


FormDeclarationSinistre.propTypes = {
	donneesContrat: PropTypes.array,
	donneesTarificateur: PropTypes.object,
	setContratActif: PropTypes.func,
	assolement: PropTypes.object,
	change: PropTypes.func,
	initialValues: PropTypes.object,
	contratSelected: PropTypes.string,
	parcellesAdded: PropTypes.array,
	setContratSelected: PropTypes.func

}
const selector = formValueSelector('declarationDeSinistre')

const mapStateToProps = (state, ownProps) => {
	return {
		donneesContrat: contratSelector.getDonneesContratsNonResilies(state),
		donneesTarificateur: tarificateurSelectors.getTarificateursParContrat(state),
		assolement: assolementSelector.getAssolementActif(state),
		formParcellesValues: selector(state, ...(ownProps.parcellesAdded.length > 0 ? (ownProps.parcellesAdded.map(parcelle => `parcelleN${parcelle.numeroParcelle}`)) : [''])),
		flagSinistreValues: selector(state, ...(ownProps.parcellesAdded.length > 0 ? (ownProps.parcellesAdded.map(parcelle => `parcelleN${parcelle.numeroParcelle}-flagSinistree`)) : [''])),
	}
}
const actions = {
	setContratActif: contratActions.setContratActif
}

export default compose(
	reduxForm({
		form: 'declarationDeSinistre',
		touchOnBlur: false,
		enableReinitialize : true,
		validate
	}),
	connect(mapStateToProps, actions),
	withRouter
)(FormDeclarationSinistre)
