import Paper from "../../../global/component/paper/Paper";
import React from "react";
import {Col, Row, Button, Table} from "reactstrap";
import {connect} from "react-redux";
import * as tarificateurSelectors from "../tarificateur/tarificateurSelectors";
import * as quittancementSelectors from "../quittancement/quittancementSelectors";
import * as contratSelectors from "../contratSelectors";
import PropTypes from "prop-types";
import {formatDecimales, formatMillier} from "../../../utils";
import * as decomptesSelectors from "../decomptesProvisoires/decomptesSelectors";
import {downloadFile} from "../../../fileUtils";
import * as contratActions from '../contratActions'

class Decomptes extends React.Component{

    downloadDecompteProvisoire(numeroContrat) {
        return downloadFile(numeroContrat, "decompte-provisoire-contrat-" + numeroContrat,this.props.downloadDecompteProvisoire(numeroContrat))
            .catch(() => {
                this.props.messagesErreur('Télécharger mon décompte provisoire', 'Le service de téléchargement de votre attestation d’assurance est actuellement indisponible. Veuillez réessayer ultérieurement.')
            })
    }

    downloadQuittanceCotisation(numeroContrat, exercice) {
        return downloadFile(numeroContrat, "quittance-cotisation-contrat-" + numeroContrat,this.props.downloadQuittancementCotisation(numeroContrat, exercice))
            .catch(() => {
                this.props.messagesErreur('Télécharger ma quittance de cotisation', 'Le service de téléchargement de votre attestation d’assurance est actuellement indisponible. Veuillez réessayer ultérieurement.')
            })
    }

    render() {
        const { donneesTarificateur, donneesContrat, donneesQuittancement, donneesDecomptes } = this.props
        return(
            <Paper className="mon-contrat-page push-tpl-1">

                <Row className="push-head">
                    <Col>
                        <Row className="mb-3">
                            <Col className="d-flex align-items-center">
                                <div className="title">
                                    {Object.keys(donneesTarificateur).length !== 0 ?
                                        (Object.keys(donneesQuittancement).length !== 0 ?
                                        "Appel de cotisation - campagne " + donneesTarificateur.exercice
                                        :
                                        "Décompte provisoire de cotisation - campagne " + donneesTarificateur.exercice)
                                        :
                                        (Object.keys(donneesQuittancement).length !== 0 ?
                                            "Appel de cotisation - campagne " + donneesDecomptes.exercice
                                            :
                                            "Décompte provisoire de cotisation - campagne " + donneesDecomptes.exercice)
                                    }
                                </div>
                            </Col>
                            <Col md={4} sm={12} xs={12}>
                                {Object.keys(donneesQuittancement).length !== 0 ?
                                    <Button color="light" size="xs" className="btn-block" onClick={()=>{Object.keys(donneesTarificateur).length !== 0 ? this.downloadQuittanceCotisation(donneesContrat.contrat,donneesTarificateur.exercice) : this.downloadQuittanceCotisation(donneesContrat.contrat,donneesDecomptes.exercice)}}>
                                        Télécharger ma quittance de cotisation
                                    </Button>
                                        :
                                    <Button color="light" size="xs" className="btn-block" onClick={()=>this.downloadDecompteProvisoire(donneesContrat.contrat)}>
                                        Télécharger mon décompte provisoire
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="push-body decompte-cotisations">
                    <Col className="content flex-column text-left" xs="12">
                        <div className="assolement-content decompte cotisations">
                            <Row>
                                <Col>
                                    <div className="push-body assolement decompte">
                                        <div className="table-item d-flex">
                                            <div className="d-flex flex-column col-12">
                                                <Row >
                                                    <Col><h6
                                                        className="font-weight-bold">Cotisation T.T.C.</h6>
                                                    </Col>
                                                    <Col className="d-flex justify-content-end font-weight-bold valeurCotisation">
                                                        {Object.keys(donneesTarificateur).length !== 0 ?
                                                            formatMillier(formatDecimales(donneesTarificateur.cotisationTtc, 2)) + "€"
                                                            :
                                                            formatMillier(formatDecimales(donneesDecomptes.totalCotisation, 2)) + "€"
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col className="sousTitre">(Y compris : taxe attentat à {Object.keys(donneesTarificateur).length !== 0 ? donneesTarificateur.taxeAttentat : "5,9"} € et frais à {Object.keys(donneesTarificateur).length !== 0 ? donneesTarificateur.fraisFixe : "30"} €)</Col>
                                                </Row>
                                            </div>
                                        </div>
                                        {donneesContrat.packCode === "R3" &&
                                        <span>
                                            <Table>
                                                <tbody>
                                                    <tr>
                                                        <td>Montant de l'assiette <sup>(1)</sup> subventionnable à {(isNaN(donneesTarificateur.coeffPartSubventionnableNiv1 * 100)) ? '?' : donneesTarificateur.coeffPartSubventionnableNiv1 * 100} % (1er niveau)</td>
                                                        {Object.keys(donneesTarificateur).length !== 0 ?
                                                            <td className="font-weight-bold">{formatMillier(formatDecimales(donneesTarificateur.partSubventionnableNiv1Totale, 2))} €</td>
                                                            :
                                                            <td className="font-weight-bold">donnée non disponible</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Montant de l'assiette <sup>(1)</sup> subventionnable à {(isNaN(donneesTarificateur.coeffPartSubventionnableNiv2 * 100)) ? '?' : donneesTarificateur.coeffPartSubventionnableNiv2 * 100} % (2ème niveau)</td>
                                                        {Object.keys(donneesTarificateur).length !== 0 ?
                                                            <td className="font-weight-bold">{formatMillier(formatDecimales(donneesTarificateur.partSubventionnableNiv2Totale, 2))} €</td>
                                                            :
                                                            <td className="font-weight-bold">donnée non disponible</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Montant de l'assiette non subventionnable</td>
                                                        {Object.keys(donneesTarificateur).length !== 0 ?
                                                            <td className="font-weight-bold">{formatMillier(formatDecimales(donneesTarificateur.partNonSubventionnableTotale, 2))} €</td>
                                                            :
                                                            <td className="font-weight-bold">donnée non disponible</td>
                                                        }
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <div className="footer-cotisations decompte">
                                                (1) : L’assiette de subvention est calculée selon le dernier décret en vigueur.
                                            </div>
                                        </span>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Paper>
        )
    }
}

Decomptes.propTypes = {
    donneesTarificateur: PropTypes.object,
    donneesContrat: PropTypes.object,
    donneesQuittancement: PropTypes.object,
    downloadDecompteProvisoire: PropTypes.func,
    downloadQuittancementCotisation: PropTypes.func,
}

const mapStateToProps = (state) => {
    return{
        donneesTarificateur: tarificateurSelectors.getHistoriqueTarificateurExerciceActif(state),
        donneesContrat:contratSelectors.getDonneesContratEnCours(state),
        donneesQuittancement:quittancementSelectors.getQuittancementContratExerciceActif(state),
        donneesDecomptes:decomptesSelectors.getHistoriqueDecomptesExerciceActif(state),

    }
}

const actions = {
    downloadDecompteProvisoire: contratActions.downloadDecompteProvisoire,
    downloadQuittancementCotisation: contratActions.downloadQuittancementCotisation,

}

export default connect(mapStateToProps, actions)(Decomptes);