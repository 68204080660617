import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import * as configurationSelectors from '../../config/metier/configurationSelectors'
import FilAriane from '../../global/filAriane/FilAriane'
import MonAgence from '../contrat/monAgence/MonAgence'
import NotificationDashboard from '../notification/NotificationDashboard'
import ComponentQuestions from './components/ComponentQuestions'
import CoordonneesRoutes from './coordonneesRoutes'
import { enumThematiquesFaq } from '../../constantes/FaqThematiques'

const CoordonneesPage = ({ props, pathname, pathToHome,notifications }) => {

    const [isVisible, setIsVisible] = React.useState(true)
    return (
        <div className="background-coordonnees">
            <div className="bg-image-primary bannerSize theme-color"></div>
            <Container className="wrapper-dashboard">
                <FilAriane name={'Mes services'} path={props} />
                <Row className={`${notifications.length !== 0 && 'row-with-notifications'}`}>
                    <CoordonneesRoutes pathToHome={pathToHome} pathname={pathname} />
                    <Col md="12" lg={3} className="position-initial">
                        <Row>
                            <Col xs="12" className={`notifications ${notifications.length > 0 && 'mb-3'}`}>
                                <NotificationDashboard thematiques={enumThematiquesFaq.COMPTE.code} />
                            </Col>
                            <Col className="mb-3" xs="12">
                                <MonAgence provenance={'D'} />
                            </Col>
                            {isVisible &&
                            <Col className="mb-3" xs="12">
                                <ComponentQuestions thematiques={enumThematiquesFaq.COMPTE.code} setIsVisible={setIsVisible} />
                            </Col>
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        notifications: configurationSelectors.getNotificationsTraitees(state)
    }
}

export default connect(mapStateToProps)(CoordonneesPage)