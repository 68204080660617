export const enumThematiquesFaq = {
	CONTRAT: {
		code: 'CG',
		label: 'Contrats et garanties'
	},
	DECLARATION: {
		code: 'DA',
		label:
			'Déclarations d\'assolement'
	},
	COTISATION: {
		code: 'CO',
		label:
			'Cotisations'
	},
	SINISTRE: {
		code: 'SI',
		label:
			'Sinistres'
	},
	COMPTE: {
		code: 'CD',
		label:
			'Compte et données personnelles'
	},
	DOCUMENT: {
		code: 'DO',
		label:
			'Documents'
	}
}