import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Col, Row } from 'reactstrap'
import { compose } from 'recompose'
import Paper from '../../../global/component/paper/Paper'
import { extractParamsUrl } from '../../../utils'
import * as agenceSelector from '../../agence/agenceSelectors'
import * as contratSelector from '../../contrat/contratSelectors'
import * as contratActions from '../../contrat/contratActions'
import * as messagerieActions from '../messagerieActions'
import FormNouveauMessage from './FormNouveauMessage'

const initialValuesTemplate = {
	objet: null,
	message: null
}

const NouveauMessage = ({ history,contrat,setContratActif, location, agence, setMessageActif, createNewDiscussion, sendMailToAgence, donneesContrat }) => {

	const [objet, setObjet] = React.useState(null)
	const [message, setMessage] = React.useState(null)
	const [provenance] = React.useState(extractParamsUrl(history.location.search).provenance)
	const [contratSelected, setContratSelected] = React.useState(donneesContrat.length > 1 ? "" : contrat)

	const redirectToTarget = (path) => {
		history.push(path)
	}
	const goToMessage = () => {
		setMessageActif(null)
		redirectToTarget(`${location.pathname.replace('/nouveauMessage', '/consultation')}`)
	}
	const submitNewDiscussion = (data) => {
		createNewDiscussion(data)
			.then(() => {
				sendMailToAgence(data)
				goToMessage()
			})
	}
	React.useEffect(() => {
		if (donneesContrat.length === 1 && !contratSelected) {
			setContratSelected(contrat)
		}
	}, [contrat])

	React.useEffect(() => {
		if (contratSelected != null) {
			if (provenance === 'D' || provenance === 'C' || provenance === 'S' || provenance === 'D' || provenance === 'G') {
				setObjet('Contrat n° ' + contratSelected + ' - Demande de rendez-vous')
				setMessage("Bonjour,<br/>" +
					"<br/>" +
					" Je souhaite prendre rendez-vous avec vous le :<br/>" +
					" Au sujet de :<br/>" +
					" Merci de me confirmer votre disponibilité ou me proposer un autre créneau de rendez-vous.<br/>" +
					"<br/>" +
					" Cordialement.")
			} else if (provenance === 'I') {
				setObjet('Contrat n° ' + contratSelected + ' - Modification de ma déclaration d’assolement')
				setMessage(" ")
			} else if (provenance === 'A') {
				setObjet('Contrat n° ' + contratSelected + ' - Déclaration d\'assolement')
				setMessage("Bonjour,<br/>" +
					"<br/>" +
					"Merci de bien vouloir enregistrer ma déclaration d\'assolement.<br/>" +
					"Ci-joint les documents nécessaires.<br/>" +
					"<br/>" +
					"Cordialement.<br/>")
			} else {
				setObjet('Contrat n° ' + contratSelected + ' - ')
			}
		}
	}, [contratSelected])
	const buildInitialValues = () => {
		let initialValues = initialValuesTemplate
		initialValues = {
			...initialValues,
			objet: objet,
			message: message,
			contrat: contratSelected
		}
		return initialValues
	}
	return (
		<Paper className="push-tpl-1 nouveauMessage">
			<Row className="push-head">
				<Col lg={12} sm={12} md={12}>
					<Row style={{ marginBottom: 36 }}>
						<Col lg={12} sm={12} md={12} className={'titre'}>
							Nouveau message
						</Col>
					</Row>
					<Row style={{ marginBottom: 37 }}>
						<Col lg={12} sm={12} md={12} className={'sousTitre'}>
							À : {agence.nom}
						</Col>
					</Row>
					<Row>
						<Col lg={12} sm={12} md={12}>
							<FormNouveauMessage
								contratSelected={contratSelected}
								setContratSelected={setContratSelected}
								donneesContrat={donneesContrat}
								initialValues={buildInitialValues()}
								objet={objet}
								message={message}
								agence={agence}
								onSubmit={submitNewDiscussion}
								goToMessage={() => {
									goToMessage()
								}} />
						</Col>
					</Row>
				</Col>
			</Row>
		</Paper>
	)
}
NouveauMessage.propTypes = {
	agence: PropTypes.object,
	createNewMessage: PropTypes.func
}

const mapStateToProps = (state, ownProps) => {
	return {
		contrat: contratSelector.getContratActif(state),
		agence: agenceSelector.getDonneesAgence(state),
		donneesContrat: contratSelector.getDonneesContratsEnCoursOuRemplaces(state)
	}
}
const actions = {
	setContratActif: contratActions.setContratActif,
	setMessageActif: messagerieActions.setMessageActif,
	createNewDiscussion: messagerieActions.createNewDiscussion,
	sendMailToAgence: messagerieActions.sendMailToAgence
}

export default compose(
	connect(mapStateToProps, actions),
	withRouter
)(NouveauMessage)