import * as actions from './assolementActions'

const initialStateAssolement = {
    isLoading: false,
    assolementParContrat: {},
    historiqueAssolementParContrat: {}
}


const assolement = (state = initialStateAssolement, action) => {
    switch (action.type) {
        case actions.GET_ASSOLEMENT: {
            return {...state, isLoading: true}
        }
        case actions.GET_ASSOLEMENT_SUCCES: {
            return {
                ...state,
                assolementParContrat: Object.keys(action.payload).reduce((acc, key) => ({
                    ...acc,
                    [parseInt(key).toString()]: action.payload[key][0]
                }), {}),
                isLoading: false
            }
        }
        case actions.GET_ASSOLEMENT_HISTORIQUE_SUCCES: {
            const keys = Object.keys(action.payload);
            const newHistorique = {}
            for (let i = 0; i < keys.length; i++) {
                const key = parseInt(keys[i])
                if(newHistorique[key.toString()] === undefined ){
                    if (action.payload[keys[i]].length === 0){
                        newHistorique[key.toString()]=[]
                    }else {
                        newHistorique[key.toString()]=action.payload[keys[i]]
                    }
                } else if (action.payload[keys[i]].length !== 0){
                    newHistorique[key.toString()]=action.payload[keys[i]]
                }
            }
            action.payload = newHistorique
            return {
                ...state,
                historiqueAssolementParContrat: Object.keys(action.payload).reduce((acc, key) => {
                    return {
                        ...acc,
                        [parseInt(key).toString()]: action.payload[key].sort((a,b)=>a.exercice - b.exercice)
                            .reduce((acc, asso) => {
                            return {
                                ...acc,
                                exerciceActif: asso.exercice,
                                [(asso.exercice).toString()]: asso
                            }
                        }, {})
                    }
                }, {}),
                isLoading: false
            }
        }
        case actions.SET_ASSOLEMENT_EXERCICE_ACTIF: {
            const contratActif = action.payload.contratActif
            const exerciceActif = parseInt(action.payload.exerciceActif)
            return {
                ...state,
                historiqueAssolementParContrat: {
                    ...state.historiqueAssolementParContrat,
                    [contratActif]: {
                        ...state.historiqueAssolementParContrat[contratActif],
                        exerciceActif
                    }
                }
            }
        }
        case actions.GET_ASSOLEMENT_ERROR: {
            return {
                ...state,
                isLoading: false
            }
        }
        default:
            return state
    }
}

export default assolement