import * as actions from './metier/configurationActions'

const initialState = []

const questionsFrequentes = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_QUESTIONS: {
            return {
                ...state
            }
        }
        case actions.GET_QUESTIONS_SUCCESS :
            return action.questions.reduce((acc, question) => (
                {
                    ...acc,
                    [question.id]: question,
                }), {})
        case actions.GET_QUESTIONS_ERROR:
            return state
        default:
            return state
    }
}

export default questionsFrequentes