import { LOGIN_SUCCESS, LOGOUT_SUCCESS, TOKEN_REFRESH, GET_USER_SUCCESS } from './loginActions';
import { removeLocalUser as removeLocalUserApi } from './loginApi';
import * as actionsModification from "../modules/coordonnees/modification/modificationActions"; // eslint-disable-line max-len

export default (localStorage) =>  {
	const token = localStorage.getItem('tokenFO');

	const initialState = {
		token: token,
		authenticated: false
	};

	return (state = initialState, action) =>  {

		switch (action.type) {
			case LOGIN_SUCCESS:
			case TOKEN_REFRESH:
				return Object.assign({}, state, {
					token: action.token,
					authenticated: true
				});
			case GET_USER_SUCCESS:
			{
				const user = action.user;
				return Object.assign({}, state, user);
			}
			case LOGOUT_SUCCESS:
				removeLocalUserApi();
				return {};
			case actionsModification.SET_MODIF_USER_MAIL_SUCCES:
				return {
					...state,
					email: action.infoUser.mail
				};
			case actionsModification.SET_MODIF_USER_PREFERENCES_SUCCES:
				return {
					...state,
					constementNewsletter: action.infoUser.consentement
				};
			default:
				return state;
		}
	};
}