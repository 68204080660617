import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import IndemnisationsHeader from './IndemnisationsHeader'
import ListeQuittances from './ListeQuittances'

const ConsulterIndemnisations = ({ setThematique, setThematiqueFAQ }) => {

	const [childThematique] = React.useState("NO")

	useEffect(() => {
		setThematique(childThematique)
		setThematiqueFAQ(childThematique)
	}, [childThematique])

	return (
		<Col md="12" lg="9">
			<Row>
				<Col className="mb-3">
					<IndemnisationsHeader />
				</Col>
			</Row>
			<ListeQuittances />
		</Col>
	)
}

export default ConsulterIndemnisations