import React from 'react'
import MiniPaper from '../../global/component/miniPaper/MiniPaper'
import {Col, Row} from 'reactstrap'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import * as selectors from '../agence/agenceSelectors'
import * as messagerieSelectors from '../messagerie/messagerieselectors'
import TileLayer from 'ol/layer/Tile.js'
import Map from 'ol/Map.js'
import View from 'ol/View.js'
import {fromLonLat} from 'ol/proj.js'
import OSM from 'ol/source/OSM.js'
import * as Gp from 'geoportal-extensions-openlayers'
import * as agenceAction from './agenceActions'
import {withRouter} from 'react-router'
import {usePrevious} from '../../utils'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import Point from 'ol/geom/Point'
import { Feature } from 'ol'
import { Icon, Style } from 'ol/style'
import icon from '../../img/pin.png'

const AgenceDashboard = ({donneesAgence, baseLocation, apiKey, getCoordonnees, history, getMessagesNonLus}) => {
	const [isLoading, setIsLoading] = React.useState(true)
	const previousDonneesAgence = usePrevious(donneesAgence)

	const redirectToTarget = (path) => {
		history.push(path)
	}
	const initMap = () => {
		let lon = parseFloat(donneesAgence.longitude)
		let lat = parseFloat(donneesAgence.latitude)
		let centre = fromLonLat([lon, lat])
		let view = new View({
			center: centre,
			zoom: 14
		})
		let map = new Map({
			target: 'map',
			layers: [
				new TileLayer({
					preload: 10,
					source: new OSM()
				})
			],
			loadTilesWhileAnimating: true,
			view: view
		})
		let source = new VectorSource({
			wrapX: false
		})
		let vector = new VectorLayer({
			source: source
		})
		map.addLayer(vector)
		let geom = new Point(fromLonLat([lon, lat]))
		let feature = new Feature(geom)
		let iconStyle = new Style({
			image: new Icon({
				anchor: [0.5, 46],
				anchorXUnits: 'fraction',
				anchorYUnits: 'pixels',
				src: icon
			})
		})
		feature.setStyle(iconStyle)

		source.addFeature(feature)
		setIsLoading(false)
	}

	const getConfigMap = () => {
		Gp.Services.getConfig({
			apiKey: apiKey,
			onSuccess: initMap
		})
	}

	React.useEffect(() => {
			if (apiKey  && donneesAgence.longitude && donneesAgence.latitude && (isLoading ||
				(donneesAgence.longitude !== previousDonneesAgence.longitude && donneesAgence.latitude !== previousDonneesAgence.latitude))) {
				setTimeout(() => {
						getConfigMap()
					}
					, 500)
			} else if ((donneesAgence.ville && donneesAgence.codePostal) &&
				(donneesAgence.ville !== '' && donneesAgence.codePostal !== '') &&
				(donneesAgence.ville !== previousDonneesAgence.ville && donneesAgence.codePostal !== previousDonneesAgence.codePostal)) {
				getCoordonnees(donneesAgence.ville, donneesAgence.codePostal)
			}
		}
		, [donneesAgence, apiKey, isLoading])

	return (
		<MiniPaper
			titre={'Mon agence'}
			className={'mon-agence dashboard'}
			type={'primary'}
			icone={'icon icon-partner-handshake'}
			boutons={[{
				texte: 'Consulter mes messages',
				icone: 'icon icon-mail',
				disabled: false,
				notification: getMessagesNonLus.toString(),
				isMessageNonLu: true,
				action: () => {
					redirectToTarget(`${baseLocation}/services/messagerie/consultation`)
				}
			}, {
				texte: 'Demander un rendez-vous',
				disabled: false,
				action: () => {
					redirectToTarget(`${baseLocation}/services/messagerie/nouveauMessage?provenance=D`)
				},
				icone: 'icon icon-calendar-2'
			}]}
		>
			<Row>
				<Col className="push-body-top">
					<div className="content">
						<p style={{ fontSize: 16, marginBottom: 14, lineHeight: '20px' }} className={'ft-s-allianz-semi-bold text-uppercase'}>{donneesAgence.titre} {donneesAgence.nom} {donneesAgence.prenom}</p>
						<p style={{ lineHeight: '15px', marginBottom: 12 }} className={'less-important'}>N°ORIAS : {donneesAgence.orias}</p>
						<p style={{ marginBottom: 12, lineHeight: '17px' }} className={'text-uppercase'}>{donneesAgence.adresse1} <br/>{donneesAgence.codePostal} {donneesAgence.ville}</p>
						{donneesAgence.telephone ? <p style={{ marginBottom: 0, lineHeight: '17px' }} className={'text-uppercase'}>Tél : {donneesAgence.telephone}</p> : null}
					</div>
				</Col>
				<Col lg={6} sm={6} md={6} xs={6} className={'d-flex justify-content-center align-items-center'}>
					<Row style={{height: '100%'}}>
						<Col lg={12} sm={12} md={12} style={{height: '100%'}}>
							<div id="map" style={{height: '100%'}}></div>
						</Col>
					</Row>
				</Col>
			</Row>
		</MiniPaper>
	)
}

AgenceDashboard.propTypes = {
	donneesAgence: PropTypes.object,
	baseLocation: PropTypes.string.isRequired,
	apiKey: PropTypes.string
}

const mapStateToProps = (state) => ({
	donneesAgence: selectors.getDonneesAgence(state),
	apiKey: state.configuration.GEOPORTAIL_API_KEY,
	getMessagesNonLus: messagerieSelectors.getNombreDiscussionsNonLues(state)
})
const actions = ({
	getCoordonnees: agenceAction.getMap
})
export default compose(
	connect(mapStateToProps, actions),
	withRouter
)(AgenceDashboard)