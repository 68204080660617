import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import MiniPaper from '../../../global/component/miniPaper/MiniPaper'
import * as selectors from '../coordonneesSelectors'
import ModificationCoordonnees from '../modification/ModificationCoordonnees'

class ComponentCoordonnees extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isOpened: false
		}
		this.toggle = this.toggle.bind(this)
	}

	toggle() {
		this.setState(prevState => ({
			isOpened: !prevState.isOpened
		}))
	}

	render() {
		const { donnees } = this.props
		const { isOpened } = this.state
		return (
			<MiniPaper
				titre={'Mes coordonnées'}
				className="mes-coordonnees-paper"
				type={'tertiary'}
				boutons={[{
					texte: 'Mettre à jour mes coordonnées', couleur: 'blanc', action: () => {
						this.toggle()
					}
				}]}
				icone={''}>
				<div className="content">
					<div className={'text-uppercase'}>
						<p style={{ lineHeight: '25px', marginBottom: 24 }} className="ft-s-allianz-bold c-light ft-s-20">{donnees.titre} {donnees.nom} {donnees.prenom}</p>
						{donnees.typePm && <p className="ft-s-14" style={{lineHeight:'17px',marginBottom: 24}}>{donnees.representantTitre} {donnees.representantNom} {donnees.representantPrenom}</p>}
						{donnees.sirenSiret && <p  className="ft-s-14" style={{lineHeight:'17px',marginBottom: 12}}>SIRET : {donnees.sirenSiret}</p>}
						<p className="ft-s-14" style={{lineHeight:'17px',marginBottom: 12}}>{donnees.adresseNumero} {donnees.adresseTypeVoie} {donnees.adresseVoie} </p>
						<p className="ft-s-14" style={{lineHeight:'17px',marginBottom: 12}}>{donnees.adresseComplement}</p>
						<p className="ft-s-14" style={{lineHeight:'17px',marginBottom: 24}}>{donnees.codePostal} {donnees.ville}</p>
						{donnees.telephoneFixe ?
							<div style={{lineHeight:'17px',marginBottom:12}} className="ft-s-14 info">
								<span className="icon icon-phone-o mr-2 c-light"> </span>
								<span className="wording-small">{donnees.telephoneFixe}</span>
							</div> : <div style={{lineHeight:'17px'}} className="ft-s-14 info"> </div>}
						{donnees.telephoneMobile ?
							<div style={{lineHeight:'17px'}} className="ft-s-14 info">
								<span className="icon icon-mobile mr-2 c-light"> </span>
								<span className="wording-small">{donnees.telephoneMobile}</span>
							</div> : <div style={{lineHeight:'17px'}} className="ft-s-14 info"> </div>}
					</div>
					<ModificationCoordonnees isOpened={isOpened} toggle={this.toggle} />
				</div>
			</MiniPaper>
		)
	}
}

ComponentCoordonnees.propTypes = {
	donnees: PropTypes.object
}

const mapStateToProps = (state) => {
	return {
		donnees: selectors.getDonneesPerso(state)
	}
}

export default connect(mapStateToProps)(ComponentCoordonnees)