import {Col, Collapse, Row} from "reactstrap";
import React from "react";
import Paper from "../../../global/component/paper/Paper";
import {formatDate, formatDateTime, openWindowPdf} from "../../../utils";
import {connect} from "react-redux";
import * as contratActions from "../../contrat/contratActions"
import * as PropTypes from "prop-types";
import * as messageActions from "../../../global/component/messages/messageActions";

const Expertise = ({ messagesErreur, sinistreActif, sinistreDocuments, sinistreMissionsEnvoyees, sinistreRetourExpertise, downloadConstatPerte, user }) => {
    const [open, setOpen] = React.useState(true)

    const toggle = () =>{
        setOpen(!open)
    }
    const listDoc = []
    sinistreDocuments.forEach((doc, index) => {
        if(doc.etatConstat !== ""){
            listDoc.push(doc)
        }
    })
    function downloadDocument(idDocument){
        return downloadConstatPerte(idDocument, user.societe)
            .then((response) => {
                openWindowPdf(response.url, user.societe,"Constat Perte", "status=0,height=400,width=600,resizable=1");
            })
            .catch(() => {
                messagesErreur("Consulter la version intégrale du constat","Le service de téléchargement de vos documents est actuellement indisponible. Veuillez réessayer ultérieurement.")
            })
    }

    return (
        <Paper className="push-tpl-1">
            <Row className="push-head">
                <Col>
                    <Row onClick={toggle}>
                        <Col md={11} sm={11} xs={11} className="d-flex align-items-center">
                            <div className="title">
                                Expertise
                            </div>
                        </Col>
                        <Col md={1} sm={1} xs={1} className="d-flex justify-content-end">
                            <span className={open ? "icon icon-chevron-up c-dark mr-0" : "icon icon-chevron-down c-dark mr-0"}> </span>
                        </Col>
                    </Row>
                    <Collapse isOpen={open}>
                        <Row className="mt-4">
                            <Col md={12} sm={12} xs={12}>
                                <Row className="mb-3">
                                    <Col md={12} sm={12} xs={12}>
                                        {sinistreMissionsEnvoyees.length > 0 ?
                                            "Mission d'expertise lancée le " + formatDate(sinistreMissionsEnvoyees[0].dateEnvoi)
                                            :
                                            "Un expert sera missionné dans les plus brefs délais"}
                                    </Col>
                                </Row>
                                <Row>
                                    {sinistreRetourExpertise.length !== 0 &&
                                        <Col lg={6} md={6} sm={12} xs={12}>
                                            <Row>
                                                <Col md={12} sm={12} xs={12}>
                                                    Votre dossier a été expertisé :
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12} sm={12} xs={12}>
                                                    <ul>
                                                        {sinistreRetourExpertise.map((retour, index) =>
                                                            <li key={`${retour.numeroSinistre}-${retour.numeroExpertIntervenu}-${retour.dateExpertise}`}>le {formatDate(retour.dateExpertise)} par {parseInt(retour.numeroExpertIntervenu)} - {retour.nomExpert}</li>
                                                        )}
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                    {sinistreDocuments.length !== 0 &&
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <Row>
                                            <Col md={12} sm={12} xs={12}>
                                                Rapports d'expertise :
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} sm={12} xs={12}>
                                                <ul>
                                                    {listDoc.map((doc, index) =>
                                                        <div onClick={()=>{downloadDocument(doc.id)}}>
                                                            <li key={`${doc.id}`}><div className="lienCliquable">Constat de perte {doc.etatConstat} du {formatDateTime(doc.dateEnvoi)}</div></li>
                                                        </div>
                                                    )}
                                                </ul>
                                            </Col>
                                        </Row>
                                    </Col>
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </Collapse>
                </Col>
            </Row>
        </Paper>
    )
}
Expertise.propTypes = {
    downloadConstatPerte:PropTypes.func,
    messagesErreur:PropTypes.func
}
const mapStateToProps = (state) => ({
    user: state.user
})
const actions = {
    downloadConstatPerte: contratActions.downloadConstatPerte,
    messagesErreur:messageActions.messagesErreur

}
export default connect(mapStateToProps, actions) (Expertise)