import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
import { Col, Collapse, Row } from 'reactstrap'
import { compose } from 'recompose'
import { Field } from 'redux-form'
import { formatDecimales, formatMillier } from '../../../utils'
import * as sinistreSelectors from '../../sinistre/sinistreSelectors'
import AutoCompleteField from './CustomFields/AutoCompleteField'
import RenderCheckbox from './CustomFields/RenderCheckbox'
import RenderNumberField from './CustomFields/RenderNumberField'

const ParcelleSinistree = ({ change, listeCultures, toggle, isOpen, parcelle }) => {
	const [valueCulture,setValueCulture] = React.useState(null)
	return (
		<div className="push-body assolement">
			<div style={{ cursor: 'default' }} className="table-item d-flex">
				<div className="d-flex flex-column col-12">
					<Row className="reference">
						<Col>
							<Row className="d-flex declaration-sinistre-checkbox">
								<Col lg={1} md={1} sm={1} xs={1}>
									<Field
										component={RenderCheckbox}
										onClick={() => toggle(false)}
										type="checkbox"
										id={`parcelleN${parcelle.numeroParcelle}`}
										name={`parcelleN${parcelle.numeroParcelle}-flagSinistree`}
										inline
									/>
								</Col>
								<Col lg={11} md={11} sm={11} xs={11}>
									<h6 onClick={() => toggle(true)} style={{ cursor: 'pointer' }}
									    className="font-weight-bold m-0 d-flex align-items-center">Parcelle {parcelle.numeroParcelle} : {parcelle.libelleCommune} {(parcelle.nom !== '' && isNaN(parcelle.nom)) ? '(Lieu-dit : ' + parcelle.nom + ')' : ''}</h6>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</div>
			<Collapse isOpen={isOpen}>
				<Table>
					<Thead>
						<Tr>
							<Th width="50%"><b>Culture<b className="c-red">* </b></b></Th>
							<Th><b>Surface totale</b></Th>
							<Th><b>Surface sinistrée<b className="c-red">* </b></b></Th>
						</Tr>
					</Thead>
					<Tbody>
						<Tr>
							<Td className="font-weight-bolder">
								<AutoCompleteField
									displayParamName="nom"
									onSelect={change('codeCulture')}
									maxSuggestions={5}
									setCulture={setValueCulture}
									suggestions={listeCultures}
									name={`parcelleN${parcelle.numeroParcelle}-codeCulture`}
									defaultValue={parcelle.codeCulture.nom} />
							</Td>
							<Td>{formatDecimales(formatMillier(parcelle.surface), 4)} ha</Td>
							<Td>
								{(parcelle.surface) ?
									<Field component={RenderNumberField} className="d-inline-flex justify-content-center align-items-center w-100"
									       value={parcelle.surface} name={`parcelleN${parcelle.numeroParcelle}-surfaceSinistree`} type="text"
									       parcelle={parcelle} />
									:
									<Field component={RenderNumberField} className="d-inline-flex justify-content-center align-items-center w-100"
									       name={`parcelleN${parcelle.numeroParcelle}-surfaceSinistree`} type="text" parcelle={parcelle} />}
							</Td>
						</Tr>
					</Tbody>
				</Table>
				{valueCulture != null && valueCulture != parcelle.codeCulture.nom &&
				<div className="warning-message d-flex warning-line align-items-center">
					<span style={{ paddingLeft: 5, fontSize: '22px', color: '#EFBE25' }} className={`d-flex justify-content-center align-items-center h-100 icon-info-circle`}> </span>
					<p style={{ paddingLeft: 5 }}
					   className="m-0 warning ft-s-13 d-flex align-items-center h-100">La modification de culture apportée au niveau de cette parcelle n'impacte pas votre déclaration d'assolement.
						Elle ne sera prise en compte que dans votre déclaration de sinistre.</p>
				</div>}
			</Collapse>
		</div>
	)
}
ParcelleSinistree.propTypes = {
	listeCultures: PropTypes.array
}
const mapStateToProps = (state) => {
	return {
		listeCultures: sinistreSelectors.getListeCultures(state)
	}
}
export default compose(
	connect(mapStateToProps)
)(ParcelleSinistree)