import React from 'react'
import { Col, Pagination, PaginationItem, PaginationLink, Row } from 'reactstrap'

const PaginationParcelles = ({ currentPage, pagesCount, changePages }) => {
	return (
		<Row style={{ marginTop: 18, marginBottom: 18 }}>
			<Col className="d-flex justify-content-center">
				<Pagination size="sm" aria-label="Page navigation example">
					{/*Pour éviter le comportement bizarre qui call le onClick du premier PaginationItem quand on appuie sur Entrée*/}
					<PaginationItem style={{ display: 'none' }} disabled={currentPage <= 0}>
						<PaginationLink
							onClick={() => {}}
						/>
					</PaginationItem>
					<PaginationItem disabled={currentPage <= 0}>
						<PaginationLink  previous onClick={(e) => changePages(e,currentPage - 1)}>
							<span className="icon-chevron-small-left"> </span>
							<div className="texte">Parcelles précédentes</div>
						</PaginationLink>
					</PaginationItem>
					{[...Array(pagesCount)].map((page, i) =>
						<PaginationItem active={i === currentPage} key={i}>
							<PaginationLink onClick={(e) => changePages(e, i)}>
								{i + 1}
							</PaginationLink>
						</PaginationItem>
					)}
					<PaginationItem disabled={currentPage >= pagesCount - 1}>
						<PaginationLink onClick={(e) => changePages(e, currentPage + 1)} next>
							<div className="texte">Parcelles suivantes</div>
							<span className="icon-chevron-tree-navi"> </span>
						</PaginationLink>
					</PaginationItem>
				</Pagination>
			</Col>
		</Row>
	)
}

export default PaginationParcelles