import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import * as configurationSelectors from '../../config/metier/configurationSelectors'
import FilAriane from '../../global/filAriane/FilAriane'
import ComponentQuestions from '../coordonnees/components/ComponentQuestions'
import NotificationDashboard from '../notification/NotificationDashboard'
import ContratRoutes from './contratRoutes'
import MonAgence from './monAgence/MonAgence'
import { enumThematiquesFaq } from '../../constantes/FaqThematiques'

const ContratPage = ({ pathname, pathToHome, props,notifications }) => {

	const [isVisible, setIsVisible] = React.useState(true)
	const [thematique, setThematique] = React.useState(enumThematiquesFaq.CONTRAT.code)
	const [thematiqueFAQ, setThematiqueFAQ] = React.useState(enumThematiquesFaq.CONTRAT.code)

	return (
		<div className="background-contrat">
			<div className="bg-image-primary bannerSize theme-color"> </div>
			<Container className="wrapper-dashboard">
				<FilAriane name={'Mon contrat'} path={props} />
				<Row className={`${notifications.length !== 0 && thematique !== "NO" && 'row-with-notifications'}`}>
					<ContratRoutes pathname={pathname} pathToHome={pathToHome} setThematique={setThematique} setThematiqueFAQ={setThematiqueFAQ}/>
					<Col md="12" lg="3" className="position-initial">
						<Row>
							<Col xs="12" className={`notifications ${notifications.length > 0 && thematique !== "NO" && 'mb-3'}`}>
								<NotificationDashboard thematiques={thematique} />
							</Col>
							<Col xs="12" className="mb-3">
								<MonAgence provenance={'C'} />
							</Col>
							{isVisible &&
							<Col xs="12" className="mb-3">
								<ComponentQuestions thematiques={thematiqueFAQ} setIsVisible={setIsVisible} />
							</Col>
							}
						</Row>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		notifications: configurationSelectors.getNotificationsTraitees(state)
	}
}

export default connect(mapStateToProps)(ContratPage)
