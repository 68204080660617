import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as sinistreActions from "../sinistreActions";
import { withRouter } from 'react-router'
import * as sinistreSelectors from "../sinistreSelectors";
import SinistreIntrouvable from "./SinistreIntrouvable";
import {Col} from "reactstrap";
import Row from "reactstrap/es/Row";

export const loadConsultation = (WrappedComponent,setThematique,setThematiqueFAQ) => {
    const ConsultationInjector = ({ setSinistreActif, sinistreActif, history, listSinistre, ...otherProps }) => {

        const [loading, setLoading] = React.useState(true)

        // function extractParamsUrl(chaineGET) {
        //     chaineGET = chaineGET.split('&')
        //     var result = {}
        //     chaineGET.forEach(function (el) {
        //         var param = el.split('=')
        //         param[0] = param[0].replace('?', '')
        //         result[param[0]] = param[1]
        //     })
        //     return result
        // }

        React.useEffect(()=> {
            if(sinistreActif){
                setLoading(false)
            }
        },[sinistreActif])
        /* todo refactor le code ici pour afficher le sinistre en cours */
        // React.useEffect(() => {
        //     if(Object.keys(listSinistre).length !== 0 && loading){
        //         // setLoading(true)
        //             setSinistreActif(extractParamsUrl(history.location.search))
        //             }
        // }, [listSinistre])

        return loading ?
            <Col md="12" lg="9">
                <Row>
                    <SinistreIntrouvable texteChargement={"Impossible de trouver ce sinistre."}/>
                </Row>
            </Col>
            : <WrappedComponent setThematique={setThematique} setThematiqueFAQ={setThematiqueFAQ}/>
    }

    ConsultationInjector.propTypes = {
        setSinistreActif: PropTypes.func,
        sinistreActif: PropTypes.string,
        listSinistre: PropTypes.object
    }
    const mapStateToProps = (state) => ({
        sinistreActif: sinistreSelectors.getSinistreActif(state),
        listSinistre: sinistreSelectors.getDonneesSinistreParNumero(state)
    })
    const actions = {
        setSinistreActif: sinistreActions.setSinistreActif
    }

    return compose(
        connect(mapStateToProps, actions), withRouter
    )(ConsultationInjector)
}