import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'reactstrap'
import * as messageActions from '../../../global/component/messages/messageActions'
import * as assolementActions from '../../contrat/assolement/assolementActions'
import FormAjoutParcelles from './FormAjoutParcelles'

const ModaleAjoutParcelles = ({isOpened, toggle,submitAddParcelles,ajoutParcelle,messagesSucces,messagesErreur}) => {
	return (
		<Modal centered={true} isOpen={isOpened} toggle={toggle}>
			<FormAjoutParcelles onSubmit={submitAddParcelles} toggle={toggle}/>
		</Modal>
	)
}

ModaleAjoutParcelles.propTypes = {
	ajoutParcelle:PropTypes.func
}
const mapStateToProps = (state) => {
	return {

	}
}
const actions = {
	ajoutParcelle:assolementActions.ajoutParcelle,
	messagesSucces:messageActions.messagesSucces,
	messagesErreur:messageActions.messagesErreur
}
export default connect(mapStateToProps,actions)(ModaleAjoutParcelles)