import React from 'react'
import { connect } from 'react-redux'
import * as configurationSelectors from '../../config/metier/configurationSelectors'
import CarouselNote from '../../global/component/carousel/CarouselNote'

const NotificationDashboard = ({ thematiques, notifications,baseLocation }) => {
	return (
		<div className="push-tpl-2 paper m-0">
			<CarouselNote notifications={notifications.filter(notification => notification.thematique.includes(thematiques))} baseLocation={baseLocation} />
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		notifications: configurationSelectors.getNotificationsTraitees(state)
	}
}

export default connect(mapStateToProps)(NotificationDashboard)

