import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
	Button,
	Collapse,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	Navbar,
	NavbarBrand,
	NavItem,
	NavLink,
	UncontrolledDropdown
} from 'reactstrap'
import ModaleDeconnexion from '../../accueil/deconnexion/ModaleDeconnexion'
import * as baseActions from '../../containers/baseActions'
import * as selectors from '../../modules/coordonnees/coordonneesSelectors'
import * as messageActions from '../component/messages/messageActions'


class NavigationDesktop extends React.Component {
	constructor(props) {
		super(props)
		this.toggleModale = this.toggleModale.bind(this)
		this.state = {
			isOpen: false,
			isModaleDeconnexionOpened: false
		}
	}

	toggleModale() {
		this.setState(prevState => ({
			isModaleDeconnexionOpened: !prevState.isModaleDeconnexionOpened
		}))
	}

	isPhisyque = titre => titre.toUpperCase().trim() === "M" || titre.toUpperCase().trim() === "MME"

	render() {
		const { informations, baseLocation, messagesAlerte, navigation } = this.props
		return (
			<Navbar light expand="md" className="navbar-desktop">
				<div className="nav-secondary">
					<Container>
						<div style={{ marginRight: 20 }} className="d-flex justify-content-end align-items-center">
							<div className="welcomeUser ml-5 d-flex justify-content-end align-items-center">
								<span>{this.isPhisyque(informations.titre) ? "Bonjour " : ""}<b>{informations.titre} {informations.nom} {informations.prenom}</b></span>
							</div>
							<div className="logout" style={{ marginLeft: 188 }}>
								<Button className="btn btn-link d-flex justify-content-end align-items-center" onClick={this.toggleModale}>
									<span>Se déconnecter</span>
								</Button>
							</div>
						</div>
					</Container>
				</div>
				<div className="nav-primary">
					<Container>
						<NavbarBrand tag={Link} to="/"> </NavbarBrand>
						<Collapse isOpen={this.state.isOpen} navbar>
							<Nav className="ml-auto" navbar>
								<NavItem>
									<NavLink active={window.location.pathname === baseLocation} tag={Link} to={baseLocation}>
										Accueil
									</NavLink>
								</NavItem>
								{navigation.map((menu) =>
									<UncontrolledDropdown active={window.location.pathname.indexOf(menu.nom) > -1} nav inNavbar key={`${menu.titre}`}>
										<DropdownToggle nav caret>
											{menu.titre}
										</DropdownToggle>
										<DropdownMenu right>
											{menu.sousMenus.map((sousMenu) => {
													if (Object.keys(sousMenu).length !== 0) return (
														<NavLink key={`${menu.titre}/${sousMenu.titre}`}
														         className={(sousMenu.lien && !sousMenu.disabled) ? '' : 'inactive'}
														         onClick={() => {
															         if (sousMenu.disabled) {
																         messagesAlerte('Votre espace client', 'Votre contrat a été suspendu. Vous ne pouvez plus déclarer de sinistre. En cas d’erreur sur cette procédure de suspension, veuillez contacter votre agence.')
															         }
														         }}
														         tag={Link} to={(sousMenu.lien && !sousMenu.disabled) ? sousMenu.lien : '#'}>
															<DropdownItem active={window.location.pathname === sousMenu.lien}>
																{sousMenu.titre}
															</DropdownItem>
														</NavLink>
													)
												}
											)}
										</DropdownMenu>
									</UncontrolledDropdown>
								)}
							</Nav>
						</Collapse>
					</Container>
				</div>
				<ModaleDeconnexion onSubmit={this.props.deconnexion} toggle={this.toggleModale}
				                   isOpened={this.state.isModaleDeconnexionOpened} />
			</Navbar>
		)
	}
}

NavigationDesktop.propTypes = {
	messagesAlerte: PropTypes.func,
	baseLocation: PropTypes.string.isRequired,
	informations: PropTypes.object,
	navigation: PropTypes.array.isRequired,
	toggleModale: PropTypes.func,
	deconnexion: PropTypes.func
}

const actions = {
	deconnexion: baseActions.logoutAction,
	messagesAlerte: messageActions.messagesAlerte
}

const mapStateToProps = (state) => {
	return {
		informations: selectors.getDonneesPerso(state)
	}
}

export default connect(mapStateToProps, actions)(NavigationDesktop)
