import {Col, Collapse, Row, Table} from "reactstrap";
import React from "react";
import {formatDateTime} from "../../../utils";
import Paper from "../../../global/component/paper/Paper";

const Acomptes = ({ sinistreActif, sinistreDecompte, sinistreAcompte }) => {
    const [open, setOpen] = React.useState(true)

    const toggle = () =>{
        setOpen(!open)
    }

    let totalIndemnite = 0
    if (sinistreDecompte.length !== 0) {
        sinistreDecompte.forEach((decompte) => {
                totalIndemnite += decompte.indemnite
            }
        )
    }
    let totalMontant = 0
    let totalReste = 0
    if (sinistreAcompte.length !== 0){
        totalReste = totalIndemnite - sinistreAcompte[sinistreAcompte.length - 1].montant
        sinistreAcompte.forEach((acompte) => {
            totalMontant += acompte.montant
            }
        )
    }

    return (
        <Paper className="push-tpl-1">
            <Row className="push-head">
                <Col>
                    <Row>
                        <Col md={12} sm={12} xs={12}>
                            <Row onClick={toggle}>
                                <Col md={11} sm={11} xs={11} className="d-flex align-items-center">
                                    <div className="title">
                                        Acomptes
                                    </div>
                                </Col>
                                <Col md={1} sm={1} xs={1} className="d-flex justify-content-end">
                                    <span className={open ? "icon icon-chevron-up c-dark mr-0" : "icon icon-chevron-down c-dark mr-0"}> </span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Collapse isOpen={open}>
                        <Row>
                            <Col md={12} sm={12} xs={12} className="mt-4">
                                <Row>
                                    <Col md={12} sm={12} xs={12}>
                                        <Table className="tableSuivreSinistre">
                                            <thead>
                                                <tr>
                                                    <th className="font-weight-bold"></th>
                                                    <th className="font-weight-bold" style={{textAlign:'center'}}>Date</th>
                                                    <th className="font-weight-bold" style={{textAlign:'center'}}>Montant</th>
                                                    <th className="font-weight-bold" style={{textAlign:'center'}}>Reste à payer</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {sinistreAcompte.map((acompte, index) =>
                                                        <tr key={index}>
                                                            <td style={{paddingLeft:30}}>Acomptes sur indemnités</td>
                                                            <td style={{textAlign:'center'}}>{formatDateTime(acompte.dateValidation)}</td>
                                                            <td style={{textAlign:'center'}}>{acompte.montant} €</td>
                                                            <td style={{textAlign:'center'}}>{totalIndemnite - acompte.montant} €</td>
                                                        </tr>
                                            )}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td style={{textAlign:'right'}}>Solde</td>
                                                    <td style={{textAlign:'center'}}>{totalMontant} €</td>
                                                    <td style={{textAlign:'center'}}>{totalReste} €</td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Collapse>
                </Col>
            </Row>
        </Paper>
    )
}
export default Acomptes