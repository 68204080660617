import moment from 'moment'
import React from 'react'
import momentLocaliser from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import 'moment/locale/fr';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import { Col, FormFeedback, Label, Row } from 'reactstrap'
import { CHAMP_OBLIGATOIRE, DATE_ERREUR } from '../FormDeclarationSinistre'

momentLocaliser(moment)

const styleIcon = {
	position: 'relative',
	right: 0,
	fontSize: 22,
	top: 0
}

const RenderDatePicker = ({ placeholder,po, initialDate, disabled, initialHeure, info, meta: { touched, error }, changeDate, input }) => {
	const [date, setDate] = React.useState( po ? initialDate : null)
	const [hour, setHour] = React.useState( null)
	let parts = null
	let temps = null
	let dateUpdate = null
	if (initialDate && initialHeure) {
		initialDate = initialDate.replace(/[^\x20-\x7E]/g, '')
		initialHeure = initialHeure.replace(/[^\x20-\x7E]/g, '')
		parts = initialDate.split("/")
		temps = initialHeure.split(':')
		dateUpdate = new Date(parts[2], parts[1] - 1, parts[0], temps[0], temps[1])
    }

	const changeDateForm = () => {
		let trueDate = date
		let timeZone = moment.parseZone(new Date()).utcOffset()
		if(!po) {
			if (date) {
				if (hour) {
					date.setHours(hour.getHours() + timeZone/60)
					date.setMinutes(hour.getMinutes())
					date.setSeconds(hour.getSeconds())
				} else {
                    date.setHours(timeZone/60)
					date.setMinutes(0)
					date.setSeconds(0)
				}
			}
            changeDate(trueDate)
		} else {
			let parts = date.replace(/[^\x20-\x7E]/g, '').split("/")
		    let newDate = new Date(parts[1] + "-" + parts[0] + "-" +  parts[2])
            newDate.setHours(newDate.getHours() + timeZone/60)
            changeDate(newDate)
        }
	}
	React.useEffect(changeDateForm, [date, hour])

	const formatInputDate = ['DDMMYYYY', 'DDMMYY']
	const formatInputHeure = ['HHmm']

	return (
		<Row style={{ marginBottom: 40, marginTop: 40 }}>
			<Col lg={3} md={3}>
				<Label className="c-primary ft-s-14">Date<b className="c-red">* </b>et heure du sinistre</Label>
			</Col>
			<Col lg={{ size: 3, offset: 1 }} md={{ size: 3, offset: 1 }} sm={{ size: 5, offset: 0 }} xs={{ size: 6, offset: 0 }}>
				<DateTimePicker
					{...{
						onFocus: input.onFocus
					}}
					culture="fr"
					disabled={disabled}
					defaultValue={dateUpdate}
					placeholder="JJ/MM/AAAA"
					format={'DD/MM/YYYY'}
					containerClassName="declaration-sinistre-date"
					dateIcon={<span style={styleIcon} className="icon-calendar-plain"></span>}
					time={false}
					parse={formatInputDate}
					onChange={(value) => {
						setDate(value)
					}}
					max={new Date()}
				/>
				<FormFeedback valid={!error} style={{ display: error === DATE_ERREUR || (error === CHAMP_OBLIGATOIRE && touched)? 'block' : 'none' }}>
					<div style={{ display: 'flex' }}>
						<p style={{ fontSize: '12px' }}>{error}</p>
					</div>
				</FormFeedback>
			</Col>
			<Col lg={{ size: 3 }} md={{ size: 3 }} sm={{ size: 4 }} xs={{ size: 6 }}>
				<DateTimePicker
					culture="fr"
					defaultValue={dateUpdate}
					disabled={disabled}
					placeholder="hh:mm"
					timeFormat={'HH:mm'}
					format={'HH:mm'}
					parse={formatInputHeure}
					containerClassName="declaration-sinistre-time"
					timeIcon={<span style={styleIcon} className="icon-clock-o"></span>}
					date={false}
					onChange={(value) => {
						setHour(value)
					}}

				/>
			</Col>
		</Row>
	)
}
export default RenderDatePicker