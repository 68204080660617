import * as contratApi from './contratApi'

export const GET_CONTRAT_ERROR = 'GET_CONTRAT_ERROR'
export const GET_CONTRAT = 'GET_CONTRAT'

export const GET_CONTRAT_SUCCES = 'GET_CONTRAT_SUCCES'
export const SET_EXERCICE_ACTIF = 'SET_EXERCICE_ACTIF'
export const SET_CONTRAT_ACTIF = 'SET_CONTRAT_ACTIF'

export const GET_ATTESTATION_ASSURANCE = 'GET_ATTESTATIOTN_ASSURANCE'

export const GET_PATH_ERROR = 'GET_PATH_ERROR'
export const GET_PATH_SUCCES = 'GET_PATH_SUCCES'
export const GET_PATH = 'GET_PATH'

export const getContrat = () => dispatch => {
    dispatch({type: GET_CONTRAT})
    return new Promise((resolve, reject) => {
        return contratApi.getContrat()
            .then(response => {
                if (response.status === 200) {
                    resolve(response.json())
                } else {
                    reject(response)
                }
            })
    }).then(response => {
        return setContrat(response)(dispatch)
    }).catch(() => {
        dispatch({ type: GET_CONTRAT_ERROR })
    })
}

export const setContrat = (response) => (dispatch) => {
    dispatch({
        type: GET_CONTRAT_SUCCES,
        response
    })
};


export  const setContratActif = (response) => (dispatch) => {
    dispatch({
        type: SET_CONTRAT_ACTIF,
        response
    })
}

export const downloadAttestationAssurance = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return contratApi.downloadAttestationAssurance(data)
            .then(response => {
                if (response.status === 200) {
                    resolve(response.blob())
                } else {
                    reject(response)
                }
            })
    })
}

export const downloadDecompteProvisoire = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return contratApi.downloadDecompteProvisoire(data)
            .then(response => {
                if (response.status === 200) {
                    resolve(response.blob())
                } else {
                    reject(response)
                }
            })
    })
}

export const downloadConstatPerte = (data, societe) => dispatch => {
    return new Promise ((resolve, reject) => {
        return contratApi.downloadConstatPerte(data, societe)
            .then(response => {
                if (response.status === 200) {
                    resolve(response)
                } else {
                    throw reject(response)
                }
            })
    })
}
export const downloadLettreDesistement = (data) => dispatch => {
    return new Promise ((resolve, reject) => {
        return contratApi.downloadLettreDesistement(data)
            .then(response => {
                if (response.status === 200) {
                    resolve(response)
                } else {
                    throw reject(response)
                }
            })
    })
}
export const downloadQuittancementIndemnite = (numeroContrat, exercice) => dispatch => {
    return new Promise ((resolve, reject) => {
        return contratApi.downloadQuittancementIndemnite(numeroContrat, exercice)
            .then(response => {
                if (response.status === 200) {
                    resolve(response.blob())
                } else {
                    throw reject(response)
                }
            })
    })
}

export const downloadQuittancementCotisation = (numeroContrat, exercice) => dispatch => {
    return new Promise ((resolve, reject) => {
        return contratApi.downloadQuittancementCotisation(numeroContrat, exercice)
            .then(response => {
                if (response.status === 200) {
                    resolve(response.blob())
                } else {
                    throw reject(response)
                }
            })
    })
}

export const downloadQuittancementAcompte = (numeroContrat, exercice, numeroQuittance, identifiantQuittance) => dispatch => {
    return new Promise ((resolve, reject) => {
        return contratApi.downloadQuittancementAcompte(numeroContrat, exercice, numeroQuittance, identifiantQuittance)
            .then(response => {
                if (response.status === 200) {
                    resolve(response.blob())
                } else {
                    throw reject(response)
                }
            })
    })
}

export const downloadAttestationAssolement = (numeroContrat, exercice) => dispatch => {
    return new Promise((resolve, reject) => {
        return contratApi.downloadAttestationAssolement(numeroContrat, exercice)
            .then(response => {
                if (response.status === 200) {
                    resolve(response.blob())
                } else {
                    reject(response)
                }
            })
    })
}

export const downloadContrat = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return contratApi.downloadContrat(data)
            .then(response => {
                if (response.status === 200) {
                    resolve(response.json())
                } else {
                    reject(response)
                }
            })
    })
}
