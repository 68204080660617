import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Col } from 'reactstrap'
import { compose } from 'recompose'
import { Field, reduxForm } from 'redux-form'
import LoaderButton from '../../../global/component/loader/LoaderButton'
import * as agenceSelector from '../../agence/agenceSelectors'
import * as messagerieActions from '../messagerieActions'
import RenderEditor from './customFields/RenderEditor'
import RenderFileMessage from './customFields/RenderFileMessage'

const validate = (values) => {
	const errors = {}
	const requiredFields = [
		'message'
	]
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = 'Ce champ est obligatoire'
		}
	})

	return errors
}


const FormReponseMessage = ({ cancel, isLoading, handleSubmit, submitFailed, pristine, submitting, change }) => {

	const [visibleDoc, setVisibleDoc] = React.useState(1)

	const addFieldDoc = () => {
		setVisibleDoc(visibleDoc + 1)
	}

	const deleteFieldDoc = (numberFileField) => {
		setVisibleDoc(visibleDoc - 1)
		change(numberFileField, null)
	}

	React.useEffect(() => {
		change("message"," ")
	}, [])

	return (
		<Col lg={12} sm={12} md={12}>
			<form onSubmit={handleSubmit} className="w-100">
				<div className="form-group sinistre">
					<div>
						<Field
							name="message"
							component={RenderEditor}
							label="Message"
							placeholder="Saisissez ici le texte de votre message .."
							newMessage={false}
						/>
					</div>
					<div>
						<Field
							name="file"
							libelle={true}
							component={RenderFileMessage}
							type="file"
							addDeleteFieldDoc={() => {
								visibleDoc === 1 ? addFieldDoc() : visibleDoc === 2 ? deleteFieldDoc('file2') : deleteFieldDoc('file3')
							}}
							classButton={visibleDoc === 1 ? 'icon-plus' : 'icon-minus'}
							label="Joindre un document"
							sousTitre="(Taille maximum : 5Mo / formats acceptés : .pdf, .jpg, .jpeg, .png, .bmp)"
						/>
					</div>
					{(visibleDoc === 2 || visibleDoc === 3) &&
					<div>
						<Field
							name="file2"
							libelle={false}
							component={RenderFileMessage}
							addDeleteFieldDoc={() => {
								visibleDoc === 2 ? addFieldDoc() : deleteFieldDoc('file3')
							}}
							classButton={visibleDoc === 2 ? 'icon-plus' : 'icon-minus'}
							type="file"
							label="Joindre un document"
							sousTitre="(Taille maximum : 5Mo / formats acceptés : .pdf, .jpg, .jpeg, .png, .bmp)"
						/>
					</div>
					}
					{visibleDoc === 3 &&
					<div>
						<Field
							name="file3"
							libelle={false}
							component={RenderFileMessage}
							addDeleteFieldDoc={() => {
								deleteFieldDoc('file3')
							}}
							classButton={'icon-minus'}
							type="file"
							label="Joindre un document"
							sousTitre="(Taille maximum : 5Mo / formats acceptés : .pdf, .jpg, .jpeg, .png, .bmp)"
						/>
					</div>
					}
					<div style={{ paddingTop: 21 }} className="d-flex justify-content-center">
						<Button
							outline color="light" size="sm" style={{ marginLeft: 25, marginRight: 25 }}
							onClick={() => {
								cancel()
							}}
							className="border-2 btn-block w-25"
						>Annuler</Button>
						<Button
							style={{ marginLeft: 25, marginRight: 25, marginTop: 0 }}
							type="submit"
							disabled={pristine || submitting}
							color="light" size="sm"
							className="btn-block w-25 d-flex align-items-center justify-content-center"
						>{isLoading ? <LoaderButton /> : 'Envoyer'}</Button>
					</div>
				</div>
				{submitFailed && window.scrollTo(0, 0)}
			</form>
		</Col>
	)
}


FormReponseMessage.propTypes = {
	agence: PropTypes.object
}

const mapStateToProps = (state) => {
	return {
		agence: agenceSelector.getDonneesAgence(state)
	}
}
const actions = {
	setMessageActif: messagerieActions.setMessageActif
}
export default compose(
	reduxForm({
		form: 'reponseMessage',
		touchOnBlur: false,
		enableReinitialize: true,
		validate
	}),
	connect(mapStateToProps, actions),
	withRouter
)(FormReponseMessage)
