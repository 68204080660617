export function fetchUtilisateur(token) {
	return fetch(process.env.REACT_APP_SERVER_URL + '/auth/utilisateur', {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json; charset=utf-8',
			Authorization: token
		}
	}).then((response) => {
		if (response.ok) {
			return response.json()
		}
		return
	})
}

export function refresh(token) {
	return fetch(process.env.REACT_APP_SERVER_URL + '/auth/raffraichir', {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json; charset=utf-8',
			Authorization: token
		}
	}).then((response) => {
		if (response.ok) {
			return response.json()
		}
		return
	})
}

export const connectUtilisateur = (data) => {
	const username = data.identifiant,
		password = data.motDePasse

	return fetch(process.env.REACT_APP_SERVER_URL + '/auth/connexion', {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			login: username,
			password: password
		})
	})
		.then((response) => {
			if (response.ok) {
				return response.json()
			} else {
				throw response.json()
					.then(json => {
						return json
					})
			}
		})
}


export const motDePasseOublie = data => {

	return fetch(process.env.REACT_APP_SERVER_URL + '/auth/motDePasseOublie/demande', {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	})
}

export const reinitialiseMdp = (motDePasse, token) => {

	return fetch(process.env.REACT_APP_SERVER_URL + '/auth/motDePasseOublie/reinitialisation', {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			motDePasse,
			token
		})
	})
}
export const storeLocalUser = token => {
	window.localStorage.setItem('tokenFO', token)
}

export const removeLocalUser = () => {
	window.localStorage.removeItem('tokenFO')
}