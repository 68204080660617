import React from "react";
import Paper from "../../../global/component/paper/Paper";
import {Col, Row, Table} from "reactstrap";
import {connect} from "react-redux";
import * as assolementSelectors from "../assolement/assolementSelectors";
import PropTypes from "prop-types";
import * as tarificateurSelectors from "../tarificateur/tarificateurSelectors";
import * as decomptesSelectors from "../decomptesProvisoires/decomptesSelectors";
import {formatDecimales, formatMillier} from "../../../utils";
import * as quittancementSelectors from "../quittancement/quittancementSelectors";
import LigneDetailParculture from "./LigneDetailParCulture";

const DetailParCulture = ({ donneesTarificateur, donneesTarificateurCulture, donneesDecomptesCulture, donneesDecomptes,donneesQuittancementCulture}) =>{

    return(
        <Paper className="mon-contrat-page push-tpl-1">
            <Row className="push-head">
                <Col>
                    <Row className="mb-3">
                        <Col className="d-flex align-items-center">
                            <div className="title">
                                {Object.keys(donneesTarificateur).length !== 0 ?
                                        "Détail de la cotisation - campagne " + donneesTarificateur.exercice
                                    :
                                        "Détail de la cotisation - campagne " + donneesDecomptes.exercice
                                }
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="push-body cotisations">
                <Col className="content flex-column text-left" xs="12">
                    <div className="avantage-content cotisations">
                        <Row>
                            <Col>
                                <div className="push-body">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'left' }} className="font-weight-bold">Cultures garanties</th>
                                                <th className="font-weight-bold">Cotisations</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {donneesTarificateurCulture.length !== 0 ?
                                                donneesTarificateurCulture
                                                    .sort(({codeCulture: previousCode},{codeCulture: currentCode})=> previousCode - currentCode)
                                                    .map((culture,index) =>
                                                        <LigneDetailParculture
                                                            key={index}
                                                            codeCulture={culture.codeCulture.numeroCulture}
                                                            nom={culture.variete.nom || culture.codeCulture.nom}
                                                            variation={culture.variation && culture.variation}
                                                            cotisation={culture.cotisation}
                                                        />
                                                    )
                                                :
                                                donneesQuittancementCulture.length !== 0 ?
                                                    donneesQuittancementCulture
                                                        .sort(({codeCulture: previousCode},{codeCulture: currentCode})=> previousCode - currentCode)
                                                        .map((culture,index) =>
                                                            <LigneDetailParculture
                                                                key={index}
                                                                codeCulture={culture.codeCulture}
                                                                nom={culture.libelleCulture.toLowerCase()}
                                                                variation={culture.variation && culture.variation}
                                                                cotisation={culture.cotisationBase}
                                                            />
                                                                )
                                                    :
                                                donneesDecomptesCulture
                                                .sort(({numeroCulture: previousCode},{numeroCulture: currentCode})=> previousCode - currentCode)
                                                .map((culture,index) =>
                                                    <LigneDetailParculture
                                                        key={index}
                                                        codeCulture={culture.numeroCulture}
                                                        nom={(culture.codeCulture.nom).toLowerCase()}
                                                        variation={culture.variation && culture.variation}
                                                        cotisation={culture.cotisation}
                                                    />
                                                )}
                                        </tbody>
                                    </Table>
                                    <div className="footer-cotisations detailCulture">
                                        <Col lg={9} md={9} sm={7} xs={7}>
                                            <Row className="fraisFixes">Frais fixes et taxes</Row>
                                            <Row className="font-weight-bold totalTTC">Total T.T.C.</Row>
                                        </Col>
                                        <Col lg={3} md={3} sm={5} xs={5}>
                                            <Row className="fraisFixes">{Object.keys(donneesTarificateur).length !== 0 ? formatMillier(formatDecimales(donneesTarificateur.taxeAttentat + donneesTarificateur.fraisFixe, 2)) : "35,90"} €</Row>
                                            {Object.keys(donneesTarificateur).length !== 0 ?
                                                <Row className="font-weight-bold totalTTC">{formatMillier(formatDecimales(donneesTarificateur.cotisationTtc, 2))} €</Row>
                                                :
                                                <Row className="font-weight-bold totalTTC">{formatMillier(formatDecimales(donneesDecomptes.totalCotisation, 2))} €</Row>
                                            }
                                        </Col>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Paper>
        )
}

DetailParCulture.propTypes = {
    assolementHistoriqueExerciceActif: PropTypes.object,
    donneesTarificateurCulture: PropTypes.array,
    donneesQuittancementCulture: PropTypes.array,
    donneesTarificateur: PropTypes.object,
    donneesDecomptes: PropTypes.object,
}

const mapStateToProps = (state) => {
    return {
        assolementHistoriqueExerciceActif: assolementSelectors.getHistoriqueAssolementExerciceActif(state),
        donneesTarificateur: tarificateurSelectors.getHistoriqueTarificateurExerciceActif(state),
        donneesTarificateurCulture: tarificateurSelectors.getDonneesHistoriqueTarificateurCulture(state),
        donneesDecomptesCulture: decomptesSelectors.getHistoriqueDecomptesRecapitulatifExerciceActif(state),
        donneesDecomptes:decomptesSelectors.getHistoriqueDecomptesExerciceActif(state),
        donneesQuittancementCulture: quittancementSelectors.getQuittancementCultureContratExerciceActif(state)
    }
}

export default connect(mapStateToProps)(DetailParCulture);