import * as actions from './metier/configurationActions'

const initialState = []

 const libelles = (state = initialState, action) => {
	switch (action.type) {
		case actions.GET_LIBELLES: {
			return {
				...state
			}
		}
		case actions.GET_LIBELLES_SUCCESS :
			return {
				...state,
				listeLibelles: action.libelles
			}
		case actions.GET_LIBELLES_ERROR:
			return state
		default:
			return state
	}
}

export default libelles