import React from 'react'
import { Collapse } from 'reactstrap'
import Col from 'reactstrap/es/Col'
import Row from 'reactstrap/es/Row'

const Question = ({ question, index, isOpen }) => {

	const [collapse, setCollapse] = React.useState(isOpen)

	return (
		<Row>
			<Col>
				<Row onClick={() => {
					setCollapse(!collapse)
				}} className={'d-flex align-items-center elementQuestion p-3'}>
					<Col lg={11} md={11} sm={11} xs={11}>
						Question {index} - {question.question}
					</Col>
					<Col lg={1} md={1} sm={1} xs={1} className={'text-right'}>
						<span className={collapse ? 'icon icon-chevron-up c-dark' : 'icon icon-chevron-down c-dark'}> </span>
					</Col>
				</Row>
				<Collapse isOpen={collapse}>
					<Row className={'elementReponse p-3'}>
						<Col>
							<p dangerouslySetInnerHTML={{ __html: question.reponse }} />
						</Col>
					</Row>
				</Collapse>
			</Col>
		</Row>
	)
}
export default Question