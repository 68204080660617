import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { enumThematiquesFaq } from '../../../../constantes/FaqThematiques'
import * as donneesMetierActions from '../../../donneesMetierActions'
import * as assolementSelectors from '../../assolement/assolementSelectors'
import * as tarificateurSelectors from '../../tarificateur/tarificateurSelectors'
import MonAssolement from '../MonAssolement'
import MonContrat from '../MonContrat'

const HistoriqueAssolementPage = ({ assolementsHistorique, donneesTarificateur, assolementHistoriqueExerciceActif, parcelles,setThematique,setThematiqueFAQ }) => {
	const [childThematique] = React.useState('NO')
	const [childThematiqueFAQ] = React.useState(enumThematiquesFaq.DECLARATION.code)

	useEffect(() => {
		setThematique(childThematique)
	}, [childThematique])

	useEffect(() => {
		setThematiqueFAQ(childThematiqueFAQ)
	}, [childThematiqueFAQ])

	return (
		<Col md="12" lg="9">
			<Row>
				<Col className="mb-3">
					<MonContrat
						titre="Historique de mes assolements"
						selectExercice="true"
						preListExercice={Object.keys(assolementsHistorique)}
						donneesTarificateur={donneesTarificateur}
						isMesGaranties={false}
						texteMulti={'Vous pouvez consulter les garanties de chaque contrat en faisant défiler vos contrats dans le bloc ci-dessous.'}
					/>
				</Col>
			</Row>
			<Row>
				<Col className="mb-3">
					<MonAssolement
						libelleBouton="Télécharger la déclaration d'assolement"
						titre="Ma déclaration d’assolement"
						sousTitre="Détail parcellaire"
						afficherDate={false}
						assolementHistoriqueExerciceActif={assolementHistoriqueExerciceActif}
						parcelles={parcelles}
						isHistorique={true}
					/>
				</Col>
			</Row>
		</Col>
	)

}

HistoriqueAssolementPage.propTypes = {
	recupHistoriqueAssolements: PropTypes.func,
	recupHistoriqueDecomptes: PropTypes.func,
	assolementsHistorique: PropTypes.object,
	assolementHistoriqueExerciceActif: PropTypes.object,
	parcelles: PropTypes.array,
	donneesTarificateur: PropTypes.object
}

const mapStateToProps = (state) => ({
	assolementsHistorique: assolementSelectors.getHistoriqueAssolementActif(state),
	assolementHistoriqueExerciceActif: assolementSelectors.getHistoriqueAssolementExerciceActif(state),
	parcelles: assolementSelectors.getHistoriqueParcellesAssolementActif(state),
	donneesTarificateur: tarificateurSelectors.getHistoriqueTarificateurExerciceActif(state)

})

const actions = {
	recupHistoriqueAssolements: donneesMetierActions.getHistoriqueAssolement,
	recupHistoriqueTarificateur: donneesMetierActions.getHistoriqueTarificateur,
	recupHistoriqueDecomptes: donneesMetierActions.getHistoriqueDecomptes
}

export default connect(mapStateToProps, actions)(HistoriqueAssolementPage)