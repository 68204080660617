const getLocalState = (state) => state

export const getDonneesConfiguration = (state) => getLocalState(state).configuration || {}

export const getDonneeConfiguration = (state) => nomDonnee => getDonneesConfiguration(state)[nomDonnee] || {}

export const getLibelles = (state) => getLocalState(state).libelles.listeLibelles || []

export const getMajosMinos = (state) => getLocalState(state).majosMinos || {}

export const getQuestions = (state) => getLocalState(state).questionsFrequentes || []

export const getEcheancier = (state) => getLocalState(state).echeancier || []

export const getEcheancierMaj = (state) => {
	let echeancier = getEcheancier(state)
	let resultat = []
	Object.keys(echeancier).forEach((key) => {
		let listObjet = []
		let largeur = null
		let avant = null
		let jourDebut = echeancier[key].dateDebut.split('/')[0]
		let moisDebut = echeancier[key].dateDebut.split('/')[1]
		let jourFin = echeancier[key].dateFin.split('/')[0]
		let moisFin = echeancier[key].dateFin.split('/')[1]

		if (parseInt(moisDebut) > parseInt(moisFin)) {
			largeur = ((12 - parseInt(moisDebut)) + parseInt(moisFin) + 1) * 2
			avant = (Math.abs(10 - parseInt(moisDebut))) * 2
			if (parseInt(jourDebut) >= 15) {
				avant = avant + 1
				if (parseInt(jourFin) > 15) {
					largeur = largeur - 1
				}
			}
			if (parseInt(jourFin) <= 15) {
				largeur = largeur - 1
			}
		} else {
			largeur = (parseInt(moisFin) - parseInt(moisDebut) + 1) * 2
			avant = (parseInt(moisDebut) + 2) * 2
			if (parseInt(jourDebut) >= 15) {
				avant = avant + 1
			}
			if (parseInt(jourFin) <= 15 || (parseInt(jourDebut) >= 15 && parseInt(jourFin) >= 15)) {
				largeur = largeur - 1
			}
		}

		let objet = {
			largeur: largeur,
			avant: avant,
			pack: echeancier[key].pack,
			type: echeancier[key].type,
			texte: echeancier[key].libelle,
			info: echeancier[key].informations
		}
		listObjet.push(objet)
		resultat.push(listObjet)
    })
	resultat.sort(sortByType)
	return resultat.sort(sortByDate)
}

const sortByDate = (evenementA, evenementB) => {
    if (evenementA[0].avant > evenementB[0].avant && evenementA[0].type === evenementB[0].type) {
        return -1
    }
    if (evenementA[0].avant < evenementB[0].avant && evenementA[0].type === evenementB[0].type) {
        return 1
    }
    return 0
}

const sortByType = (evenementA, evenementB) => {
	if (evenementA[0].type === "assurance" && evenementB[0].type === "administration") {
		return -1
	}
	if (evenementA[0].type === "administration" && evenementB[0].type === "assurance") {
		return 1
	}
	return 0
}

export const getNotifications = (state) => getLocalState(state).notifications || []

export const getAssoNotifications = (state) => getLocalState(state).assoNotifications || []

export const getNotificationsTraitees = (state) => {
    let assoNotifications = getAssoNotifications(state)
    let notifications = getNotifications(state)
    if (Object.values(notifications).length !== 0 && Object.values(assoNotifications).length !== 0){
        let objetReponse = []
        Object.keys(assoNotifications).forEach((keyAsso, index) => {
            Object.keys(notifications).forEach((key) => {
                let objet =  null
                if (notifications[key].type === "1" && assoNotifications[keyAsso].notification === "1"){
                    objet = {
                        key : index + 1,
                        type : notifications[key].type,
                        pack : notifications[key].pack,
                        titre : "Décompte provisoire (contrat n° ",
                        message1 : "Votre décompte provisoire de cotisation pour l'année ",
                        message2 : " est disponible. Vérifiez-le et signalez sans attendre tous changements à votre assureur.",
                        texteLien : notifications[key].texteLien,
                        thematique : notifications[key].thematique,
	                    contrat: assoNotifications[keyAsso].contrat,
	                    exercice: assoNotifications[keyAsso].exercice,
	                    sinistre: assoNotifications[keyAsso].sinistre,
	                    date: assoNotifications[keyAsso].date
                    }
                }
                if (notifications[key].type === "2"  && assoNotifications[keyAsso].notification === "2"){
                    objet = {
                        key : index + 1,
                        type : notifications[key].type,
                        pack : notifications[key].pack,
                        titre : "Cotisation (contrat n° ",
                        message1 : "L'appel de cotisation due pour l'année ",
                        message2 : " est disponible. Merci de bien vouloir vous en acquitter avant le ",
                        texteLien : notifications[key].texteLien,
                        thematique : notifications[key].thematique,
	                    contrat: assoNotifications[keyAsso].contrat,
	                    exercice: assoNotifications[keyAsso].exercice,
	                    sinistre: assoNotifications[keyAsso].sinistre,
	                    date: assoNotifications[keyAsso].date
                    }
                }
                if (notifications[key].type === "3" && assoNotifications[keyAsso].notification === "3"){
                    objet = {
                        key : index + 1,
                        type : notifications[key].type,
                        pack : notifications[key].pack,
                        titre : "Cotisation (contrat n° ",
                        message1 : "L'appel de cotisation due pour l'année ",
                        message2 : " est disponible, ce montant sera prélevé automatiquement sur le compte bancaire que vous avez indiqué.",
                        texteLien : notifications[key].texteLien,
                        thematique : notifications[key].thematique,
	                    contrat: assoNotifications[keyAsso].contrat,
	                    exercice: assoNotifications[keyAsso].exercice,
	                    sinistre: assoNotifications[keyAsso].sinistre,
	                    date: assoNotifications[keyAsso].date
                    }
                }
                if (notifications[key].type === "4" && assoNotifications[keyAsso].notification === "4"){
                    objet = {
                        key : index + 1,
                        type : notifications[key].type,
                        pack : notifications[key].pack,
                        titre : "Rapport d'expertise disponible",
                        message1 : "Le rapport d'expertise provisoire de votre sinistre N° ",
                        message2 : " est disponible, ce montant sera prélevé automatiquement sur le compte bancaire que vous avez indiqué.",
                        texteLien : notifications[key].texteLien,
                        thematique : notifications[key].thematique,
	                    contrat: assoNotifications[keyAsso].contrat,
	                    exercice: assoNotifications[keyAsso].exercice,
	                    sinistre: assoNotifications[keyAsso].sinistre,
	                    date: assoNotifications[keyAsso].date
                    }
                }
                if (notifications[key].type === "5" && assoNotifications[keyAsso].notification === "5"){
                    objet = {
                        key : index + 1,
                        type : notifications[key].type,
                        pack : notifications[key].pack,
                        titre : "Rapport d'expertise disponible",
                        message1 : "Le rapport d'expertise définitif de votre sinistre N° ",
                        message2 : " a été réalisé par notre expert. Vous pouvez le consulter dans votre suivi de sinistre.",
                        texteLien : notifications[key].texteLien,
                        thematique : notifications[key].thematique,
	                    contrat: assoNotifications[keyAsso].contrat,
	                    exercice: assoNotifications[keyAsso].exercice,
	                    sinistre: assoNotifications[keyAsso].sinistre,
	                    date: assoNotifications[keyAsso].date
                    }
                }
                if (notifications[key].type === "6" && assoNotifications[keyAsso].notification === "6"){
                    objet = {
                        key : index + 1,
                        type : notifications[key].type,
                        pack : notifications[key].pack,
                        titre : "Quittance d'indemnité disponible",
                        message1 : "La quittance d'indemnité du sinistre N° ",
                        message2 : " est disponible. Télécharger-la dans votre suivi de sinistre et  transmettez-la signée à votre assureur.",
                        texteLien : notifications[key].texteLien,
                        thematique : notifications[key].thematique,
	                    contrat: assoNotifications[keyAsso].contrat,
	                    exercice: assoNotifications[keyAsso].exercice,
	                    sinistre: assoNotifications[keyAsso].sinistre,
	                    date: assoNotifications[keyAsso].date
                    }
                }
                if (notifications[key].type === "7" && assoNotifications[keyAsso].notification === "7"){
                    objet = {
                        key : index + 1,
                        type : notifications[key].type,
                        pack : notifications[key].pack,
                        titre : "Clôture de votre sinistre",
                        message1 : "Afin de clôturer votre dossier sinistre N° ",
                        message2 : ", merci de bien vouloir signer votre quittance d'indemnité et de la transmettre à votre agence.",
                        texteLien : notifications[key].texteLien,
                        thematique : notifications[key].thematique,
	                    contrat: assoNotifications[keyAsso].contrat,
	                    exercice: assoNotifications[keyAsso].exercice,
	                    sinistre: assoNotifications[keyAsso].sinistre,
	                    date: assoNotifications[keyAsso].date
                    }
                }
                if ((notifications[key].type === "8" && assoNotifications[keyAsso].notification === "8") || (notifications[key].type === "9" && assoNotifications[keyAsso].notification === "9")){
                    objet = {
                        key : index + 1,
                        type : notifications[key].type,
                        pack : notifications[key].pack,
                        titre : "Pensez à compléter votre déclaration d'assolement!",
                        message1 : "En cas de sinistre, seule une déclaration d'assolement avec un détail parcellaire complet nous permettra de calculer vos indemnités. ",
                        texteLien : notifications[key].texteLien,
                        thematique : notifications[key].thematique,
	                    contrat: assoNotifications[keyAsso].contrat,
	                    exercice: assoNotifications[keyAsso].exercice,
	                    sinistre: assoNotifications[keyAsso].sinistre,
	                    date: assoNotifications[keyAsso].date
                    }
                }
                if (objet != null){
                    objetReponse.push(objet)
                }
            })
        })
        return objetReponse
    } else {
        return []
    }
}