import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as selectors from '../../modules/contrat/contratSelectors'
import * as coordonneesSelectors from '../../modules/coordonnees/coordonneesSelectors'
import { isContratEnInstanceResiliation, isContratEnInstanceSuspensionOuContencieux, isContratSuspenduOuContencieux } from '../../utils'
import * as messageActions from '../component/messages/messageActions'
import NavigationDesktop from './NavigationDesktop'
import NavigationMobile from './NavigationMobile'

class Navigation extends Component {

	constructor(props) {
		super(props)
		this.state = {}
	}

	getNavigation(baseLocation) {
		return [
			{
				titre: 'Mon Contrat',
				nom: 'contrat',
				sousMenus: [
					{
						titre: 'Mes garanties',
						lien: `${baseLocation}/contrat/garanties`
					},
					{ titre: 'Ma déclaration d\'assolement',
						lien: `${baseLocation}/contrat/assolement`
					},
					{ titre: 'Mes cotisations',
					lien: `${baseLocation}/contrat/cotisations`
					},
					{ titre: 'Historique de mes assolements',
						lien: `${baseLocation}/contrat/historiqueAssolement`
					},
					{ titre: 'Dates clés de mon contrat',
						lien: `${baseLocation}/contrat/echeancier`
					}
				]
			}, {
				titre: 'Mes Sinistres',
				nom: 'sinistre',
				sousMenus: [
					this.props.isUserAllowed ? { titre: 'Déclarer un sinistre',
						disabled: isContratEnInstanceResiliation(this.props.getDonneesContratByContrat(this.props.contratActif).etatCode, this.props.getDonneesContratByContrat(this.props.contratActif).etatDate)
							|| isContratEnInstanceSuspensionOuContencieux(this.props.getDonneesContratByContrat(this.props.contratActif).etatCode, this.props.getDonneesContratByContrat(this.props.contratActif).etatDate)
							|| isContratSuspenduOuContencieux(this.props.getDonneesContratByContrat(this.props.contratActif).etatCode, this.props.getDonneesContratByContrat(this.props.contratActif).etatDate),
						lien: `${baseLocation}/sinistre/declarerUnSinistre`
					} : {},
					{ titre: 'Suivre mes sinistres',
					  lien: `${baseLocation}/sinistre/mesSinistres`
					},
					{ titre: 'Consulter mes indemnisations',
						lien: `${baseLocation}/sinistre/consulterMesIndemnisations`
					}
				]
			}, {
				titre: 'Mes Services',
				nom: 'services',
				sousMenus: [
					{
						titre: 'Modifier mes données personnelles',
						lien: `${baseLocation}/services/mesCoordonnees`
					},
					{
						titre: 'Mes échanges avec mon agence',
						lien: `${baseLocation}/services/messagerie/consultation`
					},
				]
			}, {
				titre: 'Aide & Contact',
				nom: '/aideEtContact/',
				sousMenus: [
					{ titre: 'Contacter mon agence',
						lien: `${baseLocation}/aideEtContact/contacterMonAgence`
					},
					{
						titre: 'Questions fréquentes',
						lien: `${baseLocation}/aideEtContact/questionsFrequentes`
					}
				]
			}
		]
	}

	render() {
		const { baseLocation, isUserAllowed } = this.props
		return (
			<div>
				<NavigationDesktop isUserAllowed={isUserAllowed} baseLocation={baseLocation} navigation={this.getNavigation(baseLocation)} inProgressLink={`${baseLocation}/inprogress`} />
				<NavigationMobile baseLocation={baseLocation} navigation={this.getNavigation(baseLocation)} inProgressLink={`${baseLocation}/inprogress`} />
			</div>
		)
	}

}

Navigation.propTypes = {
	isUserAllowed: PropTypes.bool,
	messagesAlerte: PropTypes.func,
	contratListe: PropTypes.array,
	getDonneesContratByContrat: PropTypes.func,
	baseLocation: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
	return {
		isUserAllowed: coordonneesSelectors.isDeclarationSinistreAllowed(state),
		contratListe: selectors.getContratsNonResiliesListe(state),
		contratActif: selectors.getContratActif(state),
		getDonneesContratByContrat: selectors.getDonneesContratByContrat(state)
	}
}

const actions = {
	messagesAlerte: messageActions.messagesAlerte
}

export default compose(connect(mapStateToProps, actions))(Navigation)
