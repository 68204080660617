import {
	GET_CONFIGURATION_SUCCESS
} from './configurationActions'

export default (state = {}, action) => {
	switch (action.type) {

		case GET_CONFIGURATION_SUCCESS: {
			return action.payload
		}
		default:
			return state
		}
	}