import {fetchFactory} from "../../entities/fetchEntities";

export const getCoordonnees = () =>
	fetchFactory('/extranet/coordonnees', {method:'GET'})
	.then((response) => {
		if (response.ok) {
			return response
		} else {
			throw response
		}
	})