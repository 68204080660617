import { combineReducers } from 'redux'

import agenceReducer from './agence/agenceReducer'
import contratReducer from './contrat/contratReducer'
import coordonneesReducer from './coordonnees/coordonneesReducer'
import notificationsReducer from './notification/reducer'
import sinistreReducer from './sinistre/sinistreReducer'
import messagerieReducer from './messagerie/messagerieReducer'

//TODO => Faire un etat "donnée chargé ou non"
export default combineReducers({
	contrat: contratReducer,
	sinistre: sinistreReducer,
	infosClient: coordonneesReducer,
	notifications: notificationsReducer,
	agence: agenceReducer,
	messagerie: messagerieReducer
})