// import { unflatten } from 'flat'
import 'whatwg-fetch'
import { serializeFormValues } from './serialize'
import qs from 'qs'

const defaultUserApi = '/api'

/**
 * Convertit les messages d'erreur reçus du serveur au format Intl.
 *
 * Petite subtilité pour l'attribut `values` qui doit être un Object.
 *
 * @param error
 */
// const convertError = error => ({
// 	id: error.code || '__ERROR__',
// 	ids: error.codes,
// 	defaultMessage: error.defaultMessage,
// 	values: Object.assign({}, error.arguments, {
// 		rejectedValue: error.rejectedValue
// 	})
// })

// const checkErrors = ([response, json]) => {
// 	if (response.status === 400) {
// 		// conversion des erreurs
// 		// TODO améliorer
// 		if (json.errors) {
// 			const formattedErrors = json.errors.reduce(({
// 				                                            globalErrors,
// 				                                            fieldErrors
// 			                                            }, nextError) => {
// 				if (nextError.field) {
// 					// fieldError
// 					return {
// 						globalErrors,
// 						fieldErrors: {
// 							...fieldErrors,
// 							[nextError.field]: convertError(nextError)
// 						}
// 					}
// 				}
// 				// globalError
// 				return {
// 					globalErrors: [...globalErrors, convertError(nextError)],
// 					fieldErrors
// 				}
// 			}, {
// 				globalErrors: [],
// 				fieldErrors: {}
// 			})
// 			return Promise.reject({
// 				_error: formattedErrors.globalErrors,
// 				...unflatten(formattedErrors.fieldErrors),
// 				response,
// 				bodyError: json
// 			})
// 		}
// 		// erreurs globales
// 		const globalErrors = (json.globalErrors || [])
// 			.map(convertError)
// 			.reduce((previousValue, currentValue) => [...previousValue, currentValue], [])
// 		// erreurs sur les champs du formulaire
//
// 		const fieldErrors = (json.fieldErrors || [])
// 			.map(error => ({
// 				[error.field.replace(/\[(\d)]/g, '.$1')]: convertError(error)
// 			}))
// 			.reduce((previousValue, currentValue) => ({
// 				...previousValue,
// 				...currentValue
// 			}), {})
// 		return Promise.reject({
// 			_error: globalErrors,
// 			...unflatten(fieldErrors),
// 			response,
// 			bodyError: json
// 		})
// 	}
// 	return json
// }

const qsOptions = {
	skipNulls: true,
	arrayFormat: 'repeat'
}

/**
 * Construit une querystring à partir des paramètres.
 *
 * @param params les paramètres
 * @return la querystring
 */
// export const buildParams = params => (
// 	qs.stringify(params, qsOptions)
// )

/**
 * Retourne l'URL avec les paramètres en GET.
 *
 * @param url       l'URL, pouvant déjà contenir des paramètres
 * @param params    les paramètres à ajouter
 * @return l'URL contenant les paramètres, fusionnés avec ses propres paramètres
 */
export const buildUrlWithParams = (url, params) => {
	const indexOfQueryString = url.indexOf('?')
	if (indexOfQueryString >= 0) {
		// s'il y a des paramètres dans l'URL d'origine, on les sort et on recommence
		const initialQs = qs.parse(url.substr(indexOfQueryString + 1), qsOptions)
		return buildUrlWithParams(url.substr(0, indexOfQueryString), {
			...initialQs,
			...params
		})
	}
	const querystring = qs.stringify(params, qsOptions)
	if (querystring) {
		return `${url}?${querystring}`
	}
	return url
}


// const handleResponse = (response) => {
// 	// vérification du token
// 	// if (response.status === 401) {
// 	// 	// si le token n'est pas valide...
// 	// 	return response.text().then(result => {
// 	// 		if (typeof logOut === 'function') {
// 	// 			// on déconnecte l'utilisateur
// 	// 			_store.dispatch(logOut())
// 	// 		}
// 	// 		// et on rejette l'appel
// 	// 		return Promise.reject(new Error(result))
// 	// 	})
// 	// }
// 	if (response.status === 204) {
// 		// no content
// 		return Promise.resolve()
// 	}
// 	if (!response.ok && response.status !== 400) {
// 		// si la réponse est en erreur sauf erreur de validation, on rejette l'appel
// 		return Promise.reject(response)
// 	}
// 	// dans tous les autres cas, la réponse est traitée en JSON
// 	return Promise.all([
// 		response,
// 		response.json()
// 	])
// 		.then(checkErrors)
// }

export const fetchFactory = (path, config = {}, apiUrl = defaultUserApi) => {
	const {
		headers,
		...others
	} = config
	const defaultHeaders = {
		Accept: 'application/json',
		'Content-Type': 'application/json;charset=utf-8',
		Authorization: window.localStorage.tokenFO
	}
	const newConfig = {
		headers: {
			...defaultHeaders,
			...headers
		},
		...others,
		// envoi des cookies pour authentification
		credentials: 'include'
	}
	return fetch(`${process.env.REACT_APP_SERVER_URL}${apiUrl}${path}`, newConfig)
		// .then(handleResponse)
}

export const postMultipart = (path, config = {}, apiUrl = defaultUserApi) => {
	const {
		body,
		headers,
		...others
	} = config
	const newConfig = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			...headers,
			Authorization: window.localStorage.tokenFO
		},
		body: body instanceof FormData ? body : serializeFormValues(body),
		...others,
		// envoi des cookies pour authentification
		credentials: 'include'
	}
	return fetch(`${process.env.REACT_APP_SERVER_URL}${apiUrl}${path}`, newConfig)
		// .then(handleResponse)
}

