import {fetchFactory, postMultipart} from "../../../entities/fetchEntities";
import {serializeParts} from "../../../utils";

// export const ajoutSinistre = (data) =>{
// 	return 	fetchFactory('/extranet/declarationSinistre',{
// 		method:'POST',
// 		body: JSON.stringify(data)
// 	})
// }

export const ajoutSinistre = (data) => {
	const dataWithoutFile = {
		...data,
		file: undefined
	}
	const parts = [
		{ key: 'file', value: data.file },
		{ key: 'form', value: dataWithoutFile } ]
	return 	postMultipart('/extranet/declarationSinistre', {
		method: 'POST',
		body: serializeParts(parts)
	})
}

export const getFileSinistre = (id) =>
	fetchFactory('/extranet/file?id='+ id + '&numero=0&typeFile=1', {method:'GET'})
		.then((response) => {
			if(response.ok) {
				return response
			} else throw response
		})

