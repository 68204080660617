import React from "react";

export default class InProgress extends React.Component {
    render(){
        return(
            <div className="background-error">
                <div className="bg-image-primary bannerSize theme-color"> </div>
                <div className={"accroche"}>
                    <h1> <span className={"icon icon-nature_plant_02"}> </span> Page en jachère</h1>
                    <h6>Cette page arrivera bientôt, soyez patient !</h6>
                </div>
            </div>
        )
    }
}