import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import MiniPaper from '../../global/component/miniPaper/MiniPaper'
import * as assolementSelectors from '../contrat/assolement/assolementSelectors'

class MeteoDashboard extends React.Component {
	render() {
		const { communeRisque } = this.props
		return (
			<MiniPaper titre={'Vigilance météo'}
			           type={'primary'}
			           className={'vigilance-meteo'}
			           icone={'icon icon-weather'}>
				<div>
					<div className="titre-commune w-100">Commune : <span>{communeRisque.communeRisque}({communeRisque.codePostalRisque})</span></div>
					<div className="content flex-column text-left" style={{height:'210px'}}>
						{communeRisque.communeRisque && communeRisque.codePostalRisque &&
						<iframe title={"Meteo"} src={`/proxy/meteo/widget/prevision/${communeRisque.codePostalRisque.replace(/\s/g, '').substr(0,5)}0`}  width='425'  frameBorder='0'>
						    <p>Votre navigateur est incompatible avec les iframes.</p>
						</iframe>}
					</div>
				</div>
			</MiniPaper>
		)
	}
}

MeteoDashboard.propTypes = {
	communeRisque: PropTypes.object
}
const mapStateToProps = (state) => {
	return {
		communeRisque: assolementSelectors.getCommuneRisque(state)
	}
}

export default connect(mapStateToProps)(MeteoDashboard)
