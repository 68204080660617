import Paper from "../../../global/component/paper/Paper";
import { Col, Row} from "reactstrap";
import React from "react";
import LoaderGeneral from "../../../global/component/loader/LoaderGeneral";

const Loader = ({texteChargement}) => {
    return (
        <Col md="12" lg="12">
            <Row>
                <Col lg="12" className="mb-3">
                    <Paper className="push-tpl-1">
                        <Row className="d-flex justify-content-center">
                            <Col md={6} sm={6} xs={6} className="texteLoader text-center m-3">
                                {texteChargement}
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Col md={1} sm={1} xs={1} className="d-flex justify-content-center align-items-center mb-3">
                                <LoaderGeneral/>
                            </Col>
                        </Row>
                    </Paper>
                </Col>
            </Row>
        </Col>
    )
}

export default Loader