import { logOut, refreshToken } from '../accueil/loginActions'
import { replace } from 'connected-react-router'
import { getThemeFromUser,replaceTitle,replaceFavicon } from '../utils'


export const refreshAction = (token) => dispatch =>
	dispatch(refreshToken(token))

export const gotoLogin = (location, theme) => dispatch => {
	document.querySelector("link[rel='shortcut icon']").href = `/img/${theme}.ico`;
	replaceTitle(theme)
	replaceFavicon(theme)
	dispatch(replace({
		//TODO => gérer le  thème ici
		pathname: `/${theme}/accueil`,
		state: {
			nextPathname: location.pathname
		}
	}))
}

export const logoutAction = () => dispatch => {
	dispatch(logOut())
}

export const redirectToDashboard = (user, theme) => dispatch => {
	if(user.societe){
		if (theme === getThemeFromUser(user)) {
			replaceTitle(getThemeFromUser(user))
			replaceFavicon(getThemeFromUser(user));
			dispatch(replace({
				pathname: `/${getThemeFromUser(user)}/client`
			}))
		} else {
			replaceTitle(getThemeFromUser(user))
			replaceFavicon(getThemeFromUser(user))
			window.location = `/${getThemeFromUser(user)}/client`
		}
	}
}
