import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { enumThematiquesFaq } from '../../constantes/FaqThematiques'
import * as contratSelectors from '../contrat/contratSelectors'
import * as coordonneesSelectors from '../coordonnees/coordonneesSelectors'
import SinistresTraites from './autresSinistres/AutresSinistres'
import Loader from './consultationSinistre/Loader'
import * as selectors from './sinistreSelectors'
import SinistresEnCours from './sinistresEnCours/SinistresEnCours'
import SinistresHeader from './SinistresHeader'

const SinistresPage = ({ contratActif, getDonneesContratByContrat, isLoading, donneesSinistre, isUserAllowed, location, setThematique, setThematiqueFAQ }) => {
	const [childThematique] = React.useState(enumThematiquesFaq.SINISTRE.code)

	useEffect(() => {
		setThematique(childThematique)
		setThematiqueFAQ(childThematique)
	}, [childThematique])

	let sinistreEnCours = false, sinistreTraites = false
	donneesSinistre.forEach((sinistre) => {
		if (sinistre.sinistre.etatSinistre !== 'S' && !((sinistre.sinistre.etatSinistre === 'D' || sinistre.sinistre.etatSinistre === 'I') && sinistre.statutQuittanceIndemnite === 'REG') && !sinistre.quittance.flQuittanceSignee) {
			sinistreEnCours = true
		}
		if (sinistre.sinistre.etatSinistre === 'S' || ((sinistre.sinistre.etatSinistre === 'D' || sinistre.sinistre.etatSinistre === 'I') && sinistre.statutQuittanceIndemnite === 'REG') || sinistre.quittance.flQuittanceSignee) {
			sinistreTraites = true
		}
	})
	return (
		<Col md="12" lg="9">
			<Row>
				<Col className="mb-3">
					<SinistresHeader contratActif={contratActif} getDonneesContratByContrat={getDonneesContratByContrat}
					                 isUserAllowed={isUserAllowed}
					                 baseLocation={window.location.pathname.replace('/mesSinistres', '')} />
				</Col>
			</Row>
			{isLoading &&
			<Row>
				<Loader
					texteChargement="Chargement des sinistres"
				/>
			</Row>
			}
			{sinistreEnCours &&
			<Row>
				<Col lg="12" className="mb-3">
					<SinistresEnCours baseLocation={window.location.pathname.replace('/mesSinistres', '')} />
				</Col>
			</Row>
			}
			{sinistreTraites &&
			<Row>
				<Col lg="12" className="mb-3">
					<SinistresTraites baseLocation={window.location.pathname.replace('/mesSinistres', '')} />
				</Col>
			</Row>
			}
		</Col>
	)

}

SinistresPage.propTypes = {
	isUserAllowed: PropTypes.bool,
	donneesSinistre: PropTypes.array,
	getDonneesContratByContrat: PropTypes.func,
	contratActif: PropTypes.string,
	isLoading: PropTypes.bool
}
const mapStateToProps = (state) => {
	return {
		isUserAllowed: coordonneesSelectors.isDeclarationSinistreAllowed(state),
		donneesSinistre: selectors.getDonneesSinistre(state),
		isLoading: selectors.getSinitreLoading(state),
		contratActif: contratSelectors.getContratActif(state),
		getDonneesContratByContrat: contratSelectors.getDonneesContratByContrat(state)
	}
}

export default connect(mapStateToProps)(SinistresPage)