import React, { Component } from 'react'
import { Button, Row, Col, Container } from 'reactstrap'
import ModaleCookies from './ModaleCookies'
import { isCookieNotExist, updateCookies } from './cookieUtils'


const cookieName = 'isGoogleAnalyticsAuthorized'
const cookiePath = '/'

class BandeauCookies extends Component {

	constructor(props) {
		super(props)
		this.state = {
			display: isCookieNotExist(cookieName) ? 'flex' : 'none',
			isOpened: false,
			selectedOption: null
		}
		this.handleOptionChange = this.handleOptionChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.toggle = this.toggle.bind(this)
	}

	handleOptionChange(changeEvent) {
		this.setState({
			selectedOption: changeEvent.target.value
		})

	}

	handleSubmit() {

		var today = new Date()
		today.setMonth(today.getMonth() + 13)
		if (this.state.selectedOption === 'true') {
			if (isCookieNotExist(cookieName)) {
				updateCookies(cookieName, true,
					{
						path: cookiePath,
						expires: new Date(today.getFullYear(), today.getMonth())
					})
			}

		} else if (this.state.selectedOption === 'false') {
			if (isCookieNotExist(cookieName)) {
				updateCookies(cookieName, false,
					{
						path: cookiePath,
						expires: new Date(today.getFullYear(), today.getMonth())
					})
			}
		}
		this.setState({ display: 'none' })
		this.toggle()
	};

	toggle() {
		this.setState(prevState => ({
			isOpened: !prevState.isOpened
		}))
	}

	render() {
		const { isOpened, selectedOption } = this.state
		return (
			<div id="bandeauCookie" style={{ display: this.state.display }}>
				<Container>
					<Row>
						<Col className="texte" lg={7} md={12}>
							<div>
								<div>Ce site utilise des cookies pour son bon fonctionnement et également afin de réaliser des statistiques de visites et recueillir des informations visant à améliorer votre expérience
									utilisateur. En cliquant sur le bouton Accepter, vous acceptez les cookies et l'utilisation de ces informations.
								</div>
							</div>
						</Col>
						<Col className="modale" lg={3} md={10} sm={6} xs={6}>
							<ModaleCookies isOpened={isOpened} selectedOption={selectedOption} handleSubmit={this.handleSubmit} handleOptionChange={this.handleOptionChange} toggle={this.toggle} />
						</Col>
						<Col className="bouton" lg={2} md={2} sm={6} xs={6}>
							<div>
								<Button
									onClick={() => {
										if (isCookieNotExist(cookieName)) {
											var today = new Date()
											today.setMonth(today.getMonth() + 13)
											updateCookies(cookieName, true,
												{
													path: cookiePath,
													expires: new Date(today.getFullYear(), today.getMonth())
												})
											this.setState({ display: 'none' })
										}
									}}
								>
									Accepter
								</Button>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

export default BandeauCookies
