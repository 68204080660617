import * as api from './declarationSinistreApi'
import * as sinistreActions from "../sinistreActions";
import {GET_FILE} from "../../messagerie/messagerieActions";
export const SET_SINISTRE_SUCCES = 'SET_SINISTRE_SUCCES'

export const submitSinistreAction = data => dispatch => {
	return api.ajoutSinistre(data)
		.then(response => {
			if (response.status === 200) {
				return new Promise((resolve) => {
					dispatch(sinistreActions.getSinistres()).then(() => {
						resolve(response)
					})
				})
			} else {
				return response
			}
		})
		.then(response => {
			return new Promise((resolve) => {
				dispatch(sinistreActions.getSinistres()).then(() => {
					resolve(response)
				})
			})
		})
}

export const setSinistre = (sinistre) => (dispatch) => {
	dispatch({
		type: SET_SINISTRE_SUCCES,
		sinistre
	})
};
export const getFile = (id) => dispatch => {
	dispatch({type: GET_FILE})
	return new Promise((resolve, reject) => {
		return api.getFileSinistre(id)
			.then(response => {
				if (response.status === 200) {
					resolve(response.blob())
				} else {
					reject(response)
				}
			})
	})
}
