import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ThemeContainer extends Component {

	constructor(props) {
		super(props)
		this.state = {}
	}

	//TODO: Fonction qui renvoie auto vers /larurale sur la racine
	render() {
		const { theme } = this.props
		return React.cloneElement(this.props.children, {
			theme
		})
	}

}

ThemeContainer.propTypes = {
	theme: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
}

ThemeContainer.defaultProps = {
	theme: 'larurale'
}

export default ThemeContainer
