import React from 'react'
import { connect } from 'react-redux'
import Col from 'reactstrap/es/Col'
import Row from 'reactstrap/es/Row'
import Paper from '../../../global/component/paper/Paper'
import * as contratSelectors from '../contratSelectors'
import EcheancierContratDetail from './EcheancierContratDetail'

const EcheancierContrat = ({ listeContrat }) => {
	return (
		<Paper className="push-tpl-1 echeancier push-head">
			<Row className={'mb-5'}>
				<Col className={'titre'}>
					Mon échéancier contrat
				</Col>
			</Row>
			{listeContrat ? listeContrat.map((contrat) => {
				return (
					<EcheancierContratDetail
						key={contrat.contrat}
						contrat={contrat}
					/>
				)
			}) : <div>Pour consulter votre échéancier contrat, vous devez avoir un contrat actif.</div>
			}
		</Paper>
	)
}

const mapStateToProps = (state) => {
	return {
		listeContrat: contratSelectors.getDonneesContratsEnCoursOuRemplaces(state)
	}
}

export default connect(mapStateToProps)(EcheancierContrat)