import { fetchFactory } from '../../entities/fetchEntities'
import qs from 'qs'

export const loadListeCommunes = (nomCommune) =>
	fetchFactory(`/extranet/communes/like?${qs.stringify({ nomCommune })}`, {
		method: 'GET'
	})
		.then(response => {
			if (response.status === 200) {
				return response.json()
			} else {
				return response
			}
		})

