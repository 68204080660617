import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, CustomInput, FormFeedback, FormGroup, FormText, Input, Label, Row } from 'reactstrap'
import { Field, reduxForm } from 'redux-form'
import Captcha from '../../global/component/captcha/Captcha'
import LoaderButton from '../../global/component/loader/LoaderButton'
import { isEmailValid, isLuhnValid, isPasswordValid } from '../../utils'

const validate = (values) => {
	const errors = {}
	const requiredFields = [
		'numeroContrat',
		'siret',
		'email',
		'validEmail',
		'motDePasse',
		'validMotDePasse',
		'captcha'

	]
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = 'Ce champ est obligatoire.'
		}
	})
	if (values.conditions !== 'true') {
		errors.conditions = 'Vous devez accepter les conditions d\'utilisations pour vous inscrire.'
	}
	if (!values.infos) {
		errors.infos = 'Vous devez obligatoirement sélectionner une option.'
	}
	if (values.email && !isEmailValid(values.email)) {
		errors.email = 'L\'adresse e-mail renseignée est incorrecte.'
	}
	if (values.motDePasse && !isPasswordValid(values.motDePasse)) {
		errors.motDePasse = 'Mot de passe incorrect : il doit contenir au moins 10 caractères, dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial.'
	}
	if (values.email !== values.validEmail) {
		errors.validEmail = 'L’adresse e-mail renseignée est incorrecte.'
	}
	if (values.motDePasse !== values.validMotDePasse) {
		errors.validMotDePasse = 'La confirmation du mot de passe est incorrecte.'
	}
	if (isNaN(parseInt(values.siret))) {
		errors.siret = 'Le siret doit être numérique'
	} else {
		if (values.siret.length < 14 || !isLuhnValid(values.siret)) {
			errors.siret = 'Ce SIRET n’est pas associé à un contrat assurance récoltes connu. Veuillez vérifier le code SIRET de votre exploitation, indiqué sur votre contrat.'
		}
	}
	return errors
}

const renderField = ({ maxLength, input, label, type, info, meta: { touched, error } }) => {
	//Pour palier au type number. Inutile pour le type text
	if (input.value.length > maxLength) {
		input.value = input.value.slice(0, maxLength)
	}
	return (
		<FormGroup>
			<Label style={{ fontSize: 14, fontFamily: 'Allianz SemiBold' }}>{label}<b className="c-red"> *</b></Label>
			<Input {...input} maxLength={maxLength} invalid={touched && !!error}
			       style={{ width: '100%' }} type={type}
			/>
			<FormFeedback valid={!(touched && !!error)}>
				<div style={{ display: 'flex' }}>
					<p style={{ fontSize: '12px' }}>{error}</p>
				</div>
			</FormFeedback>
			<FormText style={{ display: (!!error && touched) ? 'none' : '' }}>
				<div style={{ display: 'flex' }}>
					<span style={{ marginRight: '8px', marginTop: '2px', fontSize: '15px' }} className="icon-info-circle"> </span>
					<p style={{ fontSize: '12px', width: '85%' }}>{info}</p>
				</div>
			</FormText>
		</FormGroup>
	)
}

const renderSiretField = ({ maxLength, input, label, type, info, meta: { touched, error } }) => {
	//Pour palier au type number. Inutile pour le type text
	if (input.value.length > maxLength) {
		input.value = input.value.slice(0, maxLength)
	}
	if (isNaN(parseInt(input.value.slice(input.value.length - 1, input.value.length)))) {
		input.value = input.value.slice(0, input.value.length - 1)
	}
	return (
		<FormGroup>
			<Label style={{ fontWeight: 'bold' }}>{label}<b className="c-red"> *</b></Label>
			<Input {...input} maxLength={maxLength} invalid={touched && !!error}
			       style={{ width: '100%' }} type={type}
			/>
			<FormFeedback valid={!(touched && error)}>
				<div style={{ display: 'flex' }}>
					<p style={{ fontSize: '12px' }}>{error}</p>
				</div>
			</FormFeedback>
			<FormText style={{ display: (error && touched) ? 'none' : '' }}>
				<div style={{ display: 'flex' }}>
                        <span
	                        style={{ marginRight: '8px', marginTop: '2px', fontSize: '15px' }}
	                        className="icon-info-circle"
                        > </span><p style={{ fontSize: '12px', width: '85%' }}>{info}</p>
				</div>
			</FormText>
		</FormGroup>
	)
}

const renderRadioField = ({ input, info, valueYes, valueNo, idYes, idNo, meta: { touched, error } }) => {
	return (
		<FormGroup>
			<CustomInput {...input} type={'radio'} id={idYes} value={valueYes} label="Oui" inline />
			<CustomInput {...input} type={'radio'} id={idNo} value={valueNo} label="Non" inline />
			<FormFeedback valid={!error} style={{ display: (error && touched) ? 'block' : 'none' }}>
				<div style={{ display: 'flex' }}>
                    <span
	                    style={{ marginRight: '8px', marginTop: '2px', fontSize: '15px' }}
                    > </span><p style={{ fontSize: '12px' }}>{error}</p>
				</div>
			</FormFeedback>
			<FormText style={{ display: (error && touched) ? 'none' : '' }}>
				<div style={{ display: 'flex' }}>
					<p style={{ fontSize: '12px', width: '85%' }}>{info}</p>
				</div>
			</FormText>
		</FormGroup>
	)
}


const InscriptionForm = ({ handleSubmit, pristine, submitting, showHide, type, isLoading, theme, openModalCGU }) => {

	return (
		<form onSubmit={handleSubmit}>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<Row>
					<Col lg={6} md={12} xs={12}>
						<Field
							name="numeroContrat"
							component={renderField}
							maxLength={10}
							type="text"
							label={'Numéro de contrat'}
							//TODO => A rendre administrable, I.E. à aller chercher en BDD au lancement de l'appli
							info={'Indiquez ici le N°de votre contrat en cours. Vous le trouverez par exemple sur votre dernier décompte de cotisations.'}
						/>
					</Col>
					<Col lg={6} md={12} xs={12}>
						<Field
							name="siret"
							maxLength={14}
							component={renderSiretField}
							type="text"
							label={'SIRET'}
							info={'Indiquez le N°SIRET de votre exploitation.'}
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6} md={12} xs={12}>
						<Field
							name="email"
							component={renderField}
							type="text"
							label={'Adresse e-mail'}
							info={'Votre adresse e-mail sera utilisée pour vous transmettre des notifications par mail. Vous pourrez également l\'utiliser en tant qu\'identifiant de connexion.'}
						/>
					</Col>
					<Col lg={6} md={12} xs={12}>
						<Field
							name="validEmail"
							component={renderField}
							type="text"
							label={'Confirmation e-mail'}
							info={'Confirmez votre adresse e-mail (pour éviter les erreurs de saisie).'}
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6} md={12} xs={12}>
						<Field
							name="motDePasse"
							component={renderField}
							type={type}
							label={'Mot de passe'}
							info={'Votre mot de passe doit contenir au moins 10 caractères, dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial.'}
						/>
						<span
							style={{ cursor: 'pointer' }} className="icon-show-password-plain" id="TooltipPassword"
							onClick={showHide}
						> </span>
					</Col>
					<Col lg={6} md={12} xs={12}>
						<Field
							name="validMotDePasse"
							component={renderField}
							type="password"
							label={'Confirmation mot de passe'}
							info={'Confirmez votre mot de passe (pour éviter les erreurs de saisie).'}
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6} md={12} xs={12}>
						<label style={{ fontSize: '14px', cursor: 'pointer' }}>
							Je certifie avoir pris connaissance des
							<b className="c-dark" onClick={() => {openModalCGU()}}> Conditions Générales d'Utilisation</b> et je les accepte.
						</label>
						<Field
							name="conditions"
							titre="conditions"
							component={renderRadioField}
							valueYes={'true'}
							valueNo={'false'}
							idYes="conditionsOui"
							idNo="conditionsNon"
							info={''}

						/>
					</Col>
					<Col lg={6} md={12} xs={12}>
						<label style={{ fontSize: '14px' }}>J'accepte de recevoir des informations commerciales de mon agence. En aucun cas mon
							adresse e-mail ne sera cédée à des tiers.</label>
						<div style={{ display: 'flex' }}>
							<Field
								name="infos"
								titre="infos"
								component={renderRadioField}
								valueYes={'true'}
								valueNo={'false'}
								idYes="infosOui"
								idNo="infosNon"
								info={'Votre accord vaut pour les offres commerciales du Groupe Allianz en France et ses partenaires pour les services, les produits d\'assurance, bancaires et financiers qu\'ils distribuent. Le ciblage des offres commerciales peut être automatisé et basé sur des profils de clients ou de prospects.'}

							/>
						</div>
					</Col>
					{/*<Col lg={12} md={12} xs={12}>*/}
					{/*	<Field*/}
					{/*		name="captcha"*/}
					{/*		component={Captcha}*/}
					{/*	/>*/}
					{/*</Col>*/}
				</Row>
			</div>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div style={{ width: '35%', margin: '0 2%' }}>
					<Link to={`/${theme}`}>
						<Button className="border-2" style={{ width: '100%' }} outline color={'light'}
						        type={'button'}> Annuler </Button>
					</Link>
				</div>
				<Button style={{ width: '35%', margin: '0 2%' }} className={'d-flex align-items-center justify-content-center'}
				        disabled={pristine || submitting} color={'light'}
				        type={'submit'}>{isLoading ? <LoaderButton /> : 'Confirmer'}</Button>
			</div>
		</form>
	)
}


InscriptionForm.propTypes = {
	handleSubmit: PropTypes.func,
	pristine: PropTypes.bool,
	submitting: PropTypes.bool,
	submitFailed: PropTypes.bool,
	theme: PropTypes.string
}

export default (reduxForm({
	form: 'inscriptionForm', // a unique identifier for this form
	validate,
	touchOnBlur: false
})(InscriptionForm))