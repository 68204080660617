import PropTypes from 'prop-types'
import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Button from 'reactstrap/es/Button'
import Col from 'reactstrap/es/Col'
import Row from 'reactstrap/es/Row'

class ModaleMessages extends React.Component {

	render() {
		let color, classe
		const { isOpened, toggle, className, type, titre, text, textButton } = this.props
		switch (type) {
			case 'Alerte' :
				classe = 'icon-info-circle'
				color = '#EFBE25'
				break
			case 'Erreur' :
				classe = 'icon-close-circle'
				color = '#DC3149'
				break
			case 'ErreurAvecBouton' :
				classe = 'icon-close-circle'
				color = '#DC3149'
				break
			case 'Succes' :
				classe = 'icon-check-circle'
				color = '#1E8927'
				break
			case 'SuccesAvecBouton' :
				classe = 'icon-check-circle'
				color = '#1E8927'
				break
			case 'Information' :
				classe = 'icon-info-circle'
				color = '#496EBD'
				break
			default:
				classe = 'icon-info-circle'
				color = '#496EBD'

		}
		return (
			<Modal centered={true} isOpen={isOpened} toggle={(type === 'SuccesAvecBouton' || type === 'ErreurEtBouton') ? null : toggle}
			       className={className + ' small'}>
				<ModalHeader toggle={toggle}>{titre}</ModalHeader>
				<ModalBody>
					<Row>
						<Col lg={1} md={1} sm={1} xs={1}><span style={{ fontSize: '32px', color: color }}
						                                       className={`d-flex align-items-center h-100 ${classe}`}> </span></Col>
						<Col lg={11} md={11} sm={11} xs={11}>
							<div style={{ fontSize: '15px' }} className="d-flex align-items-center h-100">
								<div dangerouslySetInnerHTML={{ __html: text }} />
							</div>
						</Col>
					</Row>
				</ModalBody>
				{(type === 'SuccesAvecBouton' || type === 'ErreurEtBouton') &&
				<ModalFooter className={'d-flex justify-content-center margin-footer-modale'}>
					<Button outline color="light" className="w-25" onClick={toggle} type="submit">{textButton}</Button>
				</ModalFooter>
				}
			</Modal>
		)
	}
}

ModaleMessages.propTypes = {
	type: PropTypes.string.isRequired,
	titre: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	textButton: PropTypes.string,
	isOpened: PropTypes.bool,
	toggle: PropTypes.func
}

export default ModaleMessages