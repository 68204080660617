import {fetchFactory} from "../../entities/fetchEntities";

export const getSinistres = () =>
    fetchFactory('/extranet/sinistre',{method:'GET'})
        .then((response) => {
            if(response.ok) {
                return response
            } else throw response
        })

export const getSinistreObservation = (idSinistre) =>
    fetchFactory('/extranet/sinistre/observation',{method:'POST', body: idSinistre})
        .then((response) => {
            if(response.ok) {
                return response
            } else throw response
        })

export const getListeCultures = () =>
    fetchFactory('/extranet/listeCultures',{method: 'GET'})
        .then((response) => {
            if(response.ok) {
                return response
            } else throw response
        })

export const getQuittancesIndemnites = () =>
    fetchFactory('/extranet/quittancementIndemnite',{method:'GET'})
        .then((response) => {
            if(response.ok) {
                return response
            } else throw response
        })