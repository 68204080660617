import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


class ModaleSecurite extends React.Component {

	render() {
		const {isOpened,toggle,className, themeRurale} = this.props;
		return (
			<div>
				<div className='lien' onClick={toggle}>
					Sécurité
				</div>
				{themeRurale ?
				<Modal isOpen={isOpened} toggle={toggle} className={className + " large"}>
					<ModalHeader >Sécurité</ModalHeader>
					<ModalBody>
						<section>
							<div>
								<p>La Rurale (Société du Groupe Allianz) accorde une grande attention au niveau de sécurité de son site Internet et vous recommande
									de suivre quelques précautions pour que la sécurité soit l'affaire de tous.</p>
								<div>
									<h5>1. Avant de vous connecter à Internet, il convient de vous
										doter :</h5>
									<ul>
										<li>d'un antivirus : Logiciel qui s'assure en permanence que les fichiers de
											votre ordinateur ne contiennent pas de virus
										</li>
										<li>d'un antispyware : Logiciel qui vérifie si d'autres logiciels n'espionnent
											pas votre activité ou ne transmettent pas certaines de vos données
											confidentielles
										</li>
										<li>d'un pare-feu ou firewall : Logiciel qui contrôle toutes les informations
											entrantes et sortantes de votre ordinateur
										</li>
									</ul>
									<p>
										Si vous n'êtes pas détenteur de ces outils, nous vous recommandons de vous les
										procurer et d'en assurer la mise à jour régulière.
									</p>
								</div>
								<div>
									<h5>2. Si vous disposez d'une connexion Wi-Fi, il est nécessaire
										de la sécuriser :</h5>

									<ul>
										<li>par l'utilisation d'une clé WEP : niveau de sécurité minimum</li>
										<li>par l'utilisation d'une clé WPA : niveau de sécurité renforcé</li>
										<li>Vos fournisseurs d'accès Internet sont à même de vous renseigner quant au
											mode de fonctionnement de ces mécanismes sécuritaires
										</li>
									</ul>
								</div>
								<div>
									<h5>3. Surfer sur Internet implique de vérifier à quel site vous
										avez à faire :</h5>

									<ul>
										<li>Lors de votre connexion à un espace sécurisé, vérifier l'orthographe de
											l'adresse du site et le début de l'adresse URL : pour notre
											site, elle doit commencer par https://www.larurale.fr ...
										</li>
										<li>Un cadenas doit apparaître et vous pouvez double cliquer dessus. Ainsi, vous
											ferez s'afficher une fenêtre et apparaitra sur
											notre site le certificat délivré pour La Rurale (Société du Groupe Allianz)
										</li>
									</ul>
								</div>
								<div>
									<h5>4. La sécurité de vos données suppose de changer :</h5>

									<p>
										La sécurité de vos données suppose de changer vos mots de passe régulièrement et
										de les protéger. Le mot de passe pour accéder à
										l'Espace Client de notre site doit comporter à minima une combinaison
										alphanumérique.
									</p>
								</div>
								<div>
									<h5>5. Une fois vos opérations ou consultations effectuées :</h5>

									<p>
										N'oubliez pas de vous déconnecter de votre espace client par le bouton "Se
										déconnecter".
									</p>
								</div>
								<div>
									<h5>6. Ne répondez jamais à une promesse dont l'offre semble trop
										belle pour être vraie :</h5>

									<p>
										surtout s'il vous est demandé de payer à l'avance certains frais pour débloquer
										de l'argent. Gardez à l'esprit que
										si on vous demande de prendre contact par téléphone avec une personne dont le
										numéro est un numéro de mobile, il est
										impossible de vérifier le nom de la société, l'adresse dans un annuaire
										professionnel ou à la Chambre de Commerce.
									</p>
								</div>
								<div>
									<h5>En résumé</h5>

									<ul>
										<li>Veillez à disposer d'un antivirus, d'un antispyware et d'un pare-feu avant
											de « surfer » sur Internet
										</li>
										<li>Assurez-vous de la sécurité de votre accès Wi-Fi</li>
										<li>Vérifiez à quel site vous avez à faire</li>
										<li>Ne communiquez jamais vos codes d'accès, protégez-les et changez-les
											régulièrement
										</li>
										<li>Déconnectez-vous après chaque session</li>
										<li>Restez attentif aux emails reçus</li>
									</ul>
								</div>
							</div>
						</section>
					</ModalBody>
					<ModalFooter>
						<Button onClick={toggle} color="light" size="lg" className="btn-block w-50 m-auto">C'est compris</Button>
					</ModalFooter>
				</Modal>
					:
					<Modal isOpen={isOpened} toggle={toggle} className={className + " large"}>
						<ModalHeader >Sécurité</ModalHeader>
						<ModalBody>
							<section>
								<div>
									<h5>Alerte sécurité – e-mails frauduleux</h5>
									<p>
										Nous avons identifié des e-mails factices provenant de « Service Allianz » informant les destinataires d’un versement effectué sur leur(s) contrat(s) d’assurance vie et les incitant ensuite à cliquer sur un lien « Mon compte Allianz » pour visualiser les détails du versement.
										Si vous êtes confronté(e) à cette situation, nous vous recommandons de ne pas répondre à la sollicitation, de ne transmettre aucune information personnelle, de n’effectuer aucune opération financière liée à cette sollicitation. Nous vous remercions d’en informer immédiatement Allianz France à l’adresse suivante : ANTI-FRAUDE@allianz.fr.
									</p>
								</div>
							</section>
							<section>
								<div>
									<p>Allianz accorde une grande attention au niveau de sécurité de son site Internet et vous recommande de suivre quelques précautions pour que la sécurité soit l'affaire de tous.</p>
									<div>
										<h5>1. Avant de vous connecter à Internet, il convient de vous
											doter :</h5>
										<ul>
											<li>d'un antivirus : Logiciel qui s'assure en permanence que les fichiers de
												votre ordinateur ne contiennent pas de virus
											</li>
											<li>d'un antispyware : Logiciel qui vérifie si d'autres logiciels n'espionnent
												pas votre activité ou ne transmettent pas certaines de vos données
												confidentielles
											</li>
											<li>d'un pare-feu ou firewall : Logiciel qui contrôle toutes les informations
												entrantes et sortantes de votre ordinateur
											</li>
										</ul>
										<p>
											Si vous n'êtes pas détenteur de ces outils, nous vous recommandons de vous les
											procurer et d'en assurer la mise à jour régulière.
										</p>
									</div>
									<div>
										<h5>2. Si vous disposez d'une connexion Wi-Fi, il est nécessaire
											de la sécuriser :</h5>

										<ul>
											<li>par l'utilisation d'une clé WEP : niveau de sécurité minimum</li>
											<li>par l'utilisation d'une clé WPA : niveau de sécurité renforcé</li>
											<li>Vos fournisseurs d'accès Internet sont à même de vous renseigner quant au
												mode de fonctionnement de ces mécanismes sécuritaires
											</li>
										</ul>
									</div>
									<div>
										<h5>3. Surfer sur Internet implique de vérifier à quel site vous
											avez à faire :</h5>

										<ul>
											<li>Lors de votre connexion à un espace sécurisé, vérifier l'orthographe de
												l'adresse du site et le début de l'adresse URL : pour notre
												site, elle doit commencer par https://www.allianz.fr ...
											</li>
											<li>Un cadenas doit apparaître et vous pouvez double cliquer dessus. Ainsi, vous
												ferez s'afficher une fenêtre et apparaitra sur
												notre site le certificat délivré pour La Rurale (Société du Groupe Allianz)
											</li>
										</ul>
									</div>
									<div>
										<h5>4. La sécurité de vos données suppose de changer :</h5>

										<p>
											La sécurité de vos données suppose de changer vos mots de passe régulièrement et de les protéger. Le mot de passe pour accéder à l'Espace Client de notre site doit comporter à minima une combinaison alphanumérique.
										</p>
									</div>
									<div>
										<h5>5. Une fois vos opérations ou consultations effectuées :</h5>

										<p>
											N'oubliez pas de vous déconnecter de votre espace client par le bouton "Se
											déconnecter".
										</p>
									</div>
									<div>
										<h5>6. Ne répondez jamais à une promesse dont l'offre semble trop
											belle pour être vraie :</h5>

										<p>
											surtout s'il vous est demandé de payer à l'avance certains frais pour débloquer de l'argent. Gardez à l'esprit que si on vous demande de prendre contact par téléphone avec une personne dont le numéro est un numéro de mobile, il est impossible de vérifier le nom de la société, l'adresse dans un annuaire professionnel ou à la Chambre de Commerce.
										</p>
									</div>
									<div>
										<h5>En résumé</h5>

										<ul>
											<li>Veillez à disposer d'un antivirus, d'un antispyware et d'un pare-feu avant de « surfer » sur Internet ;
												Assurez-vous de la sécurité de votre accès Wi-Fi
											</li>
											<li>Vérifiez à quel site vous avez à faire</li>
											<li>Vérifiez à quel site vous avez à faire</li>
											<li>Ne communiquez jamais vos codes d'accès, protégez-les et changez-les régulièrement
											</li>
											<li>Déconnectez-vous après chaque session</li>
											<li>Restez attentif aux emails reçus</li>
										</ul>
									</div>
								</div>
							</section>
						</ModalBody>
						<ModalFooter>
							<Button onClick={toggle} color="light" size="lg" className="btn-block w-50 m-auto">C'est compris</Button>
						</ModalFooter>
					</Modal>}
			</div>
		);
	}
}

ModaleSecurite.propTypes = {
	isOpened: PropTypes.bool,
	toggle: PropTypes.func.isRequired

};

export default ModaleSecurite;