import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Connexion from './connexion/Connexion'
import InscriptionPage from './inscription/InscriptionPage'
import ReinitialisationPage from './reinitialisation/ReinitialisationPage'

export default (previousPath, theme) =>{
	return <Switch>
		{/*<Route path={`${previousPath}/inscription`} component={InscriptionPage} theme={theme}/>*/}
		{/*<Route path={`${previousPath}`} component={Connexion} theme={theme}/>*/}
		<Route path={`${previousPath}/inscription`} render={(otherProsp) => <InscriptionPage {...otherProsp} theme={theme}/>} />
		<Route path={`${previousPath}/reinitialisation`} render={(otherProsp) => <ReinitialisationPage {...otherProsp} theme={theme}/>} />
		<Route path={`${previousPath}`} render={(otherProsp) => <Connexion {...otherProsp} theme={theme}/>} />
	</Switch>
}

