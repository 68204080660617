import React from 'react'
import {Modal} from "reactstrap";
import ModificationCoordonnesForm from './ModificationCoordonnnesForm'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as modificationAction from "./modificationActions";
import * as messageActions from "../../../global/component/messages/messageActions";
import * as selectors from '../coordonneesSelectors'

class ModificationCoordonnees extends React.Component {
    constructor(props){
        super(props)
        this.submitModificationCoordonnees = this.submitModificationCoordonnees.bind(this)
    }
    submitModificationCoordonnees(data) {
        return this.props.modificationCoordonnees(data)
            .then(() => {
                this.props.messagesSuccesBlocked("Mise à jour de vos coordonnées","Votre demande de modification a bien été enregistrée. Vos coordonnées ont été mises à jour. Si votre SIRET a également été modifié merci de le communiquer à votre agence.","Fermer")
                this.props.toggle()
            })
            .catch(() => {
                this.props.messagesErreurBlocked("Mise à jour de vos coordonnées","Votre demande de modification n’a pas pu être enregistrée car une erreur technique est survenue. Veuillez réessayer ultérieurement.","Fermer")
                this.props.toggle()
            })
    }
    render() {
        const { isOpened, toggle,className,initialValues} = this.props;
        return(
            <Modal isOpen={isOpened} toggle={toggle} className={className + " small"}>
                    <ModificationCoordonnesForm toggle={toggle} onSubmit={this.submitModificationCoordonnees} initialValues={initialValues} />
            </Modal>
        )
    }
}

ModificationCoordonnees.propTypes = {
    isOpened: PropTypes.bool,
	initialValues: PropTypes.object,
    toggle: PropTypes.func.isRequired,
    modificationCoordonnees:PropTypes.func,
    messagesSuccesBlocked:PropTypes.func,
    messagesErreurBlocked: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		initialValues: selectors.getDonneesPerso(state)
	}
}

const actions = {
    modificationCoordonnees: modificationAction.modificationCoordonnees,
    messagesSuccesBlocked:messageActions.messagesSuccesBlocked,
    messagesErreurBlocked:messageActions.messagesErreurBlocked
}

export default connect(mapStateToProps,actions)(ModificationCoordonnees)