import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Error404 from '../../global/error404/Error404'
import MesCoordonnesPage from './MesCoordonneesPage'

export default ({ pathname, pathToHome }) => (
	<Switch>
		<Route path={`${pathname}/mesCoordonnees`} component={MesCoordonnesPage} />
		<Route path={`${pathToHome}/404`} component={Error404} />
		<Redirect from={`${pathname}/*`} to={`${pathToHome}/404`} />
	</Switch>
)
