import React from "react";
import {Button, Col, Row, Tooltip} from "reactstrap";
import { formatDateSinistre, formatDate, supprimeZerosContratString } from '../../../utils'


class LigneAutreSinistre extends React.Component{
    render() {
        const {sinistre,isOpen,toggle,target, contratListe,donneesContrat, goToSinistre} = this.props
        let isCloture = false
        if(sinistre.quittance.length > 0){
            isCloture = sinistre.quittance[0].flQuittanceSignee
        }
        if(sinistre.sinistre.etatSinistre === "S" || ((sinistre.sinistre.etatSinistre === "D" || sinistre.sinistre.etatSinistre === "I") && sinistre.statutQuittanceIndemnite === "REG") || sinistre.quittance.flQuittanceSignee) {
            return (
                <Button onClick={() => {goToSinistre(sinistre.declaration)}}>
                    <Row className="table-item liste-sinistre-traite">
                        <Col xs="1" className="text-left">
                            {sinistre.sinistre.etatSinistre === "S" && !isCloture ?
                                <div>
                                    <span className="icon icon-cross-triangle" id={target}> </span>
                                    <Tooltip placement="right" isOpen={isOpen} target={target} toggle={toggle}>
                                        Dossier sans suite (désistement)
                                    </Tooltip>
                                </div>
                                : null}
                            {sinistre.sinistre.etatSinistre === "D"  && !isCloture ?
                                <div>
                                    <span className="icon icon-survey" id={target}> </span>
                                    <Tooltip placement="right" isOpen={isOpen} target={target} toggle={toggle}>
                                        Dossier traité (ou en cours de paiement)
                                    </Tooltip>
                                </div>: null}
                            {isCloture  ?
                                <div>
                                    <span className="icon icon-check-circle-o" id={target}> </span>
                                    <Tooltip placement="right" isOpen={isOpen} target={target} toggle={toggle}>
                                        Dossier cloturé
                                    </Tooltip>
                                </div>: null}
                        </Col>
                        <Col xs="10" className="d-flex flex-column align-items-start">
                            <div style={{marginBottom:8,lineHeight:'20px'}}
                                className="ft-s-allianz-semi-bold ft-s-16">
                                Sinistre N° {sinistre.sinistre.numeroSinistre} du {formatDateSinistre(sinistre.sinistre.dateSinistre)} - {sinistre.libellePeril}
                            </div>
	                        {contratListe.length > 1 ?
                                <div className="ft-s-14" style={{ color: '#5F6368', lineHeight: '16px' }}>Déclaré le {formatDate(sinistre.sinistre.dateEnregistrement)} (sur contrat N° {supprimeZerosContratString(sinistre.declaration.contrat)} :
			                        {donneesContrat.packCode === "A1" ? " Assurance des récoltes - Grêle, Tempête) " : " Garantie multirisques des récoltes)"}
		                        </div> :
                                <div className="ft-s-14" style={{ color: '#5F6368', lineHeight: '16px' }}>Déclaré le {formatDate(sinistre.sinistre.dateEnregistrement)}</div>

	                        }
                        </Col>
                        <Col xs="1" className="text-right p-0">
                            <span className="icon icon-chevron-right"> </span>
                        </Col>
                    </Row>
                </Button>
            )
        } else return null
    }
}

export default LigneAutreSinistre