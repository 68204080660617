import React from 'react'
import { Col, Input, Label, Row } from 'reactstrap'

const RenderFile = ({ label, sousTitre, input: {value: omitValue, onChange, onBlur, ...inputProps}}) => {

	const adaptFileEventToValue = (delegate) => e => {
		e.persist()
		delegate(e.target.files)
	};

	return (
		<Row className="declaration-sinistre-complement" style={{ marginBottom: 40 }}>
			<Col lg={12} md={12} sm={12} xs={12}>
				{label && <Label style={{ marginBottom: 0 }} className="c-primary ft-s-14">{label}</Label>}
			</Col>
			<Col lg={12} md={12} sm={12} xs={12}>
				{sousTitre && <Label className="ft-s-12 ft-s-allianz" style={{ color: '#414141', fontWeight: 'normal' }}>{sousTitre}</Label>}
			</Col>
			<Col lg={12} md={12} sm={12} xs={12}>
				<Row>
					<Input className="bc-lighten-1" style={{ padding: 5, border: '2px solid', borderRadius: 6 }}  onChange={adaptFileEventToValue(onChange)}
						   onBlur={adaptFileEventToValue(onBlur)} type="file"/>
				</Row>
			</Col>
		</Row>
	)
}
export default RenderFile