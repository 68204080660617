import React from 'react'
import MiniPaper from '../../global/component/miniPaper/MiniPaper'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import * as selectors from './contratSelectors'
import * as tarificateurSelectors from './tarificateur/tarificateurSelectors'
import { withRouter } from 'react-router-dom'
import { formatMillier, formatDecimales, triggerGAEvent } from '../../utils'
import * as decomptesSelectors from "./decomptesProvisoires/decomptesSelectors";
import * as contratActions from "./contratActions";
import { downloadFile } from "../../fileUtils"


class ContratDashboard extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.redirectToTarget = this.redirectToTarget.bind(this)
	}

	redirectToTarget = (path) => {
		this.props.history.push(path)
	}

	render() {
		const { donneesContrat, baseLocation, donneesTarificateur, donneesDecomptes, downloadAttestationAssurance } = this.props
		return (
			<MiniPaper
				titre={'Mon contrat'}
				className={'mon-contract'}
				type={'primary'}
				icone={'icon icon-umbrella-protection'}
				boutons={[{
					texte: 'Consulter mes garanties', icone: 'icon icon-to-do-list', action: () => {
						this.redirectToTarget(`${baseLocation}/contrat/garanties`)
						triggerGAEvent('Dashboard', 'click', 'Consultation des garanties')
					}, couleur: 'vert'
				},
					{ texte: 'Télécharger mon attestation', icone: 'icon icon-download', disabled:false,couleur: 'vert',action: () => {
							triggerGAEvent('Dashboard', 'click', 'Téléchargement attestation')
							downloadFile(donneesContrat.contrat, "attestation-assurance-contrat-" + donneesContrat.contrat, downloadAttestationAssurance(donneesContrat.contrat))
						}}]}
			>
				<div className="push-body-top">
					<h3>N°{donneesContrat.contrat}</h3>
					<h4>{donneesContrat.packCode === "A1" ? ("Assurance des récoltes - Grêle, Tempête " + (donneesTarificateur.optionGel ? "(option Gel)" :"")) : "Garantie multirisques des récoltes"}</h4>
				</div>
				<div className="push-body-bottom">
					<div className="content">
						{Object.keys(donneesTarificateur).length !== 0 ?
							<div className="left">
								Capital assuré {donneesTarificateur.exercice}
							</div>
							:
							<div className="left">
								Capital assuré {donneesDecomptes.exercice}
							</div>
						}
						{Object.keys(donneesTarificateur).length !== 0 ?
							<div className="right ft-s-18 ft-s-allianz-semi-bold">
								{formatMillier(donneesTarificateur.capitalTotal)} €
							</div>
							:
							<div className="right ft-s-18 ft-s-allianz-semi-bold">
								{formatMillier(donneesDecomptes.totalCapitalAssure)} €
							</div>
						}
					</div>
					<div className="content">
						{Object.keys(donneesTarificateur).length !== 0 ?
							<div className="left">
								Surface assurée {donneesTarificateur.exercice}
							</div>
							:
							<div className="left">
								Surface assurée {donneesDecomptes.exercice}
							</div>
						}
						{Object.keys(donneesTarificateur).length !== 0 ?
							<div className="right ft-s-18 ft-s-allianz-semi-bold">
								{formatMillier(formatDecimales(donneesTarificateur.surfaceTotale, 4))} ha
							</div>
							:
							Object.keys(donneesDecomptes).length !== 0 ?
								<div className="right ft-s-18 ft-s-allianz-semi-bold">
									{formatMillier(formatDecimales(donneesDecomptes.totalSurface, 4))} ha
								</div>
								:
								<div className="right ft-s-18 ft-s-allianz-semi-bold">
									donnée non disponible
								</div>
						}
					</div>
					<div className="content">
						{Object.keys(donneesTarificateur).length !== 0 ?
							<div className="left">
								Cotisations {donneesTarificateur.exercice}
							</div>
							:
							<div className="left">
								Cotisations {donneesDecomptes.exercice}
							</div>
						}
						{Object.keys(donneesTarificateur).length !== 0 ?
							<div className="right ft-s-18 ft-s-allianz-semi-bold">
								{formatMillier(Math.round(donneesTarificateur.cotisationTtc))} €
								{/*{donneesContrat.packCode === "R3" ? (donneesTarificateur.niveau === "1" ? formatMillier(Math.round(donneesTarificateur.cotisationNiv1ApresSubvention)) : donneesTarificateur.niveau === "2" ? formatMillier(Math.round(donneesTarificateur.cotisationNiv2ApresSubvention)) : formatMillier(Math.round(donneesTarificateur.cotisationNiv3ApresSubvention))) : formatMillier(Math.round(donneesTarificateur.cotisationTtc))} €*/}
							</div>
							:
							Object.keys(donneesDecomptes).length !== 0 ?
								<div className="right ft-s-18 ft-s-allianz-semi-bold">
									{formatMillier(Math.round(donneesDecomptes.totalCotisation))} €
								</div>
								:
								<div className="right ft-s-18 ft-s-allianz-semi-bold">
									donnée non disponible
								</div>
						}
					</div>
				</div>
			</MiniPaper>
		)
	}
}

ContratDashboard.propTypes = {
	donneesContrat: PropTypes.object,
	donneesTarificateur: PropTypes.object,
	donneesDecomptes: PropTypes.object,
	downloadAttestationAssurance: PropTypes.func,
	baseLocation: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
	return {
		donneesContrat: selectors.getDonneesFirstContratNonResilie(state),
		donneesTarificateur: tarificateurSelectors.getDonneesTarificateur(state),
		donneesDecomptes:decomptesSelectors.getHistoriqueDecomptesExerciceActif(state)
	}
}
const actions = {
	downloadAttestationAssurance: contratActions.downloadAttestationAssurance
}

export default compose(
	connect(mapStateToProps, actions),
	withRouter
)(ContratDashboard)
