import {Container, Row} from "reactstrap";
import FilAriane from "../../global/filAriane/FilAriane";
import React from "react";
import QuestionsFrequentes from "./QuestionsFrequentes";
import Col from "reactstrap/es/Col";

const QuestionsFrequentesPage = (props) => {
        return(
            <div className="background-coordonnees">
                <div className="bg-image-primary bannerSize theme-color"> </div>
                <Container className="wrapper-dashboard">
                    <FilAriane name={"Aide & Contact"} path={props}/>
                    <Row>
                        <Col>
                            <QuestionsFrequentes/>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
}

export default QuestionsFrequentesPage;
