import PropTypes from 'prop-types'
import React from 'react'
import { Col, Label, Row } from 'reactstrap'
import Button from 'reactstrap/es/Button'
import { Field } from 'redux-form'
import RenderFalseField from './CustomFields/RenderFalseField'
import ParcelleSinistree from './ParcelleSinistree'
import PaginationParcelles from '../../../global/component/PaginationParcelles'

const extractNumero = (value) => {
	return value.replace('parcelleN','').replace('-flagSinistree', '')
}

const ParcellesTouchees = ({ change, currentPage, changePages, toggleModale, assolement, parcellesAdded, flagSinistreValues}) => {
	const [parcelleOpen, setParcelleOpen] = React.useState({})

	React.useEffect(() => {
		Object.keys(flagSinistreValues).forEach( key => {
			if (flagSinistreValues[key]) {
				setParcelleOpen({
					...parcelleOpen,
					[extractNumero(key)] : true
				})
			}

		})
	}, [flagSinistreValues])
	const pageSize = 5
	const pagesCount = Math.ceil(parcellesAdded.length / pageSize)


	const changeParcelle = (parcelle) => (name) => (value) => {
		change(`parcelleN${parcelle.numeroParcelle}-${name}`, value)
	}
	const toggle = (numeroParcelle,surfaceParcelle, valueToChange = false) => {
		if(!parcelleOpen[numeroParcelle] && valueToChange) {
			change(`parcelleN${numeroParcelle}-surfaceSinistree`,surfaceParcelle.toString())
		}
		setParcelleOpen({
				...parcelleOpen,
				[numeroParcelle] : parcelleOpen[numeroParcelle] ? !parcelleOpen[numeroParcelle] : true
		})
		valueToChange && change(`parcelleN${numeroParcelle}-flagSinistree`, !flagSinistreValues[`parcelleN${numeroParcelle}-flagSinistree`])
	}

	return (
		<Row style={{ marginTop: 40 }}>
			<Col lg={12} md={12} sm={12} xs={12}>
				<Label className="c-primary ft-s-14 ft-s-allianz-semi-bold">Quelles sont les parcelles touchées ?<b className="c-red">* </b></Label>
			</Col>
			<Col lg={12} md={12} sm={12} xs={12}>
				<div style={{ fontSize: 13 }}>
					Dans la liste ci-dessous, cochez chaque parcelle sinistrée, et modifiez si besoin la surface sinistrée de la parcelle. <br />
					Utilisez les touches "Parcelles suivantes" et "Parcelles précédentes" pour voir toutes les parcelles de votre déclaration d'assolement. <br /><br />
					Si votre déclaration d'assolement n'est pas à jour, vous pouvez si besoin corriger la culture sur la parcelle sinistrée, ou bien ajouter une parcelle afin de la déclarer parcelle sinistrée.
				</div>
			</Col>
			<Field component={RenderFalseField} className="col-xs-12" name={`parcelles`} type="text"  />
			<Col style={{ marginBottom: 10 }} lg={{ size: 4, offset: 8 }} md={{ size: 4, offset: 8 }} sm={{ size: 12 }}
				xs={{ size: 12 }}
			>
				<Button onClick={toggleModale} outline color="light" className="btn-b-1 w-100">Ajouter une parcelle</Button>
			</Col>
			<Col className="assolement-content sinistre" lg={12} md={12} sm={12} xs={12}>
				{parcellesAdded.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((parcelle) =>
					<ParcelleSinistree change={changeParcelle(parcelle)} key={`${parcelle.numeroParcelle}`} isOpen={parcelleOpen[parcelle.numeroParcelle] || false} toggle={(valueToChange) => toggle(parcelle.numeroParcelle,parcelle.surface, valueToChange)} parcelle={parcelle} assolement={assolement} />
				)}
				{(parcellesAdded.length > 0 && pagesCount > 1 ) &&
				<PaginationParcelles changePages={changePages} currentPage={currentPage} pagesCount={pagesCount} />
				}
			</Col>
		</Row>
	)
}
ParcellesTouchees.propTypes = {
	change: PropTypes.func,
	assolement: PropTypes.object
}

export default ParcellesTouchees