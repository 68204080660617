import * as api from '../../../modules/contrat/assolement/assolementAPI'


export const GET_ASSOLEMENT = 'GET_ASSOLEMENT';
export const GET_ASSOLEMENT_SUCCES = 'GET_ASSOLEMENT_SUCCES';
export const GET_ASSOLEMENT_HISTORIQUE_SUCCES = 'GET_ASSOLEMENT_HISTORIQUE_SUCCES';
export const GET_ASSOLEMENT_ERROR = 'GET_ASSOLEMENT_ERROR';
export const SET_ASSOLEMENT_EXERCICE_ACTIF = 'SET_ASSOLEMENT_EXERCICE_ACTIF';
export const SET_PARCELLE_SUCCES = 'SET_PARCELLE_SUCCES'

export const getAssolementExtranet = (isHistorique) => (dispatch) => {
	dispatch({ type: GET_ASSOLEMENT })
	return new Promise((resolve, reject) => {
		return api.getAssolement(isHistorique)
			.then(response => {
				if (response.status !== 200) {
					resolve(response.json())
				} else {
					resolve(response.json())
				}
			})
	}).then(response => {
		if (!isHistorique) {
			return setAssolement(response)(dispatch)
		} else {
			return setAssolementHistorique(response)(dispatch)
		}
	}).catch(() => {
		dispatch({ type: GET_ASSOLEMENT_ERROR })
	})
}

export const setAssolement = (assolement) => (dispatch) => {
	dispatch({
		type: GET_ASSOLEMENT_SUCCES,
		payload: assolement
	})
}

export const setAssolementHistorique = (assolement) => (dispatch) => {
	dispatch({
		type: GET_ASSOLEMENT_HISTORIQUE_SUCCES,
		payload: assolement
	})
}

export const setAssolementExerciceActif = (response) => (dispatch) => {
	dispatch({
		type: SET_ASSOLEMENT_EXERCICE_ACTIF,
		payload: response
	})
}

export const setParcelle = (parcelle) => (dispatch) => {
	dispatch({
		type: SET_PARCELLE_SUCCES,
		parcelle
	})
};

export const ajoutParcelle = (data)  => dispatch =>  {
	return api.ajoutParcelle(data.commune,data.lieuDit,data.culture,data.surfaceTotale,data.surfaceSinistree)
		.then(response => {
			if (response.status === 200) {
				return response.json()
			} else {
				return response
			}
		}).then(response => {
			return setParcelle(response)(dispatch)
		})
}