import * as actions from './agenceActions'

const initialState = {
    isLoading: false,
	numeroAgence: '',
    orias: '',
	societe: '',
	titre: '',
	nom: '',
	adresse1: '',
	adresse2: '',
	codePostal: '',
	ville: '',
	lieuCommercial: '',
	telephone: '',
	email: '',
    longitude:'',
    latitude:''
}

const agence = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_AGENCE:{
            return {
                ...state,
                isLoading: true
            }
        }
        case actions.GET_AGENCE_SUCCES:{
            return {
                ...state,
                isLoading:false,
                numeroAgence: action.agence.numeroAgence,
                orias: action.agence.orias,
                societe: action.agence.societe,
                titre: action.agence.titre,
                nom: action.agence.nom,
                adresse1: action.agence.adresse1,
                adresse2: action.agence.adresse2,
                codePostal: action.agence.codePostal,
                ville: action.agence.ville,
                lieuCommercial: action.agence.lieuCommercial,
                telephone: action.agence.telephone,
                email: action.agence.email,
                latitude: '',
                longitude: ''
            }
        }
        case actions.GET_MAP_ERROR:{
            return state
        }
        case actions.GET_MAP_SUCCES:{
            return {
                ...state,
                longitude: action.longitude,
                latitude: action.latitude
            }
        }
        case actions.GET_AGENCE_ERROR:{
            return state
        }
        default:
            return state
    }
}

export default agence