import * as api from './modificationAPI'
export const SET_MODIF_USER_INFO_SUCCES = 'SET_MODIF_USER_INFO_SUCCES'
export const SET_MODIF_USER_MAIL_SUCCES = 'SET_MODIF_USER_MAIL_SUCCES'
export const SET_MODIF_USER_PREFERENCES_SUCCES = 'SET_MODIF_USER_PREFERENCES_SUCCES'
export const SET_MODIF_INFO_CLIENT_SUCCES = 'SET_MODIF_INFO_CLIENT_SUCCES'

export const modificationPreferences = (data) => dispatch => {
	return api.modificationPreferences(data.consentement)
		.then(response => {
			if (response.status !== 200) {
				throw response
			} else {
				return response.json()
			}
		}).then( response => {
			return setModifPreferences(response.infoUser)(dispatch)
			}
		)
}

export const modificationEmail = (data) => dispatch => {
	return api.modificationEmail(data.email)
		.then(response => {
			if (response.status === 200) {
				return response.json()
			} else {
				throw response
			}
		}).then( response => {
				return setModifUserMail(response.infoUser)(dispatch)
			}
		)
}

export const modificationPassword= (data) => dispatch => {
	return api.modificationPassword(data.oldPassword,data.newPassword)
		.then(response => {
			if (response.status === 200) {
				return response
			} else {
				throw response
			}
		})
}

export const modificationCoordonnees= (data) => dispatch => {
	return api.modificationCoordonnees(data.adresseNumero,data.adresseTypeVoie,data.adresseVoie,data.adresseComplement,data.codePostal,data.ville,data.telephoneFixe,data.telephoneMobile)
		.then(response => {
			if (response.status === 200) {
				return response.json()
			} else {
				throw response
			}
		}).then( response => {
				return setModifUser(response.infoUser)(dispatch)
			}
		)
}

export const setModifUser = (infoUser) => (dispatch) => {
	dispatch({
		type: SET_MODIF_USER_INFO_SUCCES,
		infoUser
	})
};

export const setModifUserMail = (infoUser) => (dispatch) => {
	dispatch({
		type: SET_MODIF_USER_MAIL_SUCCES,
		infoUser
	})
};

export const setModifUserPreferences = (infoUser) => (dispatch) => {
	dispatch({
		type: SET_MODIF_USER_PREFERENCES_SUCCES,
		infoUser
	})
};

export const setModifInfoClientPreferences = (infoUser) => (dispatch) => {
	dispatch({
		type: SET_MODIF_INFO_CLIENT_SUCCES,
		infoUser
	})
};

export const setModifPreferences = (infoUser) => (dispatch) => {
	setModifUserPreferences(infoUser)(dispatch)
	setModifInfoClientPreferences(infoUser)(dispatch)
}