
export const inscriptionUtilisateur = (data) => {
	return fetch(process.env.REACT_APP_SERVER_URL + '/auth/inscription', {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	}).then((response) => {
		return response
	})
}