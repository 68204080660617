import React from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col, Button,} from 'reactstrap'
import EspaceAssureMessages from '../../config/metier/EspaceAssureMessages'
import Paper from '../../global/component/paper/Paper'
import ModaleMdp from '../motDePasseOublie/ModaleEtape1'
import LoginForm from './LoginForm'
import * as loginActions from '../loginActions'
import connect from 'react-redux/es/connect/connect'
import {SubmissionError} from 'redux-form'
import {Link} from 'react-router-dom'
import * as messageActions from "../../global/component/messages/messageActions";

// Nombre de tentative max de connexion avant blocage du bouton
const NB_TENTATIVE_MAX = 5

class Connexion extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			type: 'password',
			tooltipOpen: false,
			isOpened: false,
			openMessage: false,
			modaleType: '',
			modaleText: '',
			nbTentative: 0,
			connexionDisabled: false
		}
		this.showHide = this.showHide.bind(this)
		this.toggle = this.toggle.bind(this)
		this.toggleModale = this.toggleModale.bind(this)
		this.submitLoginForm = this.submitLoginForm.bind(this)
		this.submitMotdePasseOublieForm = this.submitMotdePasseOublieForm.bind(this)
	}

	addTentative(){
		this.setState({ nbTentative: this.state.nbTentative + 1 })
		if(this.state.nbTentative >= NB_TENTATIVE_MAX){
			this.setState({ connexionDisabled : true })
		}
	}

	showHide() {
		this.setState({
			type: this.state.type === 'input' ? 'password' : 'input'
		})
	}

	toggle() {
		this.setState({
			tooltipOpen: !this.state.tooltipOpen
		})
	}

	toggleModale() {
		this.setState(prevState => ({
			isOpened: !prevState.isOpened
		}))
	}

	submitLoginForm(data) {
		return this.props.connexion(data)
			.catch((e) => {
				this.addTentative()
				throw new SubmissionError(e)
			})
	}

	closeMdpOublieModale(){
		this.props.messagesAlerte("Réinitialisation de votre mot de passe","Un e-mail vient de vous être envoyé pour réinitialiser votre mot de passe. Si vous n’avez pas reçu cet email, merci de vérifier dans vos courriers indésirables. ")
		this.toggleModale()
	}

	submitMotdePasseOublieForm(data) {
		return this.props.motDePasseOublie(data)
			.then(() => {
				this.closeMdpOublieModale()
			}).catch(() => {
				this.closeMdpOublieModale()
			})
	}

	render() {
		const { type, tooltipOpen } = this.state
		return (
			<Container className="connexion-body d-flex">
				<Row className="m-auto">
					<Col className="d-flex" xs={12} md={{ size: 10, offset: 1 }} lg={{ size: 7, offset: 5 }} xl={{ size: 6, offset: 6 }}>
						<Paper>
							<div className="main-log">
								<div className="head">
									<Row>
										<Col xs={6}>
											<div className="branding">

											</div>
										</Col>
										<Col xs={6}>
											<div className="baseline">
												Espace Client
											</div>
										</Col>
									</Row>
								</div>
								<div className="body">
									<Row>
										<Col>
											<div className="message-information mb-3">
												<EspaceAssureMessages id="connexion_introduction" />
											</div>
										</Col>
									</Row>
									<Row>
										<Col>
											<div className="login mb-4">
												<Row>
													<Col>
														<div className="login-head">
															<h4>
																J'ai déja un espace client
															</h4>
														</div>
													</Col>
												</Row>
												<Row>
													<Col>
														<div className="login-body">
															<LoginForm
																type={type}
																showHide={this.showHide}
																toggleModale={this.toggleModale}
																toggle={this.toggle}
																tooltipOpen={tooltipOpen}
																onSubmit={this.submitLoginForm}
																motDePasseOublieSubmit={this.submitMotdePasseOublieForm}
																connexionDisabled={this.state.connexionDisabled}
															/>
														</div>
													</Col>
												</Row>
											</div>
										</Col>
									</Row>
									<Row>
										<Col>
											<div className="suscribe">

												<Row>
													<Col>
														<div className="suscribe-body">
															<div className="title"><EspaceAssureMessages id="titre_bloc_premiere_visite"/> </div>
															<div className="message-standard">
																<EspaceAssureMessages id="texte_bloc_premiere_visite"/>
																<div className="important">
																	<EspaceAssureMessages id="detail_bloc_premiere_visite"/>
																</div>
															</div>
															<div className="charte-button">
																<Link
																	to={`${this.props.location.pathname}/inscription`}>
																	<Button outline color="light" className="btn-block border-2">
																		Je m'inscris
																	</Button>
																</Link>
															</div>
														</div>
													</Col>
												</Row>
											</div>
										</Col>
									</Row>
								</div>
							</div>
						</Paper>

					</Col>
				</Row>
				<ModaleMdp onSubmit={this.submitMotdePasseOublieForm} toggle={this.toggleModale}
						   isOpened={this.state.isOpened}/>
			</Container>
		)
	}
}
Connexion.propTypes = {
	messagesAlerte:PropTypes.func,
	messagesErreur: PropTypes.func,
	connexion: PropTypes.func,
	motDePasseOublie: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const actions = {
	connexion: loginActions.connecteUtilisateur,
	motDePasseOublie: loginActions.motDePasseOublie,
	messagesAlerte:messageActions.messagesAlerte,
	messagesErreur:messageActions.messagesErreur
}

export default connect(mapStateToProps, actions)(Connexion)
