import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import FilAriane from '../../../global/filAriane/FilAriane'
import EcheancierContrat from './EcheancierContrat'

const EcheancierPage = ({ props }) => {

	return (
		<div className="background-dashboard background-echeancier">
			<div className="bg-image-primary bannerSize theme-color"></div>
			<Container className="wrapper-dashboard">
				<FilAriane name={'Mon contrat'} path={props} />
				<Row>
					<Col md="12">
						<EcheancierContrat />
					</Col>
				</Row>
			</Container>
		</div>
	)
}

EcheancierPage.propTypes = {}

export default EcheancierPage
