import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Col, Row } from 'reactstrap'
import { compose } from 'recompose'
import Paper from '../../../global/component/paper/Paper'
import ShadowScrollbars from '../../../global/component/scrollbar/ShadowScrollbar'
import * as agenceSelector from '../../agence/agenceSelectors'
import * as messagerieActions from '../messagerieActions'
import * as messagerieSelectors from '../messagerieselectors'
import ElementMessage from './ElementMessage'

const BlockListeMessages = ({ history, location, agence, setMessageActif, discussions, messages, handleScrollStart, messageActif }) => {

	const [uneSeuleDiscussion] = React.useState(Object.keys(discussions).length === 1)
	const [visibleFils] = React.useState(location.pathname.includes('nouveauMessage'))

	const redirectToTarget = (path) => {
		history.push(path)
	}
	const createNewMessage = () => {
		redirectToTarget(`${location.pathname.replace('/consultation', '/nouveauMessage')}`)
	}
	const setMessageFocus = (message) => {
		setMessageActif(message)
		if (location.pathname.indexOf("/nouveauMessage") > -1) {
			redirectToTarget(`${location.pathname.replace('/nouveauMessage', '/consultation')}`)
		}
	}
	let listDiscussionNonLues = []
	let listDiscussionLues = []
	Object.values(discussions).forEach(discussion => {
		if (discussion.messageAssure.nonLu === '1') {
			listDiscussionNonLues.push(discussion)
		} else {
			listDiscussionLues.push(discussion)
		}
	})

	return (
		<Paper className={`push-tpl-1 messagerie-content ${messageActif != null || visibleFils ? 'withMessage' : ''}`}>
			<Row className="push-head">
				<Col lg={12} sm={12} md={12} className={'p-0'}>
					<Row className={'titre'}>
						<Col lg={12} sm={12} md={12}>
							Mes échanges avec mon agence :
						</Col>
					</Row>
					<Row className={'titre agence'}>
						<Col lg={12} sm={12} md={12}>
							{agence.nom}
						</Col>
					</Row>
					<Row className={'d-flex justify-content-center align-items-center mt-4 mb-2'}>
						<Button onClick={() => {
							createNewMessage()
						}} color="light" size="xs"
						        className="btn-block w-75 d-flex justify-content-center align-items-center">
							<span className={'icon-mail-circle-o iconMessage mr-1'} />
							<div className={'texteBouton'}>Nouveau message</div>
						</Button>
					</Row>
				</Col>
			</Row>
			{(listDiscussionLues.length !== 0 || listDiscussionNonLues.length !== 0) &&
			<ShadowScrollbars style={{ height: '49vh', marginBottom: 21 }} className="list-group-messages" onScrollStart={handleScrollStart}>
				<Row className={'p-3 listMessage'}>
					<Col lg={12} sm={12} md={12}>
						{listDiscussionNonLues.length !== 0 &&
						<div>
							<Row className={'mt-3 mb-2 titre petit'}>
								<Col>
									Non lus
								</Col>
							</Row>
							<Row>
								<Col>
									{listDiscussionNonLues
										.sort((previousDiscussion, currentDiscussion) => new Date(currentDiscussion.messageAssure.dateDerniereModification) - new Date(previousDiscussion.messageAssure.dateDerniereModification))
										.map((discussion) =>
											<ElementMessage
												uneSeuleDiscussion={uneSeuleDiscussion}
												key={discussion.messageAssure.idMessageAssure}
												discussion={discussion}
												messages={messages}
												activeMessage={(value) => {
													setMessageFocus(value)
												}}
												nonLu={true}
											/>
										)
									}
								</Col>
							</Row>
						</div>
						}
						{listDiscussionLues.length !== 0 &&
						<div>
							<Row className={'mt-4 mb-2 titre petit'}>
								<Col>
									Autres messages
								</Col>
							</Row>
							<Row>
								<Col>
									{listDiscussionLues
										.sort((previousDiscussion, currentDiscussion) => new Date(currentDiscussion.messageAssure.dateDerniereModification) - new Date(previousDiscussion.messageAssure.dateDerniereModification))
										.map((discussion) =>
											<ElementMessage
												uneSeuleDiscussion={uneSeuleDiscussion}
												key={discussion.messageAssure.idMessageAssure}
												discussion={discussion}
												messages={messages}
												activeMessage={(value) => {
													setMessageFocus(value)
												}}
												nonLu={false}
											/>
										)
									}
								</Col>
							</Row>
						</div>
						}
					</Col>
				</Row>
			</ShadowScrollbars>
			}
		</Paper>
	)
}
BlockListeMessages.propTypes = {
	discussions: PropTypes.object,
	messages: PropTypes.array,
	agence: PropTypes.object
}

const mapStateToProps = (state) => {
	return {
		agence: agenceSelector.getDonneesAgence(state),
		discussions: messagerieSelectors.getListDiscussions(state),
		messages: messagerieSelectors.getMessagesDiscussionActive(state)
	}
}
const actions = {
	setMessageActif: messagerieActions.setMessageActif
}

export default compose(
	connect(mapStateToProps, actions),
	withRouter
)(BlockListeMessages)