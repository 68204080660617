import React from 'react'
import { CustomInput, FormFeedback } from 'reactstrap'

const RenderCheckbox = ({ label, checked, disabled, input, meta: { touched, error }, id, name, inline, onClick }) => {

	return (
		<div>
			<CustomInput {...input} type="checkbox" id={id}
			             style={{ cursor: 'pointer' }}
			             name={name}
			             disabled={disabled}
			             label={label}
			             inline={inline}
			             onClick={onClick}
			             defaultChecked={checked}
			/>
			<FormFeedback valid={!(touched && error)} style={{ display: (error && touched) ? 'block' : 'none' }}>
				<div style={{ display: 'flex' }}>
					<p style={{ whiteSpace:'nowrap',fontSize: '12px' }}>{error}</p>
				</div>
			</FormFeedback>
		</div>

	)
}

RenderCheckbox.defaultProps = {
	onClick: () => {
	}
}
export default RenderCheckbox