import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Col, Row } from 'reactstrap'
import { compose } from 'redux'
import * as configurationActions from '../../../config/metier/configurationActions'
import { formatDateTime } from '../../../utils'

class CarouselCaption extends Component {

	redirectToTarget = (path) => {
		this.props.history.push(path)
	}

	render() {
		const { item, total, deleteNotif,index } = this.props
		return (
			<div className='carousel-caption d-block push-tpl-1 btn-push-tpl '>
				<Row>
					<Col lg={4} md={4} sm={4} xs={4}>
						<p className="index-notifications" style={{ position: 'absolute', top: '-22px', left: '-10px' }}>{index}/{total}</p>
					</Col>
					<Col lg={4} md={4} sm={4} xs={4}>
						<span className='icon icon-bell'> </span>
					</Col>
					<Col lg={4} md={4} sm={4} xs={4}>
						<span onClick={() => {
							deleteNotif(item.type)
						}}
						      className="icon icon-close">
						</span>
					</Col>
				</Row>
				{item.type === '1' &&
				<div>
					<h3>{item.titre} {item.contrat})</h3>
					<p style={{ fontSize: '13px' }}>{item.message1} {item.exercice} {item.message2}</p>
					<Button size="md" style={{ borderColor: '#fff', borderWidth: '1px' }} onClick={() => {
						this.redirectToTarget(`${window.location.pathname.substring(0, 16) + `/contrat/cotisations`}`)
					}}
					        className="btn-light d-inline-flex align-items-center justify-content-center btn-push-tpl btn-block ">
						<div className="wording">{item.texteLien}</div>
					</Button>
				</div>
				}
				{item.type === '2' &&
				<div>
					<h3>{item.titre} {item.contrat})</h3>
					<p style={{ fontSize: '13px' }}>{item.message1} {item.exercice} {item.message2} {formatDateTime(item.date)}</p>
					<Button size="md" style={{ borderColor: '#fff', borderWidth: '1px' }} onClick={() => {
						this.redirectToTarget(`${window.location.pathname.substring(0, 16) + `/contrat/cotisations`}`)
					}}
					        className="btn-light d-inline-flex align-items-center justify-content-center btn-push-tpl btn-block ">
						<div className="wording">{item.texteLien}</div>
					</Button>
				</div>
				}
				{item.type === '3' &&
				<div>
					<h3>{item.titre} {item.contrat}{item.contrat !=='' ? ')' : ''}</h3>
					<p style={{ fontSize: '13px' }}>{item.message1} {item.exercice} {item.message2}</p>
					<Button size="md" style={{ borderColor: '#fff', borderWidth: '1px' }} onClick={() => {
						this.redirectToTarget(`${window.location.pathname.substring(0, 16) + `/contrat/cotisations`}`)
					}}
					        className="btn-light d-inline-flex align-items-center justify-content-center btn-push-tpl btn-block ">
						<div className="wording">{item.texteLien}</div>
					</Button>
				</div>
				}
				{item.type === '4' &&
				<div>
					<h3>{item.titre}</h3>
					<p style={{ fontSize: '13px' }}>{item.message1} {item.sinistre} {item.message2}</p>
					<Button disabled={true} size="md" style={{ borderColor: '#fff', borderWidth: '1px' }}
					        className="btn-light d-inline-flex align-items-center justify-content-center btn-push-tpl btn-block ">
						<div className="wording">{item.texteLien}</div>
					</Button>
				</div>
				}
				{item.type === '5' &&
				<div>
					<h3>{item.titre} {item.contrat}{item.contrat !=='' ? ')' : ''}</h3>
					<p style={{ fontSize: '13px' }}>{item.message1} {item.sinistre} {item.message2}</p>
					<Button disabled={true} size="md" style={{ borderColor: '#fff', borderWidth: '1px' }}
					        className="btn-light d-inline-flex align-items-center justify-content-center btn-push-tpl btn-block ">
						<div className="wording">{item.texteLien}</div>
					</Button>
				</div>
				}
				{item.type === '6' &&
				<div>
					<h3>{item.titre}</h3>
					<p style={{ fontSize: '13px' }}>{item.message1} {item.sinistre} {item.message2}</p>
					<Button disabled={true} size="md" style={{ borderColor: '#fff', borderWidth: '1px' }}
					        className="btn-light d-inline-flex align-items-center justify-content-center btn-push-tpl btn-block ">
						<div className="wording">{item.texteLien}</div>
					</Button>
				</div>
				}
				{item.type === '7' &&
				<div>
					<h3>{item.titre}</h3>
					<p style={{ fontSize: '13px' }}>{item.message1} {item.sinistre} {item.message2}</p>
					<Button disabled={true} size="md" style={{ borderColor: '#fff', borderWidth: '1px' }}
					        className="btn-light d-inline-flex align-items-center justify-content-center btn-push-tpl btn-block ">
						<div className="wording">{item.texteLien}</div>
					</Button>
				</div>
				}
				{item.type === '8' &&
				<div>
					<h3>{item.titre}</h3>
					<p style={{ fontSize: '13px' }}>{item.message1}</p>
					<Button size="md" style={{ borderColor: '#fff', borderWidth: '1px' }} onClick={() => {
						this.redirectToTarget(`${window.location.pathname.substring(0, 16) + `/contrat/assolement`}`)
					}}
					        className="btn-light d-inline-flex align-items-center justify-content-center btn-push-tpl btn-block ">
						<div className="wording">{item.texteLien}</div>
					</Button>
				</div>
				}
				{item.type === '9' &&
				<div>
					<h3>{item.titre}</h3>
					<p style={{ fontSize: '13px' }}>{item.message1}</p>
					<Button size="md" style={{ borderColor: '#fff', borderWidth: '1px' }} onClick={() => {
						this.redirectToTarget(`${window.location.pathname.substring(0, 16) + `/contrat/assolement`}`)
					}}
					        className="btn-light d-inline-flex align-items-center justify-content-center btn-push-tpl btn-block ">
						<div className="wording">{item.texteLien}</div>
					</Button>
				</div>
				}
			</div>
		)
	}
}

CarouselCaption.propTypes = {
	captionHeader: PropTypes.string,
	captionText: PropTypes.string,
	cssModule: PropTypes.object,
	className: PropTypes.string
}

const mapStateToProps = (state) => ({})

const actions = ({
	deleteNotif: configurationActions.deleteAssoNotification
})
export default compose(connect(mapStateToProps, actions), withRouter)(CarouselCaption)