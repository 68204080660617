import {fetchFactory, postMultipart} from "../../entities/fetchEntities";
import {serializeParts} from "../../utils";

export const getMessagerie = () =>
    fetchFactory('/extranet/messagerie', {method:'GET'})
        .then((response) => {
            if(response.ok) {
                return response
            } else throw response
        })

export const getFile = (id, numero) =>
    fetchFactory('/extranet/file?id='+ id + '&numero=' + numero + '&typeFile=2', {method:'GET'})
        .then((response) => {
            if(response.ok) {
                return response
            } else throw response
        })

export const createNewDiscussion = (data) => {
    const parts = [
        {key: 'contrat', value: data.contrat},
        {key: 'objet', value: data.objet},
        {key: 'message', value: data.message ? data.message : " "}
    ]
    if (data.file) {
        parts.push({key:'files', value: data.file})
    }
    if (data.file2) {
        parts.push({key:'files', value: data.file2})
    }
    if (data.file3) {
        parts.push({key:'files', value: data.file3})
    }
    return postMultipart('/extranet/messagerie/addDiscussion', {
        method: 'POST',
        body: serializeParts(parts)
    })
}

export const createNewMessage = (data) => {
    const parts = [
        {key: 'id', value: data.id},
        {key: 'message', value: data.message ? data.message : " "}
    ]
    if (data.file) {
        parts.push({key: 'files', value: data.file})
    }
    if (data.file2) {
        parts.push({key: 'files', value: data.file2})
    }
    if (data.file3) {
        parts.push({key: 'files', value: data.file3})
    }
    return postMultipart('/extranet/messagerie/addMessage', {
            method: 'POST',
            body: serializeParts(parts)
        })
}

export const saveNonLu = (data) =>
    fetchFactory('/extranet/messagerie/nonLu', {
        method:'POST',
        body: JSON.stringify(data)
    })

export const sendMailToAgence = (data) =>
    fetchFactory('/extranet/messagerie/sendMailNewDiscussion', {
        method:'POST',
        body: JSON.stringify(data),
    })

export const sendMailMessageToAgence = (data) =>
    fetchFactory('/extranet/messagerie/sendMailNewMessage', {
        method:'POST',
        body: JSON.stringify(data),
    })