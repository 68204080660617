import React from 'react'
import { Button, Col, Row, Tooltip } from 'reactstrap'
import { formatDateSinistrePO, formatDateTime, supprimeZerosContratString } from '../../../utils'


class LigneDeclarationPO extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			tooltipOpen: false
		}
	}

	render() {
		const { declaration, isOpen, toggle, target, contratListe, donneesContrat, goToDeclaration } = this.props
		return (
			<Button onClick={() => {
				goToDeclaration(declaration.declaration)
			}}>
				<Row className="table-item liste-sinistre-en-cours">
					<Col xs="1" className="text-left">
						<div>
							<span className="icon icon-file" id={target}> </span>
							<Tooltip placement="top" isOpen={isOpen} target={target} toggle={toggle}>
								Déclaration pour ordre
							</Tooltip>
						</div>
					</Col>
					<Col xs="10" className="d-flex flex-column align-items-start">
						<div style={{ marginBottom: 8, lineHeight: '20px' }}
						     className="ft-s-allianz-semi-bold ft-s-16"
						>Sinistre du {formatDateSinistrePO(declaration.declaration.dateSinistre)} - {declaration.libellePeril}</div>
						{contratListe.length > 1 ?
							<div style={{ color: '#5F6368', lineHeight: '16px',marginBottom:8 }}
							     className="ft-s-14">Déclaré le {formatDateTime(declaration.declaration.dateModification)} sur contrat N° {supprimeZerosContratString(declaration.declaration.contrat)} :
								{donneesContrat.packCode === 'A1' ? ' Assurance des récoltes - Grêle, Tempête ' : ' Garantie multirisques des récoltes'}
							</div> :
							<div className="ft-s-14" style={{ color: '#5F6368', lineHeight: '16px',marginBottom:8 }}>Déclaré le {formatDateTime(declaration.declaration.dateModification)}</div>
						}
						<div style={{lineHeight:'17px'}} className="ft-s-14 motif">"Pour information" - A confirmer avant le {formatDateTime(declaration.declaration.dateConfirmation)}</div>
					</Col>
					<Col xs="1" className="text-right p-0">
						<span className="icon icon-chevron-right"> </span>
					</Col>
				</Row>
			</Button>
		)
	}
}

export default LigneDeclarationPO