import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import * as tarificateurSelectors from '../../tarificateur/tarificateurSelectors'
import MesAvantages from '../MesAvantages'
import MesGaranties from '../MesGaranties'
import MonContrat from '../MonContrat'

const MesGarantiesPage = ({ donneesTarificateur, donneesTarificateurContrat,setThematique, setThematiqueFAQ }) => {
	const [childThematique] = React.useState("CG")

	useEffect( () => {
		setThematique(childThematique)
		setThematiqueFAQ(childThematique)
	}, [childThematique])

	return (
		<Col md="12" lg="9">
			<Row>
				<Col className="mb-3">
					<MonContrat
						libelleBouton="Télécharger mon attestation d'assurance"
						isMesGaranties={true}
						action="AttestationAssurance"
						donneesTarificateur={donneesTarificateur}
						preListExercice={Object.keys(donneesTarificateurContrat)}
						texteMulti={'Vous pouvez consulter les garanties de chaque contrat en faisant défiler vos contrats dans le bloc ci-dessous. '}
					/>
				</Col>
			</Row>
			<Row>
				<Col className="mb-3">
					<MesGaranties />
				</Col>
			</Row>
			<Row>
				<Col className="mb-3">
					<MesAvantages />
				</Col>
			</Row>
		</Col>
	)
}

MesGarantiesPage.propTypes = {
	donneesTarificateur: PropTypes.object,
	donneesTarificateurContrat: PropTypes.object
}

const mapStateToProps = (state) => ({
	donneesTarificateur: tarificateurSelectors.getDonneesTarificateur(state),
	donneesTarificateurContrat: tarificateurSelectors.getHistoriqueTarificateurActif(state)
})

const actions = {}

export default connect(mapStateToProps, actions)(MesGarantiesPage)
