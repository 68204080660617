import * as actions from './messagerieActions'

const initialState = {
    isLoading: false,
    messageActif: null,
    listeMessagerie: null
};

const messagerie = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_MESSAGERIE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listeMessagerie:action.payload.reduce((acc, discussion) => ({
                    ...acc,
                    [discussion.messageAssure.idMessageAssure]: discussion,
                }), {}),
            }
        }
        case actions.SET_MESSAGE_ACTIF: {
            return {
                ...state,
                messageActif: action.payload
            }
        }
        case actions.SET_MESSAGE_NON_LU: {
            return {
                ...state,
                listeMessagerie:{
                    ...state.listeMessagerie,
                    [action.payload]: {
                        ...state.listeMessagerie[action.payload],
                        messageAssure:{
                            ...state.listeMessagerie[action.payload].messageAssure,
                            nonLu:false
                        }
                    }
                }
            }
        }
        default:
            return state

    }
};

export default messagerie