import React from 'react'
import PropTypes from 'prop-types'
import Footer from '../global/footer/Footer'
import BandeauCookies from '../global/cookies/BandeauCookies'
import * as baseActions from '../containers/baseActions'
import { connect } from 'react-redux'
import ModaleMessages from "../global/component/messages/ModaleMessages";
import * as messageActions from "../global/component/messages/messageActions";

class AccueilPage extends React.Component {

	componentWillMount() {
		if (this.props.user.authenticated) {
			this.props.redirectToDashBoard(this.props.user, this.props.theme)
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.user.authenticated) {
			this.props.redirectToDashBoard(nextProps.user, nextProps.theme)
		}
	}

	render() {
		const {messages,closeModale, theme } = this.props
		return (<div className="wrapper-connexion">
				<div className="bg-image-connexion fullscreen theme-color"></div>
				{this.props.children}
				<ModaleMessages isOpened={messages.modaleOpened}
								type={messages.modaleType}
								titre={messages.modaleTitre}
								text={messages.modaleTexte}
								textButton={messages.modaleTexteButton}
								toggle={closeModale}/>
				{/*<BandeauCookies />*/}


				<div hidden>
					{/*Début de la liste OneTrust de cookies*/}
					<div id="optanon-cookie-policy"></div>
					{/*Fin de la liste OneTrust de cookies*/}
				</div>
				<Footer
					theme={theme}
				/>
			</div>
		)
	}
}

AccueilPage.propTypes = {
	children: PropTypes.node.isRequired,
	redirectToDashBoard: PropTypes.func.isRequired,
	closeModale: PropTypes.func,
	theme: PropTypes.string.isRequired,
	messages: PropTypes.object

}

const mapStateToProps = state => ({
	user: state.user,
	messages:state.messages
})

const actions = {
	redirectToDashBoard: baseActions.redirectToDashboard,
	closeModale: messageActions.closeModale
}
export default connect(mapStateToProps, actions)(AccueilPage)
