import React from 'react'
import { withRouter } from 'react-router'
import { Col, Row } from 'reactstrap'
import NouveauMessage from './components/NouveauMessage'

const NouveauMessagePage = ({ history }) => {
	const redirectToTarget = (path) => {
		history.push(path)
	}

	function goBackToMessages() {
		redirectToTarget(`${window.location.pathname.replace('/nouveauMessage', '/consultation')}`)
	}

	return (
		<Col>
			<Row className="lienSinistre">
				<Col>
					<div className="p-0 d-flex backLink" onClick={() => {
						goBackToMessages()
					}}>
						<span className="icon icon-chevron-left chevronRetourSinistre"></span>
						<div className="backLink">Retour vers la liste des messages</div>
					</div>
				</Col>
			</Row>
			<NouveauMessage />
		</Col>
	)
}
export default withRouter(NouveauMessagePage)