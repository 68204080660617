import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Error404 from '../../global/error404/Error404'
import HistoriqueAssolementPage from './monContrat/pages/HistoriqueAssolementPage'
import MesCotisationsPage from './monContrat/pages/MesCotisationsPage'
import MesGarantiesPage from './monContrat/pages/MesGarantiesPage'
import MonAssolementPage from './monContrat/pages/MonAssolementPage'

export default ({ pathname, pathToHome, setThematique, setThematiqueFAQ }) => (
	<Switch>
		<Route exact path={`${pathname}/garanties`} render={() => <MesGarantiesPage setThematique={setThematique} setThematiqueFAQ={setThematiqueFAQ}/>} />
		<Route exact path={`${pathname}/assolement`} render={() => <MonAssolementPage setThematique={setThematique} setThematiqueFAQ={setThematiqueFAQ} />} />
		<Route exact path={`${pathname}/cotisations`} render={() => <MesCotisationsPage setThematique={setThematique} setThematiqueFAQ={setThematiqueFAQ} />} />
		<Route exact path={`${pathname}/historiqueAssolement`} render={() => <HistoriqueAssolementPage setThematique={setThematique} setThematiqueFAQ={setThematiqueFAQ} />} />
		<Route path={`${pathToHome}/404`} component={Error404} />
		<Redirect from={`${pathname}/*`} to={`${pathToHome}/404`} />
	</Switch>
)
