import React from 'react'
import { Col, Row } from 'reactstrap'
import { formatDecimales, formatMillier } from '../../../utils'

const ParcelleSinistree = ({ parcelle }) => {
	return (
		<Row className="parcelleSinistree">
			<Col>
				<Row>
					<Col className="font-weight-bold pt-3 pl-3">
						Parcelle {parcelle.numeroParcelle} : {parcelle.libelleCommune} { (parcelle.nomParcelle !== ''&& isNaN(parcelle.nomParcelle)) ?  '(Lieu-dit : ' + parcelle.nomParcelle + ')' : ''}
					</Col>
				</Row>
				<Row>
					<Col className="pt-2 pl-3 pb-3">
						{parcelle.codeCultureDetail.nom} - Surface sinistrée : {formatMillier(formatDecimales(parcelle.surfaceSinistree, 4))} ha
					</Col>
				</Row>
			</Col>
		</Row>

	)
}
export default ParcelleSinistree