import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Col, Row, Table } from 'reactstrap'
import { formatDecimales, formatMillier } from '../../../utils'
import * as decomptesSelectors from '../decomptesProvisoires/decomptesSelectors'

class TableMonContrat extends React.Component {
    render() {
        const { donneesContrat, donneesInfosClient, donneesTarificateur, donneesDecomptes } = this.props
        return (
            <Row className="push-body">
                <Col className="content flex-column text-left" xs="12">
                    <div className="contrat-content">
                        <Row>
                            <Col>
                                <div className="push-head multi">
                                    <div className="title">
                                        <span className="icon icon-star mr-2"> </span>
                                        <span className="wording-small">
                                            Contrat N° {donneesContrat.contrat} : {donneesContrat.packCode === 'A1' ? ('Assurance des récoltes - Grêle, Tempête ' + (donneesTarificateur.optionGel ? '(option Gel)' : '')) : 'Garantie multirisques des récoltes'}
                                        </span>
                                    </div>
                                </div>
                                <div className="push-body multi">
                                    <Table>
                                        <tbody>
                                        <tr className="coordonnees">
                                            <td></td>
                                            <td>Exploitation</td>
                                            <td className="font-weight-bolder">{donneesInfosClient.titre} {donneesInfosClient.nom} {donneesInfosClient.prenom}</td>
                                        </tr>
                                        <tr className="coordonnees">
                                            <td></td>
                                            <td>Représentée par</td>
                                            {donneesInfosClient.typePm && <td>{donneesInfosClient.representantTitre} {donneesInfosClient.representantNom} {donneesInfosClient.representantPrenom}</td>}
                                        </tr>
                                        <tr className="coordonnees">
                                            <td></td>
                                            <td>Adresse</td>
                                            <td>{donneesInfosClient.adresseNumero} {donneesInfosClient.adresseTypeVoie && donneesInfosClient.adresseTypeVoie.toUpperCase()} {donneesInfosClient.adresseVoie && donneesInfosClient.adresseVoie.toUpperCase()} {donneesInfosClient.adresseComplement === '' ? '' : donneesInfosClient.adresseComplement.toUpperCase()}<br /> {donneesInfosClient.codePostal} {donneesInfosClient.ville && donneesInfosClient.ville.toUpperCase()}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bolder">{donneesInfosClient.titre} {donneesInfosClient.nom} {donneesInfosClient.prenom}</td>
                                            {Object.keys(donneesTarificateur).length !== 0 ?
                                                <td>Capital assuré {donneesTarificateur.exercice}</td>
                                                :
                                                <td>Capital assuré {donneesDecomptes.exercice}</td>
                                            }
                                            {Object.keys(donneesTarificateur).length !== 0 ?
                                                <td> {formatMillier(donneesTarificateur.capitalTotal)} €</td>
                                                :
                                                Object.keys(donneesDecomptes).length !== 0 ?
                                                    <td> {formatMillier(donneesDecomptes.totalCapitalAssure)} €</td>
                                                    :
                                                    <td>donnée non disponible</td>
                                            }
                                        </tr>
                                        <tr>
                                            <td>
                                            {
                                                donneesInfosClient.representantTitre + " " + donneesInfosClient.representantNom + " " + donneesInfosClient.representantPrenom
                                            }
                                            </td>
                                            {Object.keys(donneesTarificateur).length !== 0 ?
                                                <td>Surface assurée {donneesTarificateur.exercice}</td>
                                                :
                                                <td>Surface assurée {donneesDecomptes.exercice}</td>
                                            }
                                            {Object.keys(donneesTarificateur).length !== 0 ?
                                                <td>{formatMillier(formatDecimales(donneesTarificateur.surfaceTotale, 4))} ha</td>
                                                :
                                                Object.keys(donneesDecomptes).length !== 0 ?
                                                    <td>{formatMillier(formatDecimales(donneesDecomptes.totalSurface, 4))} ha</td>
                                                    :
                                                    <td>donnée non disponible</td>
                                            }
                                        </tr>
                                        <tr>
                                            <td>{donneesInfosClient.adresseNumero} {donneesInfosClient.adresseTypeVoie && donneesInfosClient.adresseTypeVoie.toUpperCase()} {donneesInfosClient.adresseVoie && donneesInfosClient.adresseVoie.toUpperCase()} {donneesInfosClient.adresseComplement === '' ? '' : donneesInfosClient.adresseComplement.toUpperCase()}<br /> {donneesInfosClient.codePostal} {donneesInfosClient.ville && donneesInfosClient.ville.toUpperCase()}
                                            </td>
                                            {Object.keys(donneesTarificateur).length !== 0 ?
                                                <td>Cotisations {donneesTarificateur.exercice}</td>
                                                :
                                                <td>Cotisations {donneesDecomptes.exercice}</td>
                                            }
                                            {Object.keys(donneesTarificateur).length !== 0 ?
                                                <td>{formatMillier(Math.round(donneesTarificateur.cotisationTtc))} €</td>
                                                // <td>{donneesContrat.packCode === "R3" ? (donneesTarificateur.niveau === "1" ? formatMillier(Math.round(donneesTarificateur.cotisationNiv1ApresSubvention)) : donneesTarificateur.niveau === "2" ? formatMillier(Math.round(donneesTarificateur.cotisationNiv2ApresSubvention)) : formatMillier(Math.round(donneesTarificateur.cotisationNiv3ApresSubvention))) : formatMillier(Math.round(donneesTarificateur.cotisationTtc))} €</td>
                                                :
                                                Object.keys(donneesDecomptes).length !== 0 ?
                                                    <td>{formatMillier(Math.round(donneesDecomptes.totalCotisation))} €</td>
                                                    :
                                                    <td>donnée non disponible</td>
                                            }
                                        </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}

TableMonContrat.propTypes = {
    donneesDecomptes: PropTypes.object
}
const mapStateToProps = (state) => {
    return {
        donneesDecomptes: decomptesSelectors.getHistoriqueDecomptesExerciceActif(state)
    }
}

export default connect(mapStateToProps)(TableMonContrat)