import * as actions from './metier/configurationActions'

const initialState = {}

 const majosMinos = (state = initialState, action) => {
	switch (action.type) {
		case actions.GET_MAJOS_MINOS: {
			return {
				...state
			}
		}
		case actions.GET_MAJOS_MINOS_SUCCESS :
			return action.majosMinos.reduce((acc, majoMino) => (
					{
						...acc,
						[majoMino.numero]: majoMino,
					}), {})
		case actions.GET_MAJOS_MINOS_ERROR:
			return state
		default:
			return state
	}
}

export default majosMinos