import React, { Component } from 'react'
import PropTypes from 'prop-types'
import connect from 'react-redux/es/connect/connect'
import {
	Col,
	Container, Modal, ModalBody, ModalHeader,
	Row
} from 'reactstrap'
import ReinitialisationForm from './ReinitialisationForm'
import * as loginAction from '../loginActions'
import qs from 'qs'
import * as messageActions from '../../global/component/messages/messageActions'
import { gotoLogin } from '../../containers/baseActions'

class ReinitialisationPage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			modalType: '',
			modalMessage: '',
			modalClasse: '',
			modalColor: '',
			modalTitle: '',
			isModalOpened: false
		}
		this.submitReinitialisationMotDePasseForm = this.submitReinitialisationMotDePasseForm.bind(this)
		this.messageErreur = this.messageErreur.bind(this)
		this.messageSuccess = this.messageSuccess.bind(this)
		this.closeModalMessage = this.closeModalMessage.bind(this)
	}

	messageErreur() {
		this.setState({
			modalTitle: 'Réinitialisation de mot de passe',
			modalClasse: 'icon-close-circle',
			modalColor: '#DC3149',
			modalMessage: 'Erreur lors de la réinitialisation. Votre lien est peut-etre expiré.\n' +
				'Veuillez effectuer une nouvelle demande.',
			isModalOpened: true
		})
	}

	messageSuccess() {
		this.setState({
			modalTitle: 'Réinitialisation de mot de passe',
			modalClasse: 'icon-check-circle',
			modalColor: '#1E8927',
			modalMessage: 'Votre nouveau mot de passe a bien été enregistré.',
			isModalOpened: true
		})
	}

	closeModalMessage() {
		this.setState({ isModalOpened: false })
		this.props.gotoLogin(this.props.location)
	}


	submitReinitialisationMotDePasseForm(data) {
		const token = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).token
		return this.props.reinitialiseMotDePasse(data, token)
			.then(
				this.messageSuccess()
			)
			.catch((e) => {
				this.messageErreur()
			})
	}


	render() {
		const { modalTitle, modalClasse, modalColor, modalMessage, isModalOpened } = this.state
		return (
			<Container className="reinitialisation-body d-flex">
				<Row className="m-auto">
					<Col className="d-flex m-auto" xs={12}>
						<div className="small reinitialisationPage">
							<ReinitialisationForm
								onSubmit={this.submitReinitialisationMotDePasseForm}
							/>
						</div>
					</Col>
				</Row>
				<Modal isOpen={isModalOpened} toggle={this.closeModalMessage} className={'small'}>
					<ModalHeader>{modalTitle}</ModalHeader>
					<ModalBody>
						<Row>
							<Col md={1}><span style={{ fontSize: '32px', color: modalColor }} className={`d-flex align-items-center h-100 ${modalClasse}`}> </span></Col>
							<Col md={11}><p className="d-flex align-items-center h-100">{modalMessage}</p></Col>
						</Row>
					</ModalBody>
				</Modal>
			</Container>
		)
	}
}

ReinitialisationPage.propTypes = {
	theme: PropTypes.string,
	params: PropTypes.object,
	location: PropTypes.object,
	reinitialiseMotDePasse: PropTypes.func.isRequired,
	messagesSucces: PropTypes.func,
	messagesErreur: PropTypes.func,
	gotoLogin: PropTypes.func

}

const mapStateToProps = (state) => ({})

const actions = ({
	reinitialiseMotDePasse: loginAction.reinitialiseMdp,
	messagesSucces: messageActions.messagesSucces,
	messagesErreur: messageActions.messagesErreur,
	gotoLogin: gotoLogin
})

export default connect(mapStateToProps, actions)(ReinitialisationPage)

