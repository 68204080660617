import * as ReactGA from 'react-ga'
import { getCookie } from '../../global/cookies/cookieUtils'
import * as api from './configurationApi'

export const GET_CONFIGURATION = 'GET_CONFIGURATION'
export const GET_CONFIGURATION_SUCCESS = 'GET_CONFIGURATION_SUCCESS'
export const GET_CONFIGURATION_ERROR = 'GET_CONFIGURATION_ERROR'

export const GET_LIBELLES = 'GET_LIBELLES'
export const GET_LIBELLES_SUCCESS = 'GET_LIBELLES_SUCCESS'
export const GET_LIBELLES_ERROR = 'GET_LIBELLES_ERROR'

export const GET_MAJOS_MINOS = 'GET_MAJOS_MINOS'
export const GET_MAJOS_MINOS_SUCCESS = 'GET_MAJOS_MINOS_SUCCESS'
export const GET_MAJOS_MINOS_ERROR = 'GET_MAJOS_MINOS_ERROR'

export const GET_QUESTIONS = 'GET_QUESTIONS'
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS'
export const GET_QUESTIONS_ERROR = 'GET_QUESTIONS_ERROR'

export const GET_ECHEANCIER = 'GET_ECHEANCIER'
export const GET_ECHEANCIER_SUCCESS = 'GET_ECHEANCIER_SUCCESS'
export const GET_ECHEANCIER_ERROR = 'GET_ECHEANCIER_ERROR'

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR'

export const GET_ASSO_NOTIFICATIONS = 'GET_ASSO_NOTIFICATIONS'
export const GET_ASSO_NOTIFICATIONS_SUCCESS = 'GET_ASSO_NOTIFICATIONS_SUCCESS'
export const GET_ASSO_NOTIFICATIONS_ERROR = 'GET_ASSO_NOTIFICATIONS_ERROR'

export const DELETE_ASSO_NOTIFICATIONS = 'DELETE_ASSO_NOTIFICATIONS'
export const DELETE_ASSO_NOTIFICATIONS_SUCCESS = 'DELETE_ASSO_NOTIFICATIONS_SUCCESS'
export const DELETE_ASSO_NOTIFICATIONS_ERROR = 'DELETE_ASSO_NOTIFICATIONS_ERROR'


export const getConfiguration = () => dispatch => {
	dispatch({ type: GET_CONFIGURATION })
	api.getConfiguration()
		.then(reponse => {
			dispatch({
				type: GET_CONFIGURATION_SUCCESS,
				payload: reponse
			})
			if(getCookie('isGoogleAnalyticsAuthorized')) {
				if (reponse != null && reponse.GOOGLE_ANALYTICS_TRACKING_ID != null) {
					ReactGA.initialize(reponse.GOOGLE_ANALYTICS_TRACKING_ID)
					ReactGA.pageview(window.location.pathname + window.location.search)
				}
			}
		})
		.catch(e => {
			dispatch({ type: GET_CONFIGURATION_ERROR })
			console.log(e)
		})
}

export const getLibelles = () => dispatch => {
	dispatch({type : GET_LIBELLES})
	return new Promise((resolve,reject) => {
		return api.getLibelles()
			.then(response => {
				if (response.status === 200) {
					resolve(response.json())
				} else {
					reject(response)
				}
			})
	}).then(response => {
		return setLibelles(response)(dispatch)
	})
}
export const setLibelles = (libelles) => (dispatch) => {
	dispatch({
		type: GET_LIBELLES_SUCCESS,
		libelles
	})
}

export const getMajosMinos = () => dispatch => {
	dispatch({type: GET_MAJOS_MINOS})
	return new Promise((resolve,reject) => {
		return api.getMajosMinos()
			.then(response => {
				if (response.status === 200) {
					resolve(response.json())
				} else {
					reject(response)
				}
			})
	}).then(response => {
	return setMajosMinos(response)(dispatch)
})
}

export const setMajosMinos = (majosMinos) => (dispatch) => {
	dispatch({
		type: GET_MAJOS_MINOS_SUCCESS,
		majosMinos
	})
}

export const getQuestions = () => dispatch => {
	dispatch({type: GET_QUESTIONS})
	return new Promise((resolve,reject) => {
		return api.getQuestions()
			.then(response => {
				if (response.status === 200) {
					resolve(response.json())
				} else {
					reject(response)
				}
			})
	}).then(response => {
		return setQuestions(response)(dispatch)
	})
}

export const setQuestions = (questions) => (dispatch) => {
	dispatch({
		type: GET_QUESTIONS_SUCCESS,
		questions
	})
}

export const getEcheancier = () => dispatch => {
	dispatch({type: GET_ECHEANCIER})
	return new Promise((resolve,reject) => {
		return api.getEcheancier()
			.then(response => {
				if (response.status === 200) {
					resolve(response.json())
				} else {
					reject(response)
				}
			})
	}).then(response => {
		return setEcheancier(response)(dispatch)
	})
}

export const setEcheancier = (echeancier) => (dispatch) => {
	dispatch({
		type: GET_ECHEANCIER_SUCCESS,
		echeancier
	})
}

export const getNotifications = () => dispatch => {
    dispatch({type: GET_NOTIFICATIONS})
    return new Promise((resolve,reject) => {
        return api.getNotifications()
            .then(response => {
                if (response.status === 200) {
                    resolve(response.json())
                } else {
                    reject(response)
                }
            })
    }).then(response => {
        return setNotifications(response)(dispatch)
    })
}

export const setNotifications = (notifications) => (dispatch) => {
    dispatch({
        type: GET_NOTIFICATIONS_SUCCESS,
        notifications
    })
}

export const getAssoNotifications = () => dispatch => {
    dispatch({type: GET_ASSO_NOTIFICATIONS})
    return new Promise((resolve,reject) => {
        return api.getAssoNotifications()
            .then(response => {
                if (response.status === 200) {
                    resolve(response.json())
                } else {
                    reject(response)
                }
            })
    }).then(response => {
        return setAssoNotifications(response)(dispatch)
    })
}

export const setAssoNotifications = (assoNotifications) => (dispatch) => {
    dispatch({
        type: GET_ASSO_NOTIFICATIONS_SUCCESS,
        assoNotifications
    })
}

export const deleteAssoNotification = (id) => dispatch => {
    dispatch({type: DELETE_ASSO_NOTIFICATIONS})
    return new Promise((resolve,reject) => {
        return api.deleteAssoNotifications(id)
            .then(response => {
                if (response.status === 200) {
                    resolve(response.json())
                } else {
                    reject(response)
                }
            })
    }).then(response => {
        return setAssoNotifications(response)(dispatch)
    })
}
