import React from 'react'
import {Col, Input, Row} from 'reactstrap'

const RenderSearchField = ({ input, placeholder, search }) => {
    return (
        <Row>
            <Col lg={11} md={11} sm={12} xs={12}>
                <Input {...input} value={search} type="text" placeholder={placeholder}/>
                <span className="icon-search" style={{right:'20px',top:'8px'}}> </span>
            </Col>
        </Row>
    )
}
export default RenderSearchField