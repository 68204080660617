import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { compose } from 'redux'
import * as configurationSelectors from '../../config/metier/configurationSelectors'
import EspaceAssureMessages from '../../config/metier/EspaceAssureMessages'
import AgenceDashboard from '../../modules/agence/AgenceDashboard'
import ContratDashboard from '../../modules/contrat/ContratDashboard'
import * as selectors from '../../modules/contrat/contratSelectors'
import MultiContratsDashboard from '../../modules/contrat/multiContrats/MultiContratsDashboard'
import CoordonneesDashboard from '../../modules/coordonnees/CoordonneesDashboard'
import MeteoDashboard from '../../modules/meteo/MeteoDashboard'
import NotificationDashboard from '../../modules/notification/NotificationDashboard'
import SinistreDashboard from '../../modules/sinistre/SinistreDashboard'
import { isContratEnInstanceResiliation, isContratEnInstanceSuspensionOuContencieux, isContratSuspenduOuContencieux } from '../../utils'
import * as messageActions from '../component/messages/messageActions'

class Dashboard extends React.Component {
	constructor(props) {
		super(props)

		this.toggle = this.toggle.bind(this)
		this.state = {
			isOpen: false
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.contratActif !== this.props.contratActif) {
			var contrat = nextProps.getDonneesContratByContrat(nextProps.contratActif)
			var d = new Date(contrat.etatDate)
			var datestring = d.getDate()  + "/" + (d.getMonth()+1) + "/" + d.getFullYear()
			if (isContratEnInstanceResiliation(contrat.etatCode, contrat.etatDate)) {
				nextProps.messagesAlerte('Votre espace client', 'Votre contrat est en instance de résiliation. Dès la prise d’effet de cette résiliation (au ' +
					datestring + '), vous n’aurez plus accès à l’espace Client. En cas d’erreur sur cette procédure de résiliation, veuillez contacter votre agence.')
			} else if (isContratEnInstanceSuspensionOuContencieux(contrat.etatCode, contrat.etatDate)) {
				nextProps.messagesAlerte('Votre espace client', 'Votre contrat est en instance de suspension. Dès la prise d’effet de cette suspension (au ' +
					datestring +'), vous aurez toujours accès à votre espace client mais vous ne pourrez plus déclarer de sinistre. En cas d’erreur sur cette procédure de suspension, veuillez contacter votre agence.')
			} else if (isContratSuspenduOuContencieux(contrat.etatCode, contrat.etatDate)) {
				nextProps.messagesAlerte('Votre espace client', 'Votre contrat a été suspendu. Vous ne pouvez plus déclarer de sinistre. En cas d’erreur sur cette procédure de suspension, veuillez contacter votre agence.')
			}
		}
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		})
	}

	render() {
		const { contratListe, notifications } = this.props
		const isMulti = contratListe.length > 1
		return (
			<div className="background-dashboard">
				<div className="bg-image-primary bannerSize theme-color"></div>
				<Container className="wrapper-dashboard">
					<Row>
						<Col className="wrapper-main-title">
							<EspaceAssureMessages id="accueil_titre" />
						</Col>
					</Row>
					<Row className={`${notifications.length !== 0 && 'row-with-notifications'}`}>
						<Col md="12" lg="9">
							<Row>
								<Col className="mb-3" md="6">
									{isMulti ? <MultiContratsDashboard baseLocation={`${this.props.location.pathname}`} /> :
										<ContratDashboard baseLocation={`${this.props.location.pathname}`} />}
								</Col>
								<Col className="mb-3" md="6">
									<AgenceDashboard baseLocation={`${this.props.location.pathname}`} />
								</Col>
								<Col className="mb-3" md="6">
									<SinistreDashboard baseLocation={`${this.props.location.pathname}`} />
								</Col>
								<Col className="mb-3" md="6">
									<MeteoDashboard baseLocation={`${this.props.location.pathname}`} />
								</Col>
							</Row>
						</Col>
						<Col className="position-initial" md="12" lg="3">
							<Row>
								<Col xs="12" className={`notifications ${notifications.length > 0 && 'mb-3'}`}>
									<NotificationDashboard thematiques="" baseLocation={`${this.props.location.pathname}`} />
								</Col>
								<Col xs="12">
									<CoordonneesDashboard baseLocation={`${this.props.location.pathname}`} />
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

Dashboard.propTypes = {
	messagesAlerte: PropTypes.func,
	contratListe: PropTypes.array,
	getDonneesContratByContrat: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		contratListe: selectors.getContratsNonResiliesListe(state),
		contratActif: selectors.getContratActif(state),
		getDonneesContratByContrat: selectors.getDonneesContratByContrat(state),
		notifications: configurationSelectors.getNotificationsTraitees(state)
	}
}

const actions = {
	messagesAlerte: messageActions.messagesAlerte
}

export default compose(connect(mapStateToProps, actions))(Dashboard)