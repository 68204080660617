import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import LoaderGeneral from '../../global/component/loader/LoaderGeneral'
import MiniPaper from '../../global/component/miniPaper/MiniPaper'
import ShadowScrollbars from '../../global/component/scrollbar/ShadowScrollbar'
import { isEtatResilieOuSuspenduOuContentieux } from '../../utils'
import * as contratSelectors from '../contrat/contratSelectors'
import * as coordonneesSelectors from '../coordonnees/coordonneesSelectors'
import LigneDeclarationPODashboard from './LigneDeclarationPODashboard'
import LigneSinistreDashboard from './LigneSinistreDashboard'
import * as sinistreActions from './sinistreActions'
import * as selectors from './sinistreSelectors'


class SinistreDashboard extends React.Component {
	constructor(props) {
		super(props)
		this.toggle = this.toggle.bind(this)
		this.state = {
			tooltipOpen: {}
		}
		this.redirectToTarget = this.redirectToTarget.bind(this)
		this.goToSinistre = this.goToSinistre.bind(this)
	}

	redirectToTarget = (path) => {
		this.props.history.push(path)
	}

	toggle(numerosinistre) {

		this.setState({
			tooltipOpen: {
				...this.state.tooltipOpen,
				[numerosinistre]: this.state.tooltipOpen[numerosinistre] ? !this.state.tooltipOpen[numerosinistre] : true
			}
		})
	}

	goToSinistre(nSinistre) {
		this.props.setSinistreActif(nSinistre)
		this.redirectToTarget(`${this.props.baseLocation}/sinistre/mesSinistres/suivreSinistre?idDeclarationSinistre=` + nSinistre.idDeclarationSinistre + `&exercice=` + nSinistre.exercice + `&sinistre=` + nSinistre.numeroSinistre + `&date=` + nSinistre.dateSinistre.substring(0, 10))
	}

	render() {
		const { donneesSinistre, isUserAllowed, baseLocation, isLoading,getDonneesContratByContrat,contratActif } = this.props
		let isSinistres = false
		donneesSinistre.forEach(sinistre => {
			if ((sinistre.declaration.typeDeclaration !== 'PO' && (sinistre.sinistre.etatSinistre !== 'S' && !((sinistre.sinistre.etatSinistre === 'D' || sinistre.sinistre.etatSinistre === 'I') && sinistre.statutQuittanceIndemnite === 'REG') && !sinistre.quittance.flQuittanceSignee)) || (sinistre.declaration.typeDeclaration === 'PO')) {
				isSinistres = true
			}
		})
		return (
			<MiniPaper titre={'Mes sinistres en cours'} className={`mon-sinistre ${!isSinistres ? 'vide' : ''}`} type={'primary'}
			           icone={'icon icon-thunderstorm'}
			           isUserAllowed={isUserAllowed}
			           boutons={[{
				           texte: 'Voir tous mes sinistres', icone: 'icon icon-search', couleur: 'vert', action: () => {
					           this.redirectToTarget(`${baseLocation}/sinistre/mesSinistres`)
				           }
			           },
				           {
					           texte: 'Déclarer un sinistre', icone: 'icon icon-thunderstorm', couleur: 'vert',
					           disabled: isEtatResilieOuSuspenduOuContentieux(getDonneesContratByContrat(contratActif).etatCode), action: () => {
						           this.redirectToTarget(`${baseLocation}/sinistre/declarerUnSinistre`)
					           }
				           }]}>
				{isLoading ?
					(
						<div style={{ height: 228, marginBottom: 32 }} className="d-flex align-items-center justify-content-center">
							<LoaderGeneral />
						</div>
					)
					:
					((isSinistres) ?
						<ShadowScrollbars
							style={{ height: 228, marginBottom: 32 }}
							className="list-group"
							onScrollStart={this.handleScrollStart}
						>
							{donneesSinistre.map((sinistre, index) => {
								if (sinistre.declaration.typeDeclaration !== 'PO') {
									return (
										<LigneSinistreDashboard
											key={`sinistre-dashboard-${sinistre.declaration.idDeclarationSinistre}`}
											sinistre={sinistre}
											isOpen={this.state.tooltipOpen[sinistre.declaration.idDeclarationSinistre] || false}
											target={`Tooltip${sinistre.declaration.idDeclarationSinistre}`}
											toggle={() => {
												this.toggle(sinistre.declaration.idDeclarationSinistre)
											}}
											goToSinistre={this.goToSinistre}
										/>)
								} else {
									return (
										<LigneDeclarationPODashboard
											key={`declaration-dashboard-${sinistre.declaration.idDeclarationSinistre}`}
											declaration={sinistre}
											isOpen={this.state.tooltipOpen}
											target={`Tooltip${sinistre.declaration.idDeclarationSinistre}`}
											toggle={this.toggle}
											goToDeclaration={this.goToSinistre}
										/>)
								}
							})}
						</ShadowScrollbars>
						:
						<div className="sinistre-noresult">
							<div className="img-umbrella"></div>
							<p className="font-weight-bold m-0">Vous n'avez actuellement aucun dossier en cours.</p>
							<p style={{ marginBottom: 0 }}> En cas de sinistre, nous sommes là pour vous : déclarez votre
								sinistre en ligne 24h/24, 7j/7</p>
						</div>)
				}
			</MiniPaper>
		)
	}
}

SinistreDashboard.propTypes = {
	isUserAllowed: PropTypes.bool,
	donneesSinistre: PropTypes.array,
	baseLocation: PropTypes.string.isRequired,
	setSinistreActif: PropTypes.func,
	isLoading: PropTypes.bool,
    getDonneesContratByContrat: PropTypes.func,
    contratActif: PropTypes.string
}

const mapStateToProps = (state) => {
	return {
		isUserAllowed: coordonneesSelectors.isDeclarationSinistreAllowed(state),
		getDonneesContratByContrat: contratSelectors.getDonneesContratByContrat(state),
        contratActif: contratSelectors.getContratActif(state),
        donneesSinistre: selectors.getDonneesSinistre(state),
		isLoading: selectors.getSinitreLoading(state)
	}
}
const actions = {
	setSinistreActif: sinistreActions.setSinistreActif
}
export default compose(
	connect(mapStateToProps, actions),
	withRouter
)(SinistreDashboard)
