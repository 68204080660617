import * as actions from './metier/configurationActions'

const initialState = []

const assoNotifications = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_ASSO_NOTIFICATIONS: {
            return {
                ...state
            }
        }
        case actions.GET_ASSO_NOTIFICATIONS_SUCCESS :
            return action.assoNotifications.reduce((acc, assoNotification) => (
                {
                    ...acc,
                    [assoNotification.id]: assoNotification,
                }), {})
        case actions.GET_ASSO_NOTIFICATIONS_ERROR:
            return state
        default:
            return state
    }
}

export default assoNotifications