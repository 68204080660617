import React from 'react'
import {Input, FormFeedback, Label, Col, Row} from 'reactstrap';

const InputNumberForm = ({marge='mt-4',maxLength,labelSize,placeholder, input, label, type, info, meta: {touched, error}}) => {
    if (input.value.length > maxLength) {
        input.value = input.value.slice(0, maxLength)
    }
    if (isNaN(parseInt(input.value.slice(input.value.length-1, input.value.length)))) {
        input.value = input.value.slice(0, input.value.length-1)
    }
    return (
    <Row form className={marge}>
        <Col md={4}>
            {label && <Label style={{ fontSize: labelSize ? labelSize : 'inherit' }} className="c-primary font-weight-bold">{label}<b className="c-red"> *</b></Label>}
        </Col>
        <Col md={8}>
            <div>
                <Input {...input} maxLength={maxLength} invalid={touched && !!error} style={{width: '100%'}} placeholder={placeholder} type={type}  />
                <FormFeedback valid={!(touched && error)}>
                    <div style={{display: 'flex'}}>
                        <p style={{fontSize: '12px'}}>{error}</p>
                    </div>
                </FormFeedback>
            </div>
        </Col>
    </Row>
    )
}

export default InputNumberForm