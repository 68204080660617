import * as Gp from 'geoportal-extensions-openlayers'
import { Feature } from 'ol'
import Point from 'ol/geom/Point'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import Map from 'ol/Map'
import { fromLonLat } from 'ol/proj'
import OSM from 'ol/source/OSM'
import VectorSource from 'ol/source/Vector'
import { Icon, Style } from 'ol/style'
import View from 'ol/View'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button } from 'reactstrap'
import Col from 'reactstrap/es/Col'
import Row from 'reactstrap/es/Row'
import { compose } from 'redux'
import Paper from '../../global/component/paper/Paper'
import icon from '../../img/pin.png'
import { usePrevious } from '../../utils'
import * as messagerieSelectors from '../messagerie/messagerieselectors'
import * as agenceAction from './agenceActions'
import * as agenceSelectors from './agenceSelectors'

const ContacterMonAgence = ({ agence, location, history, apiKey, getCoordonnees, nombreMessagesNonLus }) => {

	const [isLoading, setIsLoading] = React.useState(true)
	const previousDonneesAgence = usePrevious(agence)

	const redirectToTarget = (path) => {
		history.push(path)
	}

	const initMap = () => {
		let lon = parseFloat(agence.longitude)
		let lat = parseFloat(agence.latitude)
		let centre = fromLonLat([lon, lat])
		let view = new View({
			center: centre,
			zoom: 16
		})
		let map = new Map({
			target: 'map',
			layers: [
				new TileLayer({
					preload: 10,
					source: new OSM()
				})
			],
			loadTilesWhileAnimating: true,
			view: view
		})
		var source = new VectorSource({
			wrapX: false
		})
		var vector = new VectorLayer({
			source: source
		})
		map.addLayer(vector)
		var geom = new Point(fromLonLat([lon, lat]))
		var feature = new Feature(geom)
		var iconStyle = new Style({
			image: new Icon({
				anchor: [0.5, 46],
				anchorXUnits: 'fraction',
				anchorYUnits: 'pixels',
				src: icon
			})
		})
		feature.setStyle(iconStyle)

		source.addFeature(feature)
		setIsLoading(false)
	}

	const getConfigMap = () => {
		Gp.Services.getConfig({
			apiKey: apiKey,
			onSuccess: initMap
		})
	}

	React.useEffect(() => {
			if (apiKey && agence.longitude && agence.latitude && (isLoading ||
				(agence.longitude !== previousDonneesAgence.longitude && agence.latitude !== previousDonneesAgence.latitude))) {
				setTimeout(() => {
						getConfigMap()
					}
					, 500)
			} else if ((agence.ville && agence.codePostal) &&
				(agence.ville !== '' && agence.codePostal !== '') &&
				(agence.ville !== previousDonneesAgence.ville && agence.codePostal !== previousDonneesAgence.codePostal)) {
				getCoordonnees(agence.ville, agence.codePostal)
			}
		}
		, [agence, apiKey, isLoading])

	return (
		<Paper className="push-tpl-1 contacterAgence">
			<Row className={'push-head'}>
				<Col>
					<Row className={'mb-5'}>
						<Col className={'titre3'}>
							Contacter mon agence
						</Col>
					</Row>
					<Row className={'mb-4'}>
						<Col lg={3} sm={12} md={3} xs={12}>
							<Row className={'ml-2-xs mb-3'}>
								<Col className={'titre1'}>
									{agence.nom}
								</Col>
							</Row>
							<Row className={'ml-2-xs mb-3'}>
								<Col className={'titre2'}>
									ORIAS : {agence.orias}
								</Col>
							</Row>
							<Row className={'ml-2-xs mb-2'}>
								<Col>
									{agence.adresse1}
								</Col>
							</Row>
							<Row className={'ml-2-xs mb-3'}>
								<Col>
									{agence.codePostal} {agence.ville}
								</Col>
							</Row>
							<Row className={'ml-2-xs mb-5'}>
								<Col>
									Tél. : {agence.telephone}
								</Col>
							</Row>
							<Row className={'ml-2-xs mb-4 d-flex justify-content-center'}>
								<Col>
									<Button onClick={() => {
										redirectToTarget(`${location.pathname.replace('/aideEtContact/contacterMonAgence','/services/messagerie/nouveauMessage')}`)
									}} outline size="md" color="light"
									        className="d-inline-flex align-items-center justify-content-center btn-push-tpl btn-block">
										<div className="icon icon-mail"></div>
										<div className="wording">Envoyer un message</div>
									</Button>
								</Col>
							</Row>
							<Row className={'ml-2-xs mb-4 d-flex justify-content-center'}>
								<Col>
									<Button onClick={() => {
										redirectToTarget(`${location.pathname.replace('/aideEtContact/contacterMonAgence','/services/messagerie/nouveauMessage?provenance=G')}`)
									}} outline size="md" color="light"
									        className="d-inline-flex align-items-center justify-content-center btn-push-tpl btn-block">
										<div className="icon icon-calendar-2"></div>
										<div className="wording">Demander une rendez-vous</div>
									</Button>
								</Col>
							</Row>
							<Row className={'ml-2-xs mb-4 d-flex justify-content-center'}>
								<Col>
									<Button onClick={() => {
										redirectToTarget(`${location.pathname.replace('/aideEtContact/contacterMonAgence','/services/messagerie/consultation')}`)
									}} outline size="md" color="light"
									        className="d-inline-flex align-items-center justify-content-center btn-push-tpl btn-block">
										<div className="icon icon-mail">
                                            {nombreMessagesNonLus !== 0 &&
                                            <div style={{top:'6px'}} className="notification">{nombreMessagesNonLus}</div>
                                            }
                                        </div>
										<div className="wording">Consulter mes messages</div>
									</Button>
								</Col>
							</Row>
						</Col>
						<Col lg={9} sm={12} md={9} xs={12} className={'d-flex justify-content-center align-items-center'}>
							<Row className={'h-100 w-100'}>
								<Col lg={12} sm={12} md={12}>
									<div id="map"></div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</Paper>
	)
}
ContacterMonAgence.propTypes = {
	agence: PropTypes.object,
	apiKey: PropTypes.string,
	getCoordonnees: PropTypes.func
}
const mapStateToProps = (state) => {
	return {
		agence: agenceSelectors.getDonneesAgence(state),
		apiKey: state.configuration.GEOPORTAIL_API_KEY,
		nombreMessagesNonLus: messagerieSelectors.getNombreDiscussionsNonLues(state)
	}
}
const actions = ({
	getCoordonnees: agenceAction.getMap
})
export default compose(connect(mapStateToProps, actions), withRouter)(ContacterMonAgence)