import React from 'react'
import { Col, FormFeedback, Input, Row } from 'reactstrap'

const RenderNumberField = ({ className, parcelle, input, label, name, placeholder, info, meta: { touched, error } }) => {
	if (isNaN(parseInt(input.value.slice(input.value.length - 1, input.value.length))) && input.value.indexOf(',') === -1) {
		input.value = input.value.slice(0, input.value.length - 1)
	}
	if (input.value.indexOf(',') !== -1) {
		if (input.value.substring(input.value.indexOf(','), input.value.indexOf(',').length).length > 5) {
			input.value = input.value.slice(0, input.value.length - 1)
		}
	}
	return (
		<Row className={className}>
			<Col lg={8} md={8} sm={12} xs={12}>
				<div className="d-inline-flex justify-content-center align-items-center" style={{width: '100%'}}>
					<Input {...input} invalid={touched && !!error} style={{ width: '100%' }} placeholder={placeholder}
					       type="text"
					/>&nbsp;ha
				</div>
				<FormFeedback valid={!(touched && error)} style={{ display: (error && touched) ? 'block' : 'none' }}>
					<div style={{ display: 'flex', color: 'red' }}>
						<p style={{ fontSize: '12px' }}>{error}</p>
					</div>
				</FormFeedback>
			</Col>
		</Row>
	)
}
export default RenderNumberField