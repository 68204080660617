import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import Paper from '../../../global/component/paper/Paper'
import LigneQuittanceIndemnite from './LigneQuittanceIndemnite'
import * as quittanceIndemniteSelectors from './quittanceIndemnite/quittanceIndemniteSelectors'
import { downloadFile } from '../../../fileUtils'
import * as contratActions from '../../contrat/contratActions'
import * as messageActions from '../../../global/component/messages/messageActions'

const IndemnisationsHeader = ({ listeQuittances, downloadQuittancementIndemnite, messagesErreur }) => {

	function downloadDocumentIndemnite(numeroContrat, exercice){
		try { return downloadFile(numeroContrat, "quittance-indemnite", downloadQuittancementIndemnite(numeroContrat, exercice))}
		catch(e){ return messagesErreur("Consulter la version intégrale de la quittance d'indemnité","Le service de téléchargement de vos documents est actuellement indisponible. Veuillez réessayer ultérieurement.")}
	}

	if (Object.getOwnPropertyNames(listeQuittances).length > 0) {
		const listeAllQuittances = Object.keys(listeQuittances).reduce((acc, numero)  => acc.concat(listeQuittances[numero]), [])
		return (
			<Row>
				<Col lg="12" className="mb-3">
					<Paper className="push-tpl-1 mb-3">
						<Row className="push-head">
							<Col>
								<Row style={{ marginBottom: 25 }}>
									<Col className="d-flex align-items-center">
										<div className="title">
											Liste des quittances d'indemnité
										</div>
									</Col>
								</Row>
								<Row className="assolement-content p-0">
									<Col sm={12}>
										{listeAllQuittances.map((data, index) =>
											<LigneQuittanceIndemnite donwloadQuittance={()=> downloadDocumentIndemnite(data.contrat, data.exercice)} key={`quittance${index}`} contrat={parseInt(data.contrat)} quittance={data} />
										)}
									</Col>

								</Row>
							</Col>
						</Row>
					</Paper>
				</Col>
			</Row>
		)
	} else return null
}
IndemnisationsHeader.propTypes = {
	listeQuittances: PropTypes.object,
	downloadQuittancementIndemnite:PropTypes.func,
	messagesErreur:PropTypes.func
}
const mapStateToProps = (state) => {
	return {
		listeQuittances: quittanceIndemniteSelectors.getQuittancementIndemniteParContrat(state)
	}
}

const actions = {
	downloadQuittancementIndemnite: contratActions.downloadQuittancementIndemnite,
	messagesErreur:messageActions.messagesErreur
}
export default connect(mapStateToProps, actions)(IndemnisationsHeader)