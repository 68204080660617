import React from 'react'
import {Button, Col, Input, Label, Row} from 'reactstrap'

const RenderFileMessage = ({libelle, label, sousTitre, addDeleteFieldDoc, classButton, hideButton, input: {value: omitValue, onChange, onBlur, ...inputProps}}) => {

    const adaptFileEventToValue = (delegate) => e => {
        e.persist()
        delegate(e.target.files)
    };

    return (
        <Row className="declaration-sinistre-complement" style={{marginBottom: 40}}>
            <Col>
                {libelle &&
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        {label && <Label style={{marginBottom: 0}} className="c-primary ft-s-14">{label}</Label>}
                    </Col>
                </Row>
                }
                {libelle &&
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        {sousTitre && <Label className="ft-s-12 ft-s-allianz"
                                             style={{color: '#414141', fontWeight: 'normal'}}>{sousTitre}</Label>}
                    </Col>
                </Row>
                }
                <Row>
                    <Col lg={hideButton ? 12 : 11} md={hideButton ? 12 : 11} sm={hideButton ? 12 : 11}
                         xs={hideButton ? 12 : 11}>
                        <Input className="bc-lighten-1" onChange={adaptFileEventToValue(onChange)}
                               onBlur={adaptFileEventToValue(onBlur)}
                               style={{padding: 5, border: '2px solid', borderRadius: 6}} type="file"
                               accept={".pdf, .jpg, .jpeg, .png, .bmp"}
                        />
                    </Col>
                    {!hideButton &&
                    <Col lg={1} md={1} sm={1} xs={1}
                         className={"d-flex justify-content-center align-items-center"}>
                        <Button onClick={addDeleteFieldDoc}
                                className={"d-flex justify-content-center align-items-center btn-plus btn-light"}><i
                            style={{color: "white"}}
                            className={classButton + " d-flex c-primary"}> </i></Button>
                    </Col>
                    }
                </Row>
            </Col>
        </Row>
    )
}
export default RenderFileMessage