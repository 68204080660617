import * as actions from './../../sinistreActions'

const initialStateQuittancement = {
	isLoading: false,
	quittancementIndemniteParContrat: {},
}


const quittancementIndemnite = (state = initialStateQuittancement, action) => {
	switch (action.type) {
		case actions.GET_QUITTANCES_INDEMNITES: {
			return {...state, isLoading: true}
		}
		case actions.GET_QUITTANCES_INDEMNITES_SUCCES: {
			const keys = Object.keys(action.payload);
			const quittancesNonNulles = {}
			for (let i = 0; i < keys.length; i++) {
				const key = parseInt(keys[i])
				if(quittancesNonNulles[key.toString()] === undefined ){
					if (action.payload[keys[i]].length !== 0){
						quittancesNonNulles[key.toString()]=action.payload[keys[i]]
					}
				} else if (action.payload[keys[i]].length !== 0){
					quittancesNonNulles[key.toString()]=action.payload[keys[i]]
				}
			}
			action.payload = quittancesNonNulles
			return {
				...state,
				quittancementIndemniteParContrat: quittancesNonNulles,
				isLoading: false
			}
		}
		case actions.GET_QUITTANCES_INDEMNITES_ERROR: {
			return {
				...state,
				isLoading: false
			}
		}
		default:
			return state
	}
}

export default quittancementIndemnite