import * as coordonneesActions from '../modules/coordonnees/coordonneesActions'
import * as agenceActions from '../modules/agence/agenceActions'
import * as assolementActions from '../modules/contrat/assolement/assolementActions'
import * as tarificateurActions from '../modules/contrat/tarificateur/tarificateurActions'
import * as contratActions from '../modules/contrat/contratActions'
import * as sinistreActions from './sinistre/sinistreActions'
import * as messagerieActions from './messagerie/messagerieActions'
import * as decomptesActions from '../modules/contrat/decomptesProvisoires/decomptesActions'
import * as quittancementActions from '../modules/contrat/quittancement/quittancementActions'
import * as configurationActions from '../config/metier/configurationActions'

// Fonction qui appelle les actions des différents modules pour charger séquentiellements les données métiers de l'utilisateur
export const getDonneesExtranet = () => dispatch => {
	coordonneesActions.getInfoUser()(dispatch)
	configurationActions.getLibelles()(dispatch)
	configurationActions.getMajosMinos()(dispatch)
	configurationActions.getQuestions()(dispatch)
	configurationActions.getEcheancier()(dispatch)
    configurationActions.getNotifications()(dispatch)
    configurationActions.getAssoNotifications()(dispatch)
	agenceActions.getAgence()(dispatch)
	assolementActions.getAssolementExtranet(false)(dispatch)
	assolementActions.getAssolementExtranet(true)(dispatch)
	sinistreActions.getSinistres()(dispatch)
	sinistreActions.getListeCultures()(dispatch)
	sinistreActions.getQuittancesIndemnites()(dispatch)
	tarificateurActions.getTarificateurExtranet(false)(dispatch)
	tarificateurActions.getTarificateurExtranet(true)(dispatch)
	contratActions.getContrat()(dispatch)
	decomptesActions.getDecomptesExtranet(false)(dispatch)
	decomptesActions.getDecomptesExtranet(true)(dispatch)
	quittancementActions.getQuittancementExtranet()(dispatch)
	messagerieActions.getMessage()(dispatch)

}

export const getHistoriqueAssolement = () => dispatch => {
	assolementActions.getAssolementExtranet(true)(dispatch)
}

export const getHistoriqueDecomptes = () => dispatch => {
	decomptesActions.getDecomptesExtranet(true)(dispatch)
}

export const getHistoriqueTarificateur = () => dispatch => {
	tarificateurActions.getTarificateurExtranet(true)(dispatch)
}