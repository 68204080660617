import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Error404 from '../../global/error404/Error404'
import MessageriePage from './MessageriePage'
import NouveauMessagePage from './NouveauMessagePage'

export default ({ pathname, pathToHome }) => (
	<Switch>
		<Route path={`${pathname}/consultation`} component={MessageriePage} />
		<Route path={`${pathname}/nouveauMessage`} component={NouveauMessagePage} />
		<Route path={`${pathToHome}/404`} component={Error404} />
		<Redirect from={`${pathname}/*`} to={`${pathToHome}/404`} />
	</Switch>
)
