import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Field, reduxForm} from 'redux-form'
import InputForm from '../../global/component/input/InputFormModale'
import {
		FormGroup,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button
} from 'reactstrap'
import {isPasswordValid} from "../../utils";

const validate = values => {
	const errors = {}
	const requiredFields = [
		'motDePasse',
		'validMotDePasse'
	]
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = 'Ce champ est obligatoire.'
		}
	})
	if (values.motDePasse && !isPasswordValid(values.motDePasse)) {
		errors.motDePasse = 'Mot de passe incorrect : il doit contenir au moins 10 caractères, dont au moins une minuscule, une majuscule et un chiffre.'
	}
	if (values.motDePasse !== values.validMotDePasse) {
		errors.validMotDePasse = 'La confirmation du mot de passe est incorrecte.'
	}
	return errors
}

class ReinitialisationPage extends Component {
	render() {
		const { pristine, submitting, handleSubmit } = this.props
		return (
			<form onSubmit={handleSubmit}>
				<ModalHeader>Réinitialisation de votre mot de passe</ModalHeader>
				<ModalBody>
					<p style={{fontSize:16}}>Votre mot de passe doit respecter les règles suivantes :<br/>
						- au moins 10 caractères,<br/>
						- au moins une minuscule,<br/>
						- au moins une majuscule,<br/>
						- et au moins un chiffre.
					</p>
					<FormGroup className="mb-0">
						<Field
							name="motDePasse"
							component={InputForm}
							type="password"
							labelSize={14}
							label="Nouveau mot de passe"
							info={""}
						/>
						<Field
							name="validMotDePasse"
							component={InputForm}
							type="password"
							labelSize={14}
							label="Confirmez votre nouveau mot de passe"
							info={""}
						/>
					</FormGroup>
					<label className="c-red"> </label>
				</ModalBody>
				<ModalFooter>
					<Button type="submit" disabled={pristine || submitting} color="light" size="sm"
							className="btn-block w-25 m-auto">Enregistrer</Button>
				</ModalFooter>
				<p className="notabene"><b className="c-red">* </b>Champ obligatoire</p>
			</form>
		)
	}
}

ReinitialisationPage.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
}

export default reduxForm({
	form: 'reinitialisation',
	validate,
	touchOnBlur: false
})(ReinitialisationPage)
