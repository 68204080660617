import {fetchFactory} from "../../entities/fetchEntities";

export const getContrat= () =>
    fetchFactory('/extranet/contrat', {method:'GET'})
        .then((response) => {
            if (response.ok) {
                return response
            } else {
                throw response
            }
        })

export const downloadAttestationAssurance = (numeroContrat) =>
    fetchFactory('/extranet/contrat/attestationAssurance', {
        method: 'POST',
        body: numeroContrat

    })

export const downloadDecompteProvisoire = (numeroContrat) =>
    fetchFactory('/extranet/contrat/decompteProvisoire', {
        method: 'POST',
        body: numeroContrat

    })

export const downloadConstatPerte = (idDocument, societe) =>(
    fetchFactory('/extranet/contrat/constatPerte?idDocument='+ idDocument+ '&societe=' + societe, {
        method: 'GET',
    })
        .then((response) => {
                if (response.ok) {
                    return response
                } else {
                    throw response
                }
            }
        )
)
export const downloadLettreDesistement= (idDocument) =>(
    fetchFactory('/extranet/contrat/lettreDesistement?idDocument='+ idDocument, {
        method: 'GET',
    })
        .then((response) => {
                if (response.ok) {
                    return response
                } else {
                    throw response
                }
            }
        )
)
export const downloadQuittancementIndemnite= (numeroContrat, exercice) =>(
    fetchFactory('/extranet/contrat/quittancementIndemnite?numeroContrat='+ numeroContrat + '&exercice=' + exercice, {
        method: 'GET',
    })
        .then((response) => {
                if (response.ok) {
                    return response
                } else {
                    throw response
                }
            }
        )
)

export const downloadQuittancementCotisation = (numeroContrat, exercice) =>(
    fetchFactory('/extranet/contrat/quittancementCotisation?numeroContrat='+ numeroContrat + '&exercice=' + exercice, {
        method: 'GET',
    })
        .then((response) => {
                if (response.ok) {
                    return response
                } else {
                    throw response
                }
            }
        )
)

export const downloadQuittancementAcompte= (numeroContrat, exercice, numeroQuittance, identifiantQuittance) =>(
    fetchFactory('/extranet/contrat/quittancementAcompte?numeroContrat='+ numeroContrat + '&exercice=' + exercice + '&numeroQuittance=' + numeroQuittance + '&identifiantQuittance=' + identifiantQuittance, {
        method: 'GET',
    })
        .then((response) => {
                if (response.ok) {
                    return response
                } else {
                    throw response
                }
            }
        )
)

export const downloadAttestationAssolement = (numeroContrat, exercice) =>
    fetchFactory('/extranet/contrat/attestationAssolement?numeroContrat=' + numeroContrat + '&exercice=' + exercice, {
        method: 'POST',
    })
export const downloadContrat = (numeroContrat) =>
    fetchFactory('/extranet/contrat/contratArchive', {
        method: 'POST',
        body: numeroContrat
    })
        .then((response) => {
            if(response.ok) {
                return response
            }
            else {
                throw response
            }
        })
