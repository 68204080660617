import * as actions from './metier/configurationActions'

const initialState = []

const echeancier = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_ECHEANCIER: {
            return {
                ...state
            }
        }
        case actions.GET_ECHEANCIER_SUCCESS :
            return action.echeancier.reduce((acc, echeancier) => (
                {
                    ...acc,
                    [echeancier.id]: echeancier,
                }), {})
        case actions.GET_ECHEANCIER_ERROR:
            return state
        default:
            return state
    }
}

export default echeancier