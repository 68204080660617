import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

class ModaleMentions extends Component {
    render() {
        const {isOpened, toggle, className, themeRurale} = this.props;
        return (
            <div>
                <div className='lien' onClick={toggle}>
                    Mentions Légales
                </div>
                {themeRurale ?
                <Modal isOpen={isOpened} toggle={toggle} className={className + " large"}>
                    <ModalHeader>Mentions légales du site larurale.fr (Société du Groupe Allianz)</ModalHeader>
                    <ModalBody>
                        <div>
                            <h4>Protection des données personnelles</h4>
                            <p>
                                Les informations personnelles recueillies feront l'objet d'un traitement automatisé ;
                                elles seront utilisées pour répondre aux différents services proposés par le site et,
                                avec accord exprès du client, pourront être utilisées par les différentes sociétés et
                                partenaires du groupe Allianz et leurs réseaux.
                            </p>
                            <p>
                                Conformément à la loi française n° 78-17 du 6 janvier 1978 relative à l'informatique,
                                aux fichiers et aux libertés, les internautes (clients ou prospects)
                                bénéficient d'un droit d'accès, de rectification et d'opposition relatif aux données le
                                concernant ; ce droit peut être exercé soit en adressant un mail à
                                l'adresse <a href="mailto:informatiqueetliberte@larurale.fr">informatiqueetliberte@larurale.fr</a>, soit en adressant un courrier auprès
                                de : La Rurale - Informatique et Libertés - Case courrier Y360 - Cœur Défense
                                92086 Paris La Défense Pour plus d'informations sur l'utilisation des cookies,
                            </p>
                        </div>
                        <div>
                            <h4>Informations sur les produits et services</h4>
                            <p>
                                Ces informations sont susceptibles d’évoluer à tout moment sans préavis.
                                La Rurale (Société du Groupe Allianz) s’efforcera d'assurer leur mise à jour au fur et à
                                mesure des évolutions apportées à
                                ses produits et services, ainsi que des évolutions législatives ou réglementaires
                                éventuelles. La Rurale (Société du Groupe
                                Allianz) s’engage à vous remettre sur simple demande,
                                les dispositions contractuelles en vigueur afférentes à ces produits et services.
                            </p>
                            <p>
                                La Rurale (Société du Groupe Allianz) ne pourra en aucun cas être tenu pour responsable
                                en cas de retard de mise à jour,
                                d'erreur ou d'omission quant au contenu des pages du site. La Rurale (Société du Groupe
                                Allianz) ne saurait être tenu pour
                                responsable des dommages directs et indirects résultant de l'accès ou de l'usage du
                                site.
                            </p>
                            <p>
                                Le site La Rurale (Société du Groupe Allianz) contient par ailleurs des liens
                                hypertextes orientant l’internaute vers
                                d’autres sites internet.
                            </p>
                            <p>
                                La Rurale (Société du Groupe Allianz) n’est pas responsable du contenu des informations
                                accessibles via son site au moyen
                                de ces liens hypertextes. Ainsi La Rurale (Société du Groupe Allianz) ne peut garantir
                                leur exactitude, mise à jour, caractère
                                actuel et loyal, qui relèvent de la seule et entière responsabilité des personnes
                                auteurs ou propriétaires de ces sites.
                            </p>
                            <p>
                                Les simulateurs mis en ligne sur le site La Rurale (Société du Groupe Allianz) ont été
                                conçus pour vous fournir des informations
                                ou des valeurs prévisionnelles, à titre indicatif. Les estimations sont calculées sur la
                                base des données de simulation communiquées
                                par l’internaute au jour de leur saisie et de la réglementation en vigueur à cette date,
                                mais ne sauraient constituer un indicateur
                                fiable, ni servir de justificatif vis-à-vis de toute Administration.
                            </p>
                        </div>
                        <div>
                            <h4>Avertissement</h4>
                            <p>
                                Les produits et services présentés sur le site sont destinés aux résidents français.
                            </p>
                            <p>
                                Le site et son contenu font l'objet d'une protection légale au titre de la propriété
                                littéraire et artistique (droits d'auteurs, des
                                interprètes, etc.) et de la propriété industrielle (marques, dessins, etc.).
                            </p>
                            <p>
                                Il est interdit à tout internaute de copier ou reproduire, par numérisation et tout
                                autre moyen, sur quelque support que ce soit, tout
                                ou partie du contenu du site, qu'il soit textuel, graphique ou sonore, sauf pour son
                                usage propre. Le site indique en particulier à l'internaute
                                les parties du contenu du site qu'il est autorisé à télécharger pour les besoins de sa
                                relation avec La Rurale (Société du Groupe Allianz).
                            </p>
                            <p>
                                Le non-respect de ces règles peut engager la responsabilité civile et pénale du
                                contrevenant.
                            </p>
                        </div>
                        <div>
                            <h4>Editeur</h4>
                            <p>
                                Ce site est produit sous l’autorité de La Rurale (Société du Groupe Allianz) : Siège
                                social Cœur Défense, Bâtiment 2, 82, Esplanade du Général De
                                Gaulle , 92400 Courbevoie, Compagnie d’assurances contre la grêle, S.A. Régie par le
                                Code des Assurances, capital de 960000 euros, N° 572 166 437 RCS Nanterre
                            </p>
                            <p>
                                Directeur de la publication : Pierre-Yves Alliot
                            </p>
                            <p>
                                Développement, maintenance et stockage des informations assurés par Ipsosenso, Siège
                                social 29, cité d'Antin 75009 Paris R.C.S. Paris B 524 354 685
                            </p>
                            <p>
                                Accueil La Rurale : 01 58 85 89 89 (du lundi au vendredi de 8h30 à 17h30).
                            </p>
                            <p>
                                Pour toute question concernant l’utilisation du site www.larurale.fr : envoyez un
                                courriel à <a href="mailto:gestion1@larurale.fr">gestion1@larurale.fr</a>
                            </p>
                        </div>
                        <div>
                            <h4>Coordonnées des organismes pour l'affiliation des agents</h4>

                            <p>
                                Organisme d'immatriculation : ORIAS, site internet www.orias.fr
                            </p>
                            <p>
                                Autorité de contrôle prudentiel et de résolution : ACPR - 61 rue Taitbout - 75436 Paris
                                CEDEX 09
                            </p>
                        </div>
                        <div>
                            <h4>Démarchage téléphonique</h4>
                            <p>
                                Si vous ne souhaitez pas faire l’objet de prospection commerciale par téléphone, vous
                                pouvez gratuitement vous inscrire sur
                                une liste d’opposition au démarchage téléphonique.
                            </p>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={toggle} color="light" size="lg" className="btn-block w-50 m-auto">C'est
                            compris</Button>
                    </ModalFooter>
                </Modal>
					:
					<Modal isOpen={isOpened} toggle={toggle} className={className + " large"}>
						<ModalHeader>Mentions légales du site Allianz.fr</ModalHeader>
						<ModalBody>
							<div>
								<h4>Protection des données personnelles</h4>
								<p>
									Les informations personnelles recueillies feront l'objet d'un traitement; elles seront utilisées pour répondre aux différents services proposés par le site et, avec accord exprès du client ou du prospect pourront être utilisées par les différentes sociétés et partenaires du groupe Allianz et leurs réseaux.
								</p>
								<p>
									Conformément à la loi française n° 78-17 du 6 janvier 1978, les internautes (clients ou prospects) bénéficient d'un droit d'accès, de rectification, d’effacement, de portabilité  et d'opposition relatif aux données le concernant. Pour  exercer ces droits ou contacter le responsable des données personnelles pour toute information complémentaire ou réclamation il vous suffit de nous écrire par mail à informatiqueetliberte@allianz.fr ou par courrier à l'adresse Allianz - Informatique et Libertés - Case courrier S1805 - 1 Cours Michelet - CS 30051 - 92076 Paris La Défense Cedex.
								</p>
							</div>
							<div>
								<h4>Informations sur les produits et services</h4>
								<p>
									Ces informations sont susceptibles d’évoluer à tout moment sans préavis.
									Allianz France s’efforcera d'assurer leur mise à jour au fur et à mesure des évolutions apportées à ses produits et services, ainsi que des évolutions législatives ou réglementaires éventuelles. Allianz France s’engage à vous remettre sur simple demande, les dispositions contractuelles en vigueur afférentes à ces produits et services.
								</p>
								<p>
									Allianz France ne pourra en aucun cas être tenu pour responsable en cas de retard de mise à jour, d'erreur ou d'omission quant au contenu des pages du site. Allianz France ne saurait être tenu pour responsable des dommages directs et indirects résultant de l'accès ou de l'usage du site.
								</p>
								<p>
									Le site Allianz France contient par ailleurs des liens hypertextes orientant l’internaute vers d’autres sites internet.
								</p>
								<p>
									Allianz France n’est pas responsable du contenu des informations accessibles via son site au moyen de ces liens hypertextes. Ainsi Allianz France ne peut garantir leur exactitude, mise à jour, caractère actuel et loyal, qui relèvent de la seule et entière responsabilité des personnes auteurs ou propriétaires de ces sites.
								</p>
								<p>
									Les simulateurs mis en ligne sur le site Allianz France ont été conçus pour vous fournir des informations ou des valeurs prévisionnelles, à titre indicatif. Les estimations sont calculées sur la base des données de simulation communiquées par l’internaute au jour de leur saisie et de la réglementation en vigueur à cette date, mais ne sauraient constituer un indicateur fiable, ni servir de justificatif vis-à-vis de toute Administration.

									Vous êtes invité à vous rapprocher d’un Conseiller Allianz pour toute demande de précision, communication d’informations complémentaires ou conseils sur les produits et services d’Allianz présentés sur le site.
								</p>
							</div>
							<div>
								<h4>Avertissement</h4>
								<p>
									Les produits et services présentés sur le site sont destinés aux résidents français.
								</p>
								<p>
									Le site et son contenu font l'objet d'une protection légale au titre de la propriété littéraire et artistique (droits d'auteurs, des interprètes...) et de la propriété industrielle (marques, dessins...).
								</p>
								<p>
									Il est interdit à tout internaute de copier ou reproduire, par numérisation et tout autre moyen, sur quelque support que ce soit, tout ou partie du contenu du site, qu'il soit textuel, graphique ou sonore, sauf pour son usage propre. Le site indique en particulier à l'internaute les parties du contenu du site qu'il est autorisé à télécharger pour les besoins de sa relation avec Allianz.
								</p>
								<p>
									Le non-respect de ces règles peut engager la responsabilité civile et pénale du contrevenant.
								</p>
							</div>
							<div>
								<h4>Editeur</h4>
								<p>
									Ce site est produit par les sociétés du groupe Allianz :
									- Allianz Vie, société anonyme au capital de 643 054 425 euros, Entreprise régie par le Code des Assurances, dont le siège social est situé
									1 cours Michelet - CS 30051 - 92076 Paris La Défense Cedex - 340 234 962 RCS Nanterre
									- Allianz IARD, société anonyme au capital de 991 967 200 euros, Entreprise régie par le Code des Assurances, dont le siège social est situé :
									1 cours Michelet - CS 30051 - 92076 Paris La Défense Cedex - 542 110 291 RCS Nanterre
									- Allianz Banque, société anonyme au capital de 92 252 768,19 euros, dont le siège social est situé :
									1 cours Michelet - 92800 Puteaux- 572 199 461 R.C.S. Nanterre
									- Protexia France, société anonyme au capital de 1.895.248 euros, Entreprise régie par le Code des assurances, dont le siège social est situé :
									1 cours Michelet - CS 30051 - 92076 Paris La Défense Cedex - 382 276 624 RCS Nanterre.
								</p>
								<p>
									Directeur de la publication : Jean Boucher
								</p>
								<p>
									Le stockage des informations est assuré par Allianz Informatique - GIE - N° 723 000 642 RCS Nanterre.
								</p>
								<p>
									Le serveur de données est situé à Allianz Managed Operations & Services SE | Department | Gutenbergstraße 8 | 85774 Unterföhring - Germany.
								</p>
								<p>
									Siège social : 1 cours Michelet - CS 30051 - 92076 Paris La Défense Cedex.
								</p>
								<p>
									Accueil Allianz : 01.58.85.15.00 (du lundi au vendredi de 8h30 à 18h30).
								</p>
								<p>
									Pour toute question concernant l’utilisation du site www.allianz.fr : complétez le formulaire de demande en ligne disponible ici.
								</p>
							</div>
							<div>
								<h4>Coordonnées des organismes pour l'affiliation des agents</h4>

								<p>
									Organisme d'immatriculation : ORIAS, site internet www.orias.fr.
								</p>
								<p>
									Autorité de contrôle prudentiel et de résolution : ACPR - 4 place de Budapest - CS 92459 - 75436 Paris Cedex 09.
								</p>
							</div>
							<div>
								<h4>Démarchage téléphonique</h4>
								<p>
									Si vous ne souhaitez pas faire l’objet de prospection commerciale par téléphone, vous pouvez gratuitement vous inscrire sur une liste d’opposition au démarchage téléphonique.
								</p>
							</div>
						</ModalBody>
						<ModalFooter>
							<Button onClick={toggle} color="light" size="lg" className="btn-block w-50 m-auto">C'est
								compris</Button>
						</ModalFooter>
					</Modal>
                }
            </div>
        );
    }
}

ModaleMentions.propTypes = {
    isOpened: PropTypes.bool,
    toggle: PropTypes.func.isRequired

};

export default ModaleMentions;