import React from 'react'
import { Col, FormFeedback } from 'reactstrap'

const RenderFalseField = ({ meta: { touched, error } }) => {
	return (
		<Col lg={12} md={12} sm={12} xs={12}>
			<FormFeedback valid={!(touched && error)} style={{ display: (error && touched) ? 'block' : 'none' }}>
				<div style={{ display: 'flex' }}>
					<p style={{ fontSize: '12px' }}>{error}</p>
				</div>
			</FormFeedback>
		</Col>
	)
}
export default RenderFalseField