import React from "react";
import { Col, Row } from 'reactstrap'
import Paper from '../../../global/component/paper/Paper'

const IndemnisationsHeader = () => {
	return(
		<Paper className="push-tpl-1">
			<Row className="push-head">
				<Col>
					<Row style={{marginBottom:49}}>
						<Col className="d-flex align-items-center">
							<div className="title">
								Consulter mes indemnisations
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg="10">
							<p style={{paddingLeft:16}} className="ft-s-15 m-0">Vous pouvez consulter ici l'historique de vos quittances d'indemnités, sur les 5 dernières années.<br/>
							Pour consulter l'intégralité de vos dossiers de sinistre, y compris ceux en cours, reportez vous à la rubrique Suivre mes sinistres.</p>
						</Col>
					</Row>
				</Col>
			</Row>
		</Paper>
	)
}

export default IndemnisationsHeader