import React from 'react'
import { Button, Col, Row, Tooltip } from 'reactstrap'
import { formatDateSinistre, formatDate, supprimeZerosContratString } from '../../../utils'


class LigneSinistre extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			tooltipOpen: false
		}
	}

	render() {
		const { sinistre, isOpen, toggle, target, contratListe, donneesContrat, goToSinistre } = this.props
		if (sinistre.sinistre.etatSinistre !== "S" && !((sinistre.sinistre.etatSinistre === "D" || sinistre.sinistre.etatSinistre === "I") && sinistre.statutQuittanceIndemnite === "REG") && !sinistre.quittance.flQuittanceSignee) {
			return (
				<Button onClick={() => {goToSinistre(sinistre.sinistre)}}>
					<Row className="table-item liste-sinistre-en-cours">
						<Col xs="1" className="text-left">
							{(sinistre.sinistre.etatSinistre === 'E' || sinistre.sinistre.etatSinistre === 'P' || (sinistre.sinistre.etatSinistre === '*' && sinistre.sinistre.typeDeclaration !== 'PO')) ?
								<div>
									<span className="icon icon-expertise" id={target}> </span>
									<Tooltip placement="top" isOpen={isOpen} target={target} toggle={toggle}>
										Dossier en phase d’expertise
									</Tooltip>
								</div> : null}
							{(sinistre.sinistre.etatSinistre === 'D' || sinistre.sinistre.etatSinistre === 'I') ?
								<div>
									<span className="icon icon-coins-money" id={target}> </span>
									<Tooltip placement="right" isOpen={isOpen} target={target} toggle={toggle}>
										Dossier en cours de calcul d’indemnité
									</Tooltip>
								</div> : null}
							{(sinistre.sinistre.etatSinistre === '') ?
								<div>
									<span className="icon icon-file" id={target}> </span>
									<Tooltip placement="right" isOpen={isOpen} target={target} toggle={toggle}>
										Déclaration « pour information »
									</Tooltip>
								</div> : null}
						</Col>
						<Col xs="10" className="d-flex flex-column align-items-start">
							<div style={{marginBottom:8,lineHeight:'20px'}} className="ft-s-allianz-semi-bold ft-s-16">
								Sinistre N° {sinistre.sinistre.numeroSinistre} du {formatDateSinistre(sinistre.sinistre.dateSinistre)} - {sinistre.libellePeril}
							</div>
							{contratListe.length > 1 ?
								<div className="ft-s-14" style={{ color: '#5F6368', lineHeight: '16px' }}>Déclaré le {formatDate(sinistre.sinistre.dateEnregistrement)} (sur contrat N° {supprimeZerosContratString(sinistre.declaration.contrat)} :
									{donneesContrat.packCode === "A1" ? " Assurance des récoltes - Grêle, Tempête) " : " Garantie multirisques des récoltes)"}
								</div> :
								<div className="ft-s-14" style={{ color: '#5F6368', lineHeight: '16px' }}>Déclaré le {formatDate(sinistre.sinistre.dateEnregistrement)}</div>
							}
						</Col>
						<Col xs="1" className="text-right p-0">
							<span className="icon icon-chevron-right"> </span>
						</Col>
					</Row>
				</Button>
			)
		} else return null

	}
}

export default LigneSinistre