import {Col, Row} from "reactstrap";
import React from "react";
import EspaceAssureMessages from '../../../config/metier/EspaceAssureMessages'
import Paper from "../../../global/component/paper/Paper";
import { formatDateSinistre, formatDateSinistrePO, formatDateTime } from '../../../utils'

const EtatduSinistre = ({ sinistreActif, sinistreDeclaration, sinistreSinistre, sinistreLibellePerils, sinistreQuittance }) => {
    let isCloture = false
    if(sinistreQuittance.length > 0){
        isCloture = sinistreQuittance[0].flQuittanceSignee
    }
    return (
        <Paper className="push-tpl-1 etatSinistre">
            <Row className="push-head">
                <Col>
                    <Row className="mb-5">
                        <Col className="d-flex align-items-center">
                            {sinistreDeclaration.typeDeclaration === "PO" ?
                                <div className="title">
                                    Sinistre du {formatDateSinistrePO(sinistreDeclaration.dateSinistre)} - {sinistreLibellePerils}
                                </div>
                                :
                                <div className="title">
                                    Sinistre N°{sinistreSinistre.numeroSinistre} du {formatDateSinistre(sinistreSinistre.dateSinistre)} - {sinistreLibellePerils}
                                </div>
                            }
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md={2} sm={2} xs={2}>
                            <Row className=" d-flex justify-content-center"><Col md={12} sm={12} xs={12} className="d-flex justify-content-center"><span className="icon icon-file iconEtatSinistreActif m-0"></span></Col></Row>
                            <Row className="justify-content-center"><Col md={12} sm={12} xs={12} className="p-0"><div className="sousIconActif"></div></Col></Row>
                            <Row className="justify-content-center"><Col md={12} sm={12} xs={12} className={`text-center sousTitreIconActif mt-2 ${sinistreDeclaration.typeDeclaration === "PO" && 'enCours'}`}>Déclaration</Col></Row>
                        </Col>
                        {(isCloture || (sinistreDeclaration.typeDeclaration !== "PO" && (sinistreSinistre.etatSinistre === "E" || sinistreSinistre.etatSinistre === "P" || sinistreSinistre.etatSinistre === "*") || ((sinistreSinistre.etatSinistre === "D" || sinistreSinistre.etatSinistre === "I") && sinistreActif.statutQuittanceIndemnite !== "REG") || ((sinistreSinistre.etatSinistre === "D" || sinistreSinistre.etatSinistre === "I") && sinistreActif.statutQuittanceIndemnite === "REG") || (sinistreSinistre.etatSinistre === "S"))) ?
                            <Col md={2} sm={2} xs={2}>
                                <Row className="justify-content-center">
                                    <Col md={12} sm={12} xs={12} className="d-flex justify-content-center"><span className="icon icon-expertise iconEtatSinistreActif m-0"></span></Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col md={12} sm={12} xs={12} className="p-0"><div className="sousIconActif"></div></Col>
                                </Row>
                                {(sinistreSinistre.etatSinistre === "E" || sinistreSinistre.etatSinistre === "P" || sinistreSinistre.etatSinistre === "*") ?
                                    <Row className="justify-content-center">
                                        <Col md={12} sm={12} xs={12} className="text-center sousTitreIconActif mt-2 enCours">Expertise</Col>
                                    </Row>
                                    :
                                    <Row className="justify-content-center">
                                        <Col md={12} sm={12} xs={12} className="text-center sousTitreIconActif mt-2 ">Expertise</Col>
                                    </Row>
                                }
                            </Col>
                            :
                            <Col md={2} sm={2} xs={2}>
                                <Row className="justify-content-center">
                                    <Col md={12} sm={12} xs={12} className="d-flex justify-content-center"><span className="icon icon-expertise iconEtatSinistre m-0"></span></Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col md={12} sm={12} xs={12} className="p-0"><div className="sousIcon"></div></Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col md={12} sm={12} xs={12} className="text-center sousTitreIcon mt-2">Expertise</Col>
                                </Row>
                            </Col>
                        }
                        {(isCloture || (sinistreDeclaration.typeDeclaration !== "PO" && (((sinistreSinistre.etatSinistre !== 'S' && !(sinistreSinistre.etatSinistre === 'D' && sinistreActif.statutQuittanceIndemnite === 'REG')) && (sinistreSinistre.etatSinistre === 'D' || sinistreSinistre.etatSinistre === 'I')) || (sinistreSinistre.etatSinistre === "S" || (sinistreSinistre.etatSinistre === "D" && sinistreActif.statutQuittanceIndemnite === "REG") && (sinistreSinistre.etatSinistre === "D")) || (sinistreSinistre.etatSinistre === "S")))) ?
                            <Col md={2} sm={2} xs={2}>
                                <Row className="justify-content-center">
                                    <Col md={12} sm={12} xs={12} className="d-flex justify-content-center"><span className="icon icon-coins-money iconEtatSinistreActif m-0"></span></Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col md={12} sm={12} xs={12} className="p-0"><div className="sousIconActif"></div></Col>
                                </Row>
                                {((sinistreSinistre.etatSinistre !== 'S' && !(sinistreSinistre.etatSinistre === 'D' && sinistreActif.statutQuittanceIndemnite === 'REG')) && (sinistreSinistre.etatSinistre === 'D' || sinistreSinistre.etatSinistre === 'I')) ?
                                    <Row className="justify-content-center">
                                        <Col md={12} sm={12} xs={12} className="text-center sousTitreIconActif enCours mt-2">Indemnisation</Col>
                                    </Row>
                                    :
                                    <Row className="justify-content-center">
                                    <Col md={12} sm={12} xs={12} className="text-center sousTitreIconActif mt-2">Indemnisation</Col>
                                    </Row>
                                }
                            </Col>
                            :
                            <Col md={2} sm={2} xs={2}>
                                <Row className="justify-content-center">
                                    <Col md={12} sm={12} xs={12} className="d-flex justify-content-center"><span className="icon icon-coins-money iconEtatSinistre m-0"></span></Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col md={12} sm={12} xs={12} className="p-0"><div className="sousIcon"></div></Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col md={12} sm={12} xs={12} className="text-center sousTitreIcon mt-2">Indemnisation</Col>
                                </Row>
                            </Col>
                        }
                        {(isCloture || (sinistreDeclaration.typeDeclaration !== "PO" && (sinistreSinistre.etatSinistre === "S" || (sinistreSinistre.etatSinistre === "D" && sinistreActif.statutQuittanceIndemnite === "REG") && (sinistreSinistre.etatSinistre === "D") || (sinistreSinistre.etatSinistre === "S")))) ?
                            <Col md={2} sm={2} xs={2}>
                                <Row className="justify-content-center">
                                    <Col md={12} sm={12} xs={12} className="d-flex justify-content-center"><span className="icon icon-survey iconEtatSinistreActif m-0"></span></Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col md={12} sm={12} xs={12} className="p-0"><div className="sousIconActif"></div></Col>
                                </Row>
                                {!isCloture ?
                                    <Row className="justify-content-center">
                                        <Col md={12} sm={12} xs={12} className={"text-center sousTitreIconActif mt-2 enCours"}>Traité</Col>
                                    </Row>
                                    :
                                    <Row className="justify-content-center">
                                        <Col md={12} sm={12} xs={12} className="text-center sousTitreIconActif mt-2">Traité</Col>
                                    </Row>
                                }
                            </Col>
                            :
                            <Col md={2} sm={2} xs={2}>
                                <Row className="justify-content-center">
                                    <Col md={12} sm={12} xs={12} className="d-flex justify-content-center"><span className="icon icon-survey iconEtatSinistre m-0"></span></Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col md={12} sm={12} xs={12} className="p-0"><div className="sousIcon"></div></Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col md={12} sm={12} xs={12} className="text-center sousTitreIcon mt-2">Traité</Col>
                                </Row>
                            </Col>
                        }
                        {(!isCloture && sinistreDeclaration.typeDeclaration !== "PO" && sinistreSinistre.etatSinistre === "S") ?
                            <Col md={2} sm={2} xs={2}>
                                <Row className="justify-content-center">
                                    <Col md={12} sm={12} xs={12} className="d-flex justify-content-center"><span className="icon icon-cross-triangle iconEtatSinistreActif m-0"></span></Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col md={12} sm={12} xs={12} className="p-0"><div className="sousIconActif"></div></Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col md={12} sm={12} xs={12} className="text-center sousTitreIconActif mt-2 enCours">Désistement</Col>
                                </Row>
                            </Col>
                            :
                            (isCloture ?
                                <Col md={2} sm={2} xs={2}>
                                    <Row className="justify-content-center">
                                        <Col md={12} sm={12} xs={12} className="d-flex justify-content-center"><span className="icon icon-check-circle-o iconEtatSinistreActif m-0"></span></Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col md={12} sm={12} xs={12} className="p-0"><div className="sousIconActif"></div></Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col md={12} sm={12} xs={12} className="text-center sousTitreIconActif mt-2 enCours">Cloturé</Col>
                                    </Row>
                                </Col>
                                :
                                <Col md={2} sm={2} xs={2}>
                                    <Row className="justify-content-center">
                                        <Col md={12} sm={12} xs={12} className="d-flex justify-content-center"><span className="icon icon-check-circle-o iconEtatSinistre m-0"></span></Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col md={12} sm={12} xs={12} className="p-0"><div className="sousIcon"></div></Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col md={12} sm={12} xs={12} className="text-center sousTitreIcon mt-2">Cloturé</Col>
                                    </Row>
                                </Col>
                            )
                        }
                    </Row>
                    <Row className="mt-4">
                        <Col className="" lg="auto" md="auto" sm="auto" xs="auto">
                            {sinistreDeclaration.typeDeclaration === "PO" ?
                                <span
                                    className={`d-flex justify-content-start align-items-center h-100 icon-info-circle infoCircleJaune`}
                                >
							 </span>
                                :
                                <span
                                    className={`d-flex justify-content-start align-items-center h-100 icon-info-circle infoCircle infoCircle`}
                                >
							 </span>
                            }
                        </Col>
                        {sinistreDeclaration.typeDeclaration === "PO" &&
                            <Col>
                                <Row>
                                    <Col md={12} sm={12} xs={12} className="d-flex align-items-center h-100">
                                        <div className="ft-s-allianz-bold"><EspaceAssureMessages id="messinistres_declaration_etat_po"/></div>
                                    </Col>
                                    <Col md={12} sm={12} xs={12} className="d-flex align-items-center h-100">
                                        Vous pouvez la compléter et la confirmer jusqu'au {formatDateTime(sinistreDeclaration.dateConfirmation)}.<br/>
                                        Au delà de cette date, et sans confirmation de votre part, cette déclaration sera automatiquement annulée.
                                    </Col>
                                </Row>
                            </Col>
                        }
                        {(sinistreDeclaration.typeDeclaration !== "PO" && (sinistreSinistre.etatSinistre === "E" || sinistreSinistre.etatSinistre === "P"  || sinistreSinistre.etatSinistre === "*") && !isCloture) &&
                            <Col>
                                <Row>
                                    <Col md={12} sm={12} xs={12} className="d-flex align-items-center h-100">
                                        <div className="ft-s-allianz-bold"><EspaceAssureMessages id="messinistres_declaration_etat_expertise"/></div>
                                    </Col>
                                    <Col md={12} sm={12} xs={12} className="d-flex align-items-center h-100">
                                        Une fois la phase d’expertise terminée, il passera à l’étape d’évaluation de votre
                                        indemnisation.
                                    </Col>
                                </Row>
                            </Col>
                        }
                        {(sinistreDeclaration.typeDeclaration !== "PO" && ((sinistreSinistre.etatSinistre !== 'S' && !(sinistreSinistre.etatSinistre === 'D' && sinistreActif.statutQuittanceIndemnite === 'REG')) && (sinistreSinistre.etatSinistre === 'D' || sinistreSinistre.etatSinistre === 'I')) && !isCloture) &&
                        <Col>
                            <Row>
                                <Col md={12} sm={12} xs={12} className="d-flex align-items-center h-100">
                                    <div className="ft-s-allianz-bold"><EspaceAssureMessages id="messinistres_declaration_etat_indemnisation"/></div>
                                </Col>
                                <Col md={12} sm={12} xs={12} className="d-flex align-items-center h-100">
                                    Pour information : les règlements sont effectués à partir du mois de novembre.
                                </Col>
                            </Row>
                        </Col>
                        }
                        {(sinistreDeclaration.typeDeclaration !== "PO" && ((sinistreSinistre.etatSinistre === "D" || sinistreSinistre.etatSinistre === "I") && sinistreActif.statutQuittanceIndemnite === "REG") && !isCloture) &&
                        <Col>
                            <Row>
                                <Col md={12} sm={12} xs={12} className="d-flex align-items-center h-100">
                                    <div className="ft-s-allianz-bold"><EspaceAssureMessages id="messinistres_declaration_etat_traite"/></div>
                                </Col>
                                <Col md={12} sm={12} xs={12} className="d-flex align-items-center h-100">
                                    Votre quittance d'indemnité est disponible ci-dessous.
                                </Col>
                            </Row>
                        </Col>
                        }
                        {(sinistreDeclaration.typeDeclaration !== "PO" && ((sinistreSinistre.etatSinistre === "D" || sinistreSinistre.etatSinistre === "I") && sinistreActif.statutQuittanceIndemnite === "REG") && isCloture) &&
                        <Col>
                            <Row>
                                <Col md={12} sm={12} xs={12} className="d-flex align-items-center h-100">
                                    <div className="ft-s-allianz-bold"><EspaceAssureMessages id="messinistres_declaration_etat_cloture"/></div>
                                </Col>
                                <Col md={12} sm={12} xs={12} className="d-flex align-items-center h-100">
                                    Vous avez bien retourné votre quittance d'indemnité signée.
                                </Col>
                            </Row>
                        </Col>
                        }
                        {(sinistreDeclaration.typeDeclaration !== "PO" && sinistreSinistre.etatSinistre === "S") &&
                            <Col>
                                <Row>
                                    <Col md={12} sm={12} xs={12} className="d-flex align-items-center h-100">
                                        <div className="ft-s-allianz-bold"><EspaceAssureMessages id="messinistres_declaration_etat_desistement"/></div>
                                    </Col>
                                    <Col md={12} sm={12} xs={12} className="d-flex align-items-center h-100">
                                        Votre lettre de désistement est disponible ci-dessous.
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </Row>
                </Col>
            </Row>
        </Paper>
    )
}
export default EtatduSinistre
