import React from "react";
import MiniPaper from "../../../global/component/miniPaper/MiniPaper";
import {Col, Row} from "reactstrap";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import * as selectors from "../../agence/agenceSelectors";
import {withRouter} from "react-router";
import {compose} from "redux";


class MonAgence extends React.Component{

    redirectToTarget = (path) => {
        this.props.history.push(path)
    }

    render() {
        const {donneesAgence, provenance} = this.props
        return (
            <MiniPaper titre={"Mon agence"}
                       className={"mon-agence"}
                       sizeTitre={20}
                       type={"secondary"}
                       boutons={[{
                           texte: "Envoyer un message",
                           icone: 'icon icon-mail',
                           disabled:false,
                           action: () => {
                               this.redirectToTarget(`${window.location.pathname.substring(0,16) + `/services/messagerie/nouveauMessage`}`)
                           }
                       }, { texte: "Demander un rendez-vous",
                            disabled:false,
                           action: () => {
                               this.redirectToTarget(`${window.location.pathname.substring(0,16) + `/services/messagerie/nouveauMessage?provenance=${provenance}`}`)
                           },
                            icone: 'icon icon-calendar-2'}]}>
                <Row>
                    <Col className="push-body-top">
                        <div className="content">
                            <p style={{fontSize:16,lineHeight:'20px',marginBottom:17}} className="ft-s-allianz-semi-bold text-uppercase">{donneesAgence.titre} {donneesAgence.nom} {donneesAgence.prenom}</p>
                            <p style={{fontSize:14,lineHeight:'17px',marginBottom:12}} className={"text-uppercase"}>{donneesAgence.adresse1} <br/>{donneesAgence.codePostal} {donneesAgence.ville}</p>
                            <p style={{fontSize:14,lineHeight:'17px',marginBottom:0}} className="ft-s-allianz-semi-bold">Tél : {donneesAgence.telephone}</p>
                        </div>
                    </Col>
                </Row>
            </MiniPaper>
        )
    }
}

MonAgence.propTypes = {
    donneesAgence: PropTypes.object
}

const mapStateToProps = (state) => {
    return {
        donneesAgence: selectors.getDonneesAgence(state)
    }
}
export default compose(connect(mapStateToProps), withRouter)(MonAgence)