import * as api from './inscriptionAPI'

export const inscriptionUtilisateur = (data) => dispatch => {
	return api.inscriptionUtilisateur(data)
		.then(response => {
			if (response.status === 200) {
				return "OU"
			} else if (response.status === 206) {
				return "AO"
			} else {
				throw response
			}
		})

}