import { createSelector } from 'reselect'
import { getContratActif } from '../contratSelectors'

const getLocalState = (state) => state.metier.contrat.assolement

const getListeAssolementsParContrat = (state) => {
	return getLocalState(state).assolementParContrat
}

export const getExerciceActifAssolement = (state) => {
	return getHistoriqueAssolementActif(state).exerciceActif || 0
}

const getHistoriqueAssolementParContrat = (state) => {
	return getLocalState(state).historiqueAssolementParContrat || []
}

export const getHistoriqueAssolementActif = createSelector(getHistoriqueAssolementParContrat,getContratActif,
	(historiqueAssolementParContrat, contratActif ) => historiqueAssolementParContrat[contratActif] || {})

export const getHistoriqueAssolementExerciceActif = createSelector(getHistoriqueAssolementActif,getExerciceActifAssolement,
	(historiqueAssolementParContrat, exerciceActif ) => historiqueAssolementParContrat[exerciceActif] || {})

export const getAssolementActif = createSelector(getListeAssolementsParContrat , getContratActif,
	(assolementParContrat, contratActif) => assolementParContrat[contratActif] || {})


export const getAssolementParContrat = state => contrat => getListeAssolementsParContrat(state)[contrat] || {}

export const getHistoriqueParcellesAssolementActif = (state) => getHistoriqueAssolementExerciceActif(state).parcelles || []

export const getParcellesAssolementActif = (state) => getAssolementActif(state).parcelles || []

export const getCommuneRisque = (state) => {
	let assolementActif = getAssolementActif(state)
	if (Object.values(assolementActif).length !== 0){
		let communeCapital = Object.keys(assolementActif.parcelles).reduce((acc, key) => {
			return{
				...acc,
				[assolementActif.parcelles[key].libelleCommune] : acc[assolementActif.parcelles[key].libelleCommune] ? parseInt(acc[assolementActif.parcelles[key].libelleCommune] + parseInt(assolementActif.parcelles[key].capitalAssure)).toString() + "," + assolementActif.parcelles[key].departement + assolementActif.parcelles[key].commune : parseInt(assolementActif.parcelles[key].capitalAssure).toString() + "," + assolementActif.parcelles[key].departement + assolementActif.parcelles[key].commune,
			}
		}, {})
		let communeRisque = Object.keys(communeCapital)[0]
		let codePostalRisque = communeCapital[Object.keys(communeCapital)[0]].split(",")[1]
		let capitalRisque = communeCapital[Object.keys(communeCapital)[0]].split(",")[0]
		Object.keys(communeCapital).forEach((key) => {
			if (parseInt(communeCapital[key]) > parseInt(capitalRisque)){
				capitalRisque = communeCapital[key].split(",")[0]
				communeRisque = key
				codePostalRisque = communeCapital[key].split(",")[1]
			}
		})
		let objetReponse = {
			communeRisque:communeRisque,
			codePostalRisque:codePostalRisque
		}
		return objetReponse
	}
	return {}
}