import { MESSAGE_SUCCESS, MESSAGE_ERROR, MESSAGE_WARNING, MESSAGE_INFO, CLOSE_MESSAGE, MESSAGE_SUCCESS_BLOCKED, MESSAGE_ERROR_BLOCKED } from './messageActions'

const initialState = {
	modaleOpened:false,
	modaleType: '',
	modaleTexte: '',
	modaleTitre: '',
	modaleTexteButton: '',
}

const message = (state = initialState, action) => {

	switch(action.type){
		case MESSAGE_SUCCESS:
			return {
				modaleOpened: true,
				modaleTexte: action.payload.texte,
				modaleTitre: action.payload.titre,
				modaleType: 'Succes'
			}
		case MESSAGE_SUCCESS_BLOCKED:
			return {
				modaleOpened: true,
				modaleTexte: action.payload.texte,
				modaleTitre: action.payload.titre,
				modaleTexteButton: action.payload.texteButton,
				modaleType: 'SuccesAvecBouton'
			}
		case MESSAGE_ERROR:
			return {
				modaleOpened: true,
				modaleTexte: action.payload.texte,
				modaleTitre: action.payload.titre,
				modaleType: 'Erreur'
			}
		case MESSAGE_ERROR_BLOCKED:
			return {
				modaleOpened: true,
				modaleTexte: action.payload.texte,
				modaleTitre: action.payload.titre,
				modaleTexteButton: action.payload.texteButton,
				modaleType: 'ErreurAvecBouton'
			}
		case MESSAGE_WARNING:
			return {
				modaleOpened: true,
				modaleTexte: action.payload.texte,
				modaleTitre: action.payload.titre,
				modaleType: 'Alerte'
			}
		case MESSAGE_INFO:
			return {
				modaleOpened: true,
				modaleTexte: action.payload.texte,
				modaleTitre: action.payload.titre,
				modaleType: 'Information'
			}
		case CLOSE_MESSAGE:
			return initialState
		default:
			return state
	}
}

export default message