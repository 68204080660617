import * as coordonnesApi from './coordonneesApi'

export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR'
export const GET_USER_INFO = 'GET_USER_INFO'

export const GET_USER_INFO_SUCCES = 'GET_USER_INFO_SUCCES'

export const getInfoUser = () => dispatch => {
	dispatch({type: GET_USER_INFO})
	return new Promise((resolve, reject) => {
		return coordonnesApi.getCoordonnees()
			.then(response => {
				if (response.status === 200) {
					resolve(response.json())
				} else {
					reject(response)
				}
			})
	}).then(response => {
		return setInfoUser(response.infoUser,response.isDeclarationSinistreAllowed)(dispatch)
	})
}

export const setInfoUser = (infoUser,isDeclarationSinistreAllowed) => (dispatch) => {
    dispatch({
        type: GET_USER_INFO_SUCCES,
        infoUser,
	    isDeclarationSinistreAllowed
    })
};