import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button } from 'reactstrap'
import { compose } from 'recompose'
import { Field, reduxForm } from 'redux-form'
import LoaderButton from '../../../global/component/loader/LoaderButton'
import * as assolementSelector from '../../../modules/contrat/assolement/assolementSelectors'
import * as tarificateurSelectors from '../../contrat/tarificateur/tarificateurSelectors'
import RenderSelectField from '../../sinistre/declarationSinistre/CustomFields/RenderSelectField'
import * as messagerieActions from '../messagerieActions'
import RenderEditor from './customFields/RenderEditor'
import RenderFileMessage from './customFields/RenderFileMessage'
import RenderTextFieldMessage from './customFields/RenderTextFieldMessage'
import {setContratActif} from "../../contrat/contratActions";

const SIZE_MAX = 5000000
const validate = (values) => {
	const errors = {}
	const requiredFields = [
		'objet',
		'contrat'
	]
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = 'Ce champ est obligatoire'
		}
	})
	if (values['file'] && values['file'][0] && values['file'][0].size > SIZE_MAX) {
				errors['file'] = 'Le fichier ne doit pas faire plus de 5 MO'
	}
	if (values['file2'] && values['file2'][0] && values['file2'][0].size > SIZE_MAX) {
		errors['file2'] = 'Le fichier ne doit pas faire plus de 5 MO'
	}
	if (values['file3'] && values['file3'][0] && values['file3'][0].size > SIZE_MAX) {
		errors['file3'] = 'Le fichier ne doit pas faire plus de 5 MO'
	}
	return errors
}


const FormNouveauMessage = ({ contratSelected, setContratSelected, goToMessage, isLoading, handleSubmit, donneesContrat, donneesTarificateur, submitFailed, pristine, submitting, change }) => {

	const [visibleDoc, setVisibleDoc] = React.useState(1)

	const addFieldDoc = () => {
		setVisibleDoc(visibleDoc + 1)
	}

	const deleteFieldDoc = (numberFileField) => {
		setVisibleDoc(visibleDoc - 1)
		change(numberFileField, null)
	}

	const changeContrat = (event) => {
		setContratSelected(event.target.value)
	}

	return (
		<form onSubmit={handleSubmit} className="w-100">
			<div className="form-group m-0 sinistre">
				{donneesContrat.length > 1 &&
				<div>
					<Field
						name="contrat"
						fullWidth={true}
						component={RenderSelectField}
						label="Votre message est relatif à quel contrat ?"
						placeholder="Sélectionnez un contrat"
						setContratActif={changeContrat}
						donneesContrat={donneesContrat}
						donneesTarificateur={donneesTarificateur}
						id={'contrat'}
					/>
				</div>
				}
				{contratSelected &&
				<div>
					<div>
						<Field
							name="objet"
							component={RenderTextFieldMessage}
							label="Objet"
						/>
					</div>
					<div>
						<Field
							name="message"
							component={RenderEditor}
							label="Message"
							placeholder="Saisissez ici le texte de votre message .."
							newMessage={true}
						/>
					</div>
					<div>
						<Field
							name="file"
							libelle={true}
							component={RenderFileMessage}
							type="file"
							addDeleteFieldDoc={() => {
								visibleDoc === 1 ? addFieldDoc() : visibleDoc === 2 ? deleteFieldDoc('file2') : deleteFieldDoc('file3')
							}}
							classButton={visibleDoc === 1 ? 'icon-plus' : 'icon-minus'}
							label="Joindre un document"
							sousTitre="(Taille maximum : 5Mo / formats acceptés : .pdf, .jpg, .jpeg, .png, .bmp)"
						/>
					</div>
					{(visibleDoc === 2 || visibleDoc === 3) &&
					<div>
						<Field
							name="file2"
							libelle={false}
							component={RenderFileMessage}
							addDeleteFieldDoc={() => {
								visibleDoc === 2 ? addFieldDoc() : deleteFieldDoc('file3')
							}}
							classButton={visibleDoc === 2 ? 'icon-plus' : 'icon-minus'}
							type="file"
							label="Joindre un document"
							sousTitre="(Taille maximum : 5Mo / formats acceptés : .pdf, .jpg, .jpeg, .png, .bmp)"
						/>
					</div>
					}
					{visibleDoc === 3 &&
					<div>
						<Field
							name="file3"
							libelle={false}
							component={RenderFileMessage}
							addDeleteFieldDoc={() => {
								deleteFieldDoc('file3')
							}}
							classButton={'icon-minus'}
							type="file"
							label="Joindre un document"
							sousTitre="(Taille maximum : 5Mo / formats acceptés : .pdf, .jpg, .jpeg, .png, .bmp)"
						/>
					</div>
					}
					<div style={{ paddingTop: 21 }} className="d-flex justify-content-center">
						<Button
							outline color="light" size="sm" style={{ marginLeft: 25, marginRight: 25 }}
							onClick={goToMessage}
							className="border-2 btn-block w-25"
						>Annuler</Button>
						<Button
							style={{ marginLeft: 25, marginRight: 25, marginTop: 0 }}
							type="submit"
							disabled={pristine || submitting}
							color="light" size="sm"
							className="btn-block w-25 d-flex align-items-center justify-content-center"
						>{isLoading ? <LoaderButton /> : 'Envoyer'}</Button>
					</div>
				</div>
				}
			</div>
			{submitFailed && window.scrollTo(0, 0)}
		</form>
	)
}


FormNouveauMessage.propTypes = {
	donneesContrat: PropTypes.array,
	donneesTarificateur: PropTypes.object,
	setContratActif: PropTypes.func,
	assolement: PropTypes.object,
	change: PropTypes.func,
	initialValues: PropTypes.object,
	contratSelected: PropTypes.string,
	setContratSelected: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		donneesTarificateur: tarificateurSelectors.getTarificateursParContrat(state),
		assolement: assolementSelector.getAssolementActif(state)
	}
}
const actions = {
	setMessageActif: messagerieActions.setMessageActif
}

export default compose(
	reduxForm({
		form: 'nouveauMessage',
		touchOnBlur: false,
		enableReinitialize: true,
		validate
	}),
	connect(mapStateToProps, actions),
	withRouter
)(FormNouveauMessage)
