import React from 'react'
import {Modal} from "reactstrap";
import PropTypes from "prop-types";
import ModificationMotDePasseForm from "./ModificationMotDePasseForm";
import * as modificationAction from "./modificationActions";
import {connect} from "react-redux";
import * as messageActions from "../../../global/component/messages/messageActions";
import {SubmissionError} from "redux-form";

class ModificationMotDePasse extends React.Component {
    constructor(props){
        super(props)
        this.state = {
        }
        this.submitModificationPassword = this.submitModificationPassword.bind(this)
    }

    submitModificationPassword(data) {
        return this.props.modficationPassword(data)
            .then(() => {
                this.props.messagesSuccesBlocked("Modification de votre mot de passe","Votre demande de modification a bien été enregistrée. Votre mot de passe a été mis à jour","Fermer")
                this.props.toggle()
            }).catch(e => {
                return e.json()
                    .then(json => {
                        throw json
                    })
            })
            .catch(() => {
                throw new SubmissionError({oldPassword : 'Cette valeur est incorrecte.'})
            })
    }
    render() {
        const {isOpened, toggle,className} = this.props;
        return(
            <div>
                <Modal isOpen={isOpened} toggle={toggle} className={className + " small"}>
                    <ModificationMotDePasseForm toggle={toggle} onSubmit={this.submitModificationPassword}/>
                </Modal>
            </div>

        )
    }
}

ModificationMotDePasse.propTypes = {
    isOpened: PropTypes.bool,
    toggle: PropTypes.func.isRequired,
    modficationPassword: PropTypes.func,
    messagesSuccesBlocked:PropTypes.func,
    messagesErreur: PropTypes.func
}

const mapStateToProps = (state) => ({})

const actions = ({
    modficationPassword: modificationAction.modificationPassword,
    messagesSuccesBlocked:messageActions.messagesSuccesBlocked,
    messagesErreur:messageActions.messagesErreur
})

export default connect(mapStateToProps,actions)(ModificationMotDePasse)