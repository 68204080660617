import { getContratActif } from '../contratSelectors'
import { getExerciceActifAssolement } from '../assolement/assolementSelectors'
import { createSelector } from 'reselect'

const getLocalState = (state) => state.metier.contrat.decomptes

export const getHistoriqueDecomptesParContrat = (state) => getLocalState(state).historiqueDecomptesProvisoires

export const getHistoriqueDecomptesActif = createSelector(getHistoriqueDecomptesParContrat,getContratActif,
    (historiqueDecomptesParContrat, contratActif ) => historiqueDecomptesParContrat[contratActif] || {})

export const getHistoriqueDecomptesExerciceActif = createSelector(getHistoriqueDecomptesActif,getExerciceActifAssolement,
    (historiqueDecomptesParContrat, exerciceActif ) => historiqueDecomptesParContrat[exerciceActif] || {})

export const getHistoriqueDecomptesRecapitulatifExerciceActif  = (state) => getHistoriqueDecomptesExerciceActif(state).decomptesProvisoiresRecapitulatif || []