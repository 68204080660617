import React from 'react'
import { Container, Row } from 'reactstrap'
import Col from 'reactstrap/es/Col'
import FilAriane from '../../global/filAriane/FilAriane'
import ContacterMonAgence from './ContacterMonAgence'

const ContacterMonAgencePage = (props) => {
	return (
		<div className="background-coordonnees">
			<div className="bg-image-primary bannerSize theme-color"></div>
			<Container className="wrapper-dashboard">
				<FilAriane name={'Aide & Contact'} path={props} />
				<Row>
					<Col>
						<ContacterMonAgence />
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default ContacterMonAgencePage
