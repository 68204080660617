import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import * as tarificateurSelectors from '../../tarificateur/tarificateurSelectors'
import Decomptes from '../Decomptes'
import DetailParCulture from '../DetailParCulture'
import MonContrat from '../MonContrat'

const MesCotisationsPage = ({ tarificateurHistorique, donneesTarificateur, setThematique,setThematiqueFAQ }) => {
	const [childThematique] = React.useState('CO')

	useEffect(() => {
		setThematique(childThematique)
		setThematiqueFAQ(childThematique)
	}, [childThematique])

	return (
		<Col md="12" lg="9">
			<Row>
				<Col className="mb-3">
					<MonContrat
						titre="Mes Cotisations"
						selectExercice="true"
						preListExercice={Object.keys(tarificateurHistorique)}
						isMesGaranties={false}
						donneesTarificateur={donneesTarificateur}
						texteMulti={'Vous pouvez consulter les cotisations de chaque contrat en faisant défiler vos contrats dans le bloc ci-dessous. '}
					/>
				</Col>
			</Row>
			<Row>
				<Col className="mb-3">
					<Decomptes />
				</Col>
			</Row>
			<Row>
				<Col className="mb-3">
					<DetailParCulture />
				</Col>
			</Row>
		</Col>
	)
}

MesCotisationsPage.propTypes = {
	donneesTarificateur: PropTypes.object,
	tarificateurHistorique: PropTypes.object
}

const mapStateToProps = (state) => ({
	tarificateurHistorique: tarificateurSelectors.getHistoriqueTarificateurActif(state),
	donneesTarificateur: tarificateurSelectors.getHistoriqueTarificateurExerciceActif(state)
})

export default connect(mapStateToProps)(MesCotisationsPage)