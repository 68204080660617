export var MESSAGE_SUCCESS = 'MESSAGE_SUCCESS'
export var MESSAGE_SUCCESS_BLOCKED = 'MESSAGE_SUCCESS_BLOCKED'
export var MESSAGE_ERROR = 'MESSAGE_ERROR'
export var MESSAGE_ERROR_BLOCKED = 'MESSAGE_ERROR_BLOCKED'
export var MESSAGE_WARNING = 'MESSAGE_WARNING'
export var MESSAGE_INFO = 'MESSAGE_INFO'
export var CLOSE_MESSAGE = 'CLOSE_MESSAGE'

export const messagesSuccesBlocked = (titre,texte,texteButton) => dispatch => {
	return dispatch({type: MESSAGE_SUCCESS_BLOCKED , payload:{titre, texte,texteButton} })
}

export const messagesSucces = (titre,texte) => dispatch => {
	return dispatch({type: MESSAGE_SUCCESS , payload:{titre, texte} })
}

export const messagesErreurBlocked = (titre,texte,texteButton) => dispatch => {
	return dispatch({type: MESSAGE_ERROR_BLOCKED , payload:{titre, texte,texteButton} })
}

export const messagesErreur = (titre,texte) => dispatch => {
    return dispatch({type: MESSAGE_ERROR , payload:{titre, texte} })
}

export const messagesAlerte = (titre,texte) => dispatch => {
    return dispatch({type: MESSAGE_WARNING , payload:{titre, texte} })
}

export const messagesInfo = (titre,texte) => dispatch => {
    return dispatch({type: MESSAGE_INFO , payload:{titre, texte} })
}

export const closeModale = () => (dispatch) => {
    dispatch({type: CLOSE_MESSAGE})
}