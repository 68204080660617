import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import App from './App'
import ChartePage from '../maquette/charte/ChartePage'
import AccueilPage from '../accueil/AccueilPage'
import accueilRoutes from '../accueil/accueilRoutes'
import ThemeContainer from './ThemeContainer'
import { logOut } from '../accueil/loginActions'
import Error404 from '../global/error404/Error404'
import espaceClientRoutes from '../modules/routes'
import ScrollToTop from "./ScrollToTop";

export default ({ dispatch }) => <Switch>
		{/*TODO A supprimer,modifier*/}
		<Route path="/charte" component={ChartePage} />
		<Route path='/erreur404' component={Error404} />
		<Route
			path='/allianz' render={() => <ThemeContainer theme="allianz">
			{routesWithTheme('allianz', dispatch)}
		</ThemeContainer>}
		/>
		<Route
			path='/larurale' render={() => {
			return (<ThemeContainer theme="larurale">
					{routesWithTheme('larurale', dispatch)}
				</ThemeContainer>
			)
		}}
		/>
		<Route
			path='/logout' render={() => {
			dispatch(logOut())
			return <Redirect to="/larurale/client" />
		}}
		/>
		<Route
			path="/*"
			render={() => <Redirect to='/larurale/client' />}
		/>

	</Switch>


const routesWithTheme = (theme, dispatch) =>
	<Switch>
		<Route
			theme={theme} path={`/${theme}/logout`} render={() => {
			dispatch(logOut())
			return <Redirect to={`/${theme}/accueil`} />
		}}
		/>
		<Route theme={theme} path={`/${theme}/charte`} component={ChartePage} />
		<Route
			theme={theme}
			path={`/${theme}/client`}
			render={(otherprops) => {
				return ( <ScrollToTop><App {...otherprops} theme={theme} baseLocation={`/${theme}/client`}>
					{espaceClientRoutes(`/${theme}/client`)}
				</App></ScrollToTop>)
			}
			}
		/>
		<Route
			theme={theme}
			path={`/${theme}/accueil`}
			render={() => <AccueilPage theme={theme}>
				{accueilRoutes(`/${theme}/accueil`, theme)}
			</AccueilPage>}
		/>
		<Route
			path={`/${theme}`} render={() => {
			return <Redirect to={`/${theme}/client`} />
		}}
		/>
	</Switch>
