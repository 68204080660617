import React from "react";
import Paper from "../../global/component/paper/Paper";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import FormRechercheQuestion from "./FormRechercheQuestion";
import Question from "./Question";
import {connect} from "react-redux";
import * as configurationSelectors from '../../config/metier/configurationSelectors'
import {extractParamsUrl} from "../../utils";
import {withRouter} from "react-router";
import {compose} from "redux";
import { enumThematiquesFaq } from '../../constantes/FaqThematiques'

export const enumThematiques = [
    {
        code: 'TO',
        label: 'Toutes'
    },
    {
        code: 'CG',
        label: 'Contrats et garanties'
    },
    {
        code: 'DA',
        label: 'Déclarations d\'assolement'
    },
    {
        code: 'CO',
        label: 'Cotisations'
    },
    {
        code: 'SI',
        label: 'Sinistres'
    },
    {
        code: 'CD',
        label: 'Compte et données personnelles'
    },
    {
        code: 'DO',
        label: 'Documents'
    }
]

const initialValuesTemplate = {
    thematique: null
}

const QuestionsFrequentes = ({questionsFrequentes, history}) => {

    const [search, setSearch] = React.useState("")
    const [theme, setTheme] = React.useState(extractParamsUrl(history.location.search).theme ? extractParamsUrl(history.location.search).theme : 'TO')
    const [indexQuestion] = React.useState(extractParamsUrl(history.location.search).index ? extractParamsUrl(history.location.search).index : null)
    const [listQuestions, setListQuestions] = React.useState(questionsFrequentes)

    const research = (data) => {
        if (data.search != null) {
            setSearch(data.search)
        }
        if (data.theme != null) {
            setTheme(data.theme)
        }
    }
    /* todo utiliser l'enum ne pas faire de substring */
    React.useEffect(() => {
        switch (theme){
            case 'garanties':
                setTheme(enumThematiquesFaq.CONTRAT.code)
                break;
            case 'assolement':
                setTheme(enumThematiquesFaq.DECLARATION.code)
                break;
            case 'cotisations':
                setTheme(enumThematiquesFaq.COTISATION.code)
                break;
            case 'historiqueAssolement':
                setTheme(enumThematiquesFaq.DECLARATION.code)
                break;
            case 'declarerUnSinistre':
                setTheme(enumThematiquesFaq.SINISTRE.code)
                break;
            case 'mesSinistres':
                setTheme(enumThematiquesFaq.SINISTRE.code)
                break;
            case 'consulterMesIndemnisations':
                setTheme(enumThematiquesFaq.SINISTRE.code)
                break;
            case 'mesCoordonnees':
                setTheme(enumThematiquesFaq.COMPTE.code)
                break;
            case 'suivreSinistre':
                setTheme(enumThematiquesFaq.SINISTRE.code)
                break;
	        default:
		        break;
        }
        var listQuestionsTemp = []
        Object.values(questionsFrequentes).forEach(question => {
            if (theme === 'TO' && (search == null || search === "")){
                listQuestionsTemp = questionsFrequentes
            }
            if (theme === 'TO') {
                if (search != null && search !== "") {
                    if (question.question.toUpperCase().includes(search.toUpperCase()) || question.reponse.toUpperCase().includes(search.toUpperCase())) {
                    listQuestionsTemp.push(question)
                    }
                }
            } else {
                if (search != null && search !== "") {
                    if(question.categorie.includes(theme) && (question.question.toUpperCase().includes(search.toUpperCase()) || question.reponse.toUpperCase().includes(search.toUpperCase()))){
                        listQuestionsTemp.push(question)
                    }
                } else {
                    if (question.categorie.includes(theme)) {
                        listQuestionsTemp.push(question)
                    }
                }

            }
        })
        setListQuestions(listQuestionsTemp)
    },[search, theme, questionsFrequentes])

    const buildInitialValues = () => {
        let initialValues = initialValuesTemplate
        initialValues = {
            ...initialValues,
            thematique: theme,
        }
        return initialValues
    }

    return (
        <Paper className="push-tpl-1 questions-frequentes push-head">
            <Row className={"mb-5"}>
                <Col className={"titre"}>
                    Questions fréquentes
                </Col>
            </Row>
            <Row className={"ml-2 mr-2"}>
                <Col>
                    <Row className={"mb-4"}>
                        <Col>
                            <FormRechercheQuestion
                                initialValues={buildInitialValues()}
                                thematique={enumThematiques}
                                research={(value)=>{research(value)}}
                                search={search}
                                theme={theme}
                            />
                        </Col>
                    </Row>
                    {Object.values(listQuestions).length !== 0 ?
                        Object.values(listQuestions).map((question, index) =>
                            <Question
                                key={question.id}
                                question={question}
                                index={index+1}
                                isOpen={index === indexQuestion}
                            />)
                        :
                        <Row className={"d-flex justify-content-center font-weight-bold"}><h4>Aucune questions fréquentes ne correspond à votre recherche</h4></Row>
                    }
                </Col>
            </Row>
        </Paper>
    )
}
const mapStateToProps = (state) => {
    return {
        questionsFrequentes: configurationSelectors.getQuestions(state)
    }
};
export default compose(connect(mapStateToProps), withRouter) (QuestionsFrequentes)