import * as PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import * as configurationSelectors from '../../config/metier/configurationSelectors'

const EspaceAssureMessages = (
	{
		id,
		libellesEA
	}
) => {
	const getLibelles = (libellesEA) => {
		var value = null
		libellesEA.forEach((data, index) => {
			if (data.code === id) value = data.texte
		})
		return value
	}
	if (getLibelles(libellesEA) != null) {
		return (
			<div>
				{getLibelles(libellesEA).split('\n').map(function (ligne, index) {
					if (ligne.toString() !== '') {
						return (
							<span key={`libelle-${index}`}>{ligne}<br /></span>
						)
					} else {
						return null
					}
				})}
			</div>
		)
	} else return null
}

EspaceAssureMessages.propTypes = {
	libellesEA: PropTypes.array,
	id: PropTypes.string
}
const mapStateToProps = (state) => ({
	libellesEA: configurationSelectors.getLibelles(state)
})
export default connect(mapStateToProps)(EspaceAssureMessages)