import { combineReducers } from 'redux'
import assolementReducer from './assolement/assolementReducer'
import tarificateurReducer from './tarificateur/tarificateurReducer'
import decomptesReducer from './decomptesProvisoires/decomptesReducer'
import quittancement from './quittancement/quittancementReducer'
import quittancementIndemnite from './../sinistre/consulterIndemnisations/quittanceIndemnite/quittanceIndemniteReducer'
import * as actions from "./contratActions";
import { isContratValide, isEtatResilie, supprimeZerosContrat } from '../../utils'


const contratActif = (state = '', action) => {
	switch (action.type) {
		case actions.GET_CONTRAT_SUCCES: {
			return parseInt(action.response.filter(contrat => isContratValide(contrat.etatCode,contrat.etatDate))[0].contrat).toString()
		}
		case actions.SET_CONTRAT_ACTIF: {
			return action.response
		}
		default:
			return state
	}
}

const infoParContrat = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_CONTRAT: {
			return state
		}
		case actions.GET_CONTRAT_SUCCES: {
			return action.response.reduce((tab, contrat) => {
				return {
					...tab,
					[parseInt(contrat.contrat).toString()]: supprimeZerosContrat(contrat)
				}
			}, {})
		}
		case actions.GET_CONTRAT_ERROR: {
			return state
		}
		default:
			return state
	}
}

const contratsListe = (state = [], action) => {
	switch (action.type) {
		case actions.GET_CONTRAT_SUCCES: {
			return action.response.reduce((tab, contrat) => {
				return [
					...tab,
					parseInt(contrat.contrat).toString()
				]
			}, [])
		}
		default:
			return state
	}
}

const contratsNonResiliesListe = (state = [], action) => {
	switch (action.type) {
		case actions.GET_CONTRAT_SUCCES: {
			return action.response.reduce((tab, contrat) => {
				if (isContratValide(contrat.etatCode,contrat.etatDate)) {
					return [
						...tab,
						parseInt(contrat.contrat).toString()
					]
				} else return [
					...tab
				]
			}, [])
		}
		default:
			return state
	}
}
/**
 * Affiche les contrats en cours ou contentieux ou supendus ou en instance plus les remplacés
 * @param state
 * @param action
 * @returns {Array|*}
 */
const contratsEnCoursOuRemplacesListe = (state = [], action) => {
	switch (action.type) {
		case actions.GET_CONTRAT_SUCCES: {
			return action.response.reduce((tab, contrat) => {
				if (isContratValide(contrat.etatCode,contrat.etatDate) || isEtatResilie(contrat.etatCode)) {
					return [
						...tab,
						parseInt(contrat.contrat).toString()
					]
				} else return [
					...tab
				]
			}, [])
		}
		default:
			return state
	}
}

const isLoading = (state = false, action) => {
	switch (action.type) {
		case actions.GET_CONTRAT: {
			return true
		}
		case actions.GET_CONTRAT_SUCCES: {
			return false
		}
		case actions.GET_CONTRAT_ERROR: {
			return false
		}
		default:
			return state
	}
}


export default combineReducers({
	contratActif,
	contratsListe,
	contratsNonResiliesListe,
	contratsEnCoursOuRemplacesListe,
	infosContrat: combineReducers({infoParContrat, isLoading}),
	assolement: assolementReducer,
	decomptes: decomptesReducer,
	tarificateur: tarificateurReducer,
	quittancement,
	quittancementIndemnite
})

