import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import EspaceAssureMessages from '../../../config/metier/EspaceAssureMessages'
import * as messageActions from '../../../global/component/messages/messageActions'
import Paper from '../../../global/component/paper/Paper'
import PropTypes from 'prop-types'
import * as assolementSelectors from '../../contrat/assolement/assolementSelectors'
import FormDeclarationSinistre from './FormDeclarationSinistre'
import ModaleAjoutParcelles from './ModaleAjoutParcelles'
import * as declarationSinistreAction from './declarationSinistreActions'
import { formatNumberAssolement, formatStringToDate, extractParamsUrl, formatDateAssolement, formatListePerils } from '../../../utils'
import { enumTypeDeclaration} from './FormDeclarationSinistre'
import {withRouter} from "react-router";
import {compose} from "redux";
import * as selectors from '../../contrat/contratSelectors'
import * as sinistreActions from "../sinistreActions";

const initialValuesTemplate = {
	grele : false,
	tempete : false,
	gel : false,
	eau : false,
	secheresse : false,
	date : null,
	heure : null,
	po: false
}

const DeclarationSinistrePage = ({ setThematique, setThematiqueFAQ, parcelles, submitSinistreDeclaration, messagesSucces, messagesErreur, history, location, contratsListe, contrat, baseLocation, setSinistreActif }) => {
	const [isLoading, setIsLoading] = React.useState(false)
	const [modaleOpened, setModaleOpened] = React.useState(false)
	const [currentPage, setCurrentPage] = React.useState(0)
	const [parcellesAdded, setParcellesAdded] = React.useState(parcelles)
	const [newParcelles, setNewParcelles] = React.useState({})
	const [contratSelected, setContratSelected] = React.useState(extractParamsUrl(history.location.search).contrat || null)
	const [grele] = React.useState(extractParamsUrl(history.location.search).grele === 'true')
	const [tempete] = React.useState(extractParamsUrl(history.location.search).tempete === 'true')
	const [gel] = React.useState(extractParamsUrl(history.location.search).gel === 'true')
	const [eau] = React.useState(extractParamsUrl(history.location.search).eau === 'true')
	const [secheresse] = React.useState(extractParamsUrl(history.location.search).secheresse === 'true')
	const [date] = React.useState(extractParamsUrl(history.location.search).date)
	const [heure] = React.useState(extractParamsUrl(history.location.search).heure)
	const [po] = React.useState(extractParamsUrl(history.location.search).PO === 'true')
	const isMulti = contratsListe.length > 1
	const [childThematique] = React.useState("NO")
	const [childThematiqueFAQ] = React.useState("NO")

	useEffect(() => {
		setThematique(childThematique)
	}, [childThematique])

	useEffect(() => {
		setThematiqueFAQ(childThematiqueFAQ)
	}, [childThematiqueFAQ])

	useEffect(() => {
		setParcellesAdded(parcelles)
	}, [parcelles])

	useEffect(() => {
		if (!contratSelected && !isMulti) {
			setContratSelected(contratsListe[0].contrat)
		}
	}, [])

	function goBackToSinistre(){
		redirectToTarget(`${location.pathname.replace('/declarerUnSinistre', '')}/mesSinistres`)
	}
	const submitSinistre = (data) => {
		setIsLoading(true)
		let parcellesSinistre = {}
		let otherFormData = {}
		Object.keys(data).forEach(dataName => {
			if (dataName.startsWith('parcelleN')) {
				if (dataName.includes('-')) {
					const dataNameSplit = dataName.split('-')
					parcellesSinistre = {
						...parcellesSinistre,
						[dataNameSplit[0]]: parcellesSinistre[dataNameSplit[0]] ? { ...parcellesSinistre[dataNameSplit[0]], [dataNameSplit[1]]: data[dataName] } : { [dataNameSplit[1]]: data[dataName] }
					}
				} else {
					parcellesSinistre = {
						...parcellesSinistre,
						[dataName]: parcellesSinistre[dataName] ? { ...parcellesSinistre[dataName], ...data[dataName] } : data[dataName]
					}
				}
			} else {
				otherFormData = {
					...otherFormData,
					[dataName]: data[dataName]
				}
			}
		})

		submitSinistreDeclaration({ ...otherFormData, parcelles: Object.values(parcellesSinistre) })
			.then(() => {
				setIsLoading(false)
				goToListSinistre()
				let docVigne = "- votre casier viticole informatisé (CVI)"
				parcelles.forEach(parcelle => {
					if (parcelle.numeroCulture.substring(0,1) !== "4") {
						docVigne = ""
					}
				})
				if(otherFormData.typeDeclaration ===  enumTypeDeclaration.pourOrdre.code){
					messagesSucces('Déclaration de sinistre enregistrée', 'Votre déclaration du sinistre survenu le '+ (otherFormData.date.toLocaleDateString()) +' a bien été enregistrée. Vous avez 30 jours maximum pour la confirmer et déclencher la demande d’intervention d\'un expert.','Ok')
				}
				else if (otherFormData.typeDeclaration === enumTypeDeclaration.global.code){
					messagesSucces('Déclaration de sinistre enregistrée', 'Votre déclaration du sinistre survenu le '+ (otherFormData.date.toLocaleDateString()) +' a bien été enregistrée. Vous serez avisé par mail dès que l’expert aura été missionné sur votre dossier.<br/>' +
						'Avant le passage de l\'expert, pensez à préparer les documents suivants :<br/>- votre déclaration PAC <br/>'+docVigne,"Ok")
				}
				else {
					messagesSucces('Déclaration de sinistre enregistrée', 'Votre déclaration du sinistre survenu le '+ (otherFormData.date.toLocaleDateString()) +'  a bien été enregistrée. Sous réserve de validation de votre déclaration par la compagnie, vous serez avisé par mail dès que l’expert aura été missionné sur votre dossier.<br/>' +
						'Avant le passage de l\'expert, pensez à préparer les documents suivants :<br/>- votre déclaration PAC <br/>'+docVigne,"Ok")
				}
			})
			.catch(e => {
				setIsLoading(false)
				messagesErreur('Déclaration de sinistre', 'Une erreur est survenue.')
			})

	}

	const redirectToTarget = (path) => {
		history.push(path)
	}
	const goToListSinistre = () => {
		redirectToTarget(`${location.pathname.replace('/declarerUnSinistre', '')}/mesSinistres`)
	}
	const toggleModale = () => {
		if (modaleOpened) setModaleOpened(false)
		else setModaleOpened(true)
	}

	const handleClick = (e, index) => {
		e.preventDefault()
		setCurrentPage(index)
	}
	const submitAddParcelles = (data) => {
		let maxNumParcelle = 0
		parcellesAdded.forEach(parcelle => {
			if (parcelle.numeroParcelle > maxNumParcelle) {
				maxNumParcelle = parcelle.numeroParcelle
			}
		})
		setNewParcelles({
			...newParcelles,
			[formatNumberAssolement(parseInt(maxNumParcelle) + 1)] : parseFloat(data.surfaceSinistree)
		})

		setParcellesAdded([
			...parcellesAdded,
			{
				numeroParcelle: formatNumberAssolement(parseInt(maxNumParcelle) + 1),
				codeCulture: {
					nom: data.cultureChoisie.nom,
					numeroCulture: data.cultureChoisie.numeroCulture
				},
				departement: data.commune.departement,
				canton: data.commune.canton,
				arrondissement: data.commune.arrondissement,
				commune: data.commune.commune,
				nom: formatNumberAssolement(parseInt(maxNumParcelle) + 1),
				surface: parseFloat(data.surfaceTotale),
				libelleCommune: data.commune.nomCommune
			}
		])
		const inter = (parcellesAdded.length + 1)
		console.log(parcellesAdded)
		setCurrentPage((inter % 5) === 0 ?  Math.trunc(inter/5) - 1 : Math.trunc(inter/5) )
		toggleModale()
	}

	const buildInitialValues = () => {
		let initialValues = initialValuesTemplate
		if (!isMulti) {
			initialValues = {
				...initialValues,
				contrat
			}
		}
		if (po) {
			initialValues = {
				...initialValues,
				contrat: contratSelected,
				grele: grele,
				tempete: tempete,
				gel: gel,
				eau: eau,
				secheresse: secheresse,
				date: formatStringToDate(date, heure),
				heure: null,
				po: po
			}
		}
		return initialValues
	}
	return (
		<Col md="12" lg="9">
			{po &&
				<Row className="lienSinistre">
					<Col>
						<div className="p-0 d-flex backLink" onClick={()=>{goBackToSinistre()}}>
							<span className="icon icon-chevron-left chevronRetourSinistre"></span>
							<div className="backLink">Retour vers la liste des sinistres</div>
						</div>
					</Col>
				</Row>
			}
			<Row>
				<Col className="mb-3">
					<Paper className="push-tpl-1">
						<Row className="push-head">
							<Col>
								<Row className="mb-5">
									<Col className="d-flex align-items-center">
										<div className="title">
											{po ? `Sinistre du ${formatDateAssolement(date)} - ${formatListePerils(grele,tempete,gel,eau,secheresse)}` : 'Déclaration de sinistre'}
										</div>
									</Col>
								</Row>
								<Row className="mb-5">
									<Col>
										<div style={{fontSize: '1.01rem'}}>
											{po ? <EspaceAssureMessages id="messinistres_declaration_pour_ordre_introduction"/> : <EspaceAssureMessages id="messinistres_declaration_introduction"/>}<br/>
											{!po && <div className="ft-s-allianz-semi-bold">Après avoir renseigné les données de votre déclaration, cliquez sur le bouton « Envoyer ».</div>}
											Un message vous confirmera le bon enregistrement de votre déclaration,
											puis un message sera envoyé à votre adresse e-mail pour confirmer la réception de votre déclaration par votre interlocuteur.
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<FormDeclarationSinistre isLoading={isLoading}
										                         baseLocation={location.pathname.replace('/declarerUnSinistre', '')}
										                         grele={grele} tempete={tempete} gel={gel} eau={eau} secheresse={secheresse} date={date} heure={heure} po={po}
										                         initialValues={buildInitialValues()}
										                         isMulti={isMulti}
										                         contratSelected={contratSelected} setContratSelected={setContratSelected}
										                         changePages={handleClick}
										                         parcellesAdded={parcellesAdded}
										                         newParcelles={newParcelles}
										                         currentPage={currentPage}
										                         toggleModale={toggleModale} onSubmit={submitSinistre}/>
										<ModaleAjoutParcelles submitAddParcelles={submitAddParcelles} toggle={toggleModale} isOpened={modaleOpened}/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Paper>
				</Col>
			</Row>
		</Col>
	)
}
DeclarationSinistrePage.propTypes = {
	parcelles: PropTypes.array,
	submitSinistreDeclaration: PropTypes.func,
	messagesSucces: PropTypes.func,
	messagesErreur: PropTypes.func,
	setSinistreActif: PropTypes.func
}
const mapStateToProps = (state) => ({
	parcelles: assolementSelectors.getParcellesAssolementActif(state),
	contrat: selectors.getContratActif(state),
	contratsListe: selectors.getDonneesContratsNonResilies(state)
})

const actions = {
	submitSinistreDeclaration: declarationSinistreAction.submitSinistreAction,
	messagesSucces: messageActions.messagesSuccesBlocked,
	messagesErreur: messageActions.messagesErreur,
	setSinistreActif: sinistreActions.setSinistreActif
}
export default compose(connect(mapStateToProps, actions), withRouter)(DeclarationSinistrePage)