import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput} from 'reactstrap';

class ModaleCookies extends Component {
    render() {
        const {selectedOption, isOpened, handleOptionChange, handleSubmit, toggle,className} = this.props;
        return (
            <div>
                <div style={{margin: 'auto',color:'#3DA556', display: 'flex', cursor: "pointer", fontWeight: 'bold', fontSize: '14px'}} onClick={toggle}>
                    <span style={{fontSize: '21px'}} className="icon-chevron-right"> </span>
                    <div>En savoir plus et gérer mes cookies</div>
                </div>
                <Modal isOpen={isOpened} toggle={toggle} className={className + " small"} backdrop="static">
                    <ModalHeader>Gestion des cookies et des cas d'utilisation de vos informations</ModalHeader>
                    <ModalBody>
                        <div>
                            Ce site utilise des cookies pour son fonctionnement, l'amélioration de votre expérience de
                            navigation et pour mesurer des statistiques anonymes et agrégées sur l’ensemble des
                            visiteurs. Vous pouvez gérer ces cookies et la mesure statistique depuis la page Utilisation
                            des cookies. Ce site utilise aussi des informations pour comprendre et personnaliser votre
                            expérience client. Vous pouvez contrôler l’utilisation de ces informations ci-dessous et à
                            tout moment via la page Utilisation des cookies.
                        </div>
                        <div>
                            <br/>
                            <h6>Mesure d'audience</h6>
                            <p>Nous croisons des informations clients avec nos solutions de mesure d’audience afin de
                                comprendre les attentes et le comportement de nos clients qui se connectent à leur
                                espace client.</p>
                        </div>
                        <div style={{display: 'flex'}}>
                            <p  className="text-dark" style={{fontSize: '14px',fontWeight: '800',marginRight: "15px"}}>J'accepte l'utilisation de ces cookies</p>
                            <div style={{marginRight: "45px"}}>
                                <CustomInput type="radio" id="RadioCookies" name="customRadio" label="Oui" value="true" onChange={handleOptionChange}/>
                            </div>
                            <div>
                                <CustomInput type="radio" id="RadioNoCookies" name="customRadio" label="Non" value="false" onChange={handleOptionChange}/>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={selectedOption === null} color="light" id="btn-cookies" className="btn-block m-auto {selectedOption===null ? 'disabled' : '' }" onClick={handleSubmit}>Confirmer</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

ModaleCookies.propTypes = {
    selectedOption: PropTypes.bool,
    isOpened: PropTypes.bool,
    handleOptionChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired
};

export default ModaleCookies;