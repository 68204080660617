import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModaleCredits extends React.Component {

	render() {
		const {isOpened,toggle,className} = this.props;
		return (
			<div>
				<div className='lien' onClick={toggle}>
					Crédits photos
				</div>
				<Modal isOpen={isOpened} toggle={toggle} className={className + " small"}>
					<ModalHeader >Crédits photos</ModalHeader>
					<ModalBody>
						<ul>
							<li>
								Photos La Rurale
							</li>
							<li>
								Illustrations : DCA/La Rurale
							</li>
							<li>
								Pixabay
							</li>
						</ul>
					</ModalBody>
					<ModalFooter>
						<Button onClick={toggle} color="light" size="lg" className="btn-block w-50 m-auto">C'est compris</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

ModaleCredits.propTypes = {
	isOpened: PropTypes.bool,
	toggle: PropTypes.func.isRequired

};

export default ModaleCredits;