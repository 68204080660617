import React from 'react'
import { withRouter } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import Button from 'reactstrap/es/Button'
import Paper from '../../global/component/paper/Paper'
import { isEtatResilieOuSuspenduOuContentieux } from '../../utils'


const SinistresHeader = ({ baseLocation, isUserAllowed, history, contratActif, getDonneesContratByContrat }) => {
	const redirectToTarget = (path) => {
		history.push(path)
	}
	return (
		<Paper className="push-tpl-1 sinistreHeader">
			<Row className="push-head">
				<Col>
					<Row className="mb-5">
						<Col className="d-flex align-items-center">
							<div className="title">
								Suivre mes sinistres
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg="8 mb-2">
							<p className="m-0 text">En cas de sinistre, nous sommes là pour vous.</p>
							<p className="m-0 text">Vous pouvez déclarer votre sinistre en ligne 24h/24, 7j/7.</p>
						</Col>
						<Col>
							{isUserAllowed &&
							<Button onClick={() => redirectToTarget(`${baseLocation}/declarerUnSinistre`)}
							        disabled={isEtatResilieOuSuspenduOuContentieux(getDonneesContratByContrat(contratActif).etatCode)}
							        className="btn btn-light btn-push-tpl btn-block d-inline-flex align-items-center justify-content-center">
								<div className="icon icon-thunderstorm"></div>
								<div className="wording font-weight-bold">Déclarer un sinistre</div>
							</Button>}
						</Col>
					</Row>
				</Col>
			</Row>
		</Paper>
	)

}


export default withRouter(SinistresHeader)