import React from 'react'
import {Button, Col, Row} from "reactstrap";
import {isEtatResilie, formatDecimales, formatMillier} from "../../../utils";

class LigneMultiContrats extends React.Component{
    render() {
        const {donneesContrat,donneesTarificateur,goToGaranties} = this.props
        if(isEtatResilie(donneesContrat.etatCode)) return null
        else return(
            <Button onClick={() => {goToGaranties(donneesContrat.contrat)}}>
                <Row className="table-item">
                    <Col xs="11" className="d-flex flex-column align-items-start">
                        <div className={"entete"}>
                            N°{donneesContrat.contrat} - {donneesContrat.packCode === "A1" ? ("Assurance des récoltes - Grêle, Tempête " + (donneesTarificateur.optionGel ? "(option Gel)" :"")) : "Garantie multirisques des récoltes"}
                        </div>
                        <div className="valeur">Capital assuré {donneesTarificateur.exercice} :&nbsp;<div className="ft-s-allianz-bold">{formatMillier(donneesTarificateur.capitalTotal)} €</div></div>
                        <div className="valeur">Surface assuré {donneesTarificateur.exercice} :&nbsp;<div className="ft-s-allianz-bold">{formatMillier(formatDecimales(donneesTarificateur.surfaceTotale,4))} ha</div></div>
                    </Col>
                    <Col xs="1" className="text-right p-0">
                        <span className="icon icon-chevron-right c-light"> </span>
                    </Col>
                </Row>
            </Button>
        )
    }
}

export default LigneMultiContrats