import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React from "react";

const ModalCGU = ({openModal, closeModal}) => {
    return (
        <Modal isOpen={openModal} className={"large"}>
            <ModalHeader>CONDITIONS GENERALES D’UTILISATION DU SITE</ModalHeader>
            <ModalBody>

                <h4>1.	Stipulation générale</h4>
                <p>Les présentes conditions générales sont conclues entre la société La Rurale (Société du Groupe Allianz) : Siège social Cœur Défense, Bâtiment 2, 82, Esplanade du Général De Gaulle , 92400 Courbevoie, Compagnie d’assurances contre la grêle, S.A. Régie par le Code des Assurances, capital de 960000 euros, N° 572 166 437 RCS Nanterre, ci-après dénommé « La Rurale » Entreprises régies par le Code des assurances, soumises au contrôle de l’Autorité de contrôle prudentiel et de résolution (ACPR), 4 Place de Budapest CS 92459, 75436 Paris Cedex 09 et les personnes souhaitant accéder aux services proposés via le site la Rurale.fr, ci-après dénommés « le client ». </p>

                <h4>2.	Définitions</h4>
                <p><b>Espace Client :</b> désigne l'Espace « La Rurale.fr », sécurisé, mis à disposition du Client au moyen d'un identifiant personnel et d'un code confidentiel à partir du site internet www.la Rurale.fr et proposant des Services.
                    <br/><br/>
                    L'Espace Client « La Rurale.fr » est accessible 7 jours sur 7, 22 heures sur 24, à l'exception des cas de force majeure, difficultés informatiques ou techniques, ou pour raisons de maintenance.
                    <br/><br/>
                    <b>Services :</b> désigne les actes (consultation, téléchargement, changement de coordonnées, ...) et les services proposés au Client par voie électronique par la société La Rurale dans l’Espace Client, réalisés et validés par lui à partir de cet Espace, au moyen de son identifiant personnel et de son code confidentiel.
                    <br/><br/>
                    <b>Contenu :</b> Ensemble des éléments constituants l’information présente sur le Site du Client, notamment textes – images – vidéos.
                    <br/><br/>
                    <b>Informations clients :</b> Ci-après dénommé « Information (s) » qui correspondent à l’ensemble des données personnelles susceptibles d’être détenues par La Rurale pour la gestion de votre compte, de la gestion de la relation client et à des fins d’analyses et de statistiques.
                    <br/><br/>
                    <b>Utilisateur :</b> Internaute se connectant, utilisant le site susnommé. Il déclare avoir la capacité juridique ainsi que, le cas échéant, le pouvoir juridique pour représenter le souscripteur et agir pour son compte.
                </p>

                <h4>3.	Description des services fournis </h4>
                <p>La Rurale propose au Client de lui communiquer dans son Espace Client des informations et des documents afférents à la gestion et au suivi de son ou ses contrats d’assurances souscrits auprès de La Rurale.
                    <br/><br/>
                    L’espace client offre au client la possibilité de consulter, télécharger, sauvegarder et imprimer ses documents en ligne.
                    <br/><br/>
                    Afin de permettre au Client d’être informé de la communication de ses e-courriers dans son Espace Client, il est convenu entre les Parties que La Rurale puisse lui adresser un courrier électronique d’alerte sur son adresse de messagerie personnelle ou un SMS sur l’un de ses numéros de téléphone.
                    <br/><br/>
                    En cliquant sur le bouton « Valider », le Client accepte expressément que :
                    <br/><br/>
                    • son adresse de messagerie électronique personnelle ou l’un de ses numéros de téléphone qu’il a communiqué à La Rurale et connu de l’Espace Client soit utilisé aux fins d’envoi de ses courriers électroniques d’alerte l’avisant de la communication de e-courriers dans son Espace Client.
                </p>

                <h4>4.	Acceptation </h4>
                <p>Le Service est proposé au Client sous réserve de son acceptation des présentes « Conditions Générales d’utilisation de l’Espace Client « La Rurale.fr » que le Client a acceptées lors de son inscription à l’Espace Client.
                    <br/><br/>
                    En cochant la case « J’accepte les Conditions Générales d’Utilisation » dans l’Espace Client « La Rurale.fr » et en cliquant sur « Valider », le Client déclare et reconnaît avoir préalablement été informé et avoir pris connaissance des présentes Conditions Générales et en accepter l'intégralité des termes et conditions.
                    <br/><br/>
                    La Rurale se réserve le droit de modifier à tout moment les présentes Conditions Générales, changement qui sera notifié au Client et l’oblige donc à en prendre connaissance et à les valider avant l’accès au Service. En cas de désaccord, le Client a la possibilité de résilier le Service. Si le Client continue à utiliser le Service, il sera réputé avoir accepté les nouvelles Conditions Générales applicables au Service.
                </p>

                <h4>5.	Création d’un compte La Rurale</h4>
                <p>Pour utiliser les Services du Site, l’utilisateur doit créer un espace client.
                    <br/><br/>
                    La création d’un Compte nécessite que l’Utilisateur choisisse une adresse email de correspondance ainsi qu’un mot de passe. Le mot de passe communiqué peut être modifié par l’Utilisateur sur le Site dans la rubrique « Votre compte ».
                    <br/><br/>
                    L’Utilisateur doit informer immédiatement La Rurale de toute perte ou utilisation non autorisée de son Compte, de ses identifiant et mot de passe. L’identifiant et le mot de passe sont personnels et l’Utilisateur s’engage à ne pas les divulguer. A ce titre, La Rurale ne peut être tenue responsable de l’utilisation de l’identifiant et du mot de passe de l’Utilisateur par un tiers auquel l’Utilisateur les aurait communiqués ou bien qui y aurait eu accès suite à une faute, maladresse ou négligence de la part de l’Utilisateur.
                    <br/><br/>
                    La Rurale ses partenaires, co-contractants ou ayants droit ne peuvent être tenus pour responsables des conséquences de l’utilisation fautive par l’Utilisateur de son Compte ou de toute action réalisée sur le Compte de l’Utilisateur par un tiers à qui l’Utilisateur aurait communiqué ses identifiants ou qui aurait eu accès au Compte suite à une faute, maladresse ou négligence de la part de l’Utilisateur.
                </p>

                <h4>6.	Fonctionnement de l’espace client </h4>
                <p>Pour accéder à son espace client, le client doit saisir son identifiant et son mot de passe dont il est réputé être le seul titulaire. L’utilisation de l’un et l’autre prouvent que la connexion à l’espace client et l’utilisation du service est réalisée par le client ; il l’utilise sous son contrôle et sa responsabilité.
                    <br/><br/>
                    L’accès et l’utilisation de ce service supposent que l’adresse e-mail et le numéro de téléphone, indiqués dans l’espace client du client sont valides. L’un et/ou l’autre servent à informer le client de l’arrivée d’un nouveau document en ligne (via un e-mail, un SMS, ou une alerte via tout autre moyen de communication électronique) ce que le client accepte.
                    <br/><br/>
                    Le client a l’obligation d’actualiser ces informations en cas de changement. La Rurale ne peut être tenue responsable de l’utilisation d’une adresse e-mail ou d’un numéro de téléphone non valides.
                    <br/><br/>
                    L’accès à l’espace client et l’utilisation du service nécessitent des logiciels à jour pour la navigation sur internet, l’ouverture et la lecture par le client des documents en ligne, installés sur un ordinateur, tablette ou Smartphone.
                </p>

                <h4>7.	Evolution de l’espace client </h4>
                <p>Le Client sera informé par un e-mail ou par messagerie téléphonique SMS qui sera envoyé à l’adresse e-mail ou au numéro de téléphone connu par l’Espace Client de toute suppression ou remplacement du Service. Le Client accepte de recevoir de telles informations sur cette adresse de messagerie électronique. Il appartient au Client de vérifier régulièrement l’adresse e-mail connue par l’Espace Client.</p>

                <h4>8.	Propriété intellectuelle </h4>
                <p>Le site et son contenu font l'objet d'une protection légale au titre de la propriété littéraire et artistique (droits d'auteurs, des interprètes...) et de la propriété industrielle (marques, dessins...).
                    <br/><br/>
                    Il est interdit à tout internaute de copier ou reproduire, par numérisation et tout autre moyen, sur quelque support que ce soit, tout ou partie du contenu du site, qu'il soit textuel, graphique ou sonore, sauf pour son usage propre. Le site indique en particulier à l'internaute les parties du contenu du site qu'il est autorisé à télécharger pour les besoins de sa relation avec La Rurale.
                    <br/><br/>
                    Le non-respect de ces règles peut engager la responsabilité civile et pénale du contrevenant.
                    <br/><br/>
                    Le Client ne peut en aucune manière réutiliser, céder ou exploiter pour son propre compte tout ou partie des éléments ou travaux du Site.
                    <br/><br/>
                    Ce site internet est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois décidée par La Rurale, qui s’efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l’intervention.
                </p>

                <h4>9.	Limitations contractuelles sur les données techniques </h4>
                <p>La Rurale ne pourra en aucun cas être tenue pour responsable en cas de retard de mise à jour, d'erreur ou d'omission quant au contenu des pages du site. La Rurale ne saurait être tenu pour responsable des dommages directs et indirects résultant de l'accès ou de l'usage du site.
                    <br/><br/>
                    Le site La Rurale contient par ailleurs des liens hypertextes orientant l’internaute vers d’autres sites internet.
                    <br/><br/>
                    La Rurale n’est pas responsable du contenu des informations accessibles via son site au moyen de ces liens hypertextes. Ainsi La Rurale ne peut garantir leur exactitude, mise à jour, caractère actuel et loyal, qui relève de la seule et entière responsabilité des personnes auteurs ou propriétaires de ces sites.
                    <br/><br/>
                    Le site Internet ne pourra être tenu responsable de dommages matériels liés à l’utilisation du site.
                    <br/><br/>
                    L’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.
                    <br/><br/>
                    Le site La Rurale est hébergé chez un prestataire sur le territoire de l’Union Européenne conformément à la réglementation en vigueur.
                    <br/><br/>
                    Développement, maintenance et stockage des informations assurés par IpsoSenso, Siège social 1, rue Saint-Georges 75009 Paris R.C.S. Paris B 524 354 685
                    <br/><br/>
                    L’objectif est d’apporter une prestation qui assure le meilleur taux d’accessibilité.
                    <br/><br/>
                    L’hébergeur assure la continuité de son service 24 Heures sur 24, tous les jours de l’année. Il se réserve néanmoins la possibilité d’interrompre le service d’hébergement pour les durées les plus courtes possibles notamment à des fins de maintenance, d’amélioration de ses infrastructures, de défaillance de ses infrastructures ou si les prestations et Services génèrent un trafic réputé anormal.
                    <br/><br/>
                    La Rurale et l’hébergeur ne pourront être tenus responsables en cas de dysfonctionnement du réseau Internet, des lignes téléphoniques ou du matériel informatique et de téléphonie liée notamment à l’encombrement du réseau empêchant l’accès au serveur.
                </p>

                <h4>10.	Limitations de responsabilité</h4>
                <p>La Rurale agit en tant qu’éditeur du site. La Rurale est responsable de la qualité et de la véracité du contenu qu’elle publie.
                    <br/><br/>
                    Elle ne pourra être tenu responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site internet « La Rurale.fr » et résultant soit de l’utilisation d’un matériel non conforme, soit de l’apparition d’un bug ou d’une incompatibilité.
                    <br/><br/>
                    La Rurale ne pourra également être tenu responsable des dommages indirects (tels par exemple qu’une perte de marché ou perte d’une chance) consécutifs à l’utilisation du site.
                    <br/><br/>
                    Des espaces interactifs (possibilité de poser des questions dans l’espace contact) sont à la disposition des utilisateurs. La Rurale se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la protection des données. Le cas échéant, La Rurale se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de message à caractère haineux, raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie, etc.).
                </p>

                <h4>11.	Relation Client </h4>
                <p>
                    En cas de difficultés, vous pouvez contacter LA RURALE par téléphone au 01 58 85 89 89
                    <br/><br/>
                    Vous pouvez aussi adresser un courriel à contact@La Rurale.fr ou un courrier à
                    <br/><br/>
                    La Rurale – Siège social
                    <br/>
                    Entreprise régie par le Code des assurances
                    <br/>
                    Société anonyme au capital de 960.000 €
                    <br/>
                    Case courrier 0011
                    <br/>
                    20 place de Seine
                    <br/>
                    92086 Paris La Défense Cedex
                    <br/>
                    572 166 437 RCS NANTERRE
                </p>

                <h4>12.	Etendue de la nullité d’une clause </h4>
                <p>Dans l’hypothèse où l’une des dispositions des présentes Conditions Générales d’Utilisation est invalide ou inopposable pour quelque cause que ce soit, les autres dispositions demeureront applicables sans changement, sauf les cas où le contrat ne pourrait subsister sans elle.</p>

                <h4>13.	Données personnelles</h4>
                <p>Vos données personnelles recueillies en tant qu’assuré, adhérant, bénéficiaire, payeur de prime ou affilié, sont utilisées dans le cadre de notre relation contractuelle et commerciale. Elles nous permettent de remplir nos obligations légales. A ce titre, nous vous informons que vos données font l’objet d’un traitement informatique destiné aux finalités suivantes :
                    <br/><br/>
                    <ul>
                        <li>La passation, la gestion et l’exécution des contrats d’assurance</li>
                        <li>L’exécution des dispositions légales, réglementaires et administratives en vigueur</li>
                        <li>La lutte anti-blanchiment</li>
                        <li>Les opérations relatives à la gestion commerciale des clients et à la prospection commerciale</li>
                    </ul>
                    <br/>
                    Vos données sont nécessaires à la conclusion du contrat et du respect des obligations légales. Vos données sont uniquement utilisées dans le cadre des finalités mentionnées ci-dessus de façon pertinente et proportionnée. Elles sont relatives à votre identification, à l’appréciation du risque, à la détermination ou évaluation des préjudices y compris les données de santé. Vos données médicales font l’objet d’un traitement spécifique lié au respect du secret médical.
                    <br/><br/>
                    Dans le cadre de la collecte des données d’infractions, les données sur les circonstances de l’infraction et les données postérieures à la contestation de l’infraction sont traitées.
                    <br/><br/>
                    Pour notre relation commerciale, avec votre consentement express, elles peuvent aussi être utilisées dans un but de prospection pour les produits (assurances, produits bancaires et financiers, services) distribués par le groupe Allianz et ses partenaires. Nous sommes susceptibles d’utiliser des annonceurs et des réseaux publicitaires. Vos données faisant l’objet d’un traitement sont relatives :
                    <br/><br/>
                    <ul>
                        <li>Aux habitudes de vie en lien avec la relation commerciale</li>
                        <li>Au suivi de la relation commerciale</li>
                        <li>A la localisation et la connexion</li>
                        <li>A la sélection des personnes pour réaliser des actions de fidélisation, de prospection, de promotion, ainsi que des données relatives aux contributions des personnes.</li>
                    </ul>
                    <br/>
                    Le recueil de vos données nous permet de mieux vous connaître et d’améliorer nos services en vous proposant des produits ou des services les plus adaptés à vos besoins. Cela nous aidera également à réduire la sinistralité et à lutter contre la fraude afin de préserver la mutualité des assurés.
                    <br/><br/>
                    Nous pouvons être amenés à faire du profilage c’est-à-dire à employer des traitements consistant à utiliser vos données personnelles pour évaluer certains aspects personnels de votre personne.
                    <br/><br/>
                    Enfin, nous sommes susceptibles de vous proposer des enquêtes de satisfaction.
                    <br/><br/>
                    Dans le cadre des souscriptions en ligne, nous avons recours à un processus de décision automatisé de la souscription en fonction des risques à couvrir. Quelle que soit la décision prise, vous pouvez demander des explications à l’adresse indiquée à l’article 11.
                </p>
                <p><b style={{fontSize: '16px'}}>Destinataires des données personnelles</b>
                    <br/><br/>
                    Dans le strict cadre des finalités énoncées, les destinataires de vos données sont les services des entreprises du Groupe Allianz ainsi que votre intermédiaire d’assurance. Elles sont également susceptibles d’être traitées par nos partenaires, sous-traitants, prestataires, réassureurs, et s’il y a lieu les organismes d’assurance ou les organismes sociaux des personnes impliquées ainsi que les personnes intéressées au contrat.
                    <br/><br/>
                    Ces destinataires peuvent éventuellement se situer en dehors de l’Union Européenne. La liste actualisée de ces pays ainsi que les références aux garanties appropriées vous seront communiquées sur simple demande faite à l’adresse indiquée au paragraphe "Vos contacts".
                    <br/><br/>
                    En cas de transfert de vos données personnelles en dehors de l’Europe, La Rurale (Société du Groupe Allianz) met en place les garanties appropriées pour assurer la protection des données personnelles. Si vous souhaitez obtenir des informations sur ces garanties, veuillez adresser votre demande à l’adresse indiquée au paragraphe "Vos contacts".
                </p>
                <p><b style={{fontSize: '16px'}}>Finalité des données collectées </b>
                    <br/><br/>
                    LA RURALE est susceptible de traiter tout ou partie des données :
                    <br/><br/>
                    <ul>
                        <li>Pour permettre la navigation sur le Site et la gestion et la traçabilité des prestations et services commandés par l’utilisateur : données de connexion et d’utilisation du Site, facturation, historique des commandes, etc.</li>
                        <li>Pour prévenir et lutter contre la fraude informatique (spamming, hacking…)</li>
                        <li>Pour améliorer la navigation sur le Site : données de connexion et d’utilisation</li>
                    </ul>
                    <br/>
                    La Rurale ne commercialise pas vos données personnelles qui sont donc uniquement utilisées par nécessité ou à des fins statistiques et d’analyses.
                </p>

                <h4>14.	Droit applicable </h4>
                <p>Les produits et services présentés sur le site sont destinés aux résidents français.
                    <br/><br/>
                    Tout litige en relation avec l’utilisation du site La Rurale est soumis au droit français.
                </p>

            </ModalBody>
            <ModalFooter>
                <Button onClick={closeModal}>Fermer</Button>
            </ModalFooter>
        </Modal>

    )

};
export default ModalCGU