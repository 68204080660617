import React from 'react'
import { Button, Col, Row, Tooltip } from 'reactstrap'
import { formatDate } from '../../utils'


class LigneSinistreDashboard extends React.Component {
	render() {
		const { sinistre, goToSinistre, isOpen, toggle, target } = this.props
		if (sinistre.sinistre.etatSinistre !== 'S' && !((sinistre.sinistre.etatSinistre === 'D' || sinistre.sinistre.etatSinistre === 'I') && sinistre.statutQuittanceIndemnite === 'REG') && !sinistre.quittance.flQuittanceSignee) {
			return (
				<Button onClick={() => goToSinistre(sinistre.declaration)}>
					<Row className="table-item liste-sinistre">
						<Col xs="1" className="text-left">
							{(sinistre.sinistre.etatSinistre === 'E' || sinistre.sinistre.etatSinistre === 'P' || (sinistre.sinistre.etatSinistre === '*' && sinistre.sinistre.typeDeclaration !== 'PO')) ?
								<div>
									<span className="icon icon-expertise c-light" id={target}> </span>
									<Tooltip placement="top" isOpen={isOpen} target={target} toggle={toggle}>
										Dossier en phase d’expertise
									</Tooltip>
								</div> : null}
							{sinistre.sinistre.etatSinistre === 'D' || sinistre.sinistre.etatSinistre === 'I' ?
								<div>
									<span className="icon icon-coins-money c-light" id={target}> </span>
									<Tooltip placement="top" isOpen={isOpen} target={target} toggle={toggle}>
										Dossier en cours de calcul d’indemnité
									</Tooltip>
								</div> : null}
							{sinistre.sinistre.etatSinistre === '' ?
								<div>
									<span className="icon icon-file c-light" id={target}> </span>
									<Tooltip placement="top" isOpen={isOpen} target={target} toggle={toggle}>
										Déclaration « pour information »
									</Tooltip>
								</div> : null}
						</Col>
						<Col xs="10" className="d-flex flex-column align-items-start">
							<div
								style={{ marginBottom: 8, lineHeight: '20px' }}
								className="ft-s-allianz-semi-bold ft-s-16 reference">N° {sinistre.sinistre.numeroSinistre} du {formatDate(sinistre.sinistre.dateSinistre)} - {sinistre.libellePeril}
							</div>
							<div style={{ color: '#5F6368', lineHeight: '16px' }} className="date ft-s-allianz">Déclaré le {formatDate(sinistre.sinistre.dateEnregistrement)}</div>
						</Col>
						<Col xs="1" className="text-right p-0">
							<span className="icon icon-chevron-right c-light"> </span>
						</Col>
					</Row>
				</Button>
			)
		} else return null

	}
}

export default LigneSinistreDashboard