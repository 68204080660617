import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap'

import { Accordion } from '../component/accordion/Accordion'

export default class NavigationMobile extends React.Component {
	constructor(props) {
		super(props)

		this.toggleNavbar = this.toggleNavbar.bind(this)
		this.state = {
			collapsed: true
		}
	}

	toggleNavbar() {
		this.setState({
			collapsed: !this.state.collapsed
		})
	}

	render() {
		const { inProgressLink, navigation } = this.props
		return (
			<div className="wrapper-connexion mobile">
				<div className="bg-image-connexion bannerSize"></div>
				<Navbar color="faded" light>
					<NavbarToggler onClick={this.toggleNavbar} className="mr-2">
						<span className="icon icon-bars"> </span>
					</NavbarToggler>
					<NavbarBrand tag={Link} to="/" className="m-auto navbar-brand"> </NavbarBrand>
					<Link
						to={`/logout`}
					>
						<Button>
							<span className="icon icon-arrow-first"> </span>
						</Button>
					</Link>
					<Collapse isOpen={!this.state.collapsed} navbar>
						<Nav navbar>
							<Accordion>
								{navigation.map((menu) =>
									<Accordion.Item nav inNavbar key={`${menu.titre}`} active={window.location.pathname.indexOf(menu.nom) > -1}>
										<Accordion.Header>
											{menu.titre}
										</Accordion.Header>
										{menu.sousMenus.map((sousMenu) => {
												if (Object.keys(sousMenu).length !== 0) {
													return (
														<Accordion.Body active={window.location.pathname === sousMenu.lien}
														                key={`${menu.titre}/${sousMenu.titre}`} className={(sousMenu.lien && !sousMenu.disabled ? '' : 'inactive')}>
															<Link onClick={this.toggleNavbar} to={sousMenu.lien || inProgressLink}>{sousMenu.titre}</Link>
														</Accordion.Body>
													)
												} else return <div key={`${menu.titre}/${sousMenu.titre}`}></div>
											}
										)}
									</Accordion.Item>
								)}
							</Accordion>
						</Nav>
					</Collapse>
				</Navbar>
				<Container>
					Container
				</Container>
			</div>
		)
	}
}

NavigationMobile.propTypes = {
	baseLocation: PropTypes.string.isRequired,
	informations: PropTypes.object,
	navigation: PropTypes.array.isRequired
}
