import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Button from 'reactstrap/es/Button'
import { Col, Form, FormGroup, Label, Row, Tooltip } from 'reactstrap'
import InputLogin from '../../global/component/input/InputLogin'

const validate = values => {
	const errors = {}
	if(!values.identifiant){
		errors.identifiant = 'Ce champ doit être renseigné'
	}
	if(!values.motDePasse){
		errors.motDePasse = 'Ce champ doit être renseigné'
	}
	return errors
}

class LoginForm extends Component {

	render() {
		const { handleSubmit, showHide, type, pristine, submitting, connexionDisabled } = this.props
		return (<Form onSubmit={handleSubmit}>
				<Row form>
					<Col>
						<FormGroup className="m-0">
							<Label className="ft-s-12" for="identifiant">Identifiant</Label>
							<Field
								marge="mt-0"
								component={InputLogin}
								name="identifiant"
								type="text"
								placeholder="N° client ou email"
								info={""}
							/>
							<span className="icon-info-circle" id="TooltipInfo"> </span>
							<Tooltip
								placement="right"
								isOpen={this.props.tooltipOpen}
								target="TooltipInfo"
								toggle={this.props.toggle}
							>
								Pour vous identifier, indiquez soit le N° Client qui vous a été attribué lors de votre inscription à l’espace client, soit votre adresse e-mail.
							</Tooltip>
						</FormGroup>
					</Col>
				</Row>
				<Row form>
					<Col>
						<FormGroup className={'m-0'}>
							<Label className="ft-s-12" for="motDePasse">Mot de passe</Label>
							<Field
								type={type}
								component={InputLogin}
								name="motDePasse"
								marge='0'
								placeholder="Minimum 10 caractères"
							/>
							<span
								style={{ cursor: 'pointer' }} className="icon-show-password-plain" id="TooltipPassword"
								onClick={showHide}
							> </span>
						</FormGroup>
					</Col>
				</Row>
				<Row form>
					<Col>
						<div className="modaleMdp">
							<div className="link" onClick={this.props.toggleModale}>
								Mot de passe oublié ?
							</div>
						</div>
					</Col>
				</Row>
				<Row form>
					<Col>
						<div className="charte-button">
							<Button id="button-submit-connexion" type="submit" color="light" disabled={pristine || submitting || connexionDisabled} className="btn-block">Je me connecte</Button>
						</div>
						<div className={'invalid-feedback ' + (connexionDisabled && 'd-block')}>Vous avez dépassé le nombre maximal de tentatives de connexion.</div>
					</Col>
				</Row>
			</Form>
		)
	}
}

LoginForm.propTypes = {
	type: PropTypes.string,
	showHide: PropTypes.func,
	toggleModale: PropTypes.func,
	isOpened: PropTypes.bool,
	tooltipOpen: PropTypes.bool,
	toggle: PropTypes.func,
	submitMotdePasseOublieForm: PropTypes.func
}


export default reduxForm({
	form: 'loginForm', // a unique identifier for this form
	validate,
	touchOnBlur: false
})(LoginForm)