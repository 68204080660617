import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Col, Collapse, Row, Table } from 'reactstrap'
import { formatDecimales, formatMillier,formatSpaceAfterColon } from '../../../utils'
import * as assolementSelectors from '../assolement/assolementSelectors'

const MaGarantie = ({ culture, id, donneesAssolementParcelles }) => {

	const [collapse, setCollapse] = React.useState(id === 0)
	const [variete, setVariete] = React.useState('')

	const toggle = () => {
		setCollapse(!collapse)
	}

	const ordrePerils = [
		'001',
		'009',
		'003',
		'004',
		'005'
	]

	React.useEffect(() => {
		donneesAssolementParcelles.forEach((parcelle) => {
			if (parcelle.codeCulture.numeroCulture === culture.codeCulture.numeroCulture) {
				setVariete(parcelle.variete.nom)
			}
		})
	}, [])

	return (
		<div className="push-body garanties">
			<div onClick={toggle} className="table-item d-flex garanties-title">
				<div className="d-flex flex-column align-items-start col-10">
					<Row className="reference">
						<Col className="p-0">
							<h6 style={{ marginBottom: 12 }}
							    className="font-weight-bold">{formatSpaceAfterColon(culture.codeCulture.nom)} {variete ? ' - ' + variete : ''} {culture.variation ? ' - ' + culture.variation : ''}</h6>
						</Col>
					</Row>
					<Row className="w-100">
						<Col lg="auto" className="p-0 header-garantie">
							<div className="ft-s-14 date">Superficie totale :
								<span style={{ color: '#5F6368' }} className="ft-s-allianz-bold"> {formatDecimales(formatMillier(culture.surface), 4)} ha</span>
							</div>
						</Col>
						<Col className="p-0">
							<div className="ft-s-14 motif">Capital assuré :
								<span style={{ color: '#5F6368' }} className="ft-s-allianz-bold"> {formatMillier(Math.round(culture.capital))} €</span>
							</div>
						</Col>
					</Row>
				</div>
				<div className="text-right m-auto col-2">
					<span className={collapse ? 'icon icon-chevron-up c-dark' : 'icon icon-chevron-down c-dark'}> </span>
				</div>
			</div>
			<Collapse isOpen={collapse} id={id}>
				<Table>
					<thead>
					<tr>
						<th>Péril</th>
						<th>Franchise</th>
					</tr>
					</thead>
					<tbody>
					{culture.perils
						.sort((a,b) => ordrePerils.indexOf(a.numPeril) < ordrePerils.indexOf(b.numPeril) ? -1 : 1)
						.map((peril) =>
						<tr key={`${culture.codeCulture.nom}-${culture.variete.nom}-${peril.libellePeril}`}>
							<td className="font-weight-bolder">{peril.libellePeril}</td>
							{peril.typeFranchise === 'SF' &&
							<td>Aucune</td>
							}
							{peril.typeFranchise === 'CE' &&
							<td>{peril.tauxFranchise} % des capitaux assurés pour l'exploitation</td>
							}
							{peril.typeFranchise === 'CP' &&
							<td>{peril.tauxFranchise} % des capitaux assurés pour la parcelle sinistrée</td>
							}
							{peril.typeFranchise === 'CC' &&
							<td>{peril.tauxFranchise} % des capitaux assurés pour la culture sinistrée</td>
							}
							{peril.typeFranchise === 'FP' &&
							<td>{peril.tauxFranchise} % des capitaux assurés pour la fraction de parcelle sinistrée</td>
							}
							{peril.typeFranchise === 'CA' &&
							<td>{peril.tauxFranchise} % des capitaux assurés pour l'appellation sinistrée</td>
							}
						</tr>
					)}
					</tbody>
				</Table>
			</Collapse>
		</div>
	)
}

MaGarantie.propTypes = {
	donneesAssolementParcelles: PropTypes.array
}
const mapStateToProps = (state) => {
	return {
		donneesAssolementParcelles: assolementSelectors.getParcellesAssolementActif(state)
	}
}

export default connect(mapStateToProps)(MaGarantie)