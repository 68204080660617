import {fetchFactory} from "../../../entities/fetchEntities";

export const getAssolement = (isHistorique) =>
    fetchFactory(`/extranet/assolement?isHistorique=${isHistorique}`, {method:'GET'})

export const ajoutParcelle = (commune,lieuDit,culture,surfaceTotale,surfaceSinistree) =>
	fetchFactory('/extranet/parcelle',{
		method:'POST',
		body: JSON.stringify({
			commune,
			lieuDit,
			culture,
			surfaceTotale,
			surfaceSinistree
		})
	})
