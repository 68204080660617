import React from 'react'
import ReactDOM from 'react-dom'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import { addLocaleData } from 'react-intl'
import { intlReducer, Provider } from 'react-intl-redux'
import en from 'react-intl/locale-data/en'
import fr from 'react-intl/locale-data/fr'
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { createLogger } from 'redux-logger'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { reducer as formReducer } from 'redux-form'
import thunk from 'redux-thunk'
import messages from './config/messages'
import reducers from './config/reducers'
import Root from './containers/Root'
import './containers/App.scss'
import { fetchUtilisateur } from './accueil/loginApi'
import { getConfiguration,getLibelles } from './config/metier/configurationActions'
import * as ReactGA from 'react-ga'
import { getCookie } from './global/cookies/cookieUtils'
import {replaceFavicon,replaceTitle} from "./utils";
// Cette méthode permet d'enfermer tout le code à exécuter après les vérifications sur le login

const getThemeStyleSheet = (user) => {
	if (user) {
		if (user.societe === '01') {
			if (window.location.pathname.includes('allianz')) {
				window.location.pathname.replace('allianz', 'larurale')
			}
			replaceFavicon("larurale")
			replaceTitle("larurale")
			return import('./scss/style-rurale.scss')
		} else {
			if (window.location.pathname.includes('allianz')) {
				window.location.pathname.replace('larurale', 'allianz')
			}
			replaceFavicon("allianz")
			replaceTitle("allianz")
			return import('./scss/style-allianz.scss')
		}
	} else {
		if (window.location.pathname.includes('allianz')) {
			replaceFavicon("allianz")
			replaceTitle("allianz")
			return import('./scss/style-allianz.scss')
		} else {
			replaceFavicon("larurale")
			replaceTitle("larurale")
			return import('./scss/style-rurale.scss')
		}
	}
}

const reactInit = (user) => {

	// Initialisation de la partie i18n (react-intl)
	addLocaleData([...fr, ...en])
	const formats = {
		date: {
			datetime: {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric'
			}
		}
	}

	const history = createBrowserHistory()
	if(getCookie('isGoogleAnalyticsAuthorized')) {
		history.listen(location => {
			ReactGA.set({ page: location.pathname });
			ReactGA.pageview(location.pathname);
		});
	}
	let enhancers
	if (process.env.NODE_ENV === 'production') {
		enhancers = [applyMiddleware(thunk, routerMiddleware(history))]
	} else {
		if (window.__REDUX_DEVTOOLS_EXTENSION__) {
			// utilisation de redux dev tools uniquement
			enhancers = [applyMiddleware(thunk, routerMiddleware(history)), window.__REDUX_DEVTOOLS_EXTENSION__()]
		} else {
			// à défaut, utilisation du redux-logger middleware
			const loggerMiddleware = createLogger()
			enhancers = [applyMiddleware(thunk, loggerMiddleware, routerMiddleware(history))]
		}
	}

	// Création du store de redux
	let store = createStore(
		combineReducers({
			...reducers,
			router: connectRouter(history),
			intl: intlReducer,
			form: formReducer
		}), {
			user,
			intl: {
				locale: 'fr',
				messages,
				formats: {
					...formats
				},
				defaultLocale: 'fr',
				defaultFormats: {
					...formats
				}
			}
		},
		compose(...enhancers)
	)
	getConfiguration()(store.dispatch)

	getLibelles()(store.dispatch)
	ReactDOM.render(
		<Provider store={store}>
			<ConnectedRouter history={history}
			                 onUpdate={() => {
				                 ReactGA.set({ page: window.location.pathname + window.location.search })
				                 ReactGA.pageview(window.location.pathname + window.location.search)}}
			                  >
				<Root dispatch={store.dispatch} />
			</ConnectedRouter>
		</Provider>,
		document.getElementById('root')
	)

}

const token = window.localStorage.getItem('tokenFO')
if (token) {
	fetchUtilisateur(token).then(user => {
		if (user) {
			user.token = token
			user.authenticated = true
		}
		getThemeStyleSheet(user)
			.then(() => reactInit(user))
			.catch(() => reactInit(user))
	}).catch((error) => {
		console.log(error)
		getThemeStyleSheet()
			.then(reactInit)
			.catch(reactInit)
	})
} else {
	getThemeStyleSheet()
		.then(reactInit)
		.catch(reactInit)
}


// Webpack Hot Module Replacement API
if (module.hot) {
	module.hot.accept('./containers/Root', () => {
		reactInit()
	})
}

// TODO à voir si on garde ça
// import registerServiceWorker from './registerServiceWorker';
// registerServiceWorker();
