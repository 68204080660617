import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Col, Row } from 'reactstrap'
import { compose } from 'recompose'
import { supprimeZerosContratString } from '../../../utils'
import * as contratSelectors from '../../contrat/contratSelectors'
import * as sinistreActions from '../sinistreActions'
import * as sinistreSelectors from '../sinistreSelectors'
import Acomptes from './Acomptes'
import DeclarationDuSinistre from './DeclarationDuSinistre'
import DecompteDeSinistre from './DecompteDeSinistre'
import Desistement from './Desistement'
import EtatDuSinistre from './EtatDuSinistre'
import Expertise from './Expertise'
import Quittances from './Quittances'

const ConsultationSinistre = ({ sinistreActif, setThematique, setThematiqueFAQ, sinistreDeclaration, sinistreSinistre, sinistrePerils, sinistreLibellePerils, sinistreParcelles, sinistreDocuments, sinistreMissionsEnvoyees, sinistreRetourExpertise, sinistreDecompte, sinistreAcompte, sinistreQuittance, sinistreQuittanceAcompte, getDonneesContrat, setSinistreActif, location, history }) => {
	const handleClick = (e, index) => {
		e.preventDefault()
		setCurrentPage(index)
	}
	const [currentPage, setCurrentPage] = React.useState(0)
	const [childThematique] = React.useState("NO")
	const [childThematiqueFAQ] = React.useState("SI")

	useEffect(() => {
		setThematique(childThematique)
	}, [childThematique])

	useEffect(() => {
		setThematiqueFAQ(childThematiqueFAQ)
	}, [childThematiqueFAQ])

	function redirectToTarget(path) {
		history.push(path)
	}

	function goBackToSinistre() {
		redirectToTarget(`${window.location.pathname.replace('/suivreSinistre', '')}`)
	}

	let docDesistement = null
	if (sinistreActif.length !== 0) {
		sinistreDocuments.forEach((doc, index) => {
			if (doc.etatConstat === '') {
				docDesistement = doc
			}
		})
	}

	return (
		<Col md="12" lg="9">
			<Row className="lienSinistre">
				<Col>
					<div className="p-0 d-flex backLink" onClick={() => {
						goBackToSinistre()
					}}>
						<span className="icon icon-chevron-left chevronRetourSinistre"></span>
						<div className="backLink">Retour vers la liste des sinistres</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col lg="12" className="mb-3">
					<EtatDuSinistre sinistreQuittance={sinistreQuittance} sinistreActif={sinistreActif} sinistreDeclaration={sinistreDeclaration}
					                sinistreSinistre={sinistreSinistre} sinistreLibellePerils={sinistreLibellePerils} />
				</Col>
			</Row>
			<Row>
				<Col lg="12" className="mb-3">
					<DeclarationDuSinistre sinistrePerils={sinistrePerils} sinistreSinistre={sinistreSinistre}
					                       sinistreLibellePerils={sinistreLibellePerils} sinistreDeclaration={sinistreDeclaration}
					                       sinistreParcelles={sinistreParcelles} sinistreActif={sinistreActif}
					                       donneesContrat={getDonneesContrat(supprimeZerosContratString(sinistreDeclaration.contrat))}
					                       currentPage={currentPage} changePages={handleClick} />
				</Col>
			</Row>
			{sinistreDeclaration.typeDeclaration !== 'PO' &&
			<Row>
				<Col lg="12" className="mb-3">
					<Expertise sinistreDocuments={sinistreDocuments} sinistreMissionsEnvoyees={sinistreMissionsEnvoyees}
					           sinistreRetourExpertise={sinistreRetourExpertise} sinistreActif={sinistreActif} />
				</Col>
			</Row>
			}
			{(sinistreDeclaration.typeDeclaration !== 'PO' && ((sinistreSinistre.etatSinistre !== 'S') && (sinistreSinistre.etatSinistre === 'D' || sinistreSinistre.etatSinistre === 'I'))) &&
			<Row>
				<Col lg="12" className="mb-3">
					<DecompteDeSinistre sinistreDecompte={sinistreDecompte} sinistreActif={sinistreActif} />
				</Col>
			</Row>
			}
			{(sinistreDeclaration.typeDeclaration !== 'PO' && (((sinistreSinistre.etatSinistre !== 'S') && (sinistreSinistre.etatSinistre === 'D' || sinistreSinistre.etatSinistre === 'I')) && (sinistreAcompte.length !== 0))) &&
			<Row>
				<Col lg="12" className="mb-3">
					<Acomptes sinistreDecompte={sinistreDecompte} sinistreAcompte={sinistreAcompte} sinistreActif={sinistreActif} />
				</Col>
			</Row>
			}
			{(sinistreDeclaration.typeDeclaration !== 'PO' && (((sinistreSinistre.etatSinistre !== 'S') && (sinistreSinistre.etatSinistre === 'D' || sinistreSinistre.etatSinistre === 'I')) && ((sinistreQuittance.length !== 0) || (sinistreQuittanceAcompte.length !== 0)))) &&
			<Row>
				<Col lg="12" className="mb-3">
					<Quittances sinistreQuittance={sinistreQuittance} sinistreQuittanceAcompte={sinistreQuittanceAcompte}
					            sinistreActif={sinistreActif} />
				</Col>
			</Row>
			}
			{(sinistreDeclaration.typeDeclaration !== 'PO' && (sinistreSinistre.etatSinistre === 'S' && docDesistement !== null)) &&
			<Row>
				<Col lg="12" className="mb-3">
					<Desistement sinistreActif={sinistreActif} docDesistement={docDesistement} />
				</Col>
			</Row>
			}
		</Col>
	)
}

ConsultationSinistre.propTypes = {
	sinistreActif: PropTypes.object,
	sinistreDeclaration: PropTypes.object,
	sinistreSinistre: PropTypes.object,
	sinistrePerils: PropTypes.array,
	sinistreLibellePerils: PropTypes.string,
	sinistreParcelles: PropTypes.array,
	sinistreDocuments: PropTypes.array,
	sinistreMissionsEnvoyees: PropTypes.array,
	sinistreRetourExpertise: PropTypes.array,
	sinistreDecompte: PropTypes.array,
	sinistreAcompte: PropTypes.array,
	sinistreQuittance: PropTypes.array,
	sinistreQuittanceAcompte: PropTypes.array,
	getDonneesContrat: PropTypes.func
}

const mapStateToProps = (state) => ({
	sinistreActif: sinistreSelectors.getDonneesSinistreActif(state),
	sinistreDeclaration: sinistreSelectors.getDonneesSinistreActifDeclaration(state),
	sinistreSinistre: sinistreSelectors.getDonneesSinistreActifSinistre(state),
	sinistrePerils: sinistreSelectors.getDonneesSinistreActifPerils(state),
	sinistreLibellePerils: sinistreSelectors.getDonneesSinistreActifLibellePerils(state),
	sinistreParcelles: sinistreSelectors.getDonneesSinistreActifParcelles(state),
	sinistreDocuments: sinistreSelectors.getDonneesSinistreActifDocuments(state),
	sinistreMissionsEnvoyees: sinistreSelectors.getDonneesSinistreActifMissionsEnvoyees(state),
	sinistreRetourExpertise: sinistreSelectors.getDonneesSinistreActifRetourExpertise(state),
	sinistreDecompte: sinistreSelectors.getDonneesSinistreActifDecompte(state),
	sinistreAcompte: sinistreSelectors.getDonneesSinistreActifAcomptes(state),
	sinistreQuittance: sinistreSelectors.getDonneesSinistreActifQuittance(state),
	sinistreQuittanceAcompte: sinistreSelectors.getDonneesSinistreActifQuittanceAcompte(state),
	getDonneesContrat: contratSelectors.getDonneesContratByContrat(state)
})
const actions = {
	setSinistreActif: sinistreActions.setSinistreActif
}
export default compose(
	connect(mapStateToProps, actions),
	withRouter
)(ConsultationSinistre)