import React from 'react'
import PropTypes from 'prop-types'
import Paper from '../../../global/component/paper/Paper'
import { Col, Row, Button } from 'reactstrap'
import { connect } from 'react-redux'
import * as tarificateurSelectors from '../tarificateur/tarificateurSelectors'
import MaGarantie from './MaGarantie'
import * as selectors from "../contratSelectors";
import * as contratActions from "../contratActions";
import * as messageActions from "../../../global/component/messages/messageActions";
import {openWindowPdf} from "../../../utils";
import EspaceAssureMessages from "../../../config/metier/EspaceAssureMessages";


class MesGaranties extends React.Component {

	downloadContrat(numeroContrat){
		return this.props.downloadContrat(numeroContrat)
			.then((response) => {
				openWindowPdf(response.url, "Contrat", "status=0,height=400,width=600,resizable=1");
			})
			.catch(() => {
				this.props.messagesErreur("Consulter la version intégrale du contrat","Le service de téléchargement de vos documents est actuellement indisponible. Veuillez réessayer ultérieurement.")
			})
	}

	render() {
		const {donneesTarificateurCulture } = this.props
		return (
			<Paper className="mon-contrat-page push-tpl-1">
				<Row className="push-head">
					<Col>
						<Row className="mb-3">
							<Col className="d-flex align-items-center">
								<div className="title">
									Mes garanties
								</div>
							</Col>
							<Col md={4} sm={12} xs={12}>
								<Button onClick={() => this.downloadContrat(this.props.contratActif)} outline color="light" size="xs" className="btn-block">
									Consulter la version intégrale du contrat
								</Button>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="ft-s-14">
									<EspaceAssureMessages id="texte_contrat_garanties"/>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className="push-body m-0 mes-garanties">
					<Col className="content flex-column text-left" xs="12">
						<div className="avantage-content">
							<Row>
								<Col>
									{donneesTarificateurCulture
										.sort((a,b)=> a.codeCulture.numeroCulture - b.codeCulture.numeroCulture)
										.map((culture,index) =>
										<MaGarantie
											key={`${culture.codeCulture.numeroCulture}-${index}`}
											culture={culture}
											id={index}
										/>
									)}
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</Paper>
		)
	}
}

MesGaranties.propTypes = {
	donneesTarificateurCulture: PropTypes.array,
	donneesTarificateur: PropTypes.object,
	contratActif: PropTypes.string,
	messagesErreur: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		donneesTarificateur: tarificateurSelectors.getDonneesTarificateur(state),
		donneesTarificateurCulture: tarificateurSelectors.getDonneesHistoriqueTarificateurCulture(state),
		contratActif: selectors.getContratActif(state)
	}
}

const actions = {
	downloadContrat: contratActions.downloadContrat,
	messagesErreur:messageActions.messagesErreur
}

export default connect(mapStateToProps,actions)(MesGaranties)