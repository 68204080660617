import * as actions from './coordonneesActions'
import * as actionsModification from './modification/modificationActions'

const initialState = {
	//Pour afficher le loader
	isLoading: false,

	numeroClient: '',
	titre: '',
	nom: '',
	prenom: '',
	representantTitre: '',
	representantNom: '',
	representantPrenom: '',
	adresseNumero: '',
	adresseTypeVoie: '',
	adresseVoie: '',
	adresseComplement: '',
	codePostal: '',
	ville: '',
	telephoneFixe: '',
	telephoneMobile: '',
	numeroExploitation: '',
	dateModification: '',
	mail: '',
	dateNaissance: '',
	lieuNaissance: '',
	dateInstallation: '',
	msa: '',
	sirenSiret: '',
	commentaire: '',
	surfaceExploitee: '',
	idJeuneAgriculteur: '',
	consentement: '',
	dateConsentement: '',
	isDeclarationAllowed:'',
	typePm: false
}

const informationsClient = (state = initialState, action) => {
	switch (action.type) {
		case actions.GET_USER_INFO: {
			return {
				...state,
				isLoading: true
			}
		}
		case actions.GET_USER_INFO_SUCCES:
			return {
				...state,
				isLoading: false,
				numeroClient: action.infoUser.numeroClient,
				titre: action.infoUser.titre,
				nom: action.infoUser.nom,
				prenom: action.infoUser.prenom,
				representantTitre: action.infoUser.representantTitre,
				representantNom: action.infoUser.representantNom,
				representantPrenom: action.infoUser.representantPrenom,
				adresseNumero: action.infoUser.adresseNumero,
				adresseTypeVoie: action.infoUser.adresseTypeVoie,
				adresseVoie: action.infoUser.adresseVoie,
				adresseComplement: action.infoUser.adresseComplement,
				codePostal: action.infoUser.codePostal,
				ville: action.infoUser.ville,
				telephoneFixe: action.infoUser.telephoneFixe,
				telephoneMobile: action.infoUser.telephoneMobile,
				numeroExploitation: action.infoUser.numeroExploitation,
				dateModification: action.infoUser.dateModification,
				mail: action.infoUser.mail,
				dateNaissance: action.infoUser.dateNaissance,
				lieuNaissance: action.infoUser.lieuNaissance,
				dateInstallation: action.infoUser.dateInstallation,
				msa: action.infoUser.msa,
				sirenSiret: action.infoUser.sirenSiret,
				commentaire: action.infoUser.commentaire,
				surfaceExploitee: action.infoUser.surfaceExploitee,
				idJeuneAgriculteur: action.infoUser.idJeuneAgriculteur,
				consentement: action.infoUser.consentement,
				dateConsentement: action.infoUser.dateConsentement,
				isDeclarationAllowed: action.isDeclarationSinistreAllowed,
				typePm: action.infoUser.typePm
			};
		case actions.GET_USER_INFO_ERROR:
			return state;
		case actionsModification.SET_MODIF_USER_INFO_SUCCES:
			return {
				...state,
				adresseNumero: action.infoUser.adresseNumero,
				adresseTypeVoie: action.infoUser.adresseTypeVoie,
				adresseVoie: action.infoUser.adresseVoie,
				adresseComplement: action.infoUser.adresseComplement,
				codePostal: action.infoUser.codePostal,
				ville: action.infoUser.ville,
				telephoneFixe: action.infoUser.telephoneFixe,
				telephoneMobile: action.infoUser.telephoneMobile
			};
		case actionsModification.SET_MODIF_INFO_CLIENT_SUCCES:
			return {
				...state,
				consentement: action.infoUser.consentement,
			};
		default:
			return state

	}
}

export default informationsClient