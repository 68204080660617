import { createSelector } from 'reselect'
import { isContratValide } from '../../utils'

const getLocalState = (state) => state.metier.contrat

export const getContratActif = state => getLocalState(state).contratActif

export const getExerciceActif = state => getLocalState(state).exerciceActif

export const getContratsListe = state => getLocalState(state).contratsListe || []

export const getContratsNonResiliesListe = state => getLocalState(state).contratsNonResiliesListe || []

export const getContratsEnCoursOuRemplacesListe = state => getLocalState(state).contratsEnCoursOuRemplacesListe || []

export const getDonneesContratEnCours = (state) => getLocalState(state).infosContrat.infoParContrat[getContratActif(state)] || {}

export const getDonneesFirstContratNonResilie = (state) => getLocalState(state).infosContrat.infoParContrat[getContratsNonResiliesListe(state)[0]] || {}

export const getDonneesAllContrat = (state) => getLocalState(state).infosContrat.infoParContrat || {}

export const getDonneesContratByContrat = (state) => (contrat) => getDonneesAllContrat(state)[contrat] || {}

/** Retourne seulement les contrats en cours pour declarer des sinistres, envoyer des messages */
export const getDonneesContratsNonResilies = createSelector(getContratsListe,getDonneesAllContrat,
	(contrats, donneesContrats) => {
		const listeEC = []
		contrats.forEach((data) => {
			if(donneesContrats[data].etatCode === "EC"){
				listeEC.push(donneesContrats[data])
			}
		})
		return listeEC
	})

/** Retourne seulement les contrats en cours, contencieux et suspendus ou en instance de suspension/resiliation envoyer des messages */
export const getDonneesContratsEnCoursOuRemplaces = createSelector(getContratsListe,getDonneesAllContrat,
	(contrats, donneesContrats) => {
		const listeEC = []
		contrats.forEach((data) => {
			if(isContratValide(donneesContrats[data].etatCode,donneesContrats[data].etatDate)){
				listeEC.push(donneesContrats[data])
			}
		})
		return listeEC
	})


