export const downloadFile = (id, fileName, getFile, isPdf = true) => {
    return getFile
        .then(pdf => {
            if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                isPdf ? window.navigator.msSaveBlob(pdf, `${fileName}.pdf`) : window.navigator.msSaveBlob(pdf, fileName)
            }
            else {
                const url = window.URL.createObjectURL(pdf)
                const a = document.createElement('a')
                a.href = url
                a.download = fileName
                document.body.appendChild(a)
                a.click()
                window.open(url)
                document.body.removeChild(a)
                URL.revokeObjectURL(a.href)
            }
        })
}